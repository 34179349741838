import { getContracts } from '@services/sm-api'

export const FETCH = 'contract/FETCH'
export const SUCCESS = 'contract/SUCCESS'

export function fetch() {
  return {
    type: FETCH,
  }
}

export function success(payload) {
  return {
    type: SUCCESS,
    payload,
  }
}

export function fetchContracts(id) {
  return dispatch => {
    dispatch(fetch())
    return getContracts(id).then(response => dispatch(success(response)))
  }
}
