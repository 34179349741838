import { Form, Text } from 'react-form'
import React, { Component } from 'react'

import LoginModal from '../LoginModal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { openModal } from '@actions/modal'
import { requestLogin } from '@actions/user'
import { InputPassword } from '@shared/CustomField'
import { withRouter } from '../../utils/withRouter'

class LoginForm extends Component {
  _handleLogin(values) {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'compra',
      eventAction: 'click',
      eventLabel: 'logou_4.1',
    })

    values.persitSession = true
    this.props.requestLogin(values).then(response => {
      if (response.message) {
        this.refs.Form.state.errors = {
          email: response.message.type === 'email' ? response.message.error : '',
          password: response.message.type === 'password' ? response.message.error : '',
        }
        this.refs.Form.setAllTouched()

        if (response.message.type === 'blocked') {
          this.props.openModal(<LoginModal active="login" blocked embedded />)
        }
      } else {
        this.props.navigate('/steps/atualizacao')
      }
    })
  }

  render() {
    let { title, swapTo } = this.props

    // sessionReducer
    return (
      <div className="login">
        <h3 className="login__title">Entrar</h3>
        <h4 className="login__subtitle">{title}</h4>
        <Form
          ref="Form"
          swapTo={swapTo}
          onSubmit={values => {
            this._handleLogin(values)
          }}
          validate={({ email, password }) => {
            return {
              email: !email || email === '' ? 'Por favor, preencha o seu email' : undefined,
              password: !password || password === '' ? 'Por favor, preencha a sua senha' : undefined,
            }
          }}
        >
          {({ submitForm, swapTo }) => {
            return (
              <form className="login__form" onSubmit={submitForm}>
                <label className="field">
                  <span className="field__label">Digite seu email</span>
                  <Text id="identification-email-input" className="field__text" field="email" autoComplete="off" />
                </label>
                <label className="field">
                  <span className="field__label">Digite sua senha</span>
                  <InputPassword id="identification-password-input" placeholder="" field="password" />
                </label>
                <div className="login__forgot">
                  <a href="#" onClick={() => this.props.openModal(<LoginModal active="forgot" embedded />)}>
                    Esqueci minha senha
                  </a>
                </div>
                <div className="field login__buttons">
                  <button
                    className="login__button button__normal--cancel"
                    type="button"
                    onClick={() => {
                      swapTo(0)
                    }}
                  >
                    Cancelar
                  </button>
                  <button id="identification-login-button" className="login__button button__normal" type="submit">
                    Entrar
                  </button>
                </div>
              </form>
            )
          }}
        </Form>
      </div>
    )
  }
}

LoginForm.propTypes = {
  title: PropTypes.string,
}

const mapStateToProps = state => ({
  sessionReducer: state.sessionReducer,
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  requestLogin: (user, path) => dispatch(requestLogin(user, path)),
  openModal: component => dispatch(openModal(component)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm))

