import { FETCH_SUMMARY } from '@actions/summary'

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_SUMMARY:
      return {
        ...action.payload.orders,
      }
    default:
      return state
  }
}
