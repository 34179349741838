import React from 'react'
import { connect } from 'react-redux'
import _ from 'underscore'

const Authorization = ({ permissions, children, sessionReducer }) => {
  let hasPermission = false
  let userPermissions = sessionReducer.user.permissions

  permissions.map(permission => {
    if (userPermissions.indexOf(permission) !== -1) {
      hasPermission = true
    }
    return permissions
  })

  if (hasPermission) {
    return children
  } else {
    return (
      <div className="authorization">
        <br />
        <h1>Acesso restrito</h1>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sessionReducer: state.sessionReducer,
})

export default connect(mapStateToProps)(Authorization)
