import React, { Component } from 'react'
import _ from 'underscore'
import { connect } from 'react-redux'
import { Outlet } from 'react-router-dom'

class Voucher extends Component {
  shouldComponentUpdate(nextProps) {
    if (!_.isEqual(this.props.params, nextProps.params)) {
      return true
    }

    return false
  }
  render() {
    return (
      <div className="adminvoucher">
        <Outlet />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  params: props.params,
})

export default connect(mapStateToProps, null)(Voucher)

