import {
  FAILURE_USER,
  REGISTER_USER,
  SUCCEED_USER,
  SUCCEED_UPDATE,
  USER_ALREADY_EXISTS,
  DESTROY,
  FAILURE_UPDATE,
  destroy,
} from '@actions/user'
import { REHYDRATE } from 'redux-persist/constants'

export default (state = { message: null, token: null }, action) => {
  switch (action.type) {
    case FAILURE_USER:
      return { ...state, message: action.message }

    case REGISTER_USER:
      return { ...state, ...action.user }

    case SUCCEED_USER:
      return { ...state, ...action.payload, message: null }

    case SUCCEED_UPDATE:
      return { ...state, message: 'Dados atualizados' }

    case FAILURE_UPDATE:
      return { ...state, message: 'Erro ao atualizar dados' }

    case USER_ALREADY_EXISTS:
      return { ...state, error: action.error }

    case DESTROY:
      return { ...state, token: '' }

    case REHYDRATE:
      const session = action.payload
      let token = ''
      if (typeof session.sessionReducer !== 'undefined') {
        if (session.sessionReducer.user.persitSession) {
          token = session.user.token
        } else {
          destroy()
        }
      }
      return { ...state, token, message: null }

    default:
      return state
  }
}
