import { Default, SM } from '@components/SummaryContent'
import React, { Component } from 'react'

import FilterDate from '@components/FilterDate'
import FilterText from '@components/FilterText'
import SelectSchoolInput from '@components/SelectSchoolInput'
import _ from 'underscore'
import { connect } from 'react-redux'
import { fetchSummary } from '@actions/summary'
import { fetchSchools } from '@actions/school'
import { getCampaigns } from '@components/ReportFilter/options'
import lodash from 'lodash'

class Summary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      schools: [],
      school_id: null,
      date_start: null,
      date_end: null,
      filterValue: '',
    }
    this._onChangeDate = this._onChangeDate.bind(this)
    this._onChangeSchool = this._onChangeSchool.bind(this)
    this._updateFilterValue = this._updateFilterValue.bind(this)
    
  }

  
  _getActiveCampaign() {
    return getCampaigns().then(campaigns => lodash.find(campaigns, { active: 1 }))
  }

  _onChangeDate(date) {
    this.setState(
      {
        ...date,
      },
      () => {
        let data = {
          school_id: this.state.school_id !== null ? this.state.school_id : this.props.user.school_id,
          date_start: this.state.date_start,
          date_end: this.state.date_end,
        }

        this.props.fetchSummary(this.props.user.id, data)
      },
    )
  }

  _lazyLoadData = _.debounce(school => {
    const selectedSchool = this.props.schools.find(item => item.value == school.school_id)

    this.setState({
      filterValue: selectedSchool?.label ?? '',
    })
    this._onChangeSchool(school.school_id)
  }, 1000)

  _handleSicSearch(school) {
    this._lazyLoadData(school)
    this.setState({
      school_id: school.school_id,
    })
  }

  _onChangeSchool(schoolId) {
    this.setState(
      {
        school_id: schoolId,
      },
      () => {
        let data = {
          school_id: this.state.school_id,
          date_start: this.state.date_start,
          date_end: this.state.date_end,
        }

        this.props.fetchSummary(this.props.user.id, data)
      },
    )
  }

  _updateFilterValue(value) {
    this.setState({
      filterValue: value,
    })
  }

  componentDidMount() {

    _.isEmpty(this.props.schools) && this.props.fetchSchools()

    this._getActiveCampaign().then(() => this.props.fetchSummary(this.props.user.id))

  }


  renderSummary(role_id, summary) {
    if (role_id === 1) {
      
      return <Default summary={summary} />
    } else {

      const schools = _.isEmpty(this.props.user.schools) ? this.props.schools.filter( school => school.value === this.props.user.school_id) : 
    
      this.props.schools.filter(school => [this.props.user.school_id, ...this.props.user.schools].includes(school.value))

      return (
        <SM summary={summary}>
          <div className="summary__filter-wrapper">
            <SelectSchoolInput
              value={this.state.filterValue}
              setInputValue={this._updateFilterValue}
              setState={this._onChangeSchool}
              schools={role_id === 3 ? this.props.schools : schools}
            />
            <FilterDate onChange={this._onChangeDate} />
            <div className="summary__school-id-filter">
              <FilterText
                value={this.state.school_id ?? ''}
                field="school_id"
                label="Pesquisar código SIC da escola"
                customClass="summary__school-filter"
                onChange={e => this._handleSicSearch(e)}
              />
              {Object.getOwnPropertyNames(summary).length === 0 && (
                <span className="summary__error">Escola não encontrada.</span>
              )}
            </div>
          </div>
        </SM>
      )
    }
  }
  render() {
    const { role, summary } = this.props
    return this.renderSummary(role.id, summary)
  }
}

const mapStateToProps = state => ({
  user: state.sessionReducer.user,
  role: _.first(state.sessionReducer.user.roles),
  summary: state.summary,
  schools: state.school.schools,
})

const mapDispatchToProps = dispatch => ({
  fetchSummary: (userId, data) => dispatch(fetchSummary(userId, data)),
  fetchSchools: () => dispatch(fetchSchools()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Summary)

