import { getVouchersInactivationAPI, updateVouchersInactivationAPI } from '@services/sm-api'

export const SET_VOUCHERS_INACTIVATION = 'vouchersInactivation/SET_VOUCHERS_INACTIVATION'
export const FAILURE_VOUCHERS_INACTIVATION = 'vouchersInactivation/FAILURE_VOUCHERS_INACTIVATION'
export const RESET_VOUCHERS_INACTIVATION = 'vouchersInactivation/RESET_VOUCHERS_INACTIVATION'

export function setVouchersInactivation(payload) {
  return {
    type: SET_VOUCHERS_INACTIVATION,
    payload,
  }
}

export function failureVouchersInactivation(payload) {
  return {
    type: FAILURE_VOUCHERS_INACTIVATION,
    payload,
  }
}

export function resetVouchersInactivation() {
  return {
    type: RESET_VOUCHERS_INACTIVATION,
  }
}

export function getVouchersInactivation() {
  return dispatch =>
    getVouchersInactivationAPI()
      .then(response => {
        dispatch(setVouchersInactivation(response))
      })
      .catch(error => {
        dispatch(failureVouchersInactivation(error))
      })
}

export function updateVouchersInactivation(data) {
  return dispatch =>
    updateVouchersInactivationAPI(data)
      .then(response => {
        return response
      })
      .catch(error => {
        dispatch(failureVouchersInactivation(error))
        return error
      })
}
