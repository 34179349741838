import React, { Component } from 'react'
import questions from './questions'
import arrow from './images/arrow.png'

export default class FaqList extends Component {
  state = {
    activeQuestion: {
      items: [],
    },
    activeItem: null,
  }

  _getCurrentCategory(category, index) {
    let activeQuestion = questions.find(value => value.category === category)
    this.setState({
      activeQuestion,
      activeItem: index,
    })
  }

  render() {
    let { title } = this.props
    return (
      <div className="faqlist">
        <h3 className="faqlist__title">{title}</h3>
        <ul className="faqlist__category">
          <li className="faqlist__category__item faqlist__category__item--title">Categorias</li>
          {questions.map((question, index) => {
            return (
              <li
                onClick={() => {
                  this._getCurrentCategory(question.category, index)
                }}
                key={index}
                title={question.category}
                className={`faqlist__category__item ${this.state.activeItem === index ? 'active' : ''}`}
              >
                <div dangerouslySetInnerHTML={{ __html: question.category }}></div>
              </li>
            )
          })}
        </ul>
        <ul className="faqlist__questions">
          {this.state.activeQuestion.items.map((item, index) => {
            return (
              <li
                onClick={() => {
                  this.props.getQuestion(item.question, item.answer)
                }}
                key={index}
                className="faqlist__questions__item"
              >
                <div dangerouslySetInnerHTML={{ __html: item.question }}></div>{' '}
                <img className="faqlist__questions__icon" src={arrow} alt="Ver resposta" />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}
