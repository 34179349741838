import aparencia from './images/aparencia.png'
import atendimento from './images/atendimento.png'
import buy from './images/pedidos.png'
import contrato from './images/contrato.png'
import dashboard from './images/dashboard.png'
import discounts from './images/discounts.png'
import notification from './images/notification.png'
import relatorio from './images/relatorio.png'

export default [
  {
    name: 'Dashboard',
    icon: dashboard,
    url: 'dashboard',
    items: [],
    permissions: ['access_financial_dashboard'],
  },
  {
    name: 'Pedidos',
    url: 'pedidos',
    icon: buy,
    items: [],
    permissions: ['access_order'],
  },
  {
    name: 'Sistema',
    url: 'sistema',
    icon: relatorio,
    notClickable: true,
    permissions: ['access_system'],
    items: [
      {
        name: 'Fila',
        url: 'fila',
        permissions: ['access_system_queues'],
      },
      {
        name: 'Logs',
        url: 'logs',
        permissions: ['access_system_logs'],
      },
      {
        name: 'Variáveis',
        url: 'variables',
        permissions: ['manage_system_variables'],
      },
      {
        name: 'Inativação de vouchers',
        url: 'inativacao-vouchers',
        permissions: ['manage_vouchers_inactivation'],
      },
    ],
  },
  {
    name: 'Relatórios',
    url: 'relatorios',
    icon: relatorio,
    notClickable: true,
    permissions: [
      'access_order_report',
      'access_log_error',
      'access_school_report',
      'access_user_report',
      'access_product_report',
      'access_voucher_report',
      'access_customer_report',
    ],
    items: [
      {
        name: 'Pedidos',
        url: 'pedidos',
        permissions: ['access_order_report'],
      },
      {
        name: 'Log de erros',
        url: 'log-de-erros',
        permissions: ['access_log_error'],
      },
      {
        name: 'Escola',
        url: 'escolas',
        permissions: ['access_school_report'],
      },
      {
        name: 'Usuários',
        url: 'usuarios',
        permissions: ['access_user_report'],
      },
      {
        name: 'Vouchers',
        url: 'vouchers',
        permissions: ['access_voucher_report'],
      },
      {
        name: 'Clientes',
        url: 'customer',
        permissions: ['access_customer_report'],
      },
    ],
  },
  {
    name: 'Atendimento',
    url: 'atendimento',
    icon: atendimento,
    items: [],
    permissions: ['open_case'],
  },
  {
    name: 'Aparência',
    url: 'aparencia',
    icon: aparencia,
    items: [],
    permissions: ['theme_aspect_change'],
  },
  {
    name: 'Contrato',
    url: 'contratos',
    icon: contrato,
    items: [],
    permissions: ['access_contract'],
  },
  {
    name: 'Desconto Professor',
    url: 'special-discounts',
    icon: discounts,
    items: [],
    permissions: ['access_special_discounts'],
  },
  {
    name: 'Meios de pagamento',
    url: 'school-payments',
    icon: aparencia,
    items: [],
    permissions: ['access_school_payment_options', 'manage_school_payment_options'],
  },
  {
    name: 'Notificações',
    url: 'notifications',
    icon: notification,
    items: [],
    permissions: ['access_notifications'],
  },
]
