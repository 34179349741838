import React from 'react'
import approved from './images/approved.svg'
import cancelled from './images/cancelled.svg'
import created from './images/created.svg'
import shipping from './images/shipping.svg'
import shippingCheck from './images/shippingCheck.svg'
import preparationOrder from './images/preparationOrder.svg'
import orderReady from './images/orderReady.svg'
import orderDelivered from './images/orderDelivered.svg'
import moment from 'moment'

const descriptions = {
  NEW: { image: created, description: 'Pedido realizado' },
  PW: { image: created, description: 'Aguardando pagamento' },
  AP: { image: approved, description: 'Pagamento confirmado' },
  PAV: { image: approved, description: 'Pagamento após vencimento' },
  C: { image: cancelled, description: 'Pedido cancelado' },
  CM: { image: cancelled, description: 'Pedido cancelado' },
  PP: { image: preparationOrder, description: 'Pedido em preparação' },
  PF: { image: orderReady, description: 'Pedido finalizado, aguardando expedição' },
  PET: { image: shippingCheck, description: 'Pedido em trânsito' },
  PT: { image: shipping, description: 'Pedido entregue na transportadora' },
  PE: { image: orderDelivered, description: 'Pedido entregue' },
}

const HistoryStep = ({ status, date }) => {
  return (
    <li>
      <div className="item">
        <div className="circle">
          <img src={descriptions[status].image} width="50" height="50" alt="" />
        </div>
        <p className="status">{descriptions[status].description}</p>
        <p className="date">{moment(date).format('DD/MM/YYYY')}</p>
      </div>
    </li>
  )
}

const OrderTimeline = ({ history }) => {
  return (
    <div className="container">
      <ul className={`timeline ${history.length > 1 ? 'between' : 'center'}`}>
        {history.map(item => {
          return <HistoryStep key={item.status} status={item.status} date={item.changed} />
        })}
      </ul>
      {history.length > 1 && <hr className="line" />}
    </div>
  )
}

export default OrderTimeline

