import React, { Component } from 'react'
import { closeModal } from '@actions/modal'
import { connect } from 'react-redux'

class Modal extends Component {
  componentDidMount() {
    document.addEventListener('keydown', event => {
      if (event.keyCode === 27 && this.props.modal.show) {
        this.props.closeModal()
      }
    })
  }
  render() {
    const {
      modal,
      modal: { component, showOverlay },
    } = this.props

    return (
      <div className="modal" style={{ display: modal.show ? 'block' : 'none' }}>
        <div className="modal__content" style={{ display: modal.show ? 'block' : 'none' }}>
          {component && component.type ? component : null}
        </div>
        {showOverlay && <div className="modal__overlay" onClick={() => this.props.closeModal()}></div>}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  modal: state.modal,
})

export default connect(mapStateToProps, { closeModal })(Modal)
