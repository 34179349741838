import React, { Component } from 'react'

import CustomDateInput from './CustomDateInput'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import moment from 'moment'

export default class FilterDate extends Component {
  state = {
    startDate: moment(),
    endDate: moment(),
    rawStart: null,
    rawEnd: null,
  }

  handleChangeStart(date) {
    this.setState(
      {
        startDate: date,
      },
      () => this.props.onChange({ date_start: this.state.startDate.format('YYYY-MM-DD') }),
    )
  }

  handleChangeEnd(date) {
    this.setState(
      {
        endDate: date,
      },
      () => this.props.onChange({ date_end: this.state.endDate.format('YYYY-MM-DD') }),
    )
  }

  UNSAFE_componentWillMount() {
    moment.locale('pt-br')
    this.setState({
      rawStart: this.props.startDate,
      rawEnd: this.props.endDate,
      startDate: this.props.startDate ? moment(this.props.startDate) : null,
      endDate: this.props.endDate ? moment(this.props.endDate) : null,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.rawStart !== nextProps.startDate) {
      let start = nextProps.startDate === null ? nextProps.startDate : moment(nextProps.startDate)
      this.setState({ startDate: start, rawStart: nextProps.startDate })
    }

    if (this.state.rawEnd !== nextProps.endDate) {
      let end = nextProps.endDate === null ? nextProps.endDate : moment(nextProps.endDate)
      this.setState({ endDate: end, rawEnd: nextProps.endDate })
    }
  }

  render() {
    return (
      <div className="filterdate">
        <div className="filterdate__field">
          <DatePicker
            customInput={<CustomDateInput label="de" />}
            dateFormat="DD/MM/YYYY"
            selected={this.state.startDate}
            selectsStart
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={date => this.handleChangeStart(date)}
            testProp={'from'}
          />
        </div>
        <div className="filterdate__field">
          <DatePicker
            customInput={<CustomDateInput label="até" />}
            dateFormat="DD/MM/YYYY"
            selected={this.state.endDate}
            selectsEnd
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={date => this.handleChangeEnd(date)}
            testProp={'to'}
          />
        </div>
        {this.props.children}
      </div>
    )
  }
}

FilterDate.propTypes = {
  onChange: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
}
