import React, { Component } from 'react'

export default class FilterSelect extends Component {
  state = {
    selectValue: null,
  }

  _handleOnChange(event) {
    this.setState({
      selectValue: event.target.value,
    })
    let filter = { [this.props.field]: event.target.value }
    this.props.onChange(filter)
  }

  _selectDefault(selected) {
    if (!!selected && this.state.selectValue === null) {
      this.setState(
        {
          selectValue: selected.value,
        },
        () => {
          let filter = { [this.props.field]: this.state.selectValue }
          this.props.onChange(filter)
        },
      )
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selected } = nextProps
    this._selectDefault(selected)
  }

  UNSAFE_componentWillMount() {
    this._selectDefault(this.props.selected)
  }

  render() {
    let { options, label, showEmptyOption = true } = this.props
    return (
      <div className="filterselect">
        <div className="filterselect__field">
          <label className="filterselect__label">{label}:</label>
          <div className="filterselect__inputwrapper">
            <select
              value={this.state.selectValue || ''}
              onChange={event => this._handleOnChange(event)}
              className="filterselect__select test-filterselect"
            >
              {showEmptyOption && <option value="">Selecione</option>}
              {options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    )
  }
}
