import { OPEN_MODAL, CLOSE_MODAL, SHOW_OVERLAY } from '@actions/modal.js'
import { REHYDRATE } from 'redux-persist/constants'

export default (state = { show: false, showOverlay: true }, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, component: action.component, show: action.show }

    case CLOSE_MODAL:
      return { ...state, component: null, show: action.show }

    case SHOW_OVERLAY:
      return { ...state, showOverlay: action.showOverlay }

    case REHYDRATE:
      const incoming = action.payload.modal
      return { ...state, ...incoming, show: false }

    default:
      return state
  }
}
