import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader'
import { getActiveStudent, selectProduct } from '@actions/checkout'

import { Modal } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import _ from 'lodash'
import { activateVoucherForm } from '@actions/voucherForm'
import { connect } from 'react-redux'
import iconInfo from './images/info.svg'
import { listVariables } from '@services/sm-api'
import moment from 'moment'
import { updateActiveVoucher } from '@actions/activeVoucher'

import { closeModal, openModal } from '@actions/modal.js'
import KnowTheProject from '../KnowTheProject'
import EducationalSolutionsProducts from './EducationalSolutionsProducts'

const DIDACT_ID = 1
const EDUCA_ID = 2
const LITERARY_ID = 3
const SERVICE_ID = 7
const DIGITAL_ID = 8
const BILINGUE_ID = 9

const convertMoney = value => value.toFixed(2).replace('.', ',')

const Tip = () => {
  return (
    <div className="estimate__helpicon">
      <img src={iconInfo} width="20" height="20" alt="" />
      <div className="estimate__tooltip">
        <p className="estimate__text">
          A SM Educação possui um contrato firmado com cada escola, no qual há datas de corte para que os livros possam
          ser enviados em datas específicas, por lotes.
        </p>
      </div>
    </div>
  )
}

class SelectProducts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      alertModalOpen: false,
      removeBookModalOpen: false,
      alertAlreadyShown: false,
      business_lines: [],
      voucher_business_lines: [],
      pack_educamos_business_lines: [],
      removed_books: [],
      isSpecialBilletCutDateEnabled: false,
      isSpecialCardCutDateEnabled: false,
      educa_delivery_type: null,
    }
  }

  _handleClick(student, product, event) {
    if (!event.target.checked) {
      this.setState({
        removeBookModalOpen: true,
        removed_books: [...this.state.removed_books, product],
      })
    } else {
      this.setState(prevState => ({
        removed_books: prevState.removed_books.filter(item => item.id !== product.id),
      }))
    }
    this.props.selectProduct(student, product, event.target.checked)
  }

  _showAlertModal() {
    if (this.state.voucher_business_lines.length && !this.state.alertAlreadyShown) {
      this.setState({
        alertModalOpen: true,
        alertAlreadyShown: true,
      })
    }
  }

  _showBusinessLinesMessage(business_lines) {
    return this.state.voucher_business_lines.indexOf(business_lines) !== -1
  }

  _businessLineHasPackEducamos(business_line_id) {
    return this.state.pack_educamos_business_lines.includes(business_line_id)
  }

  hasCutDate() {
    const voucher = _.last(this.props.checkout)
    const lastCutDate = _.last(voucher.contract_cut_dates)
    return moment().isBefore(moment(lastCutDate))
  }

  setDeliveryType() {
    const voucher = _.last(this.props.checkout)

    const _deliveryType = () => {
      let ensino = voucher.serie_id.substring(1).toLowerCase()
      switch (ensino) {
        case 'em':
          return voucher.ensino_em
        case 'f1':
          return voucher.ensino_ef1
        case 'f2':
          return voucher.ensino_ef2
        case 'ei':
          return voucher.ensino_ei
        case 'maternal':
          return voucher.ensino_ei
        default:
          return null
      }
    }

    this.setState({
      educa_delivery_type: _deliveryType(),
    })
  }

  _onlyBusinessLineDigitalOrService() {
    for (let line of this.state.voucher_business_lines) {
      if (line === DIDACT_ID || line === EDUCA_ID || line === LITERARY_ID || line === BILINGUE_ID) {
        return false
      }
    }
    return true
  }

  _onlyBusinessLineService() {
    for (let line of this.state.voucher_business_lines) {
      if (
        line === DIDACT_ID ||
        line === EDUCA_ID ||
        line === LITERARY_ID ||
        line === DIGITAL_ID ||
        line === BILINGUE_ID
      ) {
        return false
      }
    }
    return true
  }

  _educaDeliveryType() {
    switch (`${this.state.educa_delivery_type}`) {
      case '2':
        return 'bimestralmente'
      case '3':
        return 'trimestralmente'
      case '5':
        return 'semestralmente'
      case '6':
        return 'anualmente'
      case '7':
        return 'imediatamente'
      default:
        return 'bimestralmente/semestralmente'
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.student.name !== this.props.student.name) {
      this.props.openLoader()
      setTimeout(() => {
        this.props.closeLoader()
      }, 1000)
    }

    if (nextProps.student.active_products !== this.props.student.active_products) {
      const { student } = nextProps

      let business_lines = []

      let did_products = []

      let lit_products = []

      let dig_products = []

      let edu_products = []

      let ser_products = []

      let bil_products = []

      let voucher_business_lines = []
      let pack_educamos_business_lines = []

      for (let index in student.products) {
        switch (student.products[index].business_line_id) {
          case DIDACT_ID:
            did_products.push(student.products[index])
            break
          case EDUCA_ID:
            edu_products.push(student.products[index])
            break
          case LITERARY_ID:
            lit_products.push(student.products[index])
            break
          case DIGITAL_ID:
            dig_products.push(student.products[index])
            break
          case SERVICE_ID:
            ser_products.push(student.products[index])
            break
          case BILINGUE_ID:
            bil_products.push(student.products[index])
            break
          default:
            break
        }

        if (Boolean(student.products[index].pack_educamos)) {
          pack_educamos_business_lines.push(student.products[index].business_line_id)
        }
      }
      if (did_products.length) {
        business_lines.push({
          title: 'Livros Didáticos',
          products: did_products,
          prefix: 'smd',
        })
        voucher_business_lines.push(DIDACT_ID)
      }
      if (lit_products.length) {
        business_lines.push({
          title: 'Livros de Literatura',
          products: lit_products,
          prefix: 'sml',
        })
        voucher_business_lines.push(LITERARY_ID)
      }
      if (edu_products.length) {
        business_lines.push({
          title: 'Soluções Educacionais',
          products: edu_products,
          prefix: 'edu',
        })
        voucher_business_lines.push(EDUCA_ID)
      }
      if (dig_products.length) {
        business_lines.push({
          title: 'Livros Digitais',
          products: dig_products,
          prefix: 'dig',
        })
        voucher_business_lines.push(DIGITAL_ID)
      }
      if (ser_products.length) {
        business_lines.push({
          title: 'Serviços',
          products: ser_products,
          prefix: 'ser',
        })
        voucher_business_lines.push(SERVICE_ID)
      }
      if (bil_products.length) {
        business_lines.push({
          title: 'Bilíngue',
          products: bil_products,
          prefix: 'bil',
        })
        voucher_business_lines.push(BILINGUE_ID)
      }

      this.setState({
        business_lines,
        voucher_business_lines,
        pack_educamos_business_lines,
      })
    }
  }

  componentDidMount() {
    const { student } = this.props

    let business_lines = []

    let did_products = []

    let lit_products = []

    let dig_products = []

    let edu_products = []

    let ser_products = []

    let bil_products = []

    let voucher_business_lines = []
    let pack_educamos_business_lines = []

    for (let index in student.products) {
      switch (student.products[index].business_line_id) {
        case DIDACT_ID:
          did_products.push(student.products[index])
          break
        case EDUCA_ID:
          edu_products.push(student.products[index])
          break
        case LITERARY_ID:
          lit_products.push(student.products[index])
          break
        case DIGITAL_ID:
          dig_products.push(student.products[index])
          break
        case SERVICE_ID:
          ser_products.push(student.products[index])
          break
        case BILINGUE_ID:
          bil_products.push(student.products[index])
          break
        default:
          break
      }

      if (Boolean(student.products[index].pack_educamos)) {
        pack_educamos_business_lines.push(student.products[index].business_line_id)
      }
    }
    if (did_products.length) {
      business_lines.push({
        title: 'Livros Didáticos',
        products: did_products,
        prefix: 'smd',
      })
      voucher_business_lines.push(DIDACT_ID)
    }
    if (lit_products.length) {
      business_lines.push({
        title: 'Livros de Literatura',
        products: lit_products,
        prefix: 'sml',
      })
      voucher_business_lines.push(LITERARY_ID)
    }
    if (edu_products.length) {
      business_lines.push({
        title: 'Soluções Educacionais',
        products: edu_products,
        prefix: 'edu',
      })
      voucher_business_lines.push(EDUCA_ID)
    }
    if (dig_products.length) {
      business_lines.push({
        title: 'Livros Digitais',
        products: dig_products,
        prefix: 'dig',
      })
      voucher_business_lines.push(DIGITAL_ID)
    }
    if (ser_products.length) {
      business_lines.push({
        title: 'Serviços',
        products: ser_products,
        prefix: 'ser',
      })
      voucher_business_lines.push(SERVICE_ID)
    }
    if (bil_products.length) {
      business_lines.push({
        title: 'Bilíngue',
        products: bil_products,
        prefix: 'bil',
      })
      voucher_business_lines.push(BILINGUE_ID)
    }

    this.setDeliveryType()

    this.setState(
      {
        business_lines,
        voucher_business_lines,
        pack_educamos_business_lines,
      },
      () => {
        this._showAlertModal()
      },
    )

    listVariables().then(data => {
      const isSpecialBilletCutDateEnabled = _.get(_.find(data, { key: 'SPECIAL_BILLET_CUT_DATE' }), 'value', false)
      const isSpecialCardCutDateEnabled = _.get(_.find(data, { key: 'SPECIAL_CARD_CUT_DATE' }), 'value', false)

      this.setState({
        isSpecialBilletCutDateEnabled: Boolean(Number(isSpecialBilletCutDateEnabled)),
        isSpecialCardCutDateEnabled: Boolean(Number(isSpecialCardCutDateEnabled)),
      })
    })
  }

  _handleKnowTheProject(openModal, closeModal) {
    openModal(<KnowTheProject closeModal={closeModal} />)
  }

  get deliveryEstimate() {
    const voucher = _.last(this.props.checkout)
    const nextCutDate = voucher.contract_cut_dates.find(date => moment(date).isAfter(moment()))
    const deliveryDate = moment(nextCutDate).add(20, 'days')
    return deliveryDate.format('DD/MM/YYYY')
  }

  render() {
    const { student, specialDiscount, checkout, openModal, closeModal } = this.props
    const has_special_discount = !_.isEmpty(specialDiscount) && !_.isEmpty(specialDiscount.special_discounts_document)
    const first_letter_teacher_voucher = 'p'
    const has_special_discount_teacher = student.voucher.toLowerCase().startsWith(first_letter_teacher_voucher)
    const first = checkout ? _.first(checkout) : null
    const school_is_b2c = !!first ? Boolean(first.school_is_b2c) : false
    const immediate_delivery = '7'
    const is_immediate = this.state.educa_delivery_type == immediate_delivery

    const DeliveryTime = () => (
      <p>
        <span className="text-red">
          {school_is_b2c ? 'Estimativa de entrega: ' : 'Estimativa de entrega na escola: '}
        </span>
        {is_immediate ? (
          <span>
            Em 48 horas ou de acordo com orientação da escola.
            <span className="text-red">* </span>
          </span>
        ) : school_is_b2c || !this.hasCutDate() ? (
          <span>
            Em até 20 dias úteis após a confirmação do pagamento
            <span className="text-red">* </span>
          </span>
        ) : (
          <span>
            {this.deliveryEstimate}
            <span className="text-red">* </span>
            <Tip />
          </span>
        )}
      </p>
    )

    return (
      <div>
        <div className="select-book">
          <div className="select-book__header">
            <h3 className="select-book__title">Escolha os produtos</h3>
            <h4 className="select-book__subtitle">
              Estes são os produtos utilizados no <span>{student.study_year}</span> do {student.study_step} no{' '}
              <span>{student.school_name}</span>
            </h4>
          </div>
          <div>
            {this.state.business_lines.map((line, index) => (
              <div key={`${index}-line-container`} className="select-book__container">
                <h4>
                  {line.title}
                  {line.prefix === 'bil' ? (
                    <span className="btn_know_the_project">
                      <button onClick={() => this._handleKnowTheProject(openModal, closeModal)}>
                        Conheça o projeto
                      </button>
                    </span>
                  ) : null}
                </h4>
                {line.prefix === 'edu' ? (
                  <EducationalSolutionsProducts line={line} />
                ) : (
                  <ul className="select-book__list">
                    {line.products.map((product, index) => {
                      return (
                        <li
                          key={line.prefix + index}
                          className={`select-book__item ${product.active || 'select-book__item--deselected'}`}
                        >
                          <label htmlFor={`book_${line.prefix + index}`}>
                            <div className="select-book__list__image">
                              <img className="select-book__list__cover" src={product.images.GraficoBig} alt="images" />
                              {!product.can_remove || is_immediate ? (
                                <input
                                  id={`book_${line.prefix + index}`}
                                  className="select-book__list__checkbox disabled"
                                  type="checkbox"
                                  checked={product.active}
                                  disabled="disabled"
                                  autoComplete="off"
                                  onChange={this._handleClick.bind(this, student, product)}
                                />
                              ) : (
                                <input
                                  id={`book_${line.prefix + index}`}
                                  className="select-book__list__checkbox"
                                  type="checkbox"
                                  autoComplete="off"
                                  checked={product.active}
                                  onChange={this._handleClick.bind(this, student, product)}
                                />
                              )}
                            </div>
                            <div className="select-book__list__text">
                              {product.business_line_id === DIGITAL_ID ? (
                                <span className="select-book__list__digital-tag">
                                  <span className="digital-tag-icon">
                                    <i className="fa fa-cloud" />
                                  </span>
                                  Livro Digital
                                </span>
                              ) : null}
                              <span className="select-book__list__title">{product.name}</span>
                              {product.amount &&
                              product.amount_discounted &&
                              (product.amount !== product.amount_discounted || product.custom_discount_teacher) ? (
                                <div>
                                  <span className="select-book__list__price--discount">
                                    de: R$ {convertMoney(product.amount)}
                                  </span>
                                  <span className="select-book__list__discount-tag">
                                    {has_special_discount_teacher
                                      ? product.custom_discount_teacher
                                      : has_special_discount
                                      ? student.discount_value
                                      : product.has_custom_discount
                                      ? product.custom_discount
                                      : product.discount}
                                    % desconto
                                  </span>
                                </div>
                              ) : null}
                              <span className="select-book__list__price--full">
                                Por: R$ {convertMoney(product.amount_discounted)}
                              </span>
                            </div>
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
            ))}

            <NavLink
              id="select-products-continue-button"
              to="/steps/confirmacao"
              className="select-book__button button__normal"
              onClick={() => {
                window.ga('send', {
                  hitType: 'event',
                  eventCategory: 'compra',
                  eventAction: 'click',
                  eventLabel: 'selecionou_livros_3',
                })
              }}
            >
              Continuar
            </NavLink>
          </div>
        </div>

        <Modal show={this.state.alertModalOpen && !this._onlyBusinessLineService()}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Alerta sobre o prazo de entrega</h1>
            </div>

            <div className="modalContent">
              <ul>
                {this._showBusinessLinesMessage(DIDACT_ID) ? (
                  <li>
                    <h2>Livros Didáticos:</h2>
                    {is_immediate ? (
                      <p>
                        Os livros didáticos estarão disponíveis para{' '}
                        <span className="yellow-text">retirada na escola</span>.
                      </p>
                    ) : school_is_b2c ? (
                      <p>
                        Sua entrega será realizada no{' '}
                        <strong>endereço informado no campo endereço para faturamento no momento da compra.</strong>
                      </p>
                    ) : (
                      <p>
                        Os livros didáticos serão <span className="yellow-text">entregues na escola</span> conforme data
                        estabelecida e informada pela escola.
                      </p>
                    )}
                    <DeliveryTime />
                  </li>
                ) : null}
                {this._showBusinessLinesMessage(LITERARY_ID) ? (
                  <li>
                    <h2>Livros de Literatura Infanto Juvenil:</h2>
                    {school_is_b2c ? (
                      <p>
                        <strong>Local de Entrega: Endereço informado no cadastro no momento da compra</strong>
                      </p>
                    ) : (
                      <p>
                        Os livros de literatura também serão <span className="yellow-text">entregues na escola</span>,
                        porém essa entrega poderá ocorrer após o recebimento dos livros didáticos, conforme data
                        estabelecida e informada pela escola.
                      </p>
                    )}
                    <DeliveryTime />
                  </li>
                ) : null}
                {this._showBusinessLinesMessage(EDUCA_ID) ? (
                  <li>
                    <h2>Soluções Educacionais:</h2>
                    {school_is_b2c ? (
                      <p>
                        Os materiais Educamos serão entregues <b>{this._educaDeliveryType()}</b>{' '}
                        <span className="yellow-text">
                          no Endereço de Entrega informado no momento da criação do pedido.
                        </span>
                      </p>
                    ) : (
                      <p>
                        Os materiais Educamos serão <span className="yellow-text">entregues na escola</span>{' '}
                        {this._educaDeliveryType()}, conforme datas estabelecidas e informadas pela escola. * Exceto
                        para títulos UDP que serão entregues anualmente.
                      </p>
                    )}
                    <DeliveryTime />
                  </li>
                ) : null}
                {this._showBusinessLinesMessage(DIGITAL_ID) ? (
                  <li>
                    <h2>Livros Digitais:</h2>
                    <p>
                      Os livros digitais não são livros físicos. Assim que o pagamento for confirmado, a{' '}
                      <span className="yellow-text">chave de acesso</span> será enviada para o e-mail cadastrado.
                    </p>
                  </li>
                ) : null}
                {this._showBusinessLinesMessage(BILINGUE_ID) ? (
                  <li>
                    <h2>Bilíngue:</h2>
                    {is_immediate ? (
                      <p>
                        Os livros bilíngue estarão disponíveis para{' '}
                        <span className="yellow-text">retirada na escola</span>.
                      </p>
                    ) : school_is_b2c ? (
                      <p>
                        Sua entrega será realizada no{' '}
                        <strong>endereço informado no campo endereço para faturamento no momento da compra.</strong>
                      </p>
                    ) : (
                      <p>
                        O material bilíngue será <span className="yellow-text">entregue na escola</span>, conforme data
                        estabelecida e informada pela escola.
                      </p>
                    )}
                    <DeliveryTime />
                  </li>
                ) : null}
              </ul>
              {!this._onlyBusinessLineDigitalOrService() && (
                <p>
                  <span className="text-red">* </span>A estimativa de entrega poderá sofrer alteração de acordo com a
                  forma de pagamento escolhida.
                </p>
              )}
            </div>
            <div className="modalFooter">
              <button
                id="select-products-alert-confirm-button"
                className="modal-close"
                onClick={() => {
                  this.setState({
                    alertModalOpen: false,
                  })
                }}
              >
                Entendi
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.removeBookModalOpen}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Alerta sobre remoção de livro</h1>
            </div>

            <div className="modalContent">
              <ul>
                <li>
                  <h2>
                    Você está deixando de comprar {this.state.removed_books.length === 1 ? 'o livro abaixo' : 'os livros abaixo'}:
                  </h2>
                </li>
              </ul>
            </div>
            <div className="modalContent select-book__removed_list">
              {this.state.removed_books.map((product, index) => {
                return (
                  <li
                    key={product.id + index}
                    className={`select-book__item ${product.active || 'select-book__item--deselected'}`}
                  >
                    <label>
                      <div className="select-book__list__image">
                        <img className="select-book__list__cover" src={product.images.GraficoBig} alt="images" />
                      </div>
                      <div className="select-book__list__text">
                        {product.business_line_id === DIGITAL_ID ? (
                          <span className="select-book__list__digital-tag">
                            <span className="digital-tag-icon">
                              <i className="fa fa-cloud" />
                            </span>
                            Livro Digital
                          </span>
                        ) : null}
                        <span className="select-book__list__title">{product.name}</span>
                        {product.amount &&
                        product.amount_discounted &&
                        (product.amount !== product.amount_discounted || product.custom_discount_teacher) ? (
                          <div>
                            <span className="select-book__list__discount-tag">
                              {has_special_discount_teacher
                                ? product.custom_discount_teacher
                                : has_special_discount
                                ? student.discount_value
                                : product.has_custom_discount
                                ? product.custom_discount
                                : product.discount}
                              % desconto
                            </span>
                          </div>
                        ) : null}
                      </div>
                    </label>
                  </li>
                )
              })}
            </div>
            <p className="text-red text-center">
              <strong>Está certo disso?</strong>
            </p>
            <div className="modalFooter">
              <button
                id="select-products-alert-confirm-button"
                className="modal-close"
                onClick={() => {
                  this.setState({
                    removeBookModalOpen: false,
                  })
                }}
              >
                Continuar
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    specialDiscount: state.specialDiscount,
    student: getActiveStudent(state),
    voucherForm: state.voucherForm,
    checkout: state.checkout,
  }
}

const mapDispatchToProps = dispatch => ({
  selectProduct: (student, product, active) => dispatch(selectProduct(student, product, active)),
  updateVoucher: voucher => dispatch(updateActiveVoucher(voucher)),
  hideVoucherForm: () => dispatch(activateVoucherForm(false)),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  openModal: component => dispatch(openModal(component)),
  closeModal: () => dispatch(closeModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectProducts)

