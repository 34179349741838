import React, { useState } from 'react'

import PermissionsChecker from '@shared/PermissionsChecker'
import { PixPaymentModal } from '../Pix/PixPaymentModal'

const CancelButton = ({ orderStatus, payment, onCancelOrder }) => {
  if (orderStatus === 'C' && payment !== 'PIX') {
    return (
      <PermissionsChecker permissions={['cancel_order_billet']}>
        <button
          className="button button__small bg-darkgray card-request-detail__billetbutton"
          onClick={() => {
            onCancelOrder()
          }}
        >
          {payment === 'Cartão de crédito' ? 'Solicitar estorno' : 'Cancelar boleto'}
        </button>
      </PermissionsChecker>
    )
  }
  return null
}

const CardRequestDetail = props => {
  let {
    payment,
    due,
    icon,
    subtotal,
    prev_subtotal,
    total,
    freight,
    status,
    paymentCondition,
    paymentType,
    orderStatus,
    onCancelOrder,
    billetUrl,
    discount,
    buyer_name,
    created,
    pix: pixOrder,
  } = props

  const [showPixModal, setShowPixModal] = useState(false)

  return (
    <div className="card-request-detail">
      <div className="card-request-detail__wrapper">
        <div className="card-request-detail__column">
          <span className="card-request-detail__header">Data da compra</span>
          <span className="card-request-detail__value">{created}</span>
        </div>
        <div className="card-request-detail__column">
          <span className="card-request-detail__header">Comprador(a)</span>
          <span className="card-request-detail__value">{buyer_name}</span>
        </div>
      </div>
      <div className="card-request-detail__wrapper">
        <div className="card-request-detail__column">
          <span className="card-request-detail__header">Forma de pagamento</span>
          <div className="card-request-detail__payment_type">
            <img className="card-request-detail__icon" src={icon} alt="" />
            <div>
              <span className="card-request-detail__value">{payment}</span>
              {paymentType === 'R' ? (
                <span className="card-request-detail__desc">
                  {total} em {paymentCondition}x s/ juros
                </span>
              ) : (
                <span className="card-request-detail__desc">{due}</span>
              )}
              <CancelButton orderStatus={orderStatus} payment={payment} onCancelOrder={onCancelOrder} />
              {(orderStatus === 'NP' || orderStatus === 'BAP') && billetUrl && (
                <button
                  className="button button__small bg-darkgray card-request-detail__billetbutton"
                  onClick={() => window.open(billetUrl)}
                >
                  2º via de boleto
                </button>
              )}
              {orderStatus === 'PW' && (
                <button
                  className="button button__small bg-darkgray card-request-detail__billetbutton"
                  onClick={() => setShowPixModal(true)}
                >
                  Ver chave PIX
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="card-request-detail__column paymente_detail">
          <div className="card-request-detail__paymente_detail">
            <span className="card-request-detail__header">Subtotal</span>
            {prev_subtotal && <span className="card-request-detail__value dashed">{prev_subtotal}</span>}
            <span className="card-request-detail__value">{subtotal}</span>
          </div>
          <div className="card-request-detail__paymente_detail">
            <span className="card-request-detail__header">Desconto</span>
            <span className="card-request-detail__value">{discount}</span>
          </div>
          <div className="card-request-detail__paymente_detail">
            <span className="card-request-detail__header">Frete</span>
            <span className="card-request-detail__value">{freight}</span>
          </div>
          <div className="card-request-detail__paymente_detail _total">
            <span className="card-request-detail__value">{total}</span>
          </div>
        </div>
      </div>
      <div className="card-request-detail__status-container">{status}</div>
      {showPixModal && <PixPaymentModal order={pixOrder} total={total} onClose={() => setShowPixModal(false)} />}
    </div>
  )
}

export default CardRequestDetail

