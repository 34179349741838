import React, { Component } from 'react'
import { checkoutTotal, checkoutTotalDiscounted, clear, filterActiveProducts } from '@actions/checkout'
import { closeLoader, openLoader } from '@actions/loader'

import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import iconExport from './images/export-branco.png'
import { requestOrder } from '@actions/creditCard'
import { Navigate } from 'react-router-dom'
import { DuplicatedOrderAlertModal } from '../../shared/DuplicatedOrderAlertModal'

export class Billet extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: { message: '', status: null },
      errorModalOpen: false,
      isDone: false,
      showDuplicatedOrderAlertModal: false,
    }

    this._handleGenerateClick = this._handleGenerateClick.bind(this)
  }

  _handleGenerateClick() {
    this.btn.setAttribute('disabled', 'disabled')

    this.props
      .requestOrder()
      .then(response => {
        const responseData = response.payload

        if (responseData.status === 99) {
          
          if(responseData.error.error_type == 'duplicated_students') {
            this.setState({ showDuplicatedOrderAlertModal: true })
            return false;
          }

          this.setState({
            status: responseData.status,
            paymentError: responseData.message,
          })

          this._showErrorModal()
        }

        if (responseData.status === 2) {
          this.props.clear()
          this.setState({ isDone: true })
        }
      })
      .finally(() => {
        this.props.closeLoader()
        this.btn.removeAttribute('disabled')
      })
  }

  _showErrorModal() {
    if (!this.state.errorModalOpen) {
      this.setState({
        errorModalOpen: true,
      })
    }
  }

  render() {
    return (
      <div>
        {this.state.isDone && <Navigate to="/steps/concluido" />}
        <button
          ref={btn => (this.btn = btn)}
          className="button__medium payment__button-boleto"
          onClick={this._handleGenerateClick}
        >
          <span>{this.props.hybridBilletActivation == 1 ? 'Visualizar boleto/PIX' : 'Baixar boleto'}</span>
          <img src={iconExport} alt="Gerar boleto" className="payment__button__icon" />
        </button>
        {this.state.error.status !== null ? <span className="payment__error">{this.state.error.message}</span> : null}
        <DuplicatedOrderAlertModal
          show={this.state.showDuplicatedOrderAlertModal}
          onClose={() => {
            this.setState({ showDuplicatedOrderAlertModal: false })
          }}  
        />
        <Modal show={this.state.errorModalOpen}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert"></span>
              <h1>Erro ao gerar boleto</h1>
            </div>

            <div className="modalContent">
              <p>Desculpe, não foi possível gerar o boleto nesse momento.</p>
              <p>Tente novamente em alguns minutos.</p>
            </div>

            <div className="modalFooter">
              <button
                className="modal-close"
                onClick={() => {
                  this.setState({
                    errorModalOpen: false,
                  })
                }}
              >
                Entendi
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.sessionReducer.user,
  checkout: filterActiveProducts(state),
  orderTotal: checkoutTotalDiscounted(state),
  withoutDiscount: checkoutTotal(state),
  creditCard: state.creditCard,
})

const mapDispatchToProps = dispatch => ({
  requestOrder: data => dispatch(requestOrder(data)),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  clear: () => dispatch(clear()),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  hybridBilletActivation: ownProps.hybridBilletActivation,
  requestOrder: () => {
    dispatchProps.openLoader()
    return dispatchProps.requestOrder({
      voucher_data: [...stateProps.checkout],
      user_data: {},
      card_data: {},
      type: 'billet',
      order_total: stateProps.orderTotal.toFixed(2),
      order_without_discount: stateProps.withoutDiscount.toFixed(2),
    })
  },
  closeLoader: dispatchProps.closeLoader,
  creditCard: stateProps.creditCard,
  clear: dispatchProps.clear,
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Billet)

