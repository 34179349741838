import React, { useEffect, useState } from 'react'
import { syncSapTable, getSyncBooks } from '@services/sm-api'
import PermissionsChecker from '@shared/PermissionsChecker'
import { closeLoader, openLoader } from '@actions/loader'
import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'

const styles = {
  buttonContainer: { display: 'flex', flexDirection: 'column' },
  button: { textAlign: 'end', width: '21rem' },
  booksContainer: { maxHeight: '15rem', overflow: 'auto' },
  books: { display: 'flex', flexDirection: 'row', justifyContent: 'start', gap: '8rem' },
  bookRow: { display: 'flex', flexDirection: 'column' },
  label: { fontWeight: '600', fontSize: '15px' },
}

const TableSapPricing = props => {
  const { schoolId } = props

  const [books, setBooks] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    getSyncBooks(schoolId).then(resp => setBooks(resp.data.products_sap))
  }, [])

  const handleSyncBooks = async () => {
    await syncSapTable(schoolId)
      .then(response => {
        if (response.data.result.length > 0) {
          setBooks(response.data.result)
        }
        setMessage(response.data.message)
      })
      .finally(() => props.closeLoader())
    setOpenModal(true)
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  return (
    <div>
      <Modal show={openModal}>
        <Modal.Body>
          <div className="modalHeader">
            <span className="icon-alert" />
            <h1></h1>
          </div>
          <div className="modalContent textCenter">
            <p>{message}</p>
          </div>
          <div className="modalFooter">
            <button className="modal-close" onClick={handleModalClose}>
              Fechar
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="schoolcard">
        <div className="schoolcard__title__buttons">
          <div className="schoolcard__title">
            <span>Sincronização SAP - Tabela Pricing</span>
          </div>
          <PermissionsChecker permissions={['manage_contract_dates']}>
            <div style={styles.buttonContainer}>
              <button
                type="button"
                style={styles.button}
                className="button button__sync"
                onClick={() => {
                  props.openLoader()
                  handleSyncBooks()
                }}
              >
                Sincronizar preços por cliente
              </button>
            </div>
          </PermissionsChecker>
        </div>
        <div style={styles.booksContainer}>
          {!books.length && (
            <span className="schoolcard__title" style={{ textAlign: 'center' }}>
              Não há resultados!
            </span>
          )}
          {books &&
            books.map(book => (
              <div key={book.product_sap_code} style={styles.books}>
                <div style={styles.bookRow}>
                  <span className="schoolcard__title" style={{ marginBottom: 0 }}>
                    Código SAP
                  </span>
                  <span style={styles.label}>{book.product_sap_code}</span>
                </div>
                <div style={styles.bookRow}>
                  <span className="schoolcard__title" style={{ marginBottom: 0 }}>
                    Valor
                  </span>
                  <span style={styles.label}>
                    {book.price_sap.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
})

export default connect(null, mapDispatchToProps)(TableSapPricing)

