import React, { Component } from 'react'
import { connect } from 'react-redux'

import { closeLoader, openLoader } from '@actions/loader'
import { getNextOrders, getOrders } from '@actions/order'
import CardRequest from '@components/CardRequest'
import CardStatus from '@components/CardStatus'
import InfiniteScroll from '@shared/InfiniteScroll'
import { cancelOrder } from '@services/sm-api'
import { orderStatus } from '@utils/orderStatus'
import { withRouter } from '../../utils/withRouter'

class Order extends Component {
  state = {
    updated: [],
    type: '',
    updatedIndex: false,
  }

  _handleCancelOrder(data, index) {
    this.props.openLoader()
    cancelOrder(data)
      .then(response => {
        if (response.status === 200) {
          this.state.updated.push({
            index,
            type: response.type,
          })
          this.setState(this.state)
        }
      })
      .finally(() => {
        this.props.closeLoader()
      })
  }

  componentDidMount() {
    this.props.getOrders()
  }

  render() {
    const { user, getNextOrders } = this.props

    return (
      <div className="order">
        <h3 className="order__title">Pedidos</h3>
        <InfiniteScroll loadMore={user.page < user.total_pages} action={() => getNextOrders()}>
          {user.orders.map((order, index) => {
            let cardStatus
            let updated = this.state.updated.find(val => val.index === index)

            if (typeof updated !== 'undefined') {
              cardStatus = orderStatus[updated.type]
            } else {
              cardStatus = orderStatus[order.order_status ?? order.status]
            }

            return (
              <CardRequest
                date={order.created}
                orderNumber={`SMD-${order.order_id}`}
                price={order.total}
                key={index}
                detailsAction={() => {
                  this.props.navigate(`/admin/pedidos/${order.order_id}`)
                }}
                status={<CardStatus {...cardStatus} />}
                billetUrl={order.billet_url}
                cancelOrder={data => this._handleCancelOrder(data, index)}
                paymentType={order.payment_type}
              />
            )
          })}
        </InfiniteScroll>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.sessionReducer.user,
  order: state.order,
})

const mapDispatchToProps = {
  getOrders,
  getNextOrders,
  openLoader,
  closeLoader,
}

const mergeProps = (stateProps, dispatchProps, props) => ({
  getOrders: params => dispatchProps.getOrders({ id: stateProps.user.id, ...params }),
  getNextOrders: params => {
    return dispatchProps.getNextOrders({
      id: stateProps.user.id,
      page: +stateProps.order.page + 1,
      ...params,
    })
  },
  openLoader: () => dispatchProps.openLoader(),
  closeLoader: () => dispatchProps.closeLoader(),
  user: { ...stateProps.user, ...stateProps.order },
  navigate: props.navigate,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Order))

