import { resetPassword as resetPasswordAPI } from '@services/sm-api'

export const POST_RESET_PASSWORD = 'user/POST_RESET_PASSWORD'
export const SUCCESS_RESET_PASSWORD = 'user/SUCCESS_RESET_PASSWORD'
export const FAILURE_RESET_PASSWORD = 'user/FAILURE_RESET_PASSWORD'
export const CLEAR_RESET_PASSWORD = 'user/CLEAR_RESET_PASSWORD'

export function postResetPassword() {
  return {
    type: POST_RESET_PASSWORD,
  }
}

export function successResetPassword(payload) {
  return {
    type: SUCCESS_RESET_PASSWORD,
    payload,
  }
}

export function failureResetPassword(payload) {
  return {
    type: FAILURE_RESET_PASSWORD,
    payload,
  }
}

export function clear() {
  return {
    type: CLEAR_RESET_PASSWORD,
  }
}

export function resetPassword(data) {
  return dispatch => {
    dispatch(postResetPassword())
    return resetPasswordAPI(data)
      .then(response => dispatch(successResetPassword(response)))
      .catch(error => {
        dispatch(failureResetPassword(error))
        throw error
      })
  }
}
