import _ from 'lodash'
import axios from 'axios'
import { getCampaigns } from '@components/ReportFilter/options'
const instance = axios.create({
  baseURL: process.env.REACT_APP_APPSETTING_SM_PHP_API_ENDPOINT,
})

const storage = JSON.parse(window.localStorage.getItem('reduxPersist:user'))
const token = storage && storage.token ? storage.token : null

instance.defaults.headers.common['Authorization'] = `JWT ${token}`

export function getVoucher(voucher) {
  return instance(`/voucher/${voucher}`).then(response => response.data)
}

export function requestOrder(data) {
  return instance({
    method: 'post',
    url: '/orders/create',
    data: JSON.stringify(data),
  }).then(response => response.data)
}

export function requestInstallments(order_total, school_sap_code) {
  return instance({
    method: 'get',
    url: `/orders/credit-card/installments?order_total=${order_total}&school_sap_code=${school_sap_code}`,
  }).then(response => response.data)
}

export function setToken(token) {
  if (!token || token.length === 0) {
    throw new Error('Token parameter cannot be empty.')
  }
  instance.defaults.headers.common['Authorization'] = `JWT ${token}`
  return true
}

export function getOrdersByUser({ id, ...params }) {
  return instance({
    method: 'get',
    url: `orders/${id}/list`,
    params,
  }).then(response => response.data)
}

export function getOrder({ order_id, ...params }) {
  return instance({
    method: 'get',
    url: `orders/${order_id}/detail`,
    params,
  }).then(response => response.data)
}

export function getDefaultData(url, params) {
  return instance({
    method: 'get',
    url,
    params,
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function fetchSyncData(url) {
  return instance({
    method: 'post',
    url,
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function sendContactForm(url, data) {
  return instance({
    method: 'post',
    url,
    data: JSON.stringify(data),
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function getEcatProductLastRun() {
  return instance({
    method: 'get',
    url: 'products/integration/detail',
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function fetchSyncEcatProducts(url) {
  return instance({
    method: 'get',
    url,
  })
    .then(response => response.data)
    .catch(error => console.log(error))
}

export function sendContract(id, data) {
  return instance({
    method: 'post',
    url: `contracts/${id}/upload`,
    data: JSON.stringify(data),
  }).then(response => response.data)
}

export function getContracts(id) {
  return instance({
    method: 'get',
    url: `contracts/${id}/list`,
  }).then(response => response.data)
}

export function downloadContract(id) {
  return instance({
    method: 'get',
    responseType: 'blob',
    url: `contracts/${id}/download`,
  }).then(response => response.data)
}

export function importContracts(id) {
  return instance({
    method: 'get',
    params: {
      sicId: id,
    },
    url: 'contracts/import',
  })
}

export function syncSchoolGrid(sapId) {
  return instance({
    method: 'get',
    url: `api/educamos/sincronizar?sapId=${sapId}`,
  })
}

export function syncSapTable(schoolId) {
  return instance({
    method: 'get',
    url: `contracts/schools/${schoolId}/sync-sap-product`,
  })
}

export function getSyncBooks(schoolId) {
  return instance({
    method: 'get',
    url: `contracts/schools/${schoolId}/products-sap-price`,
  })
}

export function uploadLogo(id, data) {
  return instance({
    method: 'post',
    data: JSON.stringify(data),
    url: `contracts/schools/${id}/logo/upload`,
  }).then(response => response.data)
}

export async function uploadImage(notificationID, formData) {
  return instance
    .post(`/notifications/${notificationID}/upload-image`, formData, {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      },
    })
    .then(response => response.data)
}

export function updateContract(id, data) {
  return instance({
    method: 'post',
    data: JSON.stringify(data),
    url: `contracts/${id}/update`,
  }).then(response => response.data)
}

export function validateContract(id) {
  return instance({
    method: 'get',
    url: `contracts/${id}/validate`,
  }).then(response => response.data)
}

export function resetPassword(data) {
  return instance({
    method: 'post',
    url: `users/password/reset`,
    data: JSON.stringify(data),
  }).then(response => response.data)
}

export function voucherGenerate() {
  return instance({
    method: 'get',
    url: `voucher/generate`,
  })
}

export function cancelOrder(data) {
  return instance({
    method: 'post',
    url: `orders/cancel`,
    data: JSON.stringify(data),
  }).then(response => response.data)
}

export function approveOrder(data) {
  return instance({
    method: 'post',
    url: `orders/approve`,
    data: JSON.stringify(data),
  }).then(response => response.data)
}

export function cancelOrderItem(order_item_id) {
  return instance({
    method: 'post',
    url: `orders/cancel-item`,
    data: JSON.stringify({
      order_item_id,
    }),
  }).then(response => response.data)
}

export function updatePackEducamos(pack_id, pack_educamos) {
  return instance({
    method: 'post',
    url: `contracts/${pack_id}/update-pack`,
    data: JSON.stringify({
      pack_educamos,
    }),
  }).then(response => response.data)
}

export function listVariables() {
  return instance({
    method: 'get',
    url: `variables/list`,
  }).then(response => response.data)
}

export function updateVariable(key, value) {
  if (value instanceof File) {
    const data = new FormData()
    data.append('file', value, value.name)
    return instance({
      method: 'post',
      url: `variables/${key}/update`,
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      },
      data,
    }).then(response => response.data)
  } else {
    return instance({
      method: 'post',
      url: `variables/${key}/update`,
      data: JSON.stringify({
        value,
      }),
    }).then(response => response.data)
  }
}

export function userCreatedMailSend(data) {
  return instance({
    method: 'post',
    url: `users/created/mail`,
    data: JSON.stringify(data),
  }).then(response => response.data)
}

export function changeVoucherStatus(voucherName, data) {
  return instance({
    method: 'post',
    url: `voucher/${voucherName}/change-status`,
    data: JSON.stringify(data),
  }).then(response => response.data)
}

export function blobDownload(url, params) {
  return instance({
    method: 'get',
    url: url,
    params: params,
    responseType: 'blob',
  }).then(response => response.data)
}

export async function voucherSave(voucher) {
  if (!voucher.campaign_id) {
    const campaings = await getCampaigns()
    voucher.campaign_id = _.find(campaings, { active: 1 }).id
  }

  return instance({
    method: 'post',
    url: `voucher/save`,
    data: JSON.stringify(voucher),
  }).then(response => response.data)
}

export function schoolContractDiscount(school_id) {
  return instance({
    method: 'get',
    url: `contracts/${school_id}/discount`,
  }).then(response => response.data)
}

export function schoolContractUpdateDiscount(contract_id, discount) {
  return instance({
    method: 'post',
    url: `contracts/${contract_id}/discount`,
    data: JSON.stringify(discount),
  }).then(response => response.data)
}

export function getSpecialDiscountList(params) {
  return instance({
    method: 'get',
    params,
    url: `/special-discounts/list`,
  }).then(response => response.data)
}

export function checkSpecialDiscount(document) {
  document = `${document}`.replace(/\D/g, '')
  return instance({
    method: 'get',
    url: `/special-discounts/check/${document}`,
  }).then(response => response.data)
}

export function getSpecialDiscountById(id) {
  return instance({
    method: 'get',
    url: `/special-discounts/${id}`,
  }).then(response => response.data)
}

export function createSpecialDiscount(data) {
  return instance({
    method: 'post',
    url: `/special-discounts/create`,
    data: JSON.stringify(data),
  }).then(response => response.data)
}

export function updateSpecialDiscount(data) {
  const { id, ...payload } = data

  return instance({
    method: 'put',
    url: `/special-discounts/${id}/update`,
    data: JSON.stringify(payload),
  }).then(response => response.data)
}

export function downloadDiscountFile(data) {
  return instance({
    method: 'get',
    url: '/special-discounts/export',
    data,
    responseType: 'blob',
  }).then(response => response.data)
}

export function downloadDiscountExampleFile(data) {
  return instance({
    method: 'get',
    url: '/special-discounts/import/example',
    data,
    responseType: 'blob',
  }).then(response => response.data)
}

export function uploadDiscountFile(blob) {
  const data = new FormData()
  data.append('file', blob, blob.name)

  return instance({
    method: 'post',
    url: '/special-discounts/import',
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
    data,
  })
}

export function changePaymentBilletState(school) {
  return instance({
    method: 'post',
    url: '/schools/billet-change',
    data: JSON.stringify(school),
  })
    .then(response => response.data)
    .catch(err => console.log(err))
}

export function changePaymentCreditCardState(school) {
  return instance({
    method: 'post',
    url: '/schools/card-change',
    data: JSON.stringify(school),
  })
    .then(response => response.data)
    .catch(err => console.log(err))
}

export function changePaymentCreditCardInstallments(school) {
  return instance({
    method: 'post',
    url: '/schools/card-change/installments',
    data: JSON.stringify(school),
  })
    .then(response => response.data)
    .catch(err => console.log(err))
}

export function changeFreightAmount(school) {
  return instance({
    method: 'post',
    url: '/schools/freight-change',
    data: JSON.stringify(school),
  })
    .then(response => response.data)
    .catch(err => console.log(err))
}

export function saveExpectedStart(school) {
  return instance({
    method: 'post',
    url: '/schools/expected-start',
    data: JSON.stringify(school),
  })
    .then(response => response.data)
    .catch(err => console.log(err))
}

export function getSchoolPaymentOptions(school_sap_code) {
  return instance({
    method: 'get',
    url: `/schools/payment-options/${school_sap_code}`,
  })
    .then(response => response.data)
    .catch(err => console.log(err))
}

export function checkOrderTeacher(user_id, vouchers) {
  return instance({
    method: 'post',
    url: 'orders/orders-check-orders-teacher',
    data: {
      user_id,
      vouchers,
    },
  })
    .then(response => response.data.existOrder)
    .catch(error => console.log(error))
}

export function getNotificationList(params) {
  return instance({
    method: 'get',
    params,
    url: `/notifications/list`,
  }).then(response => response.data)
}

export function getNotificationById(id) {
  return instance({
    method: 'get',
    url: `/notifications/${id}`,
  }).then(response => response.data)
}

export function getActiveNotification() {
  return instance({
    method: 'get',
    url: `/notifications/active`,
  }).then(response => {
    if (response.data && response.data.notification) {
      return response.data.notification
    } else {
      return null
    }
  })
}

export function createNotification(data) {
  return instance({
    method: 'post',
    url: `/notifications/create`,
    data: JSON.stringify(data),
  }).then(response => response.data)
}

export function updateNotification(data) {
  const { id, ...payload } = data

  return instance({
    method: 'put',
    url: `/notifications/${id}/update`,
    data: JSON.stringify(payload),
  }).then(response => response.data)
}

export function updateDeliveryType(id, data) {
  return instance({
    method: 'post',
    data: JSON.stringify(data),
    url: `contracts/${id}/period/update`,
  }).then(response => response.data)
}

export function getFreightAPI(data) {
  return instance({
    method: 'post',
    data: JSON.stringify(data),
    url: `freight/calculate`,
  })
    .then(response => {
      if (response.data.code === 200) {
        return {
          calculated_freight: response.data.freight_price,
          error: null,
        }
      } else {
        return {
          calculated_freight: null,
          error: response,
        }
      }
    })
    .catch(error => {
      console.log(error)
      return {
        calculated_freight: null,
        error: error,
      }
    })
}

export async function updateDigitalSolutionsAPI(data) {
  let url = `contracts/${data.school_id}/digital-solution/update`
  delete data.school_id

  return instance({
    method: 'post',
    data: JSON.stringify(data),
    url: url,
  })
    .then(response => {
      return response
    })
    .catch(error => {
      return JSON.parse(error.request.response)
    })
}

export async function getDigitalSolutionsAPI(data) {
  return data
}

export async function getVouchersInactivationAPI(data) {
  return instance({
    method: 'get',
    url: `business-line/get-business-lines`,
  })
    .then(response => {
      if (response.status === 200 && response.data && response.data.business_lines) {
        return response.data.business_lines
      } else {
        return []
      }
    })
    .catch(error => {
      console.log(error)
      return JSON.parse(error.request.response)
    })
}

export async function updateVouchersInactivationAPI(data) {
  return instance({
    method: 'post',
    data: JSON.stringify(data),
    url: 'business-line/update-business-lines',
  })
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
      return JSON.parse(error.request.response)
    })
}

export async function updateAutomaticInactivationAPI(data) {
  return instance({
    method: 'post',
    data: JSON.stringify(data),
    url: 'business-line-cut-dates/store',
  })
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
      return JSON.parse(error.request.response)
    })
}

export const checkPixPayment = async order => {
  const { data } = await instance({
    method: 'get',
    url: `orders/pix/check-payment-pix/${order}`,
  })
  return data
}

export default {
  getVoucher,
  requestOrder,
  checkSpecialDiscount,
  setToken,
  getOrdersByUser,
  getDefaultData,
  sendContactForm,
  getEcatProductLastRun,
  fetchSyncEcatProducts,
  sendContract,
  getContracts,
  uploadLogo,
  updateContract,
  validateContract,
  resetPassword,
  changeVoucherStatus,
  blobDownload,
  voucherSave,
  schoolContractDiscount,
  schoolContractUpdateDiscount,
  getSpecialDiscountList,
  getSpecialDiscountById,
  createSpecialDiscount,
  getNotificationList,
  getNotificationById,
  createNotification,
  updateNotification,
  getActiveNotification,
  changePaymentBilletState,
  changePaymentCreditCardState,
  changePaymentCreditCardInstallments,
  getSchoolPaymentOptions,
  checkOrderTeacher,
  updateDeliveryType,
  getFreightAPI,
  updateDigitalSolutionsAPI,
  getDigitalSolutionsAPI,
  updateVouchersInactivationAPI,
  getVouchersInactivationAPI,
  updateAutomaticInactivationAPI,
  checkPixPayment,
}

