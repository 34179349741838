import React, { Component } from 'react'

export class Message extends Component {
  render() {
    return <div>{this.props.expr ? this.props.children : null}</div>
  }
}

export class Messaging extends Component {
  render() {
    return this.props.children.filter(child => child.props.expr)
  }
}
