import React, { Component } from 'react'

export default class ConfirmDialog extends Component {
  render() {
    return (
      <div className="confirm-dialog">
        <div className="confirm-dialog__container">
          <h1 className="confirm-dialog__message">{this.props.text}</h1>
          <div className="confirm-dialog__buttons-container">
            {typeof this.props.cancel !== 'undefined' && (
              <button className="button__normal--modal-cancel" onClick={this.props.cancel}>
                Não
              </button>
            )}
            <button className="button__normal--modal-confirm" onClick={this.props.ok}>
              {this.props.okLabel || 'Sim'}
            </button>
          </div>
        </div>
      </div>
    )
  }
}
