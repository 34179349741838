/**
 * Dependencies
 */
import smApi from '@services/sm-api.js'

/**
 * Actions types
 */
export const FETCH_VOUCHER = 'preVoucher/FETCH_VOUCHER'
export const SUCCESS_VOUCHER = 'preVoucher/FETCH_SUCCESS_VOUCHER'
export const FAILURE_VOUCHER = 'preVoucher/FETCH_FAILURE_VOUCHER'
export const SUCCESS_SPECIAL_DISCOUNT = 'preVoucher/SUCCESS_SPECIAL_DISCOUNT'
export const CLEAR = 'preVoucher/CLEAR'

/**
 * Actions creators
 */
export function fetchVoucher() {
  return {
    type: FETCH_VOUCHER,
  }
}

export function successVoucher(payload) {
  return {
    type: SUCCESS_VOUCHER,
    payload,
  }
}

export function successSpecialDiscount(payload) {
  return {
    type: SUCCESS_SPECIAL_DISCOUNT,
    payload,
  }
}

export function failureVoucher(error) {
  return {
    type: FAILURE_VOUCHER,
  }
}

export function clearVoucher() {
  return {
    type: CLEAR,
  }
}

export function requestVoucher(voucher) {
  return dispatch => {
    dispatch(fetchVoucher())
    return smApi
      .getVoucher(voucher)
      .then(response => dispatch(successVoucher({ ...response })))
      .catch(failureVoucher)
  }
}

export function checkSpecialDiscount(document) {
  return dispatch => {
    dispatch(fetchVoucher())
    return smApi
      .checkSpecialDiscount(document)
      .then(response => dispatch(successSpecialDiscount({ ...response })))
      .catch(failureVoucher)
  }
}
