import * as actions from '@actions/checkout.js'

import { REHYDRATE } from 'redux-persist/constants'
import _ from 'lodash'
import moment from 'moment'

function updateStudentsArray(students, newStudentState) {
  return students.map(student => {
    return student.voucher === newStudentState.voucher && student.name === newStudentState.name
      ? newStudentState
      : student
  })
}

function applyRule(rule, discount) {
  let result = discount

  // switch(rule) {
  //   case 0:
  //     result = discount
  //     break

  //   case 1:
  //     result = discount  / 2
  //     break

  //   default:
  //     result = 0
  //     break
  // }

  return result
}

function applyDiscount(rule, total, discount) {
  // const updatedDiscount = applyRule(rule, discount)
  const toDiscount = total * (discount / 100)
  const totalDiscount = total - toDiscount
  return { toDiscount, totalDiscount }
}

function productReducer(student, selectedProduct) {
  return {
    ...student,
    products: student.products.map(product =>
      product.product_sap_code === selectedProduct.product_sap_code ? selectedProduct : product,
    ),
    active_products: student.products.filter(product => product.active).length,
  }
}

function amountReducer(student) {
  let { products } = student
  let activeProducts = products.filter(product => product.active)
  let diff = products.length - activeProducts.length

  const has_special_discount = _.isInteger(student.special_discount_id)
  const has_special_discount_teacher = student.voucher.toLowerCase().startsWith('p')

  let newProductsState = products.map(product => {
    let result = applyDiscount(
      diff,
      product.amount,
      has_special_discount_teacher
        ? product.custom_discount_teacher
        : has_special_discount
        ? student.discount_value
        : product.has_custom_discount
        ? product.custom_discount
        : product.discount,
    )
    product.amount_discounted = result.totalDiscount
    return product
  })

  let newActiveProductsState = newProductsState.filter(product => product.active)
  let updated_discount = applyRule(diff, student.discount)
  let diffActiveProducts = newProductsState.length - newActiveProductsState.length

  const total = newActiveProductsState.reduce((prev, product) => (prev += product.amount), 0)

  let total_discounted = newActiveProductsState.reduce((prev, product) => (prev += product.amount_discounted), 0)

  return {
    ...student,
    products: newProductsState,
    active_products: newActiveProductsState.length,
    total,
    total_discounted,
    updated_discount,
    hide: updated_discount < student.discount,
    show_warning: diffActiveProducts <= 2 && diffActiveProducts > 0,
  }
}

const agreeDelivery = checkout => {
  checkout.forEach(purchase => {
    purchase.delivery_acceptance_date = moment().format('DD/MM/YYYY hh:mm:ss')
  })
  return [...checkout]
}

export default (state = [], action) => {
  switch (action.type) {
    case actions.INSERT_STUDENT:
      return state.concat(amountReducer(action.student))

    case actions.REMOVE_STUDENT:
      return [...state.slice(0, action.index), ...state.slice(action.index + 1)]

    case actions.SELECT_PRODUCT:
      return updateStudentsArray(state, amountReducer(productReducer(action.student, action.product)))

    case actions.RESET_USER_WARNING:
      return state.map(student => (student.voucher === action.voucher ? { ...student, show_warning: false } : student))

    case actions.RESET_ALL_WARNINGS:
      return state.map(student => ({ ...student, show_warning: false }))

    case actions.UPDATE_NAME:
      return state.map((student, index) => (student.voucher === action.voucher && index == action.index ? { ...student, name: action.name } : student))
    case actions.CLEAR:
      return []

    case REHYDRATE:
      return []

    case actions.AGREE_DELIVERY:
      return agreeDelivery(state)

    default:
      return state
  }
}
