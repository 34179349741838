import React from 'react'
import { FormInput } from 'react-form'
import InputMask from 'react-input-mask'

const CustomInputText = ({ field, mask, regex, ...rest }) => (
  <FormInput field={field}>
    {({ setValue, getValue, setTouched }) => {
      return (
        <InputMask
          {...rest}
          className={`field__text ${rest.className}`}
          mask={mask}
          maskChar=" "
          autoComplete="off"
          value={getValue() || ''}
          onChange={event => {
            setValue(event.target.value.replace(regex, ''))
          }}
          onBlur={() => setTouched()}
        />
      )
    }}
  </FormInput>
)

export default CustomInputText
