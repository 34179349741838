import React, { Component } from 'react'
import { Swap, SwapItem } from '../Swap'
import { closeLoader, openLoader } from '@actions/loader.js'

import Billet from '@components/Billet'
import CreditCard from '../CreditCard'
import { Modal } from 'react-bootstrap'
import Panel from './Panel'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getOrders } from '@actions/order.js'
import iconInfo from './images/info.svg'
import { listVariables } from '@services/sm-api'
import moment from 'moment'
import { agreeDelivery } from '../../actions/checkout'
import Pix from '../Pix/index.jsx'
import { DuplicatedOrderModal } from '../../shared/DuplicatedOrderModal'

const DIDACT_ID = 1
const EDUCA_ID = 2
const EDUCA_FIRST = 2.1
const LITERARY_ID = 3
const SERVICE_ID = 7
const DIGITAL_ID = 8
const BILINGUE_ID = 9

const PAYMENT_BILLET = 'boleto'
export const PAYMENT_PIX = 'pix'

const AcceptEstimate = props => {
  const message =
    props.message ||
    (props.isB2C
      ? 'Estou ciente da estimativa de entrega.'
      : 'Estou ciente da estimativa e concordo que a entrega seja realizada na escola.')
  return (
    <label className="checkbox_area">
      <input type="checkbox" className="checkbox" {...props} autoComplete="off" />
      <span className="checkbox__label">{message}</span>
    </label>
  )
}

const Tip = () => {
  return (
    <div className="estimate__helpicon">
      <img src={iconInfo} width="20" height="20" alt="" />
      <div className="estimate__tooltip">
        <p className="estimate__text">
          A SM Educação possui um contrato firmado com cada escola, no qual há datas de corte para que os livros possam
          ser enviados em datas específicas, por lotes.
        </p>
      </div>
    </div>
  )
}

class Payment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: '',
      opacity: true,
      alertModalOpen: false,
      modalBilletUnavailable: false,
      modalDeliveryNotice: false,
      purchasedOrder: null,
      hasBillet: this.props.paymentOptions.has_billet,
      hasCard: this.props.paymentOptions.has_credit_card,
      hasCutDates: false,
      voucherBusinessLines: [],
      packEducamosBusinessLines: [],
      estimateAccepts: [],
      acceptDeliveryTerms: false,
      isSpecialBilletCutDateEnabled: false,
      isSpecialCardCutDateEnabled: false,
      showPaymentMethod: false,
      addressModalClose: false,
      educa_delivery_type: null,
      hybridBilletActivation: null,
      isPixEnabled: false,
    }
    this._isMounted = false
  }

  _setActive = panel => {
    this.setState({ active: panel, acceptDeliveryTerms: false })
    this.setDeliveryType(panel)
  }

  _setModalState(modalState) {
    this.setState({ alertModalOpen: modalState })
  }

  _handleAcceptedEstimate(business_line_id) {
    let estimateAccepts = this.state.estimateAccepts
    if (_.indexOf(estimateAccepts, business_line_id) >= 0) {
      _.remove(estimateAccepts, line_id => line_id === business_line_id)
    } else {
      estimateAccepts.push(business_line_id)
      estimateAccepts = _.sortedUniq(estimateAccepts)
    }
    this.setState({ estimateAccepts })
  }

  hasCutDate() {
    const voucher = _.last(this.props.checkout)
    const lastCutDate = _.last(voucher.contract_cut_dates)
    return moment().isBefore(moment(lastCutDate))
  }

  setDeliveryType() {
    const voucher = _.last(this.props.checkout)

    const _deliveryType = () => {
      let ensino = voucher.serie_id.substring(1).toLowerCase()
      switch (ensino) {
        case 'em':
          return voucher.ensino_em
        case 'f1':
          return voucher.ensino_ef1
        case 'f2':
          return voucher.ensino_ef2
        case 'ei':
          return voucher.ensino_ei
        case 'maternal':
          return voucher.ensino_ei
        default:
          return null
      }
    }

    this.setState({
      modalDeliveryNotice: _.isEmpty(this.state.voucherBusinessLines) ? false : true,
      educa_delivery_type: _deliveryType(),
      estimateAccepts: [],
    })
  }

  _showAcceptEstimateChecked(business_line_id) {
    return _.indexOf(this.state.estimateAccepts, business_line_id) !== -1
  }

  _showDownloadBilletButton() {
    const voucherBusinessLines = this.state.voucherBusinessLines
    const estimateAccepts = _.sortedUniq(this.state.estimateAccepts)
    return _.difference(voucherBusinessLines, estimateAccepts).length === 0 || !this.state.hasCutDates
  }

  _showCloseButtonEstimateModal() {
    let _voucherBusinessLines = this.state.voucherBusinessLines
    if (_voucherBusinessLines.indexOf(EDUCA_ID) !== -1) {
      _voucherBusinessLines.push(EDUCA_FIRST)
    }
    const estimateAccepts = _.sortedUniq(this.state.estimateAccepts)
    return _.difference(_voucherBusinessLines, estimateAccepts).length === 0
  }

  _showBusinessLinesMessage(business_line_id) {
    return _.indexOf(this.state.voucherBusinessLines, business_line_id) !== -1
  }

  _businessLineHasPackEducamos(business_line_id) {
    return this.state.packEducamosBusinessLines.includes(business_line_id)
  }

  _showPaymentWithBillet() {
    const voucher = _.last(this.props.checkout)
    const endDateArray = voucher.contract_end.split('/')
    const endDate = `${endDateArray[2]}-${endDateArray[1]}-${endDateArray[0]}`
    const cutDates = voucher.contract_cut_dates

    const year = new Date().getFullYear()
    const january = moment(`${year}-01-01`).format('YYYY-MM-DD')
    const march = moment(`${year}-03-16`).format('YYYY-MM-DD')

    if (moment().isBetween(january, march)) {
      for (let cutDate of cutDates) {
        let sub4days = moment(cutDate).subtract(4, 'days').format('YYYY-MM-DD')
        if (moment().isBetween(sub4days, cutDate) || moment().isSame(cutDate, 'day')) {
          this.setState({
            hasBillet: false,
            modalBilletUnavailable: true,
          })
          return
        }
      }
    }

    let sub4days = moment(endDate).subtract(4, 'days').format('YYYY-MM-DD')

    if (moment().isSame(endDate, 'day') || moment().isBetween(sub4days, endDate)) {
      this.setState({
        hasBillet: false,
        modalBilletUnavailable: true,
      })
    }
  }

  _educaDeliveryType() {
    switch (`${this.state.educa_delivery_type}`) {
      case '2':
        return 'bimestralmente'
      case '3':
        return 'trimestralmente'
      case '5':
        return 'semestralmente'
      case '6':
        return 'anualmente'
      case '7':
        return 'imediata'
      default:
        return 'bimestralmente/semestralmente'
    }
  }

  componentWillUpdate() {
    if (_.isEmpty(this.props.checkout) && window.location.pathname.includes('pagamento')) {
      window.location.href = '/'
      return
    }
  }

  async componentDidMount() {
    let count = 0
    this._isMounted = true
    this.props.openLoader()
    await this.props.getOrders(this.props.userData.user.id)
    this.alreadyPurchase()
    this._showPaymentWithBillet()
    this.props.checkout.map(voucher => {
      voucher.products.map(product => {
        if (product.business_line_id !== SERVICE_ID && product.business_line_id !== DIGITAL_ID && product.active) {
          this.state.voucherBusinessLines.push(product.business_line_id)
        } else {
          count++
        }

        if (Boolean(product.pack_educamos)) {
          this.state.packEducamosBusinessLines.push(product.business_line_id)
        }

        return product
      })
      return voucher
    })

    if (_.isEmpty(this.state.voucherBusinessLines) && count > 0) {
      this.setState({ showPaymentMethod: true })
    }

    listVariables().then(data => {
      const isSpecialBilletCutDateEnabled = _.get(_.find(data, { key: 'SPECIAL_BILLET_CUT_DATE' }), 'value', false)
      const isSpecialCardCutDateEnabled = _.get(_.find(data, { key: 'SPECIAL_CARD_CUT_DATE' }), 'value', false)

      const hybridBilletActivation = _.find(data, { key: 'HYBRID_BILLET_ACTIVATION' })
      const isPixEnabled = _.find(data, { key: 'PIX_ITAU_ACTIVATION' }).value === '1'

      this.setState({
        isSpecialBilletCutDateEnabled: Boolean(Number(isSpecialBilletCutDateEnabled)),
        isSpecialCardCutDateEnabled: Boolean(Number(isSpecialCardCutDateEnabled)),
        hybridBilletActivation: hybridBilletActivation.value,
        isPixEnabled,
      })
      this.props.closeLoader()
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  alreadyPurchase() {
    const voucher_id = this.props.activeVoucher.toUpperCase()
    const orders = _.first(
      this.props.orders.filter(order => {
        if (['PG', 'AP', 'BAP', 'PAV'].includes(order.status)) {
          const vouchers = _.values(order.vouchers).map(voucher => {
            return _.keys(voucher)[0].toUpperCase()
          })
          return vouchers.includes(voucher_id)
        }
        return false
      }),
    )
    if (this._isMounted && orders) {
      this.setState({ purchasedOrder: orders.order_id })
      this._setModalState(true)
    }
  }

  get deliveryEstimate() {
    const voucher = _.last(this.props.checkout)
    const nextCutDate = voucher.contract_cut_dates.find(date => moment(date).isAfter(moment()))
    const deliveryDate = moment(nextCutDate).add(20, 'days')
    return deliveryDate.format('DD/MM/YYYY')
  }

  render() {
    let { checkout } = this.props
    const first = checkout ? _.first(checkout) : null
    const school_is_b2c = !!first ? Boolean(first.school_is_b2c) : false
    const immediate_delivery = '7'
    const is_immediate = this.state.educa_delivery_type == immediate_delivery

    const DeliveryTime = () => (
      <p>
        <span className="text-red">
          {school_is_b2c ? 'Estimativa de entrega: ' : 'Estimativa de entrega na escola: '}
        </span>
        {is_immediate ? (
          <span>
            Em 48 horas ou de acordo com orientação da escola.
            <span className="text-red">* </span>
          </span>
        ) : school_is_b2c || !this.hasCutDate() ? (
          <span>
            Em até 20 dias úteis após a confirmação do pagamento
            <span className="text-red">* </span>
          </span>
        ) : (
          <span>
            {this.deliveryEstimate}
            <span className="text-red">* </span>
            <Tip />
          </span>
        )}
      </p>
    )

    return (
      <React.Fragment>
        <DuplicatedOrderModal
          show={this.state.alertModalOpen}
          purchasedOrder={this.state.purchasedOrder}
          onClose={() => {
            this._setModalState(false)
          }}
        />

        <Modal show={this.state.modalBilletUnavailable}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Atenção</h1>
            </div>

            <div className="modalContent">
              <p>
                O lote para envio será encerrado dentro de alguns dias, e como o pagamento via boleto poderá demorar até
                3 dias para ser compensado, esta modalidade está automaticamente inativada. O pagamento poderá ser feito
                via cartão de crédito.
              </p>
              <p>Em caso de dúvidas acesse o menu "Ajuda".</p>
            </div>

            <div className="modalFooter">
              <button
                className="modal-close"
                onClick={() => {
                  this.setState({ modalBilletUnavailable: false })
                }}
              >
                Fechar
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.modalDeliveryNotice}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Alerta sobre o prazo de entrega</h1>
            </div>

            <div className="modalContent">
              <ul>
                {this._showBusinessLinesMessage(DIDACT_ID) ? (
                  <li>
                    <h2>Livros Didáticos:</h2>
                    {is_immediate ? (
                      <p>
                        Os livros didáticos estarão disponíveis para{' '}
                        <span className="yellow-text">retirada na escola</span>.
                      </p>
                    ) : school_is_b2c ? (
                      <p>
                        Sua entrega será realizada no{' '}
                        <strong>endereço informado no campo endereço para faturamento no momento da compra.</strong>
                      </p>
                    ) : (
                      <p>
                        Os livros didáticos serão <span className="yellow-text">entregues na escola</span> conforme data
                        estabelecida e informada pela escola.
                      </p>
                    )}
                    <DeliveryTime />
                    <AcceptEstimate
                      checked={this._showAcceptEstimateChecked(DIDACT_ID)}
                      onChange={() => this._handleAcceptedEstimate(DIDACT_ID)}
                      isB2C={school_is_b2c}
                    />
                  </li>
                ) : null}
                {this._showBusinessLinesMessage(LITERARY_ID) ? (
                  <li>
                    <h2>Livros de Literatura Infanto Juvenil:</h2>
                    {school_is_b2c ? (
                      <p>
                        <strong>Local de Entrega: Endereço informado no cadastro no momento da compra</strong>
                      </p>
                    ) : (
                      <p>
                        Os livros de literatura também serão <span className="yellow-text">entregues na escola</span>,
                        porém essa entrega poderá ocorrer após o recebimento dos livros didáticos, conforme data
                        estabelecida e informada pela escola.
                      </p>
                    )}
                    <DeliveryTime />

                    <AcceptEstimate
                      checked={this._showAcceptEstimateChecked(LITERARY_ID)}
                      onChange={() => this._handleAcceptedEstimate(LITERARY_ID)}
                      isB2C={school_is_b2c}
                    />
                  </li>
                ) : null}
                {this._showBusinessLinesMessage(EDUCA_ID) ? (
                  <li>
                    <h2>Soluções Educacionais:</h2>
                    {school_is_b2c ? (
                      <p>
                        Os materiais Educamos serão entregues <b>{this._educaDeliveryType()}</b>{' '}
                        <span className="yellow-text">
                          no Endereço de Entrega informado no momento da criação do pedido.
                        </span>
                      </p>
                    ) : (
                      <p>
                        Os materiais Educamos serão <span className="yellow-text">entregues na escola</span>{' '}
                        {this._educaDeliveryType()}, conforme datas estabelecidas e informadas pela escola. * Exceto
                        para títulos UDP que serão entregues anualmente.
                      </p>
                    )}
                    <DeliveryTime />

                    <AcceptEstimate
                      id="payment-deadline-alert-ckeckbox-1"
                      checked={this._showAcceptEstimateChecked(EDUCA_FIRST)}
                      onChange={() => this._handleAcceptedEstimate(EDUCA_FIRST)}
                      message={
                        school_is_b2c
                          ? 'Estou ciente da estimativa de entrega do primeiro livro. *'
                          : 'Estou ciente da estimativa de entrega do primeiro livro e concordo que a entrega seja realizada na escola. *'
                      }
                    />
                    <div>
                      <AcceptEstimate
                        id="payment-deadline-alert-ckeckbox-2"
                        checked={this._showAcceptEstimateChecked(EDUCA_ID)}
                        onChange={() => this._handleAcceptedEstimate(EDUCA_ID)}
                        message="Estou ciente que os demais volumes serão entregues antes de cada início de bimestre/semestre na escola. * Exceto para títulos UDP que serão entregues anualmente."
                      />
                    </div>
                  </li>
                ) : null}
                {this._showBusinessLinesMessage(DIGITAL_ID) ? (
                  <li>
                    <h2>Livros Digitais:</h2>
                    <p>
                      Os livros digitais não são livros físicos. Assim que o pagamento for confirmado, a{' '}
                      <span className="yellow-text">chave de acesso</span> será enviada para o e-mail cadastrado.
                    </p>
                  </li>
                ) : null}
                {this._showBusinessLinesMessage(BILINGUE_ID) ? (
                  <li>
                    <h2>Bilíngue:</h2>
                    {is_immediate ? (
                      <p>
                        Os livros bilíngue estarão disponíveis para{' '}
                        <span className="yellow-text">retirada na escola</span>.
                      </p>
                    ) : school_is_b2c ? (
                      <p>
                        Sua entrega será realizada no{' '}
                        <strong>endereço informado no campo endereço para faturamento no momento da compra.</strong>
                      </p>
                    ) : (
                      <p>
                        O material bilíngue será <span className="yellow-text">entregue na escola</span>, conforme data
                        estabelecida e informada pela escola.
                      </p>
                    )}
                    <DeliveryTime />

                    <AcceptEstimate
                      checked={this._showAcceptEstimateChecked(BILINGUE_ID)}
                      onChange={() => this._handleAcceptedEstimate(BILINGUE_ID)}
                      isB2C={school_is_b2c}
                    />
                  </li>
                ) : null}
              </ul>
              {this.state.active === PAYMENT_BILLET && (
                <p>
                  <span className="text-red">* </span>O pagamento por cartão de crédito poderá ter antecipação na
                  estimativa de entrega.
                </p>
              )}
            </div>
            <div className="modalFooter">
              <button
                className="modal-close inline-button"
                onClick={() => {
                  this.setState({ modalDeliveryNotice: false })
                }}
              >
                Cancelar
              </button>
              <button
                id="payment-deadline-alert-confirm-button"
                disabled={!this._showCloseButtonEstimateModal()}
                className={`modal-close inline-button ${!this._showCloseButtonEstimateModal() ? 'disabled' : 'accept'}`}
                onClick={() => {
                  this.props.agreeDelivery()
                  this.setState({
                    modalDeliveryNotice: false,
                    acceptDeliveryTerms: true,
                  })
                }}
              >
                Entendi
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Swap active={this.state.active}>
          <SwapItem name="boleto">
            {/* @TODO: pass just the object to change the acceptDeliveryTerms property */}
            <Panel
              active={this.state.active}
              setActive={this._setActive}
              hasBillet={this.state.hasBillet}
              hasCard={this.state.hasCard}
              thisParent={this}
              hybridBilletActivation={this.state.hybridBilletActivation}
              isPixEnabled={this.state.isPixEnabled}
            >
              {this.state.acceptDeliveryTerms || this.state.showPaymentMethod ? (
                this.state.hybridBilletActivation == 1 ? (
                  <div className="payment-info">
                    <div className="payment-info__section">
                      <span className="payment-info__title">ATENÇÃO</span>
                      <p className="payment-info__desc">
                        - O boleto possui validade de 1 (um) dia corrido. Após o vencimento o pedido será cancelado.
                      </p>
                      <p className="payment-info__desc">- Pagamento por boleto apenas à vista.</p>
                    </div>
                    <div className="payment-info__section">
                      <h4>
                        <b>
                          Pagamento do boleto via PIX <sup>novo</sup>
                        </b>
                      </h4>
                      <p className="payment-info__desc">- Você poderá realizar o pagamento deste boleto via PIX.</p>
                      <p className="payment-info__desc">
                        - Após o pagamento, a confirmação será realizada em alguns minutos.
                      </p>
                      <p className="payment-info__desc">
                        - Você poderá acompanhar a confirmação do pagamento na tela de Resumo do pedido.
                      </p>
                      <p className="payment-info__desc">
                        - Um e-mail de confirmação de pagamento também será enviado assim que o pagamento for confirmado
                        (verifique suas caixas de SPAM e lixo eletrônico).
                      </p>
                    </div>
                    <div className="payment-info__section">
                      <h4>
                        <b>Pagamento do boleto via código de barras (modo convencional)</b>
                      </h4>
                      <p className="payment-info__desc">
                        - Seu pedido poderá demorar até 3 (três) dias úteis para ser processado após o pagamento,{' '}
                        <b>podendo alterar a estimativa de entrega.</b>
                      </p>
                      <p className="payment-info__desc">
                        - Necessário desativar o bloqueador de pop-up de seu navegador antes de gerar o boleto.
                      </p>
                      <p className="payment-info__desc">
                        - O boleto deverá ser pago em qualquer agência bancária ou pelo internet banking.
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="payment-info">
                    <span className="payment-info__title">ATENÇÃO</span>
                    <p className="payment-info__desc">
                      - Seu pedido poderá demorar até 3 (três) dias úteis para ser processado após o pagamento,
                      <b> podendo alterar a estimativa de entrega</b>.
                    </p>
                    <p className="payment-info__desc">
                      - Necessário desativar o bloqueador de pop-up de seu navegador antes de gerar o boleto.
                    </p>
                    <p className="payment-info__desc">
                      - O boleto deverá ser pago em qualquer agência bancária ou pelo <i>internet banking</i>.
                    </p>
                    <p className="payment-info__desc">
                      - O boleto possui validade de 1 (um) dia corrido. Após o vencimento o pedido será cancelado.
                    </p>
                    <p className="payment-info__desc">- Pagamento por boleto apenas à vista.</p>
                  </div>
                )
              ) : null}

              {(this.state.acceptDeliveryTerms || this.state.showPaymentMethod) && this._showDownloadBilletButton() && (
                <Billet hybridBilletActivation={this.state.hybridBilletActivation} />
              )}
            </Panel>
          </SwapItem>

          <SwapItem name="card">
            {/* @TODO: pass just the object to change the acceptDeliveryTerms property */}
            <Panel
              active={this.state.active}
              setActive={panel => this._setActive(panel)}
              hasBillet={this.state.hasBillet}
              hasCard={this.state.hasCard}
              thisParent={this}
              hybridBilletActivation={this.state.hybridBilletActivation}
              isPixEnabled={this.state.isPixEnabled}
            >
              {(this.state.acceptDeliveryTerms || this.state.showPaymentMethod) && this._showDownloadBilletButton() && (
                <div>
                  <p>Clique em “Continuar” para ver nossas condições de parcelamento</p>
                  <button
                    id="payment-continue-button"
                    className="button__normal"
                    onClick={() => {
                      window.ga('send', {
                        hitType: 'event',
                        eventCategory: 'compra',
                        eventAction: 'click',
                        eventLabel: 'cartao_credito_5',
                      })

                      this.setState({ active: 'card-form' })
                    }}
                  >
                    Continuar
                  </button>
                </div>
              )}
            </Panel>
          </SwapItem>

          <SwapItem name={PAYMENT_PIX}>
            <Panel
              active={this.state.active}
              setActive={this._setActive}
              hasBillet={this.state.hasBillet}
              hasCard={this.state.hasCard}
              thisParent={this}
              hybridBilletActivation={this.state.hybridBilletActivation}
              isPixEnabled={this.state.isPixEnabled}
            >
              {this.state.acceptDeliveryTerms && (
                <div className="payment-info">
                  <span className="payment-info__title">ATENÇÃO</span>
                  <p className="payment-info__desc">
                    - Ao selecionar o pagamento via PIX, uma chave será gerada e o pagamento deverá ser realizado no
                    aplicativo de seu banco ou via internet banking, em até 10 minutos.
                  </p>
                  <p className="payment-info__desc">
                    - Assim que o pedido for pago, a tela será atualizada e seu pedido será aprovado automaticamente.
                  </p>
                  <p className="payment-info__desc">
                    - Caso você feche seu navegador antes de realizar o pagamento, o seu pedido será cancelado e você
                    terá que realizar um novo pedido.
                  </p>
                  <p className="payment-info__desc">
                    - Caso você feche o navegador após realizar o pagamento, aguarde 5 minutos e verifique o status de
                    seu pedido em "Pedidos" no SM Direto.
                  </p>
                  <p className="payment-info__desc">
                    - Caso o pagamento não seja realizado dentro do período de 10 minutos, o pedido será automaticamente
                    cancelado. Se cancelado, a chave expirará e você precisará gerar um novo pedido.
                  </p>
                </div>
              )}

              {(this.state.acceptDeliveryTerms || this.state.showPaymentMethod) && <Pix />}
            </Panel>
          </SwapItem>

          <SwapItem name="card-form">
            <CreditCard />
          </SwapItem>

          <SwapItem name="">
            <Panel
              active={this.state.active}
              setActive={panel => this._setActive(panel)}
              hasBillet={this.state.hasBillet}
              hasCard={this.state.hasCard}
              thisParent={this}
              hybridBilletActivation={this.state.hybridBilletActivation}
              isPixEnabled={this.state.isPixEnabled}
            />
          </SwapItem>
        </Swap>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    orders: state.order.orders,
    activeVoucher: state.activeVoucher.voucher,
    userData: state.sessionReducer,
    paymentOptions: state.school.payment_options,
    school: state.school,
    checkout: state.checkout,
  }
}

const mapDispatchToProps = dispatch => ({
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  getOrders: userId => dispatch(getOrders({ id: userId })),
  agreeDelivery: () => dispatch(agreeDelivery()),
})

// export default Payment
export default connect(mapStateToProps, mapDispatchToProps)(Payment)

