import {
  FAILURE_ORDERS,
  FETCH_ORDERS,
  SUCCEED_ACTIVE_ORDER,
  SUCCEED_ORDERS,
  SUCCEED_ORDERS_INFINITE,
} from '@actions/order.js'
const init = {
  orders: [],
}

export default (state = init, action) => {
  switch (action.type) {
    case FETCH_ORDERS:
      return { ...init, ...state, ...action.isFetching }

    case SUCCEED_ORDERS:
      return { ...state, ...action.payload }

    case FAILURE_ORDERS:
      return { ...init, ...action.error }

    case SUCCEED_ACTIVE_ORDER:
      return { ...state, active: action.payload }

    case SUCCEED_ORDERS_INFINITE:
      return {
        ...state,
        ...action.payload,
        orders: state.orders.concat(action.payload.orders),
      }

    default:
      return state
  }
}
