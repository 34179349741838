import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader'
import { downloadContract, saveExpectedStart } from '@services/sm-api'

import CustomDateInput from '../MultipleDateInput/CustomDateInput'
import DatePicker from 'react-datepicker'
import FileDownloader from '@shared/FileDownloader'
import FileInput from '@shared/FileInput'
import { Modal } from 'react-bootstrap'
import MultipleDateInput from '../MultipleDateInput'
import PermissionsChecker from '@shared/PermissionsChecker'
import Report from '@containers/Report'
import _ from 'lodash'
import { connect } from 'react-redux'
import exp from './images/export.png'
import moment from 'moment'
import { sendContract, syncSchoolGrid } from '@services/sm-api'
import { validateContract } from '@actions/school'

import DigitalSolution from './DigitalSolution'
import AutomaticInactivation from './AutomaticInactivation'
import TableSapPricing from './DigitalSolution/TableSapPricing'
import { redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Overview = props => {
  return (
    <div className="schooloverview">
      <span className="schooloverview__title">Overview</span>
      <div className="schooloverview__columns">
        {props.data.map((value, index) => (
          <div key={index} className="schooloverview__column">
            <span className="schooloverview__value">{value.field}</span>
            <span className="schooloverview__label">{value.name}</span>
          </div>
        ))}
      </div>
      <div className="schooloverview__expected_start">
        <PermissionsChecker permissions={['manage_school_expected_start']}>
          <DatePicker
            customInput={<CustomDateInput label={`Previsão de início das aulas:`} />}
            dateFormat="DD/MM/YYYY"
            selected={props.expected_start ? moment(props.expected_start) : moment()}
            onChange={date => props.handleExpectedStartChange(date)}
          />
        </PermissionsChecker>
        <PermissionsChecker permissions={['manage_school_expected_start']} negate>
          <CustomDateInput
            label={`Previsão de início das aulas:`}
            value={moment(props.expected_start).format('DD/MM/YYYY')}
          />
        </PermissionsChecker>
      </div>
    </div>
  )
}

const education_level_labels = {
  EI: 'Ensino Infantil',
  EF1: 'Ensino Fundamental 1',
  EF2: 'Ensino Fundamental 2',
  EM: 'Ensino Médio',
}

const SchoolCard = ({ title, data }) => {
  return (
    <div className="schoolcard">
      <span className="schoolcard__title">{title}</span>
      {data()}
    </div>
  )
}

const DisableDateSync = props => {
  return (
    <label className="schoolcard__checkbox_area">
      <input type="checkbox" {...props} autoComplete="off" />
      <span>Desativar sincronização de datas de corte do SIC</span>
    </label>
  )
}

const DisablePeriodSync = props => {
  return (
    <label className="schoolcard__checkbox_area">
      <input type="checkbox" {...props} autoComplete="off" />
      <span>Desativar sincronização com o SIC</span>
    </label>
  )
}

const SelectDeliveryType = props => {
  return (
    <select {...props}>
      <option value=""></option>
      <option value="2">Bimestral</option>
      <option value="3">Trimestral</option>
      <option value="5">Semestral</option>
      <option value="6">Anual</option>
      <option value="7">Imediata</option>
    </select>
  )
}

const SchoolBusinessModel = props => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirectiom: 'row',
        marginTop: '10px',
      }}
    >
      <label className="schoolcard__checkbox_area">
        <input
          type="radio"
          name="school_business_model"
          value={0}
          autoComplete="off"
          checked={props.is_b2c !== null && Number(props.is_b2c) === 0}
          {...props}
        />
        <span>Modelo B2B</span>
      </label>
      <label
        className="schoolcard__checkbox_area"
        style={{
          marginLeft: '20px',
        }}
      >
        <input
          type="radio"
          name="school_business_model"
          value={1}
          autoComplete="off"
          checked={props.is_b2c !== null && Number(props.is_b2c) === 1}
          {...props}
        />
        <span>Modelo B2C</span>
      </label>
    </div>
  )
}

class SchoolDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      message: null,
      fadeOut: false,
      date_start: null,
      date_end: null,
      disabled: true,
      cut_dates: [],
      disable_sync: false,
      priceAlertModalOpen: false,
      syncGridModalOpen: false,
      syncGridResponseText: '',
      cutDateModalOpen: false,
      updateAlertModalOpen: false,
      productsWithoutPrice: [],
      is_pristine: false,
      is_b2c: null,
      cache_buster: Date.now(),
      save_delivery_type_disabled: true,
      disable_period_sync: false,
      ef1: null,
      ef2: null,
      em: null,
      ei: null,
      delivery_type_modal_open: false,
    }
    this._handleUpload = this._handleUpload.bind(this)
    this._handleError = this._handleError.bind(this)
    this._handleOnChange = this._handleOnChange.bind(this)
    this._handleSave = this._handleSave.bind(this)
    this._handleChangeDateSync = this._handleChangeDateSync.bind(this)
    this._handleChangeBusinessModel = this._handleChangeBusinessModel.bind(this)
    this._handleExpectedStartChange = this._handleExpectedStartChange.bind(this)
  }

  _handleError(error) {
    this.setState({ message: error.message, fadeOut: false }, () =>
      setTimeout(() => this.setState({ fadeOut: true }), 1000),
    )
  }

  _handleUpload(file) {
    this.setState({ message: null, fadeOut: false })

    sendContract(this.props.school.id, {
      file_mime: file.type,
      file: file.dataUrl,
    })
      .then(res => {
        switch (res.code) {
          case 200:
            this.setState({ message: 'Arquivo carregado com sucesso!' })
            break

          case 304:
            this.setState({ message: 'Arquivo já existe.' })
            break

          default:
            break
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  _handleExpectedStartChange(date) {
    this.props.openLoader()
    saveExpectedStart({
      school_id: this.props.school.id,
      expected_start: moment(date).format('YYYY-MM-DD'),
    })
      .then(() => {
        this.setState({
          expected_start: date,
        })
      })
      .finally(() => this.props.closeLoader())
  }

  _handleChangeDateSync() {
    this.setState({
      disabled: false,
      disable_sync: !this.state.disable_sync,
    })
  }

  _handleChangeBusinessModel(e) {
    this.setState({
      disabled: false,
      is_b2c: Number(e.target.value),
    })
  }

  _handleSave() {
    this._setAlertModal('cutDateModalOpen', false, [])
    const data = {
      id: this.props.school.id,
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      cut_dates: this.state.cut_dates,
      disable_sync: this.state.disable_sync,
      is_b2c: this.state.is_b2c,
    }
    this.props.validateContract(this.props.school.id).then(response => {
      if (response.success) {
        this.props.update(data).then(updated => {
          window.location.reload()
        })
      } else {
        this._setAlertModal('priceAlertModalOpen', true, response.products)
      }
    })
  }

  _handleSaveDeliveryType() {
    if (this.state.ef1 && this.state.ef2 && this.state.em && this.state.ei) {
      const data = {
        id: this.props.school.id,
        ef1: this.state.ef1,
        ef2: this.state.ef2,
        em: this.state.em,
        ei: this.state.ei,
        disable_period_sync: this.state.disable_period_sync ? 1 : 0,
      }
      this.props.updateDeliveryType(data).then(updated => {
        window.location.reload()
      })
    } else {
      this._setAlertModal('delivery_type_modal_open', true, ['teste'])
    }
  }

  _handleOnChange(e) {
    this.setState({
      disabled: false,
    })
    this.setState({ ...e })
  }

  _setAlertModal(alertType, show, data) {
    this.setState({
      [alertType]: show,
      productsWithoutPrice: data,
    })
  }

  goToEditDiscount = schoolId => {
    redirect(`/admin/escolas/desconto/${schoolId}`)
  }

  componentDidMount() {
    this.props.school &&
      this.setState({
        date_start: this.props.school.contract_start,
        date_end: this.props.school.contract_end,
        cut_dates: this.props.school.contract_cut_dates || [],
        disable_sync: this.props.school.disable_dates_sync,
        is_pristine: this.props.school.contract_is_pristine,
        is_b2c: this.props.school.is_b2c,
      })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this._setAlertModal('updateAlertModalOpen', true, [])
    }

    if (nextProps.school) {
      const nextState = {
        date_start: nextProps.school.contract_start,
        date_end: nextProps.school.contract_end,
        cut_dates: nextProps.school.contract_cut_dates || [],
        disable_sync: nextProps.school.disable_dates_sync,
        expected_start: nextProps.school.expected_start,
        is_pristine: nextProps.school.contract_is_pristine,
        is_b2c: nextProps.school.is_b2c,
      }

      let currentState = {}

      if (this.props.school) {
        currentState = {
          date_start: this.props.school.contract_start,
          date_end: this.props.school.contract_end,
          cut_dates: this.props.school.contract_cut_dates || [],
          disable_sync: this.props.school.disable_dates_sync,
          expected_start: this.props.school.expected_start,
          is_pristine: this.props.school.contract_is_pristine,
          is_b2c: this.props.school.is_b2c,
        }
      }

      !_.isEqual(nextState, currentState) &&
        this.setState({
          date_start: nextProps.school.contract_start,
          date_end: nextProps.school.contract_end,
          cut_dates: nextProps.school.contract_cut_dates || [],
          disable_sync: nextProps.school.disable_dates_sync,
          expected_start: nextProps.school.expected_start,
          is_pristine: nextProps.school.contract_is_pristine,
          is_b2c: nextProps.school.is_b2c,
        })
    }

    if (nextProps.active_contract) {
      const nextContractState = {
        ef1: nextProps.active_contract.ensino_ef1,
        ef2: nextProps.active_contract.ensino_ef2,
        em: nextProps.active_contract.ensino_em,
        ei: nextProps.active_contract.ensino_ei,
        disable_period_sync: nextProps.active_contract.disable_period_sync,
      }

      let currentContractState = {}

      if (this.props.active_contract) {
        currentContractState = {
          ef1: this.props.active_contract.ensino_ef1,
          ef2: this.props.active_contract.ensino_ef2,
          em: this.props.active_contract.ensino_em,
          disable_period_sync: this.props.active_contract.disable_period_sync,
        }
      }

      !_.isEqual(nextContractState, currentContractState) && this.setState(nextContractState)
    }
  }

  render() {
    let { school, sync } = this.props

    if (!this.props.school || !this.props.contract) return null

    let overviewData = [
      {
        name: 'Vouchers',
        field: school.vouchers_quantity,
      },
      {
        name: 'Produtos',
        field: school.total_products,
      },
      {
        name: 'Alunos',
        field: school.students,
      },
    ]

    let directorData = [school.director_name, school.director_phone, school.director_email]
    let contactData = [school.school_address]

    let consultantData = [school.consultant_name, school.consultant_email]

    return (
      <div className="schooldetail">
        <div className="schooldetail__header">
          <h3 className="schooldetail__title">{school.name.toLowerCase()}</h3>
          <FileInput
            className="button__upload schooldetail__upload"
            restrict={['pdf']}
            limit={26214400}
            onChange={this._handleUpload}
            onError={this._handleError}
          >
            Upload de contrato
          </FileInput>

          <div className={`schooldetail__message${this.state.fadeOut ? '--opacity' : ''}`}>{this.state.message}</div>
        </div>

        <div className="schooldetail_wrapper">
          <div className="schooldetail_wrapper_col">
            <div className="overview-pack">
              <Overview
                data={overviewData}
                expected_start={this.state.expected_start}
                handleExpectedStartChange={this._handleExpectedStartChange}
              />
              {!_.isEmpty(school.contract_packs) && (
                <div className="schooloverview">
                  <span className="schooloverview__title">Pack Educamos</span>
                  <ul className="contract-packs">
                    {school.contract_packs.map(pack => {
                      let education_level = _.last(pack.education_level.split(' '))
                      education_level = education_level_labels[education_level]

                      return (
                        <li key={`pack-${pack.id}`}>
                          <span>{education_level}</span>
                          <label>
                            <PermissionsChecker permissions={['manage_contract_packs']}>
                              <input
                                id={pack.id}
                                className="field__checkbox"
                                type="checkbox"
                                checked={pack.pack_educamos}
                                value={pack.pack_educamos}
                                autoComplete="off"
                                onChange={event => {
                                  this.props.updatePackEducamos(pack.id, !pack.pack_educamos)
                                }}
                              />
                            </PermissionsChecker>
                            <PermissionsChecker permissions={['manage_contract_packs']} negate>
                              <input
                                id={pack.id}
                                className="field__checkbox"
                                type="checkbox"
                                checked={pack.pack_educamos}
                                value={pack.pack_educamos}
                                disabled
                                autoComplete="off"
                              />
                            </PermissionsChecker>
                            <span className="admin-form__checkboxlabel">{pack.pack_educamos ? 'Sim' : 'Não'}</span>
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </div>

            <SchoolCard
              title="Dados de Contato"
              data={() => (
                <div>
                  {contactData.map((value, index) => (
                    <span key={index} className="schoolcard__value">
                      {value}
                    </span>
                  ))}
                  <span className="schoolcard__title mt-15">Responsável</span>
                  {directorData.map((value, index) => (
                    <span key={index} className="schoolcard__value">
                      {value}
                    </span>
                  ))}
                  <span className="schoolcard__title mt-15">Consultor(a) SM Responsável</span>
                  {consultantData.map((value, index) => (
                    <span key={index} className="schoolcard__value">
                      {value}
                    </span>
                  ))}
                </div>
              )}
            />

            <PermissionsChecker permissions={['manage_contract_dates']}>
              <SchoolCard
                title="Tipo de entrega educamos"
                data={() => (
                  <React.Fragment>
                    <div className="delivery_type_educamos">
                      <div className="delivery_type_educamos_row">
                        <label>EI</label>
                        <SelectDeliveryType
                          onChange={event => {
                            this.setState({
                              save_delivery_type_disabled: false,
                              ei: event.target.value,
                            })
                          }}
                          value={this.state.ei}
                        />
                      </div>
                      <div className="delivery_type_educamos_row">
                        <label>EF1</label>
                        <SelectDeliveryType
                          onChange={event => {
                            this.setState({
                              save_delivery_type_disabled: false,
                              ef1: event.target.value,
                            })
                          }}
                          value={this.state.ef1}
                        />
                      </div>
                      <div className="delivery_type_educamos_row">
                        <label>EF2</label>
                        <SelectDeliveryType
                          onChange={event => {
                            this.setState({
                              save_delivery_type_disabled: false,
                              ef2: event.target.value,
                            })
                          }}
                          value={this.state.ef2}
                        />
                      </div>
                      <div className="delivery_type_educamos_row">
                        <label>EM</label>
                        <SelectDeliveryType
                          onChange={event => {
                            this.setState({
                              save_delivery_type_disabled: false,
                              em: event.target.value,
                            })
                          }}
                          value={this.state.em}
                        />
                      </div>
                      <DisablePeriodSync
                        className="schoolcard__checkbox"
                        checked={this.state.disable_period_sync}
                        onChange={() => {
                          this.setState({
                            save_delivery_type_disabled: false,
                            disable_period_sync: !this.state.disable_period_sync,
                          })
                        }}
                      />
                      <button
                        className="button schoolcard__save"
                        onClick={() => {
                          this._handleSaveDeliveryType()
                        }}
                        disabled={this.state.save_delivery_type_disabled}
                      >
                        Salvar
                      </button>
                    </div>
                  </React.Fragment>
                )}
              />
            </PermissionsChecker>

            <PermissionsChecker permissions={['manage_contract_dates']}>
              <SchoolCard
                title="Inativação automática"
                data={() => (
                  <AutomaticInactivation
                    contract_id={this.props.school.contract_id}
                    initial_business_lines_cut_dates={this.props.school.business_lines_cut_dates}
                  />
                )}
              />
            </PermissionsChecker>
          </div>
          <div className="schooldetail_wrapper_col">
            <SchoolCard
              data={() => (
                <div>
                  <span className="schoolcard__title">
                    <span>Descontos</span>
                    <PermissionsChecker permissions={['manage_school_discounts']}>
                      <Link to={`/admin/escolas/desconto/${school.id}`} className="button button__update">
                        Editar
                      </Link>
                    </PermissionsChecker>
                  </span>
                  <div className="school-discount-card">
                    <span className="d-block school-discount-card__title">Literatura</span>
                    {school.custom_discount_school_lij ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.school_discount_lij}</span>
                        <span className="schooloverview__label">Desconto escola</span>
                      </div>
                    )}
                    {school.custom_discount_lij ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.discount_voucher_lij}</span>
                        <span className="schooloverview__label">Desconto voucher</span>
                      </div>
                    )}
                  </div>
                  <div className="school-discount-card">
                    <span className="d-block school-discount-card__title">Didático</span>
                    {school.custom_discount_school ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.school_discount}</span>
                        <span className="schooloverview__label">Desconto escola</span>
                      </div>
                    )}
                    {school.custom_discount ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.discount_voucher}</span>
                        <span className="schooloverview__label">Desconto voucher</span>
                      </div>
                    )}
                  </div>
                  <div className="school-discount-card">
                    <span className="d-block school-discount-card__title">Digital</span>
                    {school.custom_discount_school_dig ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.school_discount_dig}</span>
                        <span className="schooloverview__label">Desconto escola</span>
                      </div>
                    )}
                    {school.custom_discount_dig ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.discount_voucher_dig}</span>
                        <span className="schooloverview__label">Desconto voucher</span>
                      </div>
                    )}
                  </div>
                  <div className="school-discount-card">
                    <span className="d-block school-discount-card__title">Educamos</span>
                    {school.custom_discount_school_edu ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.school_discount_edu}</span>
                        <span className="schooloverview__label">Desconto escola</span>
                      </div>
                    )}
                    {school.custom_discount_edu ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.discount_voucher_edu}</span>
                        <span className="schooloverview__label">Desconto voucher</span>
                      </div>
                    )}
                  </div>
                  <div className="school-discount-card">
                    <span className="d-block school-discount-card__title">Serviços</span>
                    {school.custom_discount_school_ser ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.school_discount_ser}</span>
                        <span className="schooloverview__label">Desconto escola</span>
                      </div>
                    )}
                    {school.custom_discount_ser ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.discount_voucher_ser}</span>
                        <span className="schooloverview__label">Desconto voucher</span>
                      </div>
                    )}
                  </div>
                  <div className="school-discount-card">
                    <span className="d-block school-discount-card__title">Bilíngue</span>
                    {school.custom_discount_school_bil ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.school_discount_bil}</span>
                        <span className="schooloverview__label">Desconto escola</span>
                      </div>
                    )}
                    {school.custom_discount_bil ? (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__label">Desconto personalizado</span>
                      </div>
                    ) : (
                      <div className="school-discount-card__column">
                        <span className="schooloverview__value">{school.discount_voucher_bil}</span>
                        <span className="schooloverview__label">Desconto voucher</span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            />

            <TableSapPricing schoolId={this.props.school.id} />

            <div className="schoolcard">
              <div className="schoolcard__title__buttons">
                <div className="schoolcard__title">
                  <span>Contrato</span>
                </div>
                <PermissionsChecker permissions={['manage_contract_dates']}>
                  {(this.state.is_pristine || this.props.user.roles[0].name === 'SM TI') && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <button
                        type="button"
                        style={{ textAlign: 'center' }}
                        className="button button__sync"
                        onClick={() => {
                          sync(school.id).then(() => window.location.reload())
                        }}
                      >
                        Sincronizar SIC
                      </button>
                      <button
                        type="button"
                        className="button button__sync"
                        onClick={() => {
                          this.props.openLoader()
                          syncSchoolGrid(school.school_sap_code)
                            .then(response =>
                              this.setState({
                                syncGridModalOpen: true,
                                syncGridResponseText: response.data.status,
                              }),
                            )
                            .catch(response =>
                              this.setState({
                                syncGridModalOpen: true,
                                syncGridResponseText: response.data.error,
                              }),
                            )
                            .finally(() => this.props.closeLoader())
                        }}
                      >
                        Sincronizar Grade Escolar
                      </button>
                    </div>
                  )}
                </PermissionsChecker>
              </div>
              <div className="schoolcard__content">Vigência do contrato</div>
              <div className="schoolcard__footer">
                <PermissionsChecker permissions={['manage_contract_dates']}>
                  {this.state.is_pristine || this.props.user.roles[0].name === 'SM TI' ? (
                    <MultipleDateInput
                      onChange={this._handleOnChange}
                      startDate={this.state.date_start}
                      cutDates={this.state.cut_dates}
                      endDate={this.state.date_end}
                    >
                      <DisableDateSync
                        className="schoolcard__checkbox"
                        checked={this.state.disable_sync}
                        onChange={this._handleChangeDateSync}
                      />

                      <SchoolBusinessModel
                        className="schoolcard__checkbox"
                        is_b2c={this.state.is_b2c}
                        onChange={this._handleChangeBusinessModel}
                      />
                      <button
                        className="button schoolcard__save"
                        onClick={() => {
                          if (this.props.user.roles[0].name === 'SM TI') {
                            this._handleSave()
                          } else {
                            this._setAlertModal('cutDateModalOpen', true, [])
                          }
                        }}
                        disabled={this.state.disabled ? 'disabled' : ''}
                      >
                        Salvar
                      </button>
                    </MultipleDateInput>
                  ) : (
                    <MultipleDateInput
                      onChange={this._handleOnChange}
                      startDate={this.state.date_start}
                      cutDates={this.state.cut_dates}
                      endDate={this.state.date_end}
                      blockEdit
                    />
                  )}
                </PermissionsChecker>
                <PermissionsChecker permissions={['manage_contract_dates']} negate>
                  <MultipleDateInput
                    onChange={this._handleOnChange}
                    startDate={this.state.date_start}
                    cutDates={this.state.cut_dates}
                    endDate={this.state.date_end}
                    blockEdit
                  />
                </PermissionsChecker>

                {school.contract_id && school.contract_has_file ? (
                  <FileDownloader
                    className="schoolcard__download"
                    onClick={() => downloadContract(school.contract_id)}
                    filename={`Contrato - ${moment(school.contract_start, 'YYYY-MM-DD').format('DD/MM/YYYY')}.pdf`}
                  >
                    <span>Fazer Download</span>
                    <img src={exp} alt="Exportar" />
                  </FileDownloader>
                ) : null}
              </div>
            </div>
          </div>
          <div className="schooldetail_wrapper_col_fullwidth">
            <PermissionsChecker permissions={['manage_contract_dates']}>
              <SchoolCard
                title="Soluções digitais"
                data={() => (
                  <DigitalSolution school_id={this.props.school.id} contract_id={this.props.school.contract_id} />
                )}
              />
            </PermissionsChecker>
          </div>
        </div>
        <div className="schooldetail__report">
          <Report
            title=""
            subTitle="Vouchers"
            url={`voucher/${school.id}/list?buster=${this.state.cache_buster}`}
            type="vouchers"
            exportId={school.id}
            onVoucherChanged={() => {
              this.props.onMount && this.props.onMount()
            }}
            onFilterLoaded={() => {
              this.props.onMount && this.props.onMount()
            }}
          />
        </div>
        <Modal show={this.state.priceAlertModalOpen}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Alerta sobre título(s) sem preço no e-cat</h1>
            </div>

            <div className="modalContent">
              <p>O(s) seguinte(s) ID(s) SAP está (estão) sem preço/inexistentes no e-cat:</p>
              <ul>
                {this.state.productsWithoutPrice.map(product => (
                  <li key={product.product_sap_code}>
                    {product.product_sap_code} {` ${!product.ecat_product_exists ? '(Não importado)' : '(Sem preço)'}`}
                  </li>
                ))}
              </ul>
              <p>Caso o livro já esteja com preço no e-cat, tente salvar o contrato novamente.</p>
            </div>

            <div className="modalFooter">
              <button className="modal-close" onClick={() => this._setAlertModal('priceAlertModalOpen', false, [])}>
                Fechar
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {this.props.error && (
          <Modal show={this.state.updateAlertModalOpen}>
            <Modal.Body>
              <div className="modalHeader">
                <span className="icon-alert" />
                <h1>Erro ao salvar contrato</h1>
              </div>

              <div className="modalContent">
                <p>{this.props.error.message}</p>
              </div>

              <div className="modalFooter">
                <button className="modal-close" onClick={() => this._setAlertModal('updateAlertModalOpen', false, [])}>
                  Fechar
                </button>
              </div>
            </Modal.Body>
          </Modal>
        )}
        <Modal show={this.state.syncGridModalOpen}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1></h1>
            </div>
            <div className="modalContent textCenter">
              <p>{this.state.syncGridResponseText}</p>
            </div>
            <div className="modalFooter">
              <button className="modal-close" onClick={() => this._setAlertModal('syncGridModalOpen', false, [])}>
                Fechar
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.cutDateModalOpen}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Atenção</h1>
            </div>

            <div className="modalContent">
              <p>
                A partir do momento que você confirmar estas datas de corte, você não poderá mais alterá-las, pois
                qualquer alteração de data de corte deverá ter a aprovação do comitê do SM Direto.
              </p>
              <p>A alteração só poderá ser realizada após a aprovação do comitê e apenas pela equipe de TI.</p>
              <p>Deseja continuar?</p>
            </div>

            <div className="actions-area">
              <button className="btn-accept" onClick={this._handleSave}>
                Sim
              </button>

              <button className="btn-decline" onClick={() => this._setAlertModal('cutDateModalOpen', false, [])}>
                Não, quero revisar
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.delivery_type_modal_open}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Erro ao salvar</h1>
            </div>

            <div className="modalContent">
              <p className="text-center">Tipo de entrega Educamos não pode ficar em branco.</p>
            </div>

            <div className="modalFooter">
              <button
                className="modal-close"
                onClick={() => this._setAlertModal('delivery_type_modal_open', false, [])}
              >
                Fechar
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  user: state.sessionReducer.user,
})

const mapDispatchToProps = dispatch => ({
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  validateContract: id => {
    return dispatch(validateContract(id))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(SchoolDetail)

