import React from 'react'

const NotFound = ({ routeToRedirect }) => {
  const styles = {
    container: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    error: {
      fontSize: '16rem',
      color: '#ec6262',
    },
    title: {
      fontSize: '5rem',
    },
    subTitle: {
      fontSize: '1.9rem',
      textDecoration: 'underline',
      cursor: 'pointer',
      color: '#6AABD6',
    },
  }

  return (
    <div style={styles.container}>
      <div>
        <h1 style={styles.error}>404</h1>
        <h1 style={styles.title}>Oops! Página não encontrada.</h1>
        <a href={routeToRedirect}>
          <h2 style={styles.subTitle}>Ir para página principal.</h2>
        </a>
      </div>
    </div>
  )
}

export default NotFound

