import { CHANGE_THEME, RESET_THEME } from '@actions/theme'
import { REHYDRATE } from 'redux-persist/constants'

export default (state = {}, action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...action.payload,
      }
    case RESET_THEME:
      return {}
    case REHYDRATE:
      return {}
    default:
      return state
  }
}
