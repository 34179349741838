import React, { useEffect, useState } from 'react'
import moment from 'moment'

import { PIX_EXPIRATION_TIME } from '@utils/pix'

export const Timer = ({ seconds: initialSeconds }) => {
  const [seconds, setSeconds] = useState(initialSeconds < 0 ? 0 : initialSeconds)
  const isValid = seconds >= 0 && seconds <= PIX_EXPIRATION_TIME

  useEffect(() => {
    const timer =
      seconds > 0 &&
      setInterval(() => {
        setSeconds(seconds - 1)
      }, 1000)
    return () => clearInterval(timer)
  }, [seconds])

  return (
    <div className="pix-timer">
      <span>{moment.utc(isValid ? seconds * 1000 : 0).format('mm:ss')}</span>
    </div>
  )
}

