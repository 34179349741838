import { FETCH_CONTRACTS, SUCCESS_CONTRACTS } from '@actions/importContracts'

export default (state = { isFetching: false }, action) => {
  switch (action.type) {
    case FETCH_CONTRACTS:
      return {
        ...state,
        isFetching: true,
      }

    case SUCCESS_CONTRACTS:
      return {
        ...action.payload,
        isFetching: false,
      }

    default:
      return state
  }
}
