import { getDigitalSolutionsAPI, updateDigitalSolutionsAPI } from '@services/sm-api'

export const SET_DIGITAL_SOLUTIONS = 'digitalSolutions/SET_DIGITAL_SOLUTIONS'
export const SET_DISABLE_DIGITAL_LICENSES = 'digitalSolutions/SET_DISABLE_DIGITAL_LICENSES'
export const SET_DIGITAL_SOLUTIONS_HYBRID = 'digitalSolutions/SET_DIGITAL_SOLUTIONS_HYBRID'
export const SET_DIGITAL_SOLUTIONS_VAL = 'digitalSolutions/SET_DIGITAL_SOLUTIONS_VAL'
export const FAILURE_DIGITAL_SOLUTIONS = 'digitalSolutions/FAILURE_DIGITAL_SOLUTIONS'
export const RESET_DIGITAL_SOLUTIONS = 'digitalSolutions/RESET_DIGITAL_SOLUTIONS'

export function setDigitalSolutions(payload) {
  return {
    type: SET_DIGITAL_SOLUTIONS,
    payload,
  }
}

export function setDisableDigitalLicenses(payload) {
  return {
    type: SET_DISABLE_DIGITAL_LICENSES,
    payload,
  }
}

export function setDigitalSolutionsHybrid(payload) {
  return {
    type: SET_DIGITAL_SOLUTIONS_HYBRID,
    payload,
  }
}

export function setDigitalSolutionsVal(payload) {
  return {
    type: SET_DIGITAL_SOLUTIONS_VAL,
    payload,
  }
}

export function failureDigitalSolutions(payload) {
  return {
    type: FAILURE_DIGITAL_SOLUTIONS,
    payload,
  }
}

export function resetDigitalSolutions() {
  return {
    type: RESET_DIGITAL_SOLUTIONS,
  }
}

export function getDigitalSolutions(data) {
  return dispatch =>
    getDigitalSolutionsAPI(data)
      .then(response => {
        dispatch(setDigitalSolutions(response))
      })
      .catch(error => {
        dispatch(failureDigitalSolutions(error))
      })
}

export function updateDigitalSolutions(data) {
  return dispatch =>
    updateDigitalSolutionsAPI(data)
      .then(response => {
        return response
      })
      .catch(error => {
        dispatch(failureDigitalSolutions(error))
        return error
      })
}
