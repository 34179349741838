import React, { Component } from 'react'
import faqicon from './images/faqicon.png'

export default class FaqHero extends Component {
  render() {
    return (
      <div className="faqhero">
        <div className="faqhero__image">
          <h1 className="faqhero__title">Perguntas Frequentes</h1>
          <img src={faqicon} alt="FAQ" />
        </div>
      </div>
    )
  }
}
