import { Message } from '@shared/Messaging'
import React, { Component } from 'react'
import { getActiveStudent, updateName } from '@actions/checkout'

import _ from 'lodash'
import { activateStudentForm } from '@actions/voucherForm'
import { connect } from 'react-redux'
import { updateActiveVoucher } from '@actions/activeVoucher'

class StudentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isInputsValid: false,
      isNameEmpty: false,
      showNameError: false,
      showEmptyVoucherError: false,
      nameErrorMessage: '',
      isSameSchool: true,
      exists: false,
      message: '',
    }

    this._checkErrors = this._checkErrors.bind(this)
    this._handleCancel = this._handleCancel.bind(this)
  }

  _checkErrors() {
    let valid = true

    let exists = false

    let name = this.refs.name.value.trim()

    let checkout = this.props.checkout

    let errorMessage = ''

    for (let i = 0; i < checkout.length; i++) {
      if (
        checkout[i].name.toLowerCase().replaceAll(" ","") === name.toLowerCase().replaceAll(" ","")
        &&
        checkout[i].voucher.toLowerCase() === this.props.activeVoucher.voucher.toLowerCase()
      ) {
        valid = false
        errorMessage = 'Esse nome já foi incluído para o voucher informado.'
        exists = true
        break
      }
    }

    if (name.match(/[^A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]/g)) {
      valid = false
      errorMessage = 'O nome não pode conter números'
    }

    if (name.split(' ').length < 2) {
      valid = false
      errorMessage = 'Por favor, preencha o nome completo'
    }

    this.setState({
      showNameError: !valid,
      nameErrorMessage: errorMessage,
      isInputsValid: valid,
      exists: exists,
    })
  }

  _handleCancel() {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'edit_aluno',
      eventAction: 'click',
      eventLabel: 'box_lateral_cancelar',
    })

    this.props.firstStudent()
  }

  componentDidMount() {
    this.refs.name.value = this.props.activeStudent.name
  }

  render() {
    return (
      <div className="voucher-form">
        <h1 className="voucher-form__title">Editar nome do aluno</h1>
        <React.Fragment>
          <input
            type="text"
            ref="name"
            autoComplete="off"
            placeholder="Qual é o nome do aluno?"
            className={`voucher-form__input${this.state.showNameError || this.state.exists ? '--error' : ''}`}
            onChange={() => {
              this.setState({ showNameError: false, exists: false })
              this._checkErrors()
            }}
          />
          <Message expr={this.state.showNameError}>
            <span className="voucher-form__info-text--error">{this.state.nameErrorMessage}</span>
          </Message>
          <div className="voucher-form__footer">
            <button
              className="button__normal voucher-form__cancel"
              onClick={this._handleCancel}
              placeholder="Qual é o nome do aluno?"
            >
              Cancelar
            </button>
            <button
              className="button__normal voucher-form__ok"
              onClick={() => {
                if (this.state.isInputsValid) {

                  var index  = this.props.checkout.findIndex((student) => {
                    return student.name === this.props.activeStudent.name
                  })

                  this.props.updateName(this.refs.name.value, index)
                }
              }}
              placeholder="Qual é o nome do aluno?"
            >
              Confirmar
            </button>
          </div>
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checkout: state.checkout,
  activeVoucher: state.activeVoucher,
  activeStudent: getActiveStudent(state),
})

const mapDispatchToProps = dispatch => ({
  updateName: (name, voucher, index) => dispatch(updateName(name, voucher, index)),
  hideForm: () => dispatch(activateStudentForm(false)),
  updateActiveVoucher: (voucher, name) => dispatch(updateActiveVoucher(voucher, name)),
})

const mergeProps = (stateProps, dispatchProps) => ({
  checkout: stateProps.checkout,
  activeVoucher: stateProps.activeVoucher,
  activeStudent: stateProps.activeStudent,
  hideForm: dispatchProps.hideForm,
  updateActiveVoucher: dispatchProps.updateActiveVoucher,
  firstStudent: () => {
    dispatchProps.hideForm()
    dispatchProps.updateActiveVoucher(stateProps.activeVoucher.voucher, stateProps.activeVoucher.name)
  },
  updateName: (name, index) => {
    dispatchProps.updateName(name, stateProps.activeVoucher.voucher, index)
    dispatchProps.hideForm()
    dispatchProps.updateActiveVoucher(stateProps.activeVoucher.voucher, name)
  },
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(StudentForm)
