import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import editarpequeno from './images/editarpequeno.png'

const StudentList = ({ students = [], edit }) => (
  <ul className="confirmlist">
    {students.map((student, index) => (
      <li className="confirmlist__item" key={index}>
        <div className="confirmlist__itemcontainer">
          {student.active_books} produtos do{' '}
          <span>
            {student.study_year} do {student.study_step}
          </span>{' '}
          para <span>{student.name}</span>
        </div>
        <NavLink onClick={() => edit(student)} to="/steps/selecionar-livros" className="confirmlist__editlink">
          <img src={editarpequeno} alt="" />
          alterar produtos
        </NavLink>
      </li>
    ))}
  </ul>
)

StudentList.propTypes = {
  students: PropTypes.array,
  edit: PropTypes.func,
}

export default StudentList
