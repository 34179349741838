import React from 'react'

const CopyToClipboard = ({ copyText }) => {
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
  }

  return (
    <span className="copy_to_clipboard_text" onClick={handleCopyClick}>
      {copyText}
    </span>
  )
}

export default CopyToClipboard

