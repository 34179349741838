const estados_brasil = {
  ACRE: 'AC',
  ALAGOAS: 'AL',
  AMAPA: 'AP',
  AMAZONAS: 'AM',
  BAHIA: 'BA',
  CEARA: 'CE',
  DISTRITOFEDERAL: 'DF',
  ESPIRITOSANTO: 'ES',
  GOIAS: 'GO',
  MARANHAO: 'MA',
  MATOGROSSO: 'MT',
  MATOGROSSODOSUL: 'MS',
  MINASGERAIS: 'MG',
  PARA: 'PA',
  PARAIBA: 'PB',
  PARANA: 'PR',
  PERNAMBUCO: 'PE',
  PIAUI: 'PI',
  RIODEJANEIRO: 'RJ',
  RIOGRANDEDONORTE: 'RN',
  RIOGRANDEDOSUL: 'RS',
  RONDONIA: 'RO',
  RORAIMA: 'RR',
  SANTACATARINA: 'SC',
  SAOPAULO: 'SP',
  SERGIPE: 'SE',
  TOCANTINS: 'TO',
  AC: 'AC',
  AL: 'AL',
  AP: 'AP',
  AM: 'AM',
  BA: 'BA',
  CE: 'CE',
  DF: 'DF',
  ES: 'ES',
  GO: 'GO',
  MA: 'MA',
  MT: 'MT',
  MS: 'MS',
  MG: 'MG',
  PA: 'PA',
  PB: 'PB',
  PR: 'PR',
  PE: 'PE',
  PI: 'PI',
  RJ: 'RJ',
  RN: 'RN',
  RS: 'RS',
  RO: 'RO',
  RR: 'RR',
  SC: 'SC',
  SP: 'SP',
  SE: 'SE',
  TO: 'TO',
}

export default function NormalizeBrazilianStates(estado) {
  estado = estado.normalize('NFD').replace(' ', '').toUpperCase()
  return estados_brasil[estado] || null
}
