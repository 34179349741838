import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { Form, Text, Textarea, Radio, RadioGroup } from 'react-form'
import { InputCheckbox, InputFile } from '@shared/CustomField'
import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader.js'
import { closeModal, openModal } from '@actions/modal.js'
import { listVariables, updateVariable } from '@services/sm-api'

import ConfirmDialog from '@shared/ConfirmDialog'
import _ from 'lodash'
import { connect } from 'react-redux'
import moment from 'moment'
import { UFInputGroup } from '../../shared/CustomField'

class VariableForm extends Component {
  _handleEdit(values) {
    updateVariable(this.props.variable.key, values.value)
      .then(() => {
        this.props.openModal(
          <ConfirmDialog
            text="Variável salva com sucesso"
            okLabel="OK"
            ok={() => {
              this.props.closeModal()
            }}
          />,
        )
      })
      .catch(error => {
        let errorMessage = 'Problemas ao salvar variável Tente novamente mais tarde.'

        this.props.openModal(
          <ConfirmDialog
            text={errorMessage}
            okLabel="OK"
            ok={() => {
              this.props.closeModal()
            }}
          />,
        )
      })
  }

  componentDidMount() {
    this.refs.Form.setAllValues({
      value: this.props.variable.value,
    })
  }

  render() {
    //console.log(this.props)
    const { variable } = this.props
    return (
      <Form
        ref="Form"
        onSubmit={values => {
          this._handleEdit(values)
        }}
      >
        {({ submitForm, swapTo, setValue, getValue, values }) => {
          return (
            <form className="admin-form__form" onSubmit={submitForm}>
              <div className="field">
                {variable.description && <div className="description">{variable.description}</div>}
                {variable.updated_at && (
                  <div>Última atualização: {moment(variable.updated_at).format('DD/MM/YYYY')}</div>
                )}
                {variable.type === 'string' && <Text className="field__text" field="value" autoComplete="off" />}

                {variable.type === 'number' && (
                  <Text className="field__text" field="value" autoComplete="off" type="number" />
                )}

                {variable.type === 'text' && variable.id !== 18 && (
                  <Textarea field="value" cols="70" rows="8" autoComplete="off" />
                )}

                {variable.type === 'file' && (
                  <InputFile
                    field="value"
                    current={
                      variable.value ? `${process.env.REACT_APP_APPSETTING_SM_PHP_API_ENDPOINT}${variable.value}` : null
                    }
                  />
                )}

                {variable.type === 'checkbox' && (
                  <InputCheckbox field="value" checked={Boolean(Number(getValue('value')))} />
                )}

                {variable.type === 'radio' && (
                  <RadioGroup errorBefore={false} field="value">
                    <label className="field__radio__option">
                      <Radio value="pcm" />
                      <span className="field__radio__label">PCM</span>
                    </label>
                    <label className="field__radio__option">
                      <Radio value="ecat" />
                      <span className="field__radio__label">ECAT</span>
                    </label>
                  </RadioGroup>
                )}

                {variable.id === 18 && <UFInputGroup field="value" />}

                <button className="admin-form__button button__normal" type="submit">
                  Salvar
                </button>
              </div>
              <div className="field">
                <hr className="admin-form__division" />
              </div>
            </form>
          )
        }}
      </Form>
    )
  }
}

class Variables extends Component {
  constructor(props) {
    super(props)

    this.hideDefaultError = { style: { display: 'none' } }

    this.state = {
      message: '',
      variables: [],
    }
  }

  componentDidMount() {
    const { openLoader, closeLoader } = this.props
    openLoader()
    listVariables()
      .then(variables => {
        this.setState({ variables })
      })
      .finally(closeLoader)
  }

  render() {
    return (
      <div className="notification-form">
        <div className="admin-form__header">
          <h1 className="admin-form__title">Variáveis</h1>
        </div>

        <Accordion allowZeroExpanded>
          {this.state.variables.map(variable => (
            <AccordionItem key={`variable-${variable.id}`}>
              <AccordionItemHeading>
                <AccordionItemButton>{variable.name}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <VariableForm variable={variable} {...this.props} />
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sessionReducer: state.sessionReducer,
  user: state.user,
})

const mapDispatchToProps = { openLoader, closeLoader, openModal, closeModal }

export default connect(mapStateToProps, mapDispatchToProps)(Variables)

