import { getDefaultData } from '@services/sm-api'

export const GET_LIST = 'ticket/GET_LIST'
export const NEXT_LIST = 'ticket/NEXT_LIST'
export const FAILURE_LIST = 'ticket/FAILURE_LIST'

export function getList(payload) {
  return {
    type: GET_LIST,
    payload,
  }
}

export function nextList(payload) {
  return {
    type: NEXT_LIST,
    payload,
  }
}

export function failureList() {
  return {
    type: FAILURE_LIST,
  }
}

export function fetchTicketList() {
  return dispatch =>
    getDefaultData('/cases/report/list')
      .then(response => dispatch(getList(response)))
      .catch(error => dispatch(failureList()))
}

export function fetchNextTicketList() {
  return (dispatch, getState) => {
    const state = getState().ticket
    let currentPage = Number(state.page)
    if (currentPage < state.total_pages) {
      let page = currentPage + 1
      getDefaultData('/cases/report/list', { page })
        .then(response => dispatch(nextList(response)))
        .catch(error => console.log)
    }
  }
}
