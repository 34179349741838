import {
  RESET_VOUCHERS_INACTIVATION,
  FAILURE_VOUCHERS_INACTIVATION,
  SET_VOUCHERS_INACTIVATION,
} from '@actions/vouchersInactivation'
import { REHYDRATE } from 'redux-persist/constants'

let initialState = {
  business_lines: [],
  error: null,
}

export default (state = initialState, action, reset_state = initialState) => {
  switch (action.type) {
    case SET_VOUCHERS_INACTIVATION:
      return {
        ...state,
        business_lines: action.payload,
      }

    case FAILURE_VOUCHERS_INACTIVATION:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_VOUCHERS_INACTIVATION:
      return {
        ...reset_state,
      }

    case REHYDRATE:
      return {
        ...state,
      }

    default:
      return state
  }
}
