import React from 'react'
import { Modal } from 'react-bootstrap'

export const TeacherDuplicatedOrderModal = ({ show }) => (
  <Modal show={show}>
    <Modal.Body>
      <div className="modalHeader">
        <span className="icon-alert" />
        <h1>Atenção</h1>
      </div>
      <div className="modalContent">
        <p className="text-center">
          Você já fez uma compra com este mesmo <strong>CPF</strong> e <strong>Voucher!</strong>
        </p>
      </div>
      <div className="modalFooter">
        <button
          id="payment-agreeing-button"
          className="modal-close"
          onClick={() => {
            window.localStorage.removeItem('reduxPersist:checkout')
            window.localStorage.removeItem('reduxPersist:preVoucher')
            window.location.href = '/'
          }}
        >
          Entendi
        </button>
      </div>
    </Modal.Body>
  </Modal>
)

