import { GET_FREIGHT, RESET_FREIGHT, FAILURE_FREIGHT } from '@actions/freight'
import { REHYDRATE } from 'redux-persist/constants'

let initialState = {
  freight_redux_requests: 0,
  calculated_freight: null,
  error: null,
}

export default (state = initialState, action, reset_state = initialState) => {
  switch (action.type) {
    case GET_FREIGHT:
      return {
        ...state,
        ...action.payload,
        freight_redux_requests: state.freight_redux_requests + 1,
      }
    case FAILURE_FREIGHT:
      return {
        ...state,
        ...action.payload,
        freight_redux_requests: state.freight_redux_requests + 1,
      }

    case RESET_FREIGHT:
      return {
        ...reset_state,
        freight_redux_requests: state.freight_redux_requests + 1,
      }

    case REHYDRATE:
      return {
        ...state,
      }

    default:
      return state
  }
}
