import { OPEN_LOADER, CLOSE_LOADER } from '@actions/loader.js'
import { REHYDRATE } from 'redux-persist/constants'
export default (state = { show: false }, action) => {
  switch (action.type) {
    case OPEN_LOADER:
      return { ...state, show: action.show }

    case CLOSE_LOADER:
      return { ...state, show: action.show }

    case REHYDRATE:
      return { ...state, show: false }

    default:
      return state
  }
}
