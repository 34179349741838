import React from 'react'
import _ from 'lodash'
import logo from './images/footer_logo.png'
import logo_facebook from './images/logo_facebook.png'
import logo_youtube from './images/logo_youtube.png'

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="footer__container">
          <div className="footer__logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className="footer__social">
            <span>Siga a SM nas redes sociais</span>
            <ul className="footer__list">
              <li>
                <a href="https://www.facebook.com/SMEducacao" target="_blank">
                  <img src={logo_facebook} alt="Facebook" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/user/edicoessm" target="_blank">
                  <img src={logo_youtube} alt="Youtube" />
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__address">
            <address>
              <span>Av. Paulista, 1842 – 18º andar</span>
              <span>Bela Vista - São Paulo - SP</span>
              <span>CEP: 01310-945</span>
            </address>
          </div>
          <div className="footer__work">
            <a href="https://www.linkedin.com/company/sm-educacao/" target="_blank" className="footer__link">
              <strong>Trabalhe Conosco</strong>
            </a>
            <br />
            <a href="/politica-de-cookies.html" target="_blank" className="footer__link">
              <strong>Política de Cookies</strong>
            </a>
            <br />
            <a href="/politica-de-compras.html" target="_blank" className="footer__link">
              <strong>Política de Compras</strong>
            </a>
            <br />
            <a href="/politica-de-privacidade.html" target="_blank" className="footer__link">
              <strong>Política de Privacidade</strong>
            </a>
            <br />
            <a href="/termos-de-uso.html" target="_blank" className="footer__link">
              <strong>Termos de uso</strong>
            </a>
          </div>
          <div className="footer__group">
            <a href="http://www.fundacaosmbrasil.org/" target="_blank" className="footer__link">
              Fundação SM
            </a>
            <br />
            <a href="https://www.smeducacao.com.br/" target="_blank" className="footer__link">
              SM Educação
            </a>
            <br />
          </div>
        </div>
      </div>
    )
  }
}

