import React, { Component } from 'react'
import { Swap, SwapItem } from '@components/Swap'

import AdminMenu from './AdminMenu'
import LoginModal from '../LoginModal'
import MainMenu from './MainMenu'
import Menu from '@components/Menu'
import { connect } from 'react-redux'
import { openModal } from '@actions/modal'
import shadeColor from '@shared/shadeColor'
import sm_logo from './images/logo_header.png'
import { withRouter } from '../../utils/withRouter'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 989,
      height: 0,
      isMobile: false,
    }

    this._resize = this._resize.bind(this)
  }

  _resize() {
    this.setState({ width: window.innerWidth, height: window.innerHeight, isMobile: window.innerWidth < 988 })
  }

  componentDidMount() {
    this._resize()
    window.addEventListener('resize', this._resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resize)
  }

  _activeMenu() {
    const { pathname } = this.props.location
    let menu
    if (
      pathname.match(/\/steps(\/|\w|-)*/) ||
      pathname === '/' ||
      pathname === '/desconto-professor' ||
      pathname.match(/\/faq(\/|\w|-)*/)
    )
      menu = 'main-menu'
    if (pathname.match(/\/admin(\/|\w|-)*/)) menu = 'admin-menu'
    return menu
  }

  render() {
    let { user, theme } = this.props
    let logoColor = theme.background ? shadeColor(theme.background, -10) : 'transparent'
    let isLogged = typeof user.email !== 'undefined' ? 'logged' : ''
    let linkColor = {
      style: {
        color: theme.text,
      },
    }

    return (
      <div className="header" style={{ backgroundColor: theme.background }}>
        <div className="header__grid">
          <div className="header__column">
            <a href="/" className={`header__logo ${isLogged}`} style={{ backgroundColor: logoColor }}>
              <img alt="logo" src={user.school_logo || sm_logo} />
            </a>
          </div>
          <div className="header__column">
            <i
              {...linkColor}
              title="Entrar"
              className="header__item fa fa-user fa-lg"
              onClick={() => this.props.openModal(<LoginModal />)}
            ></i>
            <Swap active={this._activeMenu()}>
              <SwapItem name="main-menu">
                <Menu theme={theme}>
                  <MainMenu />
                </Menu>
              </SwapItem>
              <SwapItem name="admin-menu">
                <Menu
                  theme={theme}
                  backgroundColor={this.state.isMobile ? '#fff' : ''}
                  width={this.state.isMobile ? '240px' : ''}
                >
                  {!this.state.isMobile ? <MainMenu /> : <AdminMenu />}
                </Menu>
              </SwapItem>
            </Swap>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.sessionReducer.user,
  routerReducer: state.routerReducer,
  theme: state.theme,
})

export default withRouter(connect(mapStateToProps, { openModal })(Header))

