import React, { Component } from 'react'

export default class FileDownloader extends Component {
  constructor(props) {
    super(props)
    this._handleClick = this._handleClick.bind(this)
  }

  _handleClick() {
    this.props.onClick().then(response => {
      let blob = new Blob([response])
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = this.props.filename
      link.click()
      link.remove()
    })
  }

  render() {
    return (
      <div className={this.props.className} onClick={this._handleClick}>
        {this.props.children}
      </div>
    )
  }
}
