import { GET_LIST, NEXT_LIST } from '@actions/ticket'
import { REHYDRATE } from 'redux-persist/constants'

let initialState = {
  cases: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST:
      return {
        ...state,
        ...action.payload,
      }

    case NEXT_LIST:
      return {
        ...state,
        ...action.payload,
        cases: state.cases.concat(action.payload.cases),
      }

    case REHYDRATE:
      return {
        ...state,
      }

    default:
      return state
  }
}
