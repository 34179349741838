import React, { Component } from 'react'
import { Form } from 'react-form'
import { InputText, InputCheckbox } from '@shared/CustomField'
import { fetchDiscount, updateDiscount } from '@actions/school'
import { connect } from 'react-redux'
import { openLoader, closeLoader } from '@actions/loader.js'
import { Link, Navigate } from 'react-router-dom'
import { withRouter } from '../../utils/withRouter'

class EditDiscount extends Component {
  constructor(props) {
    super(props)

    this.state = {
      contract_id: null,
      discount: 0,
      discount_school: 0,
      discount_lij: 0,
      discount_school_lij: 0,
      discount_dig: 0,
      discount_school_dig: 0,
      discount_edu: 0,
      discount_school_edu: 0,
      discount_ser: 0,
      discount_school_ser: 0,
      discount_school_bil: 0,
      discount_bil: 0,
      disable_discount_sync: false,
      has_custom_discount: false,
      has_custom_discount_lij: false,
      has_custom_discount_dig: false,
      has_custom_discount_edu: false,
      has_custom_discount_ser: false,
      has_custom_discount_bil: false,
      isDone: false,
    }
  }

  _updateDiscount(values) {
    this.props.openLoader()
    this.props
      .updateDiscount(values.contract_id, values)
      .then(() => {
        this.props.closeLoader()
        this.setState({ isDone: true })
      })
      .finally(() => {
        this.props.closeLoader()
      })
  }

  _fillFields(data) {
    if (typeof this.refs.Form !== 'undefined') {
      this.refs.Form.setAllValues({ ...data }, true)
    }
  }

  UNSAFE_componentWillMount() {
    this.props
      .fetchDiscount(Number(this.props.params.school_id))
      .then(result => {
        let {
          has_custom_discount,
          has_custom_discount_lij,
          has_custom_discount_dig,
          has_custom_discount_edu,
          has_custom_discount_ser,
          has_custom_discount_bil,
        } = result[0]

        this.setState({
          has_custom_discount,
          has_custom_discount_lij,
          has_custom_discount_dig,
          has_custom_discount_edu,
          has_custom_discount_ser,
          has_custom_discount_bil,
        })

        this._fillFields(result[0])
      })
      .catch(error => {
        throw error
      })
  }

  render() {
    return (
      <div>
        {this.state.isDone && <Navigate to={`/admin/escolas/${this.props.params.school_id}`} />}
        <h1 className="report__title">Editar descontos</h1>
        <hr />
        <Form
          ref="Form"
          onSubmit={values => {
            this._updateDiscount(values)
          }}
        >
          {({ submitForm }) => {
            return (
              <div className="row">
                <div className="col-sm-6">
                  <form className="edit_discount_Form" onSubmit={submitForm}>
                    <h1>Literatura</h1>
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Escola (%)</span>
                          <InputText
                            field="discount_school_lij"
                            onKeyPress={e => {
                              if (isNaN(e.key) && e.key !== '.' && e.key !== '.') {
                                e.preventDefault()
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Comprador (%)</span>
                          {this.state.has_custom_discount_lij ? (
                            <p className="field__text">Personalizado</p>
                          ) : (
                            <InputText
                              field="discount_lij"
                              onKeyPress={e => {
                                if (isNaN(e.key) && e.key !== '.') {
                                  e.preventDefault()
                                }
                              }}
                            />
                          )}
                        </label>
                      </div>
                    </div>

                    <h1>Didático</h1>
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Escola (%)</span>
                          <InputText
                            field="discount_school"
                            onKeyPress={e => {
                              if (isNaN(e.key) && e.key !== '.') {
                                e.preventDefault()
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Comprador (%)</span>
                          {this.state.has_custom_discount ? (
                            <p className="field__text">Personalizado</p>
                          ) : (
                            <InputText
                              field="discount"
                              onKeyPress={e => {
                                if (isNaN(e.key) && e.key !== '.') {
                                  e.preventDefault()
                                }
                              }}
                            />
                          )}
                        </label>
                      </div>
                    </div>

                    <h1>Educamos</h1>
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Escola (%)</span>
                          <InputText
                            field="discount_school_edu"
                            onKeyPress={e => {
                              if (isNaN(e.key) && e.key !== '.') {
                                e.preventDefault()
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Comprador (%)</span>
                          {this.state.has_custom_discount_edu ? (
                            <p className="field__text">Personalizado</p>
                          ) : (
                            <InputText
                              field="discount_edu"
                              onKeyPress={e => {
                                if (isNaN(e.key) && e.key !== '.') {
                                  e.preventDefault()
                                }
                              }}
                            />
                          )}
                        </label>
                      </div>
                    </div>

                    <h1>Digital</h1>
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Escola (%)</span>
                          <InputText
                            field="discount_school_dig"
                            onKeyPress={e => {
                              if (isNaN(e.key) && e.key !== '.') {
                                e.preventDefault()
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Comprador (%)</span>
                          {this.state.has_custom_discount_dig ? (
                            <p className="field__text">Personalizado</p>
                          ) : (
                            <InputText
                              field="discount_dig"
                              onKeyPress={e => {
                                if (isNaN(e.key) && e.key !== '.') {
                                  e.preventDefault()
                                }
                              }}
                            />
                          )}
                        </label>
                      </div>
                    </div>

                    <h1>Serviço</h1>
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Escola (%)</span>
                          <InputText
                            field="discount_school_ser"
                            onKeyPress={e => {
                              if (isNaN(e.key) && e.key !== '.') {
                                e.preventDefault()
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Comprador (%)</span>
                          {this.state.has_custom_discount_ser ? (
                            <p className="field__text">Personalizado</p>
                          ) : (
                            <InputText
                              field="discount_ser"
                              onKeyPress={e => {
                                if (isNaN(e.key) && e.key !== '.') {
                                  e.preventDefault()
                                }
                              }}
                            />
                          )}
                        </label>
                      </div>
                    </div>
                    <h1>Bilíngue</h1>
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Escola (%)</span>
                          <InputText
                            field="discount_school_bil"
                            onKeyPress={e => {
                              if (isNaN(e.key) && e.key !== '.') {
                                e.preventDefault()
                              }
                            }}
                          />
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <label className="field">
                          <span className="field__label">Desconto Comprador (%)</span>
                          {this.state.has_custom_discount_bil ? (
                            <p className="field__text">Personalizado</p>
                          ) : (
                            <InputText
                              field="discount_bil"
                              onKeyPress={e => {
                                if (isNaN(e.key) && e.key !== '.') {
                                  e.preventDefault()
                                }
                              }}
                            />
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <label className="field">
                          <span className="field__label">Desativar sincronização de desconto do SIC</span>
                          <InputCheckbox field="disable_discount_sync"></InputCheckbox>
                        </label>
                      </div>
                    </div>

                    <div className="field">
                      <div className="row">
                        <div className="col-sm-2">
                          <button className="button__normal" type="submit">
                            Salvar
                          </button>
                        </div>
                        <div className="col-sm-2">
                          <Link to={`/admin/escolas/${this.props.params.school_id}`} className="button button__normal">
                            Cancelar
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )
          }}
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  params: props.params,
  contract_id: state.contract_id,
  discount: state.discount,
  discount_school: state.discount_school,
  discount_lij: state.discount_lij,
  discount_school_lij: state.discount_school_lij,
  discount_dig: state.discount_dig,
  discount_school_dig: state.discount_school_dig,
  discount_edu: state.discount_edu,
  discount_school_edu: state.discount_school_edu,
  discount_ser: state.discount_ser,
  discount_school_ser: state.discount_school_ser,
  disable_discount_sync: state.disable_discount_sync,
})

const mapDispatchToProps = dispatch => ({
  fetchDiscount: id => Promise.all([dispatch(fetchDiscount(id))]),
  updateDiscount: (contractId, data) => Promise.all([dispatch(updateDiscount(contractId, data))]),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditDiscount))

