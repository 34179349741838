import { Cell, Column, Table } from 'fixed-data-table-2'
import { Form, Radio, RadioGroup, Select, Text } from 'react-form'
import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader.js'
import { closeModal, openModal } from '@actions/modal.js'
import { getDefaultData, voucherSave } from '@services/sm-api.js'

import CanRemoveCellInput from '@components/EditVoucher/CanRemoveCellInput'
import ConfirmDialog from '@shared/ConfirmDialog'
import FilterSelect from '@components/FilterSelect'
import FilterText from '@components/FilterText'
import { NavLink } from 'react-router-dom'
import PermissionsChecker from '@shared/PermissionsChecker'
import _ from 'underscore'
import { connect } from 'react-redux'
import { withRouter } from '../../utils/withRouter'

const HeaderCell = ({ ...props }) => (
  <Cell className="report-table__header-cell" {...props}>
    {props.children}
  </Cell>
)

const formatCurrency = value =>
  'R$' +
  new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)

const TextCell = ({ data, rowIndex, columnKey, fieldName, ...props }) => {
  let value = _.property([rowIndex, columnKey])(data)
  return (
    <Cell className="report-table__text-cell" {...props}>
      {fieldName === 'amount' ? formatCurrency(value) : value}
    </Cell>
  )
}

const TextCellWithImage = ({ data, rowIndex, columnKey, fieldName, ...props }) => {
  return (
    <Cell className="report-table__text-cell" {...props}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <img
          className="book-image"
          src={_.property([rowIndex, 'images', 'GraficoSmall'])(data)}
          alt="GraficoSmall"
          align="left"
        />
        {_.property([rowIndex, columnKey])(data)}
      </div>
    </Cell>
  )
}

const SchoolCard = ({ school: { name, school_address, director_name, director_phone, director_email } }) => {
  return (
    <div className="schoolcard">
      <span className="schoolcard__title school-name">{name}</span>
      <div className="schoolcard__contact-information">
        <span className="schoolcard__title">Dados de contato</span>
        <span className="schoolcard__value">{school_address}</span>
      </div>
      <div className="schoolcard__responsible-information">
        <span className="schoolcard__title">RESPONSÁVEL</span>
        <span className="schoolcard__value">{director_name}</span>
        <span className="schoolcard__value">{director_phone}</span>
        <span className="schoolcard__value">{director_email}</span>
      </div>
    </div>
  )
}

const ButtonCellStatusProduct = ({ data, rowIndex, handleChangeStatus, ...props }) => {
  const statusClass = data[rowIndex] && data[rowIndex].active ? 'active' : 'inactive'
  return (
    <Cell className="report-table__button-cell" {...props}>
      <PermissionsChecker permissions={['manage_voucher_products_status']}>
        <button
          type="button"
          className={`button button__status-book ${statusClass}`}
          onClick={() => {
            handleChangeStatus(rowIndex)
          }}
        >
          {data[rowIndex] && data[rowIndex].active ? 'Desativar' : 'Ativar'}
        </button>
      </PermissionsChecker>
      <PermissionsChecker permissions={['manage_voucher_products_status']} negate>
        <button type="button" disabled className={`button button__status-book ${statusClass}`}>
          {data[rowIndex] && data[rowIndex].active ? 'Desativar' : 'Ativar'}
        </button>
      </PermissionsChecker>
    </Cell>
  )
}

const ButtonCellAddProduct = ({ data, rowIndex, handleAddProduct, allVoucherProducts, ...props }) => {
  const has_product = !!allVoucherProducts.hasOwnProperty(data[rowIndex].school_product_id)
  return !has_product ? (
    <Cell
      className="report-table__button-cell"
      {...props}
      onClick={() => {
        handleAddProduct(data[rowIndex])
      }}
    >
      <button type="button" className="button button__add-book">
        Adicionar
      </button>
    </Cell>
  ) : (
    <Cell className="report-table__text-cell added-book" {...props}>
      Adicionado
    </Cell>
  )
}

const ButtonCellRemoveProduct = ({ data, rowIndex, handleRemoveProduct, ...props }) => {
  return (
    <Cell
      className="report-table__button-cell"
      {...props}
      onClick={() => {
        handleRemoveProduct(rowIndex)
      }}
    >
      <button type="button" className="button button__remove-book">
        Remover
      </button>
    </Cell>
  )
}

const DiscountCellInput = ({ data, products = [], rowIndex, values, type, ...props }) => {
  const discount = type === 'student' ? products[rowIndex].discount : products[rowIndex].discount_school
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <PermissionsChecker permissions={['manage_product_discount']}>
        <RadioGroup
          errorBefore={false}
          field={[`${type}DiscountInputsState`, rowIndex]}
          className="field__radio discount-cell-input"
        >
          <label className="field__radio__option">
            <Radio value="normal" />
            <span className="field__radio__label">{`${discount}%`}</span>
          </label>
          <label className="field__radio__option">
            <Radio value="custom" />
            <span className="field__radio__label">
              <Text
                placeholder="Customizado"
                field={[`${type}CustomDiscountInputsValue`, rowIndex]}
                disabled={
                  values[`${type}DiscountInputsState`] && values[`${type}DiscountInputsState`][rowIndex] !== 'custom'
                }
                autoComplete="off"
              />
            </span>
          </label>
        </RadioGroup>
      </PermissionsChecker>
      <PermissionsChecker permissions={['manage_product_discount']} negate>
        <RadioGroup
          disabled
          errorBefore={false}
          field={[`${type}DiscountInputsState`, rowIndex]}
          className="field__radio discount-cell-input"
        >
          <label className="field__radio__option">
            <Radio value="normal" disabled />
            <span className="field__radio__label">{`${discount}%`}</span>
          </label>
          <label className="field__radio__option">
            <Radio value="custom" disabled />
            <span className="field__radio__label">
              <Text
                placeholder="Customizado"
                field={[`${type}CustomDiscountInputsValue`, rowIndex]}
                disabled
                autoComplete="off"
              />
            </span>
          </label>
        </RadioGroup>
      </PermissionsChecker>
    </div>
  )
}

class ErrorDialog extends Component {
  render() {
    const errors = this.props.errors
    return (
      <div className="confirm-dialog voucher">
        <div className="confirm-dialog__container">
          <ul className="voucher__errors">
            {Object.keys(errors).map(fieldName => (
              <li key={fieldName}>
                <span className="voucher__errors__value">{errors[fieldName]}</span>
              </li>
            ))}
          </ul>
          <div className="confirm-dialog__buttons-container">
            <button className="button__normal--modal-confirm" onClick={this.props.ok}>
              {this.props.okLabel || 'Sim'}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const searchProductsUrl = 'products/search-ecat-products'
const productDisciplinesUrl = 'products/product-disciplines'
const seriesUrl = 'series/list'

const searchProductsTableColumns = [
  {
    label: 'Nome do produto',
    field: 'name',
    width: 330,
  },
  {
    label: 'Código SAP',
    field: 'product_sap_code',
    width: 140,
  },
  {
    label: 'Disciplina',
    field: 'theme',
    width: 160,
  },
  {
    label: 'Nível de Ensino',
    field: 'education_level',
    width: 140,
  },
  {
    label: 'Valor',
    field: 'amount',
    width: 95,
  },
  {
    label: '',
    field: 'action',
    width: 140,
  },
]

const voucherProductsTableColumns = [
  {
    label: 'Nome do produto',
    field: 'name',
    width: 150,
  },
  {
    label: 'Cód. SAP',
    field: 'product_sap_code',
    width: 80,
  },
  {
    label: 'Disciplina',
    field: 'theme',
    width: 90,
  },
  {
    label: 'N. Ensino',
    field: 'education_level',
    width: 80,
  },
  {
    label: 'Valor',
    field: 'amount',
    width: 85,
  },
  {
    label: 'Desc. Comprador',
    field: 'discount',
    width: 140,
  },
  {
    label: 'Desc. Escola',
    field: 'discount_school',
    width: 140,
  },
  {
    label: 'Desm. Compra',
    field: 'can_remove',
    width: 110,
  },
  {
    label: '',
    field: 'action',
    width: 140,
  },
]

class EditVoucher extends Component {
  constructor(props) {
    super(props)
    this._handleFilterOnChange = this._handleFilterOnChange.bind(this)
    this.state = {
      schoolData: {},
      voucherData: {},
      voucherProducts: [],
      addedProducts: [],
      allVoucherProducts: {},
      productDisciplines: [],
      series: [],
      searchedProducts: [],
      searchProductsCurrentPage: 1,
      searchProductsTotalPage: 1,
      searchProductsfilters: {},
      showErrors: false,
      hasVoucherTeacher: false,
      timeOut: () => { },
    }
    this.hideDefaultError = { style: { display: 'none' } }

    this._isMounted = false
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    const { params } = this.props

    getDefaultData(`/contracts/schools/${params.school_id}/detail`).then(({ school }) =>
      this.setState({ schoolData: school }),
    )

    getDefaultData(productDisciplinesUrl).then(
      disciplines =>
        this._isMounted &&
        this.setState({
          productDisciplines: disciplines.map(discipline => ({
            name: discipline,
            value: discipline,
          })),
        }),
    )

    getDefaultData(seriesUrl).then(
      series =>
        this._isMounted &&
        this.setState({
          series: series.map(series => ({
            label: `${series.id} (${series.name} - ${series.description})`,
            value: series.id,
          })),
        }),
    )

    if (this.props.edit) {
      const voucherName = params.id

      getDefaultData(`voucher/${voucherName}?allProducts=1`).then(({ products, ...voucher }) => {
        let allVoucherProducts = {}

        for (let i = 0; i < products.length; i++) {
          let school_product_id = products[i].school_product_id
          allVoucherProducts[school_product_id] = school_product_id
        }

        this._isMounted &&
          this.setState({
            voucherData: voucher,
            voucherProducts: products,
            allVoucherProducts: allVoucherProducts,
            hasVoucherTeacher: voucherName.toLowerCase().startsWith('p')
          })

        this._isMounted &&
          this.refs.Form.setAllValues(
            {
              voucher_serie: voucher.serie_id,
              voucher_status: voucher.voucher_status,
              studentDiscountInputsState: products.map(product => (product.has_custom_discount ? 'custom' : 'normal')),
              canRemoveProductInputsValue: products.map(product => (product.can_remove ? true : false)),
              studentCustomDiscountInputsValue: products.map(product =>
                product.has_custom_discount ? product.custom_discount : '',
              ),
              teacherCustomDiscountInputsValue: products.map(product =>
                product.has_custom_discount_teacher ? product.custom_discount_teacher : '',
              ),
              teacherDiscountInputsState: products.map(product =>
                product.has_custom_discount_teacher ? 'custom' : 'normal',
              ),
              schoolDiscountInputsState: products.map(product =>
                product.has_custom_discount_school ? 'custom' : 'normal',
              ),
              schoolCustomDiscountInputsValue: products.map(product =>
                product.has_custom_discount_school ? product.custom_discount_school : '',
              ),
            },
            true,
          )
      })
    }

    getDefaultData(searchProductsUrl, {
      schoolId: params.school_id,
    }).then(({ search_products, page, total_pages }) => {
      this._isMounted &&
        this.setState({
          searchedProducts: search_products,
          searchProductsCurrentPage: Number(page),
          searchProductsTotalPage: Number(total_pages),
        })
    })
  }

  _handleChangeStatus(rowIndex) {
    let voucherProducts = this.state.voucherProducts
    voucherProducts[rowIndex].active = !voucherProducts[rowIndex].active
    this.setState({
      voucherProducts: voucherProducts,
    })
  }

  _handleAddProduct(product) {
    let has_product = false
    let { voucherProducts, addedProducts, allVoucherProducts } = this.state
    const allProduct = voucherProducts.concat(addedProducts)
    for (let i = 0; i < allProduct.length; i++) {
      if (allProduct[i].school_product_id === product.school_product_id) {
        has_product = true
        this._showErrors({ product: 'O produto já foi adicionado ao voucher' })
        break
      }
    }
    if (!has_product) {
      addedProducts.push(product)
      allVoucherProducts[product.school_product_id] = product.school_product_id
      this.setState({
        addedProducts: addedProducts,
        allVoucherProducts: allVoucherProducts,
      })
    }
  }

  _handleRemoveProduct(rowIndex) {
    let { addedProducts, allVoucherProducts } = this.state
    delete allVoucherProducts[addedProducts[rowIndex].school_product_id]
    addedProducts.splice(rowIndex, 1)
    this.setState({
      addedProducts: addedProducts,
      allVoucherProducts: allVoucherProducts,
    })
  }

  _validateForm(values) {
    let errors = {}
    const { voucher_serie, voucher_status } = values
    if (!voucher_serie) {
      errors.voucher_serie = 'Campo obrigatório'
    }
    if (_.indexOf([0, 1], voucher_status) === -1) {
      errors.voucher_status = 'Campo obrigatório'
    }
    return errors
  }

  _handleSave(values) {
    this.props.openLoader()
    const { voucherData, voucherProducts, addedProducts } = this.state

    if (voucherProducts.concat(addedProducts).length === 0) {
      this._showErrors({ product: 'Nenhum produto adicionado ao voucher' })
      return false
    }

    const {
      params: { school_id },
    } = this.props

    let voucher = {}

    let updatedVoucherProducts = voucherProducts.map((voucherProduct, key) => {
      let custom_discount = parseFloat(values.studentCustomDiscountInputsValue[key]) || 0
      let custom_discount_school = parseFloat(values.schoolCustomDiscountInputsValue[key]) || 0
      let custom_discount_teacher = parseFloat(values.teacherCustomDiscountInputsValue[key]) || 0
      let can_remove = values.canRemoveProductInputsValue[key] ? true : false

      return {
        ...voucherProduct,
        has_custom_discount: values.studentDiscountInputsState[key] === 'custom',
        custom_discount,
        has_custom_discount_school: values.schoolDiscountInputsState[key] === 'custom',
        custom_discount_school,
        has_can_remove: values.canRemoveProductInputsValue[key] === true,
        can_remove,
        has_custom_discount_teacher: values.teacherDiscountInputsState[key] === 'custom',
        custom_discount_teacher,
      }
    })

    if (this.props.edit) {
      voucher = {
        voucher_id: voucherData.voucher_id,
        voucher_name: voucherData.voucher,
        serie_id: values.voucher_serie,
        status: values.voucher_status,
        school_id: school_id,
        campaign_id: voucherData.campaign_id,
        voucher_products: updatedVoucherProducts.map(product => ({
          id: product.id,
          school_product_id: product.school_product_id,
          custom_discount_teacher: product.custom_discount_teacher,
          has_custom_discount_teacher : product.has_custom_discount_teacher,
          active: product.active,
          custom_discount: product.custom_discount,
          has_custom_discount: product.has_custom_discount,
          custom_discount_school: product.custom_discount_school,
          has_custom_discount_school: product.has_custom_discount_school,
          can_remove: product.has_can_remove,
        })),
        added_products: addedProducts.map(product => ({
          school_product_id: product.school_product_id,
          custom_discount: product.custom_discount,
          custom_discount_school: product.custom_discount_school,
        })),
      }
    } else {
      voucher = {
        voucher_id: null,
        voucher_name: '',
        serie_id: values.voucher_serie,
        status: values.voucher_status,
        school_id: school_id,
        campaign_id: this.props.currentCampaign.campaign_id,
        voucher_products: [],
        added_products: addedProducts.map(product => ({
          school_product_id: product.school_product_id,
          custom_discount: product.custom_discount,
          custom_discount_school: product.custom_discount_school,
          can_remove: product.can_remove,
        })),
      }
    }

    if (voucher.serie_id) {
      voucherSave(voucher)
        .then(response => {
          this.props.openModal(
            <ConfirmDialog
              text="Voucher salvo com sucesso"
              okLabel="Confirmar"
              ok={() => {
                this.props.navigate(`/admin/escolas/${school_id}`)
                this.props.closeModal()
              }}
            />,
          )
        })
        .finally(() => this.props.closeLoader())
    } else {
      this.props.closeLoader()
    }
  }

  _handleFilterOnChange(filterValue) {
    clearTimeout(this.state.timeOut)
    this.setState({
      timeOut: setTimeout(() => {
        let searchProductsfilters = this.state.searchProductsfilters
        let page = 1
        this.setState(
          {
            searchProductsfilters: { ...searchProductsfilters, ...filterValue },
            searchProductsCurrentPage: page,
          },
          () => {
            this._searchEcatProducts(page).then(products => {
              this.setState({
                searchedProducts: products,
              })
            })
          },
        )
      }, 750),
    })
  }

  _handleLoadMore() {
    if (this.state.searchProductsTotalPage === this.state.searchProductsCurrentPage) {
      return false
    }
    const Form = this.refs.Form
    let currentScroll = Form.refs.Table.state.scrollY
    let maxScroll = Form.refs.Table.state.maxScrollY
    if (currentScroll === maxScroll) {
      let page = Number(this.state.searchProductsCurrentPage) + 1
      this._searchEcatProducts(page).then(products => {
        let searchedProducts = this.state.searchedProducts
        this.setState({
          searchedProducts: [...searchedProducts, ...products],
        })
      })
    }
  }

  _searchEcatProducts(page) {
    return new Promise((resolve, reject) => {
      this.props.openLoader()
      getDefaultData(searchProductsUrl, {
        ...this.state.searchProductsfilters,
        page: page,
      })
        .then(({ search_products, page, total_pages }) => {
          this.setState({
            searchProductsCurrentPage: Number(page),
            searchProductsTotalPage: Number(total_pages),
          })
          resolve(search_products)
        })
        .catch(reject)
        .finally(() => this.props.closeLoader())
    })
  }

  _showErrors(errors) {
    if (Object.keys(errors).length !== 0) {
      this.setState({ showErrors: true })
    }
    this.props.openModal(
      <ErrorDialog
        errors={errors}
        okLabel="Ok"
        ok={() => {
          this.setState({ showErrors: false })
          this.props.closeModal()
        }}
      />,
    )
  }

  render() {
    const {
      schoolData,
      voucherData,
      productDisciplines,
      series,
      voucherProducts,
      addedProducts,
      searchedProducts,
      allVoucherProducts,
      hasVoucherTeacher,
    } = this.state
    const { params } = this.props

    return (
      <div className="admin-form">
        <Form
          ref="Form"
          validate={values => this._validateForm(values)}
          onSubmit={values => {
            this._handleSave(values)
          }}
        >
          {({ submitForm, values, ...rest }) => {
            return (
              <form
                onKeyPress={e => {
                  e.which === 13 && e.preventDefault()
                }}
                className="admin-form__form"
                onSubmit={submitForm}
              >
                <div className="admin-form__header">
                  <h1 className="admin-form__title">Voucher</h1>
                  <h2>
                    ID: <span>{voucherData.voucher_id}</span>
                  </h2>
                  <h2>
                    NÚMERO: <span>{voucherData.voucher}</span>
                  </h2>
                </div>

                <div className="admin-form__header">
                  <span className="admin-form__title subtitle">Informações da escola</span>
                </div>
                <SchoolCard school={schoolData} />

                <div className="field field__voucher_serie">
                  <div className="field__select">
                    <PermissionsChecker permissions={['edit_vouchers']}>
                      <Select
                        disabled={voucherData.voucher_id}
                        placeholder="Nível de Ensino"
                        field="voucher_serie"
                        value="0"
                        options={series}
                      />
                    </PermissionsChecker>
                    <PermissionsChecker permissions={['edit_vouchers']} negate>
                      <Select disabled placeholder="Nível de Ensino" field="voucher_serie" value="0" options={series} />
                    </PermissionsChecker>
                  </div>
                </div>

                <div className="field field__voucher_status">
                  <div className="field__select">
                    <PermissionsChecker permissions={['manage_voucher_status']}>
                      <Select
                        placeholder="Status do voucher"
                        field="voucher_status"
                        value="0"
                        options={[
                          { label: 'Ativo', value: 1 },
                          { label: 'Inativo', value: 0 },
                        ]}
                      />
                    </PermissionsChecker>
                    <PermissionsChecker permissions={['manage_voucher_status']} negate>
                      <Select
                        placeholder="Status do voucher"
                        field="voucher_status"
                        value="0"
                        disabled
                        options={[
                          { label: 'Ativo', value: 1 },
                          { label: 'Inativo', value: 0 },
                        ]}
                      />
                    </PermissionsChecker>
                  </div>
                </div>

                <div className="books__wrapper">
                  <h4 className="books__table-title" style={{ marginBottom: '20px' }}>
                    Produtos do voucher ({voucherProducts.length})
                  </h4>
                  <Table
                    ref="Table"
                    className="report-table"
                    rowHeight={70}
                    rowsCount={voucherProducts.length}
                    width={1005}
                    height={300}
                    headerHeight={50}
                    data={voucherProducts}
                  >
                    {voucherProductsTableColumns.map((column, key) => {
                      return (
                        <Column
                          key={key}
                          header={<HeaderCell>{column.label}</HeaderCell>}
                          columnKey={column.field}
                          cell={
                            column.field === 'action' ? (
                              <ButtonCellStatusProduct
                                data={voucherProducts}
                                handleChangeStatus={product => {
                                  this._handleChangeStatus(product)
                                }}
                              />
                            ) : column.field === 'name' ? (
                              <TextCellWithImage data={voucherProducts} fieldName={column.field} />
                            ) : column.field === 'discount' ? (
                              <DiscountCellInput
                                products={voucherProducts}
                                data={addedProducts}
                                fieldName={column.field}
                                values={values}
                                type={hasVoucherTeacher ? "teacher" : 'student'} 
                              />
                            ) : column.field === 'discount_school' ? (
                              <DiscountCellInput
                                products={voucherProducts}
                                data={addedProducts}
                                fieldName={column.field}
                                values={values}
                                type="school"
                              />
                            ) : column.field === 'can_remove' ? (
                              <CanRemoveCellInput
                                products={voucherProducts}
                                data={addedProducts}
                                fieldName={column.field}
                                values={values}
                              />
                            ) : (
                              <TextCell data={voucherProducts} fieldName={column.field} />
                            )
                          }
                          fixed
                          width={column.width}
                        />
                      )
                    })}
                  </Table>
                  <h4 className="books__table-title" style={{ marginBottom: '20px', marginTop: '30px' }}>
                    Produtos adicionados ao voucher ({addedProducts.length})
                  </h4>
                  <Table
                    ref="Table"
                    className="report-table"
                    rowHeight={70}
                    rowsCount={addedProducts.length}
                    width={1005}
                    height={300}
                    headerHeight={50}
                    data={addedProducts}
                  >
                    {voucherProductsTableColumns.map((column, key) => {
                      return (
                        <Column
                          key={key}
                          columnKey={column.field}
                          cell={
                            column.field === 'action' ? (
                              <ButtonCellRemoveProduct
                                data={addedProducts}
                                handleRemoveProduct={rowIndex => {
                                  this._handleRemoveProduct(rowIndex)
                                }}
                              />
                            ) : column.field === 'name' ? (
                              <TextCellWithImage data={addedProducts} fieldName={column.field} />
                            ) : (
                              <TextCell data={addedProducts} fieldName={column.field} />
                            )
                          }
                          fixed
                          width={column.width}
                        />
                      )
                    })}
                  </Table>
                </div>

                <PermissionsChecker permissions={['create_voucher_products']}>
                  <div className="filters" id="report_container">
                    <div className="filters__item">
                      <FilterSelect
                        field="theme"
                        label="Disciplina"
                        options={productDisciplines}
                        onChange={this._handleFilterOnChange}
                      />
                      <FilterText field="sapCode" label="Código do produto" onChange={this._handleFilterOnChange} />
                      <FilterText field="name" label="Nome do produto" onChange={this._handleFilterOnChange} />
                    </div>
                  </div>

                  <div className="books__wrapper">
                    <h4 className="books__table-title" style={{ marginBottom: '20px' }}>
                      Produtos
                    </h4>
                    <Table
                      ref="Table"
                      className="report-table"
                      onScrollEnd={() => {
                        this._handleLoadMore()
                      }}
                      rowHeight={70}
                      rowsCount={searchedProducts.length}
                      width={1005}
                      height={300}
                      headerHeight={50}
                      data={searchedProducts}
                    >
                      {searchProductsTableColumns.map((column, key) => {
                        return (
                          <Column
                            key={key}
                            header={<HeaderCell>{column.label}</HeaderCell>}
                            columnKey={column.field}
                            cell={
                              column.field === 'action' ? (
                                <ButtonCellAddProduct
                                  allVoucherProducts={allVoucherProducts}
                                  data={searchedProducts}
                                  handleAddProduct={product => {
                                    this._handleAddProduct(product)
                                  }}
                                />
                              ) : column.field === 'name' ? (
                                <TextCellWithImage data={searchedProducts} fieldName={column.field} />
                              ) : (
                                <TextCell data={searchedProducts} fieldName={column.field} />
                              )
                            }
                            fixed
                            width={column.width}
                          />
                        )
                      })}
                    </Table>
                  </div>
                </PermissionsChecker>
                <div className="books__actions">
                  <NavLink className="button__normal cancel" to={`/admin/escolas/${params.school_id}`}>
                    Cancelar
                  </NavLink>
                  <PermissionsChecker permissions={['edit_vouchers']}>
                    <button className="admin-form__button button__normal" type="submit">
                      Salvar Voucher
                    </button>
                  </PermissionsChecker>
                  <PermissionsChecker permissions={['edit_vouchers']} negate>
                    <button className="admin-form__button button__normal" disabled type="submit">
                      Salvar Voucher
                    </button>
                  </PermissionsChecker>
                </div>
              </form>
            )
          }}
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  params: props.params,
  currentCampaign: state.report.currentCampaign,
})

const mapDispatchToProps = dispatch => ({
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  openModal: component => dispatch(openModal(component)),
  closeModal: () => dispatch(closeModal()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditVoucher))

