import { getFreightAPI } from '@services/sm-api'

export const GET_FREIGHT = 'freight/GET_FREIGHT'
export const FAILURE_FREIGHT = 'freight/FAILURE_FREIGHT'
export const RESET_FREIGHT = 'freight/RESET_FREIGHT'

export function _getFreight(payload) {
  return {
    type: GET_FREIGHT,
    payload,
  }
}

export function _failureFreight(payload) {
  return {
    type: FAILURE_FREIGHT,
    payload,
  }
}

export function _resetFreight() {
  return {
    type: RESET_FREIGHT,
  }
}

export function getFreight(data) {
  return dispatch =>
    getFreightAPI(data)
      .then(response => {
        dispatch(_getFreight(response))
      })
      .catch(error => {
        dispatch(_failureFreight(error))
      })
}

export function resetFreight() {
  return dispatch => dispatch(_resetFreight())
}
