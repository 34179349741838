import React from 'react'

export const renderLegend = (props, data) => (
  <ul className="customlegend">
    {data.map((entry, index) => (
      <li key={`item-${index}`} className="customlegend__item">
        <span
          className="customlegend__circle"
          style={{
            backgroundColor: entry.fill,
          }}
        ></span>
        <span className="customlegend__label">{entry.name}</span>
      </li>
    ))}
  </ul>
)

export const CustomLabel = ({ x = 0, y = 0, xOffset = 0, yOffset = 0, value, fill, chartHeight, height, ...props }) => {
  let diff = chartHeight - height
  let _x = diff < 40 ? x + xOffset : x
  let _y = diff < 40 ? y + yOffset : y

  return (
    <text style={{ fontSize: '14px' }} x={_x} y={_y} dy={-10} fill={fill}>
      {value}
    </text>
  )
}
