import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader'
import { closeModal, openModal } from '@actions/modal'
import { connect } from 'react-redux'

import CustomDateInput from '../../MultipleDateInput/CustomDateInput'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import { resetVouchersInactivation, getVouchersInactivation } from '@actions/vouchersInactivation'
import {
  resetAutomaticInactivation,
  updateAutomaticInactivation,
  setAutomaticInactivation,
} from '@actions/automaticInactivation'

import { SchoolDetailModal } from '../Modal'

export class AutomaticInactivation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      disabled_save: true,
    }
  }

  componentWillUnmount() {
    this.props.resetVouchersInactivation()
    this.props.resetAutomaticInactivation()
  }

  componentDidMount() {
    this.props.getVouchersInactivation()
    if (this.props.initial_business_lines_cut_dates) {
      this.props.setAutomaticInactivation(this.props.initial_business_lines_cut_dates)
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.initial_business_lines_cut_dates !== nextProps.initial_business_lines_cut_dates &&
      nextProps.initial_business_lines_cut_dates !== null
    ) {
      this.props.setAutomaticInactivation(nextProps.initial_business_lines_cut_dates)
    }
  }

  _openModal(title, message) {
    this.props.openModal(<SchoolDetailModal closeModal={this.props.closeModal} title={title} message={message} />)
  }

  async _setAutomaticInactivation(date, business_line_id) {
    let { business_lines_cut_dates } = this.props

    let index = business_lines_cut_dates.findIndex(business_line => business_line.business_line_id === business_line_id)

    business_lines_cut_dates[index].cut_date = date ? date.format('YYYY-MM-DD') : null

    this.setState({
      disabled_save: false,
    })
  }

  async _handleSaveAutomaticInactivation() {
    this.props.openLoader()
    this.props
      .updateAutomaticInactivation({
        contract_id: this.props.contract_id,
        business_lines_cut_dates: this.props.business_lines_cut_dates,
      })
      .then(resp => {
        if (resp && resp.code === 200) {
          this._openModal('Sucesso', () => <p>Datas de inativação automática alteradas com sucesso!</p>)
        } else {
          this._openModal('Erro', () => (
            <p>Houve algum erro ao alterar as datas de inativação automática . Por favor tente novamente.</p>
          ))
        }
        this.props.closeLoader()
      })
  }

  render() {
    const { business_lines_cut_dates, business_lines } = this.props
    let inactive_business_lines = business_lines.filter(business_line => business_line.inactive === 1)

    return (
      <div className="automatic_inactivation">
        <div className="automatic_inactivation_header">
          <p>As linhas de negócio configuradas abaixo serão inativadas no final do dia selecionado</p>
        </div>
        {inactive_business_lines.map(inactive_business_line => (
          <div className="multipledate__field" key={inactive_business_line.id}>
            <DatePicker
              customInput={<CustomDateInput label={inactive_business_line.name} />}
              dateFormat="DD/MM/YYYY"
              selected={
                business_lines_cut_dates
                  ? business_lines_cut_dates.find(
                      business_line => business_line.business_line_id === inactive_business_line.id,
                    ).cut_date
                    ? moment(
                        business_lines_cut_dates.find(
                          business_line => business_line.business_line_id === inactive_business_line.id,
                        ).cut_date,
                      )
                    : ''
                  : ''
              }
              minDate={new Date()}
              testProp={`interval_${inactive_business_line.id}`}
              onChange={date => {
                this._setAutomaticInactivation(date, inactive_business_line.id)
              }}
            />
            <button
              className="deleteCutDateButton"
              onClickCapture={() => {
                this._setAutomaticInactivation(null, inactive_business_line.id)
              }}
            />
          </div>
        ))}
        <div className="automatic_inactivation_footer">
          <button
            className="button schoolcard__save"
            disabled={this.state.disabled_save}
            onClick={() => {
              this._handleSaveAutomaticInactivation()
            }}
          >
            Salvar
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    business_lines_cut_dates: state.automaticInactivation.business_lines_cut_dates,
    business_lines: state.vouchersInactivation.business_lines,
    contract: state.contract.contracts.find(contract => contract.id === props.contract_id) || null,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModal: component => dispatch(openModal(component)),
    closeModal: () => dispatch(closeModal()),
    openLoader: () => dispatch(openLoader()),
    closeLoader: () => dispatch(closeLoader()),
    resetVouchersInactivation: () => dispatch(resetVouchersInactivation()),
    getVouchersInactivation: () => dispatch(getVouchersInactivation()),
    resetAutomaticInactivation: () => dispatch(resetAutomaticInactivation()),
    updateAutomaticInactivation: data => dispatch(updateAutomaticInactivation(data)),
    setAutomaticInactivation: data => dispatch(setAutomaticInactivation(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutomaticInactivation)
