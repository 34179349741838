import { CNPJ, CPF } from 'cpf_cnpj'
import { Form, Radio, RadioGroup, Select, Text } from 'react-form'
import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader.js'
import { closeModal, openModal } from '@actions/modal.js'
import { createSpecialDiscount, getDefaultData, getSpecialDiscountById, updateSpecialDiscount } from '@services/sm-api'

import ConfirmDialog from '@shared/ConfirmDialog'
import InlineLoader from '@components/InlineLoader'
import { InputText } from '@shared/CustomField'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from '../../utils/withRouter'

const DEFAULT_DISCOUNT_VALUE = '50'

class SpecialDiscountForm extends Component {
  constructor(props) {
    super(props)

    this.hideDefaultError = { style: { display: 'none' } }

    this.state = {
      campaigns: [],
      schools: [],
      vouchers: [],
      series: [],
      isLoadingVouchers: false,
      isLoadingSchools: false,
      isLoadingSeries: false,
    }

    this.submitMethods = {
      CREATE: createSpecialDiscount,
      UPDATE: updateSpecialDiscount,
    }
  }

  _preProcess = discountId => {
    return getSpecialDiscountById(discountId)
      .then(
        ({
          discount: {
            buyer_doc_type: type,
            buyer_document: document,
            buyer_name: buyerName,
            student_name: studentName,
            voucher_name: voucher,
            school_id: school,
            campaign_id: campaign,
            serie_id: serie,
            discount_value: discountValue,
          },
        }) => {
          this.refs.Form.setAllValues({
            type,
            document,
            buyerName,
            studentName,
            voucher,
            school,
            campaign,
            serie,
            discountValue: String(discountValue).split('.')[0] || DEFAULT_DISCOUNT_VALUE,
          })

          return Promise.all([
            this._getCampaigns(),
            this._getSchools(campaign),
            this._getSeries(),
            this._getVouchers(school),
          ])
        },
      )
      .catch(({ response }) => {
        let errorMessage = 'Erro ao exibir o desconto. Tente novamente mais tarde'

        if (response.data && response.status === 404) {
          errorMessage = 'Desconto não encontrado.'
        }

        this.props.openModal(
          <ConfirmDialog
            text={errorMessage}
            okLabel="Confirmar"
            ok={() => {
              this.props.closeModal()
              this.props.navigate('/admin/special-discounts')
            }}
          />,
        )
      })
  }

  _getCampaigns = () => {
    return getDefaultData('campaigns/list').then(campaigns => {
      this.setState({
        campaigns: campaigns.map(campaign => ({
          label: campaign.name,
          value: campaign.id,
        })),
      })
    })
  }

  _getSchools = campaignId => {
    return getDefaultData('contracts/schools/list', {
      campaign_id: campaignId,
      limit: 0,
    })
      .then(({ schools = [] }) => {
        this.setState({
          schools: schools.map(school => ({
            label: school.name,
            value: school.id,
          })),
        })

        if (schools.length === 0) this.refs.Form.setTouched('school', true)
      })
      .finally(() => {
        this.setState({ isLoadingSchools: false })
      })
  }

  _getSeries = () => {
    return getDefaultData('series/list')
      .then(series => {
        this.setState({
          series: series.map(series => ({
            label: `${series.id} (${series.name} - ${series.description})`,
            value: series.id,
          })),
        })

        if (series.length === 0) this.refs.Form.setTouched('serie', true)
      })
      .finally(() => {
        this.setState({ isLoadingSeries: false })
      })
  }

  _getVouchers = schoolId => {
    return getDefaultData(`voucher/${schoolId}/list`, {
      campaign_id: this.refs.Form.getValue('campaign'),
      serie_id: this.refs.Form.getValue('serie'),
    })
      .then(({ vouchers = [] }) => {
        this.setState({
          vouchers: vouchers.map(voucher => ({
            label: voucher.name,
            value: voucher.name,
          })),
        })

        if (vouchers.length === 0) this.refs.Form.setTouched('voucher', true)
      })
      .finally(() => {
        this.setState({ isLoadingVouchers: false })
      })
  }

  /**
   * Toggle CPF and CNPJ fields
   **/
  _toggleTypeField(name, setValue) {
    this.setState({
      showingField: name,
    })
  }

  _validateDocument = (field, documentType) => {
    let error = this._isEmpty(field)

    if (!error) {
      const validationMethod = documentType === 'cpf' ? CPF : CNPJ
      error = !validationMethod.isValid(field) ? `${documentType.toUpperCase()} inválido.` : null
    }

    return error
  }

  _validateDinamicList = (field, list) => {
    let error = this._isEmpty(field)

    if (list.length === 0) {
      error = 'Não há vouchers para esta campanha'
    }

    return error
  }

  _discountValueStringToNumber = value => Number(value.replace('%', ''))

  _validateDiscountValue = value => {
    let error = this._isEmpty(value)
    const number = value && this._discountValueStringToNumber(value)
    const isValid = number && number >= 0 && number <= 100
    if (!isValid) {
      error = 'Insira um valor entre 0% e 100%'
    }
    return error
  }

  _isEmpty = field => (field ? null : 'Este campo é obrigatório')

  /**
   * All validations rules
   **/
  _validateForm = values => {
    const { voucher, type, buyerName, studentName, campaign, school, serie, document, discountValue } = values

    const { campaigns, schools, series, vouchers } = this.state

    let errors = {
      type: this._isEmpty(type),
      buyerName: this._isEmpty(buyerName),
      studentName: this._isEmpty(studentName),
      document: this._validateDocument(document, type),
      campaign: this._validateDinamicList(campaign, campaigns),
      school: this._validateDinamicList(school, schools),
      serie: this._validateDinamicList(serie, series),
      voucher: this._validateDinamicList(voucher, vouchers),
      discountValue: this._validateDiscountValue(discountValue),
    }

    return errors
  }

  _handleEdit(values) {
    const { editMode, openModal, navigate, params } = this.props

    let payload = {
      buyer_doc_type: values.type,
      buyer_document: values.document,
      buyer_name: values.buyerName,
      student_name: values.studentName,
      voucher_name: values.voucher,
      campaign_id: values.campaign,
      discount_value: this._discountValueStringToNumber(values.discountValue),
    }

    if (editMode === 'UPDATE') {
      payload.id = params.id
    }

    this.submitMethods[editMode](payload)
      .then(() => {
        openModal(
          <ConfirmDialog
            text="Desconto salvo com sucesso."
            okLabel="OK"
            ok={() => {
              this.props.closeModal()
              navigate('/admin/special-discounts')
            }}
          />,
        )
      })
      .catch(error => {
        let errorMessage = 'Problemas ao salvar o desconto. Tente novamente mais tarde.'

        if (error.response && error.response.data && error.response.data.code === 410) {
          errorMessage = 'Desconto com este aluno e voucher já cadastrado.'
        }

        openModal(
          <ConfirmDialog
            text={errorMessage}
            okLabel="OK"
            ok={() => {
              this.props.closeModal()
            }}
          />,
        )
      })
  }

  componentDidMount() {
    const { openLoader, closeLoader, params } = this.props
    openLoader()
    if (params.id) this._preProcess(params.id).finally(this.props.closeLoader)
    else this._getCampaigns().finally(closeLoader)
  }

  render() {
    const { campaigns, schools, series, vouchers, isLoadingVouchers, isLoadingSchools, isLoadingSeries } = this.state
    const { title } = this.props

    return (
      <div className="special-discount-form">
        <Form
          ref="Form"
          validate={this._validateForm}
          onSubmit={values => {
            this._handleEdit(values)
          }}
        >
          {({ submitForm, swapTo, setValue, getValue, values }) => {
            return (
              <form className="admin-form__form" onSubmit={submitForm}>
                <div className="admin-form__header">
                  <h1 className="admin-form__title">{title}</h1>
                </div>
                <div className="field">
                  <span className="field__label admin-form__form__label">Nome do professor *</span>
                  <Text className="field__text" field="buyerName" autoComplete="off" />
                </div>
                <div className="field">
                  <span className="field__label admin-form__form__label">Nome do aluno *</span>
                  <Text className="field__text" field="studentName" autoComplete="off" />
                </div>
                <div className="field field--loader">
                  <span className="field__label admin-form__form__label">Tipo de documento *</span>
                  <RadioGroup
                    is="form-input"
                    field="type"
                    errorProps={this.hideDefaultError}
                    errorBefore={false}
                    className="field__radio"
                  >
                    <label className="field__radio__option">
                      <Radio
                        value="cpf"
                        onChange={() => {
                          this._toggleTypeField('cpf')
                          setValue('cnpj', '', true)
                          setValue('ie', null, true)
                        }}
                      />
                      <span className="field__radio__label">Pessoa física</span>
                    </label>
                    <label className="field__radio__option">
                      <Radio
                        value="cnpj"
                        onChange={() => {
                          this._toggleTypeField('cnpj')
                          setValue('cpf', '', true)
                        }}
                      />
                      <span className="field__radio__label">Pessoa jurídica</span>
                    </label>
                  </RadioGroup>
                </div>
                {getValue('type') === 'cpf' ? (
                  <div className="field admin-form__field__cpf">
                    <span className="field__label admin-form__form__label">CPF *</span>
                    <InputText field="document" mask="999.999.999-99" from={/\D/g} />
                  </div>
                ) : null}
                {getValue('type') === 'cnpj' ? (
                  <div className="field admin-form__field__cnpj">
                    <span className="field__label admin-form__form__label">CNPJ *</span>
                    <InputText field="document" mask="99.999.999/9999-99" from={/\D/g} />
                  </div>
                ) : null}
                <div className="field field--loader">
                  <span className="field__label admin-form__form__label">Campanha *</span>
                  <Select
                    className="field__text special-discount__select"
                    placeholder="Selecione"
                    field="campaign"
                    options={campaigns}
                    onChange={(event, onChange) => {
                      onChange()
                      this.setState({
                        isLoadingSchools: true,
                        schools: [],
                        series: [],
                        vouchers: [],
                      })
                      this.refs.Form.setAllValues({
                        ...this.refs.Form.props.values,
                        school: null,
                        serie: null,
                        voucher: null,
                      })

                      this.refs.Form.setValue('school', null)
                      this.refs.Form.setTouched('school', false)

                      this._getSchools(values.campaign)
                    }}
                  />
                </div>
                <div className="field field--loader">
                  <span className="field__label admin-form__form__label">Escola *</span>
                  <Select
                    className="field__text special-discount__select"
                    disabled={schools.length === 0 || !values.campaign || isLoadingSchools}
                    placeholder="Selecione"
                    field="school"
                    options={schools}
                    onChange={(event, onChange) => {
                      onChange()
                      this.setState({
                        isLoadingSeries: true,
                        series: [],
                        vouchers: [],
                      })
                      this.refs.Form.setValue('serie', null)
                      this.refs.Form.setTouched('serie', false)
                      this._getSeries(values.serie)
                    }}
                  />
                  <InlineLoader show={isLoadingSchools} />
                </div>
                <div className="field">
                  <span className="field__label admin-form__form__label">Desconto Professor Voucher *</span>
                  <InputText field="discountValue" mask="999%" />
                </div>
                <div className="field field--loader">
                  <span className="field__label admin-form__form__label">Série *</span>
                  <Select
                    className="field__text special-discount__select"
                    disabled={series.length === 0 || !values.school || isLoadingSeries}
                    placeholder="Selecione"
                    field="serie"
                    options={series}
                    onChange={(event, onChange) => {
                      onChange()
                      this.setState({ isLoadingVouchers: true, vouchers: [] })
                      this.refs.Form.setValue('voucher', null)
                      this.refs.Form.setTouched('voucher', false)
                      this._getVouchers(values.school)
                    }}
                  />
                  <InlineLoader show={isLoadingSeries} />
                </div>
                <div className="field field--loader">
                  <span className="field__label admin-form__form__label">Voucher *</span>
                  <Select
                    className="field__text special-discount__select"
                    disabled={vouchers.length === 0 || !values.school || isLoadingVouchers}
                    placeholder="Selecione"
                    field="voucher"
                    options={vouchers}
                  />
                  <InlineLoader show={isLoadingVouchers} />
                </div>
                <div className="field">
                  <hr className="admin-form__division" />
                </div>
                <div className="field__terms admin-form__terms">
                  <button className="admin-form__button button__normal" type="submit">
                    Salvar
                  </button>
                </div>
              </form>
            )
          }}
        </Form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sessionReducer: state.sessionReducer,
  user: state.user,
})

const mapDispatchToProps = { openLoader, closeLoader, openModal, closeModal }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpecialDiscountForm))

