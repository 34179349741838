import { FETCH, SUCCESS } from '@actions/contracts.js'

const init = {
  contracts: [
    {
      id: 0,
      contract_start: null,
    },
  ],
  isFetching: false,
}

export default (state = init, action) => {
  switch (action.type) {
    case FETCH:
      return { ...state, isFetching: true }

    case SUCCESS:
      return action.payload.contracts.length > 0 ? { ...action.payload, isFetching: false } : init

    default:
      return state
  }
}
