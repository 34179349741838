import React from 'react'
import { Modal } from 'react-bootstrap'

export const DuplicatedOrderModal = ({ purchasedOrder = '', show, onClose }) => (
  <Modal show={show}>
    <Modal.Body>
      <div className="modalHeader">
        <span className="icon-alert" />
        <h1>Atenção</h1>
      </div>
      <div className="modalContent">
        <p>Este aviso é apenas para que você não realize uma compra duplicada.</p>
        <p>Já existe um pedido com este voucher para a compra com o CPF informado.</p>
        {purchasedOrder && <p>Pedido: {purchasedOrder}</p>}
        <p>Para acessar os dados do pedido, basta logar no portal e verificar o menu Pedidos.</p>
        <p>Desconsidere esta mensagem, caso você queira realizar outra compra com o mesmo voucher e com o mesmo CPF.</p>
      </div>
      <div className="modalFooter">
        <button
          className="inline-button"
          onClick={() => {
            window.localStorage.removeItem('reduxPersist:checkout')
            window.localStorage.removeItem('reduxPersist:preVoucher')
            window.location.href = '/'
          }}
        >
          Cancelar
        </button>
        <button id="payment-agreeing-button" className="modal-close inline-button accept" onClick={onClose}>
          Entendi
        </button>
      </div>
    </Modal.Body>
  </Modal>
)

