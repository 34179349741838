import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

class PrivateRoute extends Component {
  render() {
    let { component, sessionReducer } = this.props
    let authenticated = sessionReducer.authenticated
    return authenticated ? React.createElement(component) : <Navigate to="/" replace />
  }
}

const mapStateToProps = state => ({
  sessionReducer: state.sessionReducer,
})

export default connect(mapStateToProps)(PrivateRoute)

