import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_APPSETTING_SM_AUTH_API_ENDPOINT,
})

instance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

export function setToken(token) {
  if (!token || token.length === 0) throw new Error('Token parameter cannot be empty.')
  instance.defaults.headers.common['Authorization'] = `JWT ${token}`
  return true
}

export function handleLogin(user) {
  return instance
    .post('auth/token', user)
    .then(response => {
      return response
    })
    .catch(function (error) {
      console.log(error)
    })
}

export function handleRegister(user) {
  return instance
    .post('user/create', user)
    .then(response => {
      return response
    })
    .catch(error => console.log(error))
}

export function getUserByEmail(email) {
  return instance
    .post('user/consult-email', email)
    .then(response => {
      return response
    })
    .catch(function (error) {
      console.log(error)
    })
}

export function handleInsertRoles(userRoles) {
  return instance.post('user/insert-roles', userRoles).then(response => {
    return response
  })
}

export function handleUpdate(user, action) {
  return instance
    .post(`user/${user.id}/${action}`, user)
    .then(response => {
      return response
    })
    .catch(function (error) {
      console.log(error)
    })
}

export function handleGetUserInformation(user) {
  return instance
    .post(`user/information`, user)
    .then(response => {
      return response
    })
    .catch(function (error) {
      console.log(error)
    })
}

export function getSchoolList(params) {
  return instance
    .get(`school/list`, { params })
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
}

export function changeThemeColor(body) {
  return instance
    .post(`school/altercolor`, body)
    .then(response => {
      return response.data
    })
    .catch(function (error) {
      console.log(error)
    })
}

export default {
  setToken,
  handleLogin,
  handleRegister,
  getUserByEmail,
  handleInsertRoles,
  handleUpdate,
  handleGetUserInformation,
  getSchoolList,
  changeThemeColor,
}

