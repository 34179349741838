import React, { Component } from 'react'

import { Modal } from 'react-bootstrap'

class ErrorsCellTest extends Component {
  render() {
    const { data = { errors: [] }, columnKey, columnIndex } = this.props
    const columnErrors = data.errors.filter(error => error.column === columnKey)

    return (
      <div>
        <span>{`${data.values[columnIndex]}`}{columnIndex === 5 ? ' %' : ''}</span>
        {columnErrors.length > 0 && (
          <ul>
            <span className="upload-errors-modal__error-label">Erros encontrados:</span>
            {columnErrors.map((error, index) => (
              <li key={index}>{error.message}</li>
            ))}
          </ul>
        )}
      </div>
    )
  }
}

class ExportErrorsModal extends Component {
  constructor(props) {
    super(props)

    this.columns = [
      {
        label: 'Linha',
        field: 'row',
      },
      {
        label: 'Tipo do Documento',
        field: 'buyer_doc_type',
      },
      {
        label: 'Documento',
        field: 'buyer_document',
      },
      {
        label: 'Nome do comprador',
        field: 'buyer_name',
      },
      {
        label: 'Nome do aluno',
        field: 'student_name',
      },
      {
        label: 'Voucher',
        field: 'voucher_name',
      },
      {
        label: 'Desconto',
        field: 'discount_value',
      },
    ]
  }

  render() {
    const { file, show, totalErrors, totalRows, totalSuccess, errors, onRetry, onClose } = this.props

    return (
      <Modal className="upload-errors-modal" show={show}>
        <Modal.Header>
          <button type="button" className="close" onClick={onClose}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <Modal.Title className="upload-file-modal__title">Resultados da importação de descontos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {show && (
            <p>
              Segue abaixo o resultado para a importação do arquivo <strong>{file.name}</strong>
            </p>
          )}
          <div className="upload-errors-modal__status">
            <table class="import-summary">
              <thead>
                <th>
                  <span>{`Total de linhas: ${totalRows}`}</span>
                </th>
                <th>
                  <span>{`Total de cadastros importados: ${totalSuccess}`}</span>
                </th>
                <th>
                  <span>{`Total de erros: ${totalErrors}`}</span>
                </th>
              </thead>
            </table>
          </div>
          <table className="table">
            <thead>
              <tr>
                {this.columns.map(column => (
                  <th key={column.field}>{column.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {errors.map(error => (
                <tr key={error.row}>
                  <td>{error.row}</td>
                  {this.columns
                    .filter(column => column.field !== 'row')
                    .map((column, index) => {
                      return (
                        <td key={column.field}>
                          <ErrorsCellTest data={error} columnKey={column.field} columnIndex={index} />
                        </td>
                      )
                    })}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="modalFooter">
            <a
              className="upload-file-modal__anchor-button"
              onClick={event => {
                event.preventDefault()
                onRetry()
              }}
            >
              Carregar outro arquivo
            </a>
            <button className="modal-close upload-file-modal__submit" onClick={onClose}>
              Fechar
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ExportErrorsModal
