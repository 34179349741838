/**
 * Dependencies
 */
import smApi from '@services/sm-api.js'

/**
 * Actions types
 */
export const FETCH_SPECIAL_DISCOUNT = 'specialDiscount/FETCH_SPECIAL_DISCOUNT'
export const FAILURE_SPECIAL_DISCOUNT = 'specialDiscount/FETCH_FAILURE_SPECIAL_DISCOUNT'
export const SUCCESS_SPECIAL_DISCOUNT = 'specialDiscount/SUCCESS_SPECIAL_DISCOUNT'
export const CLEAR = 'specialDiscount/CLEAR'

/**
 * Actions creators
 */
export function fetchSpecialDiscount() {
  return {
    type: FETCH_SPECIAL_DISCOUNT,
  }
}

export function successSpecialDiscount(payload) {
  return {
    type: SUCCESS_SPECIAL_DISCOUNT,
    payload,
  }
}

export function failureSpecialDiscount(error) {
  return {
    type: FAILURE_SPECIAL_DISCOUNT,
  }
}

export function clearSpecialDiscount() {
  return {
    type: CLEAR,
  }
}

export function checkSpecialDiscount(document) {
  return dispatch => {
    dispatch(fetchSpecialDiscount())
    return smApi
      .checkSpecialDiscount(document)
      .then(response => dispatch(successSpecialDiscount({ ...response })))
      .catch(failureSpecialDiscount)
  }
}
