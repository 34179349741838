import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'

import { RouteTransition } from 'react-router-transition'
import StepsNavigation from '@components/StepsNavigation'
import StudentForm from '@components/VoucherForm/StudentForm'
import VoucherForm from '@components/VoucherForm'
import { connect } from 'react-redux'
import StudentCheckout from '@components/StudentCheckout'
import { withRouter } from '../../utils/withRouter'

class Steps extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navStyles: {
        atEnter: { translateX: 100 },
        atLeave: { translateX: -100 },
        atActive: { translateX: 0 },
      },
    }
    this.scroll = false
  }

  render() {
    let { location } = this.props
    return (
      <div className="step">
        <StepsNavigation pathname={location.pathname} />
        <div className="step__container">
          <RouteTransition
            pathname={location.pathname}
            mapStyles={styles => {
              if (styles.translateX < 0 || styles.translateX > 100) {
                return { display: 'none' }
              } else {
                return {
                  display: 'block',
                  transform: `translateX(${styles.translateX}%)`,
                }
              }
            }}
            className="step__transition"
            {...this.state.navStyles}
          >
            {this.props.voucherForm.show || this.props.voucherForm.showStudentForm ? (
              <React.Fragment>{this.props.voucherForm.show ? <VoucherForm /> : <StudentForm />}</React.Fragment>
            ) : (
              <Outlet />
            )}
          </RouteTransition>
          <div className="step__checkout">{!location.pathname.includes('concluido') && <StudentCheckout />}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  voucherForm: state.voucherForm,
})

export default withRouter(connect(mapStateToProps)(Steps))

