import React, { Component } from 'react'

import imprimir from './images/imprimir.png'

const OrderInfo = ({ sub_order, status }) => {
  return (
    <div className="order-info-container">
      <span className="receipt__title">Informações do pedido</span>
      <ul className="receipt__order-info">
        <li className="receipt__order-info__item">
          <span className="receipt__label">Pedido</span>
          <span className="receipt__text">{sub_order.order_id}</span>
        </li>
        <li className="receipt__order-info__item">
          <span className="receipt__label">Sub pedido</span>
          <span className="receipt__text">{sub_order.business_line + '-' + sub_order.id}</span>
        </li>
        <li className="receipt__order-info__item">
          <span className="receipt__label">Total</span>
          <span className="receipt__text">
            {`
              R$
              ${new Intl.NumberFormat('pt-BR', {
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(sub_order.amount)}
            `}
          </span>
        </li>
        <li className="receipt__order-info__item">
          <span className="receipt__label">Status</span>
          <span className="receipt__text">{status.description}</span>
        </li>
      </ul>
    </div>
  )
}
export default class Receipt extends Component {
  state = {
    printerMode: '',
  }
  _handlePrintOut() {
    const appContent = document.getElementsByClassName('app__content')[0]
    const display = appContent.style.display
    appContent.style.display = 'none'
    this.setState(
      {
        printerMode: 'active',
      },
      () => {
        window.print()
        appContent.style.display = display
        this.setState({
          printerMode: '',
        })
      },
    )
  }

  render() {
    let { sub_order, buyer_name, status, title, closeModal } = this.props
    return (
      <div className={`receipt ${this.state.printerMode}`}>
        <button
          onClick={() => {
            closeModal()
          }}
          className="receipt__close"
        >
          x
        </button>
        <div className="receipt__container">
          <span className="receipt__title">{title}</span>
          <ul className="receipt__user-info">
            <li className="receipt__user-info__item">
              <span className="receipt__label">RESPONSÁVEL</span>
              <span className="receipt__text">{buyer_name}</span>
            </li>
            <li className="receipt__user-info__item">
              <span className="receipt__label">ALUNO</span>
              <span className="receipt__text">{sub_order.student_name}</span>
            </li>
            <li className="receipt__user-info__item">
              <span className="receipt__label">COLÉGIO</span>
              <span className="receipt__text">
                {sub_order.school_name} - {sub_order.serie_name}
              </span>
            </li>
            <li className="receipt__user-info__item">
              <span className="receipt__label">VOUCHER</span>
              <span className="receipt__text">{sub_order.voucher_name}</span>
            </li>
          </ul>
          {this.state.printerMode ? <OrderInfo sub_order={sub_order} status={status} /> : null}
          <span className="receipt__title">Produtos adquiridos</span>
          <span className="receipt__title">({sub_order.business_line_name})</span>
          <ul className="receipt__books">
            {sub_order.products.map((product, key) => (
              <li key={key} className="receipt__books__item">
                {product.product_name}
              </li>
            ))}
          </ul>
        </div>
        <div className="receipt__print-out" onClick={() => this._handlePrintOut()}>
          <img src={imprimir} alt="Imprimir Comprovante" /> Imprimir comprovante
        </div>
      </div>
    )
  }
}
