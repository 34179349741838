import React, { Component } from 'react'

import img_kindergarten from './images/kindergarten.png'
import img_anos_iniciais from './images/anos_iniciais.png'
import img_anos_finais from './images/anos_finais.png'

export default class KnowTheProject extends Component {
  render() {
    let { closeModal } = this.props
    return (
      <div className={`KnowTheProject`}>
        <div
          onClick={() => {
            closeModal()
          }}
          className="KnowTheProject_bg"
        />
        <div className="KnowTheProject_container container">
          <button
            onClick={() => {
              closeModal()
            }}
            className="KnowTheProject_close"
          >
            x
          </button>
          <div className="row KnowTheProject_row">
            <div className="col-md-6 col-sm-12 text">
              <h3>EDUCAÇÃO INFANTIL (Kindergarten)</h3>
              <ul>
                <li>
                  Kit <i>UDP Bilingual Program</i> com mochila, pasta, caderno de atividades para as famílias, conjunto
                  de lápis de cor, máscara e bag tag para atividades iniciais na escola;
                </li>
                <li>Livro físico com caderno de atividades extras integrado;</li>
                <li>Livro digital interativo;</li>
                <li>Objetos digitais;</li>
                <li>Músicas;</li>
                <li>Plataforma interativa;</li>
                <li>
                  Acesso à biblioteca digital <i>Highlights</i> com mais de 2 mil títulos disponíveis;
                </li>
                <li>App de jogos.</li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-12 image">
              <img
                src={img_kindergarten}
                alt="EDUCAÇÃO INFANTIL (Kindergarten)"
                title="EDUCAÇÃO INFANTIL (Kindergarten)"
                className="img-responsive"
              />
            </div>
          </div>
          <div className="row KnowTheProject_row">
            <div className="col-md-6 col-sm-12 text">
              <h3>ANOS INICIAIS – 1º ao 5º ano</h3>
              <ul>
                <li>
                  Kit <i>UDP Bilingual Program</i> com mochila, pasta, caderno de atividades para as famílias, conjunto
                  de lápis de cor, máscara e bag tag para atividades iniciais na escola;
                </li>
                <li>Livro físico com caderno de atividades extras integrado;</li>
                <li>Livro digital interativo;</li>
                <li>Músicas;</li>
                <li>Plataforma interativa;</li>
                <li>
                  Acesso à biblioteca digital <i>Highlights</i>, com mais de 2 mil títulos disponíveis;
                </li>
                <li>App de jogos;</li>
                <li>
                  Exame internacional <i>TOEFL Primary</i>;
                </li>
                <li>
                  Projetos integrados com abordagem <i>CLIL</i>.
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-12 image">
              <img
                src={img_anos_iniciais}
                alt="ANOS INICIAIS – 1º ao 5º ano"
                title="ANOS INICIAIS – 1º ao 5º ano"
                className="img-responsive"
              />
            </div>
          </div>
          <div className="row KnowTheProject_row">
            <div className="col-md-6 col-sm-12 text">
              <h3>ANOS FINAIS – 6º ao 9º ano</h3>
              <ul>
                <li>
                  Kit <i>UDP Bilingual Program</i> com mochila, pasta, caderno de atividades para as famílias, conjunto
                  de lápis e caneta e bag tag para atividades iniciais na escola;
                </li>
                <li>Livro físico com caderno de atividades extras integrado;</li>
                <li>Livro digital interativo;</li>
                <li>Plataforma interativa;</li>
                <li>Paradidáticos digitais integrados ao livro;</li>
                <li>App de jogos;</li>
                <li>
                  Exame internacional <i>TOEFL Junior</i>;
                </li>
                <li>
                  Projetos integradores com abordagem <i>CLIL</i>.
                </li>
              </ul>
            </div>
            <div className="col-md-6 col-sm-12 image">
              <img
                src={img_anos_finais}
                alt="ANOS FINAIS – 6º ao 9º ano"
                title="ANOS FINAIS – 6º ao 9º ano"
                className="img-responsive"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
