import React, { Component } from 'react'
import { setDigitalSolutionsHybrid } from '@actions/digitalSolutions'
import { closeLoader, openLoader } from '@actions/loader'
import { closeModal, openModal } from '@actions/modal'
import { connect } from 'react-redux'

import { SelectDigitalSolution } from './SelectDigitalSolution'

export class DigitalSolutionH extends Component {
  state = {
    hybrid_ensino: {},
  }

  _getOptions(key) {
    let { digital_solution_val } = this.props
    let ensino_ano = key.split('_')

    let options = {
      1: 'Educamos - Ambiente Digital',
      2: 'SM Aprendizagem',
      3: 'SM Aprendizagem Plus',
    }

    Object.entries(digital_solution_val).forEach(([ensino, value]) => {
      if (ensino_ano.length > 1) {
        if (ensino === key && value > 0) {
          options = {
            1: 'Educamos - Ambiente Digital',
          }
        }
      } else {
        ensino = ensino.split('_')
        if (ensino[0] === key && value > 0) {
          options = {
            1: 'Educamos - Ambiente Digital',
          }
        }
      }
    })
    return options
  }

  _handSetGlobalHybridEnsino(value, ensino, anos) {
    this.setState({
      hybrid_ensino: {
        ...this.state.hybrid_ensino,
        [ensino]: value,
      },
    })

    Object.entries(anos).map(([ano]) => this.props.setDigitalSolutionsHybrid({ [`${ensino}_${ano}`]: value }))
  }

  _handSetHybridEnsino(value, ensino, ano) {
    this.setState({
      hybrid_ensino: {
        ...this.state.hybrid_ensino,
        [ensino]: '',
      },
    })
    this.props.setDigitalSolutionsHybrid({ [`${ensino}_${ano}`]: value })
  }

  _normalizeEnsino(ensino) {
    switch (ensino) {
      case 'ei':
        return 'Ensino infantil'
      case 'ef1':
        return 'Fundamental 1'
      case 'ef2':
        return 'Fundamental 2'
      case 'em':
        return 'Ensino médio'
      default:
        break
    }
  }

  render() {
    let _hybrid = {}

    if (this.props.hybrid) {
      Object.entries(this.props.hybrid).forEach(([key, value]) => {
        let ensino_ano = key.split('_')
        _hybrid = {
          ..._hybrid,
          [ensino_ano[0]]: {
            ..._hybrid[ensino_ano[0]],
            [ensino_ano[1]]: value,
          },
        }
      })
    }

    return (
      <div className="digital_solution_description">
        <p>
          <b>Híbrido:</b> selecione por nível de ensino ou por ano quais serão as formas de ativação da licença digital:
          SM Aprendizagem, SM Aprendizagem Plus ou Educamos - Ambiente Digital. O e-mail de ativação da chave digital
          será enviado com instruções de acordo com a solução selecionada por nível de ensino ou por ano.
        </p>
        {this.props.hybrid && (
          <div className="digital_solution_hybrid">
            {Object.entries(_hybrid).map(([ensino, anos]) => (
              <div key={ensino} className="digital_solution_hybrid_col">
                <SelectDigitalSolution
                  key={ensino}
                  label={`${this._normalizeEnsino(ensino)}`}
                  options={this._getOptions(ensino)}
                  value={this.state.hybrid_ensino[ensino]}
                  handleChange={event => this._handSetGlobalHybridEnsino(event.target.value, ensino, anos)}
                />
                {Object.entries(anos).map(([ano, digital_solution]) => (
                  <SelectDigitalSolution
                    key={`${ensino}_${ano}`}
                    label={ano !== 'maternal' ? `${ano}º ano` : 'Maternal'}
                    options={this._getOptions(`${ensino}_${ano}`)}
                    value={digital_solution}
                    handleChange={event => this._handSetHybridEnsino(event.target.value, ensino, ano)}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    hybrid: state.digitalSolutions.hybrid,
    digital_solution_val: state.digitalSolutions.digital_solution_val,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModal: component => dispatch(openModal(component)),
    closeModal: () => dispatch(closeModal()),
    openLoader: () => dispatch(openLoader()),
    closeLoader: () => dispatch(closeLoader()),
    setDigitalSolutionsHybrid: data => dispatch(setDigitalSolutionsHybrid(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DigitalSolutionH)
