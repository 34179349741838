import React from 'react'
import { Radio, RadioGroup } from 'react-form'

import PermissionsChecker from '@shared/PermissionsChecker'

const CanRemoveCellInput = ({ data, products = [], rowIndex, values, type, ...props }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <PermissionsChecker permissions={['manage_product_discount']}>
      <RadioGroup
        errorBefore={false}
        field={['canRemoveProductInputsValue', rowIndex]}
        className="field_radio discount-cell-input"
      >
        <label className="field__radio__option">
          <Radio value={true} />
          <span className="field__radio__label">Sim</span>
        </label>
        <label className="field__radio__option">
          <Radio value={false} />
          <span className="field__radio__label">Não</span>
        </label>
      </RadioGroup>
    </PermissionsChecker>
  </div>
)

export default CanRemoveCellInput

