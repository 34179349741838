import { closeLoader, openLoader } from '@actions/loader'
/**
 * Dependencies
 */
import { getOrder, getOrdersByUser } from '@services/sm-api.js'
// import { createSelector } from 'reselect'

/**
 * Actions types
 */
export const FETCH_ORDERS = 'order/FETCH'
export const SUCCEED_ORDERS = 'order/SUCCEED'
export const SUCCEED_ACTIVE_ORDER = 'order/SUCCEED_ACTIVE_ORDER'
export const SUCCEED_ORDERS_INFINITE = 'order/SUCCEED_INFINITE'
export const FAILURE_ORDERS = 'order/FAILURE'

/**
 * Actions creators
 */
export function fetchOrders() {
  return {
    type: FETCH_ORDERS,
    isFetching: true,
  }
}

export function succeedOrders(payload) {
  return {
    type: SUCCEED_ORDERS,
    payload,
  }
}

export function succeedActiveOrder(payload) {
  return {
    type: SUCCEED_ACTIVE_ORDER,
    payload,
  }
}

export function succeedOrdersInfinite(payload) {
  return {
    type: SUCCEED_ORDERS_INFINITE,
    payload,
  }
}

export function failureOrders(error) {
  return {
    type: FAILURE_ORDERS,
    error,
  }
}

export const getOrders = params => {
  return dispatch => {
    dispatch(fetchOrders())
    return getOrdersByUser(params)
      .then(response => dispatch(succeedOrders(response)))
      .catch(error => dispatch(failureOrders(error)))
  }
}

export const getNextOrders = params => {
  return dispatch => {
    dispatch(fetchOrders())
    return getOrdersByUser(params)
      .then(response => dispatch(succeedOrdersInfinite(response)))
      .catch(error => dispatch(failureOrders(error)))
  }
}

export const getActiveOrder = params => {
  return dispatch => {
    dispatch(openLoader())
    return getOrdersByUser(params)
      .then(response => {
        dispatch(succeedActiveOrder(response.orders))
      })
      .finally(() => dispatch(closeLoader()))
  }
}

export const getOrderDetail = params => {
  return dispatch => {
    dispatch(openLoader())
    return getOrder(params)
      .then(response => {
        dispatch(succeedActiveOrder(response))
      })
      .finally(() => dispatch(closeLoader()))
  }
}
