import React, { Component } from 'react'

class Ticket extends Component {
  render() {
    return (
      <div className="ticket">
        <h3 className="ticket__title">Novo atendimento</h3>
        <iframe className="ticket__iframe" src="https://app.pipefy.com/public/form/4k5tgRPD?embedded=true%22"></iframe>
      </div>
    )
  }
}

export default Ticket
