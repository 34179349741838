import React, { Component } from 'react'
import { connect } from 'react-redux'

class Loader extends Component {
  render() {
    let show = this.props.loader.show ? 'active' : ''
    return (
      <div className={`loader ${show}`}>
        <span className="loader__box">
          <span className="loader__inner"></span>
        </span>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loader: state.loader,
})

export default connect(mapStateToProps)(Loader)
