export const links = [
  {
    name: 'voucher',
    url: '/steps/voucher',
  },
  {
    name: 'selecionar livros',
    url: '/steps/selecionar-livros',
  },
  {
    name: 'Confirmação',
    url: '/steps/confirmacao',
  },
  {
    name: 'identificação',
    url: '/steps/identificacao',
  },
  {
    name: 'atualização',
    url: '/steps/atualizacao',
  },
  {
    name: 'pagamento',
    url: '/steps/pagamento',
  },
  {
    name: 'concluído',
    url: '/steps/concluido',
  },
]
