import { getActiveNotification } from '@services/sm-api.js'

export const FETCH_ACTIVE_NOTIFICATION = 'activeNotification/FETCH_ACTIVE_NOTIFICATION'

export function _getActiveNotification(payload) {
  return {
    type: FETCH_ACTIVE_NOTIFICATION,
    payload,
  }
}

export function fetchActiveNotification() {
  return dispatch => {
    getActiveNotification().then(response => dispatch(_getActiveNotification(response)))
  }
}
