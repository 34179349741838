import { INFINITE_REPORT_LIST, REPORT_LIST, SET_CURRENT_CAMPAIGN, SUCCEED_SYNC } from '@actions/report'

import { REHYDRATE } from 'redux-persist/constants'

const initialState = {
  list: [],
  currentCampaign: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REPORT_LIST:
      return {
        ...state,
        list: { ...action.payload },
        url: action.url,
      }
    case SUCCEED_SYNC:
      action.list[action.listType].splice(action.index, 1)

      return {
        ...state,
        list: { ...action.list },
      }
    case INFINITE_REPORT_LIST:
      return {
        ...state,
        list: { ...action.payload, ...action.list },
      }
    case SET_CURRENT_CAMPAIGN:
      return {
        ...state,
        currentCampaign: action.payload,
      }
    case REHYDRATE:
      return { ...state }
    default:
      return state
  }
}
