import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import doneIcon from './Group 4958@2x.png'
import exp from './export.png'
import { listVariables } from '@services/sm-api'
import _ from 'lodash'
import CopyToClipboard from '../../shared/CopyToClipboard'

class DoneStep extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hybridBilletActivation: null,
    }

    this._handleDownloadClick = this._handleDownloadClick.bind(this)
    this._getMessage = this._getMessage.bind(this)
  }

  _handleDownloadClick(url) {
    window.ga('send', {
      hitType: 'event',
      eventCategory: 'compra',
      eventAction: 'click',
      eventLabel: 'baixou_boleto_5.1',
    })

    window.open(url)
  }

  componentDidMount() {
    listVariables().then(data => {
      const hybridBilletActivation = _.find(data, { key: 'HYBRID_BILLET_ACTIVATION' })
      this.setState({
        hybridBilletActivation: hybridBilletActivation.value,
      })
    })
  }

  _getMessage() {
    const { order } = this.props
    if (!order.order_id) {
      return 'Acesse sua conta para acompanhar o pedido.'
    }
    const messages = [
      [
        // !billet_url && !is_b2c
        'Agora é só aguardar a entrega na escola. A nota fiscal será enviada para o seu e-mail.',
        // !billet_url && is_b2c
        'Agora é só aguardar. Seu pedido será enviado para o Endereço de Entrega preenchido no ato da compra. A nota fiscal será enviada para o e-mail (verifique caixa de SPAM e lixo eletrônico).',
      ],
      [
        // billet_url && !is_b2c
        'Seu pedido será enviado para a escola após a confirmação do pagamento e a nota fiscal será enviada para o seu email.',
        // billet_url && is_b2c
        'Seu pedido será enviado para o Endereço de Entrega preenchido no ato da compra, após a confirmação do pagamento. A nota fiscal será enviada para o e-mail (verifique caixa de SPAM e lixo eletrônico)',
      ],
    ]
    return messages[+!!order.billet_url][+!!order.is_b2c]
  }

  render() {
    const { order } = this.props
    return (
      <div className="done-step">
        <h3 className="done-step__title">{!order.billet_url ? 'Sucesso' : 'Pagamento Pendente'}</h3>
        <p className="done-step__message">{this._getMessage()}</p>
        {order.qrcode && this.state.hybridBilletActivation == 1 ? (
          <div>
            <p>
              Copie a chave PIX e realize o pagamento no seu <i>internet banking</i>
            </p>
            <CopyToClipboard copyText={order.qrcode} />
          </div>
        ) : null}
        <img className="done-step__done-icon" src={doneIcon} alt="Pedido confirmado." />
        {order.order_id && (
          <p className="done-step__order-message">
            O número do seu pedido é {order.order_id}.{' '}
            {!order.billet_url || (order.billet_url && this.state.hybridBilletActivation !== 1)
              ? 'Acesse sua conta para acompanhar o status do pedido.'
              : null}
          </p>
        )}
        {order.billet_url ? (
          this.state.hybridBilletActivation == 1 ? (
            <div>
              <p className="done-step__order-message">
                Caso queira pagar mais tarde, você poderá baixar o boleto em "Visualizar boleto/Pix".
              </p>
              <p className="done-step__order-message">
                Nele contém o código de barras ou QR code para pagamento por PIX.
              </p>
              <p className="done-step__order-message">
                Você também poderá acessar o boleto em "Pedidos", acessando sua conta no SM Direto.
              </p>
            </div>
          ) : null
        ) : null}
        {order.billet_url ? (
          <button
            className="button__normal done-step__button"
            onClick={() => this._handleDownloadClick(order.billet_url)}
          >
            {this.state.hybridBilletActivation == 1 ? 'Visualizar boleto/PIX' : 'Baixar boleto'}
            <img src={exp} alt="" />
          </button>
        ) : null}
        <NavLink to="/admin/pedidos" className="button__normal done-step__button">
          Ir para a minha conta
        </NavLink>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  order: state.creditCard,
})

export default connect(mapStateToProps)(DoneStep)

