import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'

import Instructions from '../../components/Instructions'
import { clear } from '@actions/checkout'

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false,
    }
  }

  componentDidMount() {
    const modalShown = localStorage.getItem('modalShown')

    if (!modalShown) {
      localStorage.setItem('modalShown', 'true')
      this.setState({
        modalOpen: true,
      })
    }
  }


  render() {
    return (
      <div className="home">
        <Instructions specialDiscount={this.props.specialDiscount} />
        <Modal show={this.state.modalOpen}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert"></span>
              <h1>AVISO</h1>
            </div>

            <div className="modalContent">
              <p>Caro usuário,</p>
              <p>
                Após a recente atualização do nosso site, alguns usuários logados podem enfrentar problemas de
                autenticação ao tentar acessar áreas restritas. Isso ocorre devido a cookies de autenticação da versão
                anterior que não são mais compatíveis.
              </p>
              <p className="yellow-text">
                <strong>Para resolver esse problema, siga as instruções abaixo!</strong>
              </p>
              <p>
                <strong>Limpar os Cookies do Navegador:</strong>
              </p>
              <p>
                {' '}
                Você pode limpar os cookies do seu navegador seguindo as instruções específicas para o seu navegador.
              </p>
              <p>
                <strong>Aqui estão links para as instruções de alguns navegadores populares:</strong>
              </p>
              <p>
                <a href="https://support.google.com/accounts/answer/32050" target="_blank">
                  Instruções para limpar cookies no Chrome
                </a>{' '}
              </p>
              <p>
                <a
                  href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
                  target="_blank"
                >
                  Instruções para limpar cookies no Firefox
                </a>
              </p>
              <p>
                {' '}
                <strong>Após a Limpeza dos Cookies:</strong>
              </p>
              <p>
                Após limpar os cookies, você poderá fazer login novamente e acessar todas as áreas do site normalmente.
              </p>
              <p>
                Pedimos desculpas por qualquer inconveniente causado por esse problema e agradecemos sua compreensão.
              </p>
            </div>

            <div className="modalFooter">
              <button
                className="modal-close"
                onClick={() => {
                  this.setState({
                    modalOpen: false,
                  })
                }}
              >
                Entendi
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  voucherForm: state.voucherForm,
})

const mapDispatchToProps = dispatch => ({
  clear: () => dispatch(clear()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)

