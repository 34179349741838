import React, { Component } from 'react'
export default class InfiniteScroll extends Component {
  constructor(props) {
    super(props)
    this.state = { page: 1 }
    this._handleScroll = this._handleScroll.bind(this)
  }

  _handleScroll(e) {
    if (document.body.scrollHeight === e.target.scrollTop + window.innerHeight && this.props.loadMore) {
      this.props.action()
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this._handleScroll, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._handleScroll, true)
  }

  render() {
    const { children } = this.props

    return <div>{children}</div>
  }
}
