export const paymentStatus = {
  PG: { type: 'ok', description: 'Pagamento confirmado' },
  AP: { type: 'ok', description: 'Pagamento confirmado' },
  PAV: { type: 'ok', description: 'Pagamento após vencimento' },
  NP: { type: 'warning', description: 'Pagamento recusado' },
  BAP: { type: 'warning', description: 'Aguardando pagamento' },
  PW: { type: 'warning', description: 'Aguardando pagamento' },
  DV: { type: 'denied', description: 'Pagamento divergente' },
  C: { type: 'denied', description: 'Cancelado! Pagamento não Confirmado' },
  CM: { type: 'denied', description: 'Cancelado Manualmente' },
  CBS: { type: 'denied', description: 'Pedido e Boleto cancelados' },
  CES: {
    type: 'denied',
    description: 'Pedido cancelado e pagamento estornado',
  },
  PP: { type: 'ok', description: 'Pedido em preparação' },
  PF: { type: 'ok', description: 'Pedido finalizado, aguardando expedição' },
  PT: { type: 'ok', description: 'Pedido entregue na transportadora' },
  PET: { type: 'ok', description: 'Pedido em trânsito' },
  PE: { type: 'ok', description: 'Pedido entregue' },
  null: { type: 'warning', description: 'Pagamento recusado' },
}

