import React from 'react'

/**
 * formatMoney
 * @param {*} value = valor a ser formatado
 * @param {*} minimumFractionDigits = mínimo de casas decimais
 * @param {*} maximumFractionDigits = máximo de casas decimais
 * @param {*} sup = numero de caracteres a serem mostrado no canto superior direito do valor
 * @returns retura por padrão o valor formatado em moeda Brasileira com o número de casas decimais definidas
 */
export function formatMoney(value, minimumFractionDigits = 2, maximumFractionDigits = 2, sup = 0) {
  let result = new Intl.NumberFormat('pt-BR', {
    currency: 'BRL',
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  }).format(value)

  return (
    <React.Fragment>
      {`R$ ${sup > 0 ? result.substring(0, result.length - sup) : result}`}
      {sup > 0 && <sup style={{ fontSize: '63%' }}>{result.substring(result.length - sup)}</sup>}
    </React.Fragment>
  )
}

export default formatMoney
