import React, { Component } from 'react'

import './index.css'

class InlineLoader extends Component {
  render() {
    let { show } = this.props

    return (
      <div className="inline-loader__container">
        <span className="inline-loader__box" style={{ display: show ? 'inline-block' : 'none' }}>
          <span className="inline-loader__inner"></span>
        </span>
      </div>
    )
  }
}

export default InlineLoader
