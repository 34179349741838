import { CNPJ, CPF } from 'cpf_cnpj'
import { Checkbox, Form, FormError, Radio, RadioGroup, Text } from 'react-form'
import {
  InputCheckbox,
  InputText,
  regexPassword,
  InputPassword,
  SelectState,
  regexZipcodeFull,
} from '@shared/CustomField'
import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader.js'
import { closeModal, openModal } from '@actions/modal.js'
import {
  destroy,
  destroyUserSession,
  getSchools,
  getUserByEmail,
  getUserData,
  requestDelete,
  requestEnable,
  requestRegister,
  requestUpdate,
} from '@actions/user'

import { getFreight, resetFreight } from '@actions/freight'

import NormalizeBrazilianStates from '@shared/NormalizeBrazilianStates'
import { TeacherDuplicatedOrderModal } from '@shared/TeacherDuplicatedOrderModal'

import Cep from 'cep-promise'
import ConfirmDialog from '@shared/ConfirmDialog'
import { Modal } from 'react-bootstrap'

import _ from 'lodash'
import { clear as clearCheckout } from '@actions/checkout'
import { clearSpecialDiscount } from '@actions/specialDiscount'
import { connect } from 'react-redux'
import { listVariables, checkOrderTeacher } from '@services/sm-api'
import moment from 'moment'
import { withRouter } from '../../utils/withRouter'

class UserUpdateForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showingField: 'cpf',
      email: null,
      document: '',
      userAlreadyExists: {},
      userData: {},
      address_zipcode: '',
      address_found: false,
      address_incomplete: false,
      delivery_address_zipcode: '',
      delivery_address_found: false,
      delivery_address_incomplete: false,
      ie_free: false,
      ieModalOpen: false,
      showTeacherModal: false,
      school_is_b2c: false,
      swap: '',
      hasUpdateTermUse: false,
      hasUpdatePrivacyPolicy: false,
      variableTermUse: {},
      variablePrivacyPolicy: {},
      showModal: false,
      updatePassword: false,
      addressModalClose: false,
      unique_zipcode: false,
      invalid_address_zipcode: false,
      disable_address_state: true,
      disable_delivery_address_state: true,
      delivery_invalid_address_zipcode: false,
      same_address: false,
      showModalFreight: false,
      delivery_unique_zipcode: false,
    }

    /*eslint-disable */
    this.regexEmail =
      /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    /*eslint-enable */
    this.hideDefaultError = { style: { display: 'none' } }
    this._getAddress = this._getAddress.bind(this)
    this._getDeliveryAddress = this._getDeliveryAddress.bind(this)
    this._handleCancel = this._handleCancel.bind(this)
    this._handleBack = this._handleBack.bind(this)
  }

  _handleBack() {
    let { destroyUserSession } = this.props
    destroyUserSession()
    this.props.navigate('/steps/identificacao')
  }

  _handleCancel() {
    let { destroy, clearCheckout, clearSpecialDiscount } = this.props
    destroy()
    clearCheckout()
    clearSpecialDiscount()
    this.props.navigate('/desconto-professor')
  }

  /**
   * Get full address by CEP number and fill the adresses fields
   **/
  _getAddress(event, setValue, setAllValues) {
    this.props.openLoader()
    const value = event.target.value
    let cep = value.replace('-', '')
    if (cep.length === 8) {
      Cep(cep)
        .then(data => {
          this.setState({
            address_found: true,
            address_incomplete: false,
          })
          setValue('address_number', '', true)
          setValue('address_complement', '', true)
          setValue('address_street', data.street, true)
          setValue('address_city', data.city, true)
          setValue('address_neighborhood', data.neighborhood, true)
          if (data.state && NormalizeBrazilianStates(data.state)) {
            setValue('address_state', NormalizeBrazilianStates(data.state), true)
          } else {
            this.setState({ disable_address_state: false })
          }

          if (this.state.same_address) {
            this._getFreight(data)
            setValue('address_zipcode_delivery', value, true)
            setValue('address_number_delivery', '', true)
            setValue('address_complement_delivery', '', true)
            setValue('address_street_delivery', data.street, true)
            setValue('address_city_delivery', data.city, true)
            setValue('address_neighborhood_delivery', data.neighborhood, true)
            if (data.state && NormalizeBrazilianStates(data.state)) {
              setValue('address_state_delivery', NormalizeBrazilianStates(data.state), true)
            } else {
              this.setState({ disable_delivery_address_state: false })
            }
          }
          this.setState({
            invalid_address_zipcode: false,
          })
          if (this._isUniqueZipcode(data)) {
            this.setState({
              unique_zipcode: true,
            })
          } else {
            this.setState({
              unique_zipcode: false,
            })
          }
        })
        .catch(e => {
          console.log(e)
          if (this.state.same_address) {
            this._getFreight()
          }
          ;[
            'address_number',
            'address_complement',
            'address_street',
            'address_city',
            'address_neighborhood',
            'address_state',
          ].forEach(field => {
            setValue(field, '', true)
          })

          this.props.openModal(
            <ConfirmDialog
              text="Dados não encontrados para o cep informado."
              okLabel="Confirmar"
              ok={() => {
                this.props.closeModal()
              }}
            />,
          )

          this.setState({
            address_found: false,
            address_incomplete: true,
            unique_zipcode: false,
            invalid_address_zipcode: true,
          })
        })
    }
    this.props.closeLoader()
  }

  /**
   * Get full address by CEP number and fill the adresses fields
   **/
  _getDeliveryAddress(event, setValue, setAllValues) {
    this.props.openLoader()
    const value = event.target.value
    let cep = value.replace('-', '')

    if (cep.length === 8) {
      Cep(cep)
        .then(data => {
          this._getFreight(data)
          this.setState({
            delivery_address_found: true,
            delivery_address_incomplete: false,
          })
          setValue('address_number_delivery', '', true)
          setValue('address_complement_delivery', '', true)
          setValue('address_street_delivery', data.street, true)
          setValue('address_city_delivery', data.city, true)
          setValue('address_neighborhood_delivery', data.neighborhood, true)
          if (data.state && NormalizeBrazilianStates(data.state)) {
            setValue('address_state_delivery', NormalizeBrazilianStates(data.state), true)
          } else {
            this.setState({ disable_delivery_address_state: false })
          }
          this.setState({
            delivery_invalid_address_zipcode: false,
          })
          if (this._isUniqueZipcode(data)) {
            this.setState({
              delivery_unique_zipcode: true,
            })
          } else {
            this.setState({
              delivery_unique_zipcode: false,
            })
          }
        })
        .catch(e => {
          console.log(e)
          this._getFreight()
          ;[
            'address_number_delivery',
            'address_complement_delivery',
            'address_street_delivery',
            'address_city_delivery',
            'address_neighborhood_delivery',
            'address_state_delivery',
          ].forEach(field => {
            setValue(field, '', true)
          })

          this.props.openModal(
            <ConfirmDialog
              text="Dados não encontrados para o cep informado."
              okLabel="Confirmar"
              ok={() => {
                this.props.closeModal()
              }}
            />,
          )

          this.setState({
            delivery_address_found: false,
            delivery_address_incomplete: true,
            delivery_unique_zipcode: false,
            delivery_invalid_address_zipcode: true,
          })
        })
    }
    this.props.closeLoader()
  }

  _isUniqueZipcode(address) {
    if (address.street === 'undefined' || address.street.length === 0) {
      return true
    }
    if (address.neighborhood === 'undefined' || address.neighborhood.length === 0) {
      return true
    }
    return false
  }

  UNSAFE_componentWillMount() {
    this.props.openLoader()
    let editingUserRequest = {
      id: this.props.sessionReducer.user.id,
    }
    let _vouchers = []
    _vouchers = this.props.checkouts.map(checkout => ({ number: checkout.voucher }))

    checkOrderTeacher(this.props.sessionReducer.user.id, _vouchers).then(response =>
      this.setState({
        showTeacherModal: response,
      }),
    )

    listVariables().then(data => {
      const newState = {
        variableTermUse: _.find(data, {
          key: this.state.school_is_b2c ? 'B2C_TERMS_OF_USE_FILE_PATH' : 'TERMS_OF_USE_FILE_PATH',
        }),
        variablePrivacyPolicy: _.find(data, {
          key: this.state.school_is_b2c ? 'B2C_PRIVACY_POLICY_FILE_PATH' : 'PRIVACY_POLICY_FILE_PATH',
        }),
      }
      this.props.openLoader()
      this.setState(newState, () =>
        this.props
          .getUserData(editingUserRequest)
          .then(user => {
            this._toggleTypeField(user.type)

            const document = user.cpf || user.cnpj
            user.ie_free = 0

            if (user.ie === 'ISENTO') {
              this.setState({ ie_free: true, document })
              user.ie_free = 1
            } else {
              this.setState({ document })
            }

            let newUpdatedState = {}
            let date_terms_of_use_accepted = this.state.school_is_b2c
              ? user.b2c_terms_of_use_accepted_at
              : user.terms_of_use_accepted_at
            let acceptedTermsUse = moment(date_terms_of_use_accepted)
            acceptedTermsUse = moment(acceptedTermsUse.format('YYYY-MM-DD HH:mm:ss'))
            let updateTermUse = moment(this.state.variableTermUse.updated_at)

            if (updateTermUse.isAfter(acceptedTermsUse) || !date_terms_of_use_accepted) {
              newUpdatedState.hasUpdateTermUse = true
              newUpdatedState.showModal = true
              user.terms_of_use_accepted_at = false
            }

            let date_privacy_policy_accepted = this.state.school_is_b2c
              ? user.b2c_privacy_policy_accepted_at
              : user.privacy_policy_accepted_at
            let acceptedPrivacyPolicy = moment(date_privacy_policy_accepted)
            acceptedPrivacyPolicy = moment(acceptedPrivacyPolicy.format('YYYY-MM-DD HH:mm:ss'))
            let updatePrivacyPolicy = moment(this.state.variablePrivacyPolicy.updated_at)

            if (updatePrivacyPolicy.isAfter(acceptedPrivacyPolicy) || !date_privacy_policy_accepted) {
              newUpdatedState.hasUpdatePrivacyPolicy = true
              newUpdatedState.showModal = true
              user.privacy_policy_accepted_at = false
            }

            if (!_.isEmpty(newUpdatedState)) {
              this.setState(newUpdatedState)
            }

            if (_.isEmpty(user.password_updated_at)) {
              this.setState({ updatePassword: true })
            }

            this._fillFields(user)
            this.props.closeLoader()
          })
          .catch(error => {
            this.props.closeLoader()
            console.log(error)
          }),
      )
    })
  }

  _toggleIE() {
    this.setState({ ie_free: !this.state.ie_free }, () => {
      if (!this.state.ie_free) {
        this.refs.Form.setValue('ie', '')
      } else {
        this.refs.Form.setValue('ie', 'ISENTO')
      }
    })
  }

  /**
   * Toggle CPF and CNPJ fields
   **/
  _toggleSameAddress(event, setValue, getValue) {
    const isSameAddress = !this.state.same_address
    if (isSameAddress) {
      if (getValue('address_zipcode')) {
        this._getFreight({
          cep: getValue('address_zipcode'),
          city: getValue('address_city'),
          neighborhood: getValue('address_neighborhood'),
          state: getValue('address_state'),
          street: getValue('address_street'),
        })
      } else {
        this._getFreight()
      }
      setValue('address_zipcode_delivery', getValue('address_zipcode'), true)
      setValue('address_number_delivery', getValue('address_number'), true)
      setValue('address_complement_delivery', getValue('address_complement'), true)
      setValue('address_street_delivery', getValue('address_street'), true)
      setValue('address_city_delivery', getValue('address_city'), true)
      setValue('address_neighborhood_delivery', getValue('address_neighborhood'), true)
      setValue('address_state_delivery', getValue('address_state'), true)
    } else {
      ;[
        'address_zipcode_delivery',
        'address_number_delivery',
        'address_complement_delivery',
        'address_street_delivery',
        'address_city_delivery',
        'address_neighborhood_delivery',
        'address_state_delivery',
      ].forEach(field => {
        setValue(field, '', true)
      })
      this._getFreight()
    }
    this.setState({
      same_address: isSameAddress,
      delivery_address_found: false,
      delivery_address_incomplete: true,
      delivery_unique_zipcode: false,
      delivery_invalid_address_zipcode: true,
    })
  }

  isValidAddressNumber = number => ['number', 'string'].includes(typeof number) && String(number).length <= 10

  _fillFields(user) {
    this.props.openLoader()
    user.email = this.state.email ? this.state.email : user.email
    if (typeof this.refs.Form !== 'undefined') {
      this.refs.Form.setAllValues(
        {
          ...user,
          address_state: user.address_state ? NormalizeBrazilianStates(user.address_state) : '',
          address_number: this.isValidAddressNumber(user.address_number) ? user.address_number : '',
          address_number_delivery: user.address_number_delivery
            ? this.isValidAddressNumber(user.address_number_delivery)
              ? user.address_number_delivery
              : ''
            : '',
          address_complement: user.address_complement
            ? user.address_complement.length <= 10
              ? user.address_complement
              : ''
            : '',
        },
        true,
      )
      if (
        _.every([
          user.address_zipcode,
          user.address_city,
          user.address_neighborhood,
          user.address_state,
          user.address_street,
        ])
      ) {
        this.setState(
          {
            address_found: `${user.address_street}, ${user.address_zipcode} - ${user.address_neighborhood} - ${user.address_city}, ${user.address_state}`,
            address_incomplete: false,
          },
          () => {
            this.refs.Form.setAllValues(
              {
                ...user,
                address_state: user.address_state ? NormalizeBrazilianStates(user.address_state) : '',
                address_number: user.address_number
                  ? this.isValidAddressNumber(user.address_number)
                    ? user.address_number
                    : ''
                  : '',
                address_number_delivery: user.address_number_delivery
                  ? this.isValidAddressNumber(user.address_number_delivery)
                    ? user.address_number_delivery
                    : ''
                  : '',
                address_complement: user.address_complement
                  ? user.address_complement.length <= 10
                    ? user.address_complement
                    : ''
                  : '',
              },
              true,
            )
          },
        )
      }
      if (
        user.address_zipcode &&
        user.address_zipcode_delivery &&
        user.address_zipcode.replace('-', '') === user.address_zipcode_delivery.replace('-', '') &&
        String(user.address_number) === String(user.address_number_delivery) &&
        user.address_complement === user.address_complement_delivery
      ) {
        if (user.address_zipcode) {
          this._getFreight({
            cep: user.address_zipcode,
            city: user.address_city,
            neighborhood: user.address_neighborhood,
            state: user.address_state,
            street: user.address_street,
          })
        } else {
          this._getFreight()
        }
        this.refs.Form.setValue('same_address_', 0, true)
        this.setState({
          same_address: true,
        })
      } else {
        if (user.address_zipcode_delivery) {
          this._getFreight({
            cep: user.address_zipcode_delivery,
            city: user.address_city_delivery,
            neighborhood: user.address_neighborhood_delivery,
            state: user.address_state_delivery,
            street: user.address_street_delivery,
          })
        } else {
          this._getFreight()
        }
      }
    }
    this.props.closeLoader()
  }

  /**
   * Verify if the email is already taken
   **/
  _getUserByEmail(event) {
    this.props.getUserByEmail({ email: event.target.value })
    this.setState({
      email: event.target.value,
    })
  }

  /**
   * Toggle CPF and CNPJ fields
   **/
  _toggleTypeField(name, setValue) {
    if (name === 'cnpj') this.setState({ ieModalOpen: true })
    this.setState({
      showingField: name,
    })
  }

  /**
   * All validations rules
   **/
  _validateForm(values, school_is_b2c) {
    const errorMsg = {
      empty: 'Campo obrigatório',
      password: `A senha deve conter no mínimo 8 caracteres, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula, números de 0 a 9 e pelo menos um caractere especial (por exemplo '~ ! @ # $ % ^ & * ( ) _ + - = { } | \\ : " ; ' < > ? , . /)`,
      passwordLength: `A senha deve conter no mínimo 8 caracteres, pelo menos 1 letra maiúscula, pelo menos 1 letra minúscula, números de 0 a 9 e pelo menos um caractere especial (por exemplo '~ ! @ # $ % ^ & * ( ) _ + - = { } | \\ : " ; ' < > ? , . /)`,
      confirmPassword: 'As senhas devem ser iguais',
      validZipcode: 'Cep Inválido',
      notfoundZipcode: 'Cep Inválido',
      cpf: 'CPF inválido',
      cnpj: 'CNPJ inválido',
      ie: 'Inscrição Estadual inválida',
      addressNumberLength: 'O número deve conter no máximo 10 caracteres',
      addressComplementLength: 'O complemento deve conter no máximo 10 caracteres',
    }
    const {
      full_name,
      password,
      confirm_password,
      phone,
      cpf,
      cnpj,
      ie,
      address_zipcode,
      address_street,
      address_number,
      address_neighborhood,
      address_city,
      address_state,
      address_complement,
      address_zipcode_delivery,
      address_street_delivery,
      address_number_delivery,
      address_neighborhood_delivery,
      address_city_delivery,
      address_state_delivery,
      address_complement_delivery,
      terms_of_use_accepted_at,
      privacy_policy_accepted_at,
    } = values

    
    if(address_street){
      var errorAddressStreet = undefined
    }
    if(address_street && address_street.length < 3){
      var errorAddressStreet = 'Endereço inválido, mínimo de 3 caracteres'
    }
    if(!address_street){
      var errorAddressStreet = errorMsg.empty
    }

    if(address_street_delivery){
      var errorAddressStreetDelivery = undefined
    }
    if(address_street_delivery && address_street_delivery.length < 3){
      var errorAddressStreetDelivery = 'Endereço inválido, mínimo de 3 caracteres'
    }
    if(!address_street_delivery){
      var errorAddressStreetDelivery = errorMsg.empty
    }


    let error = {
      full_name: !full_name ? errorMsg.empty : undefined,
      phone: !phone ? errorMsg.empty : undefined,
      address_zipcode: !address_zipcode
        ? errorMsg.empty
        : !regexZipcodeFull.test(address_zipcode)
        ? errorMsg.validZipcode
        : undefined,
      address_street: errorAddressStreet,
      address_number: !address_number
        ? errorMsg.empty
        : address_number.toString().length > 10
        ? errorMsg.addressNumberLength
        : undefined,
      address_neighborhood: !address_neighborhood ? errorMsg.empty : undefined,
      address_city: !address_city ? errorMsg.empty : undefined,
      address_state: !address_state ? errorMsg.empty : undefined,
      address_complement:
        address_complement && address_complement.length > 10 ? errorMsg.addressComplementLength : undefined,
    }

    if (this.state.hasUpdateTermUse) {
      error = {
        ...error,
        terms_of_use_accepted_at: !terms_of_use_accepted_at ? errorMsg.empty : undefined,
      }
    }

    if (this.state.hasUpdatePrivacyPolicy) {
      error = {
        ...error,
        privacy_policy_accepted_at: !privacy_policy_accepted_at ? errorMsg.empty : undefined,
      }
    }

    if (school_is_b2c) {
      error = {
        ...error,
        address_zipcode_delivery: !address_zipcode_delivery
          ? errorMsg.empty
          : !regexZipcodeFull.test(address_zipcode_delivery)
          ? errorMsg.validZipcode
          : undefined,

        address_street_delivery: errorAddressStreetDelivery,
        address_number_delivery: !address_number_delivery
          ? errorMsg.empty
          : address_number_delivery.length > 10
          ? errorMsg.addressNumberLength
          : undefined,
        address_neighborhood_delivery: !address_neighborhood_delivery ? errorMsg.empty : undefined,
        address_city_delivery: !address_city_delivery ? errorMsg.empty : undefined,
        address_state_delivery: !address_state_delivery ? errorMsg.empty : undefined,
        address_complement_delivery:
          address_complement_delivery && address_complement_delivery.length > 10
            ? errorMsg.addressComplementLength
            : undefined,
      }
    }

    if (this.state.updatePassword) {
      error.password = !password ? errorMsg.empty : !regexPassword.test(password) ? errorMsg.passwordLength : undefined

      error.confirm_password = confirm_password !== password ? errorMsg.confirmPassword : undefined
    }

    if (this.state.showingField === 'cpf') {
      error.cpf = !CPF.isValid(cpf) ? 'CPF inválido' : !cpf ? errorMsg.empty : undefined
    }

    if (this.state.showingField === 'cnpj') {
      error.cnpj = !CNPJ.isValid(cnpj) ? 'CNPJ inválido' : !cnpj ? errorMsg.empty : undefined
      error.ie = !ie ? 'Inscrição Estadual obrigatória' : undefined
    }

    if (!address_zipcode || !regexZipcodeFull.test(address_zipcode)) {
      this.setState({
        address_incomplete: true,
      })
    }

    if (!address_zipcode_delivery || !regexZipcodeFull.test(address_zipcode_delivery)) {
      this.setState({
        delivery_address_incomplete: true,
      })
    }

    if (phone && phone.length <= 9) {
      error.phone = 'Telefone inválido'
    }

    return error
  }

  _handleEdit(values) {
    let user = values
    delete user['same_address_']

    if (this.state.hasUpdatePrivacyPolicy) {
      if (this.state.school_is_b2c) {
        user.b2c_privacy_policy_accepted_at = moment().format('YYYY-MM-DD HH:mm:ss')
        delete user.privacy_policy_accepted_at
      } else {
        user.privacy_policy_accepted_at = moment().format('YYYY-MM-DD HH:mm:ss')
        delete user.b2c_privacy_policy_accepted_at
      }
    } else {
      delete user.privacy_policy_accepted_at
      delete user.b2c_privacy_policy_accepted_at
    }

    if (this.state.hasUpdateTermUse) {
      if (this.state.school_is_b2c) {
        user.b2c_terms_of_use_accepted_at = moment().format('YYYY-MM-DD HH:mm:ss')
        delete user.terms_of_use_accepted_at
      } else {
        user.terms_of_use_accepted_at = moment().format('YYYY-MM-DD HH:mm:ss')
        delete user.b2c_terms_of_use_accepted_at
      }
    } else {
      delete user.terms_of_use_accepted_at
      delete user.b2c_terms_of_use_accepted_at
    }

    if (this.state.updatePassword) {
      user.password_updated_at = moment().format('YYYY-MM-DD HH:mm:ss')
    } else {
      delete user.password_updated_at
    }

    user.id = this.props.sessionReducer.user.id
    this.props.requestUpdate(user)
    this.props.navigate('/steps/pagamento')
  }

  _getFreight(data = null) {
    if (this.state.school_is_b2c) {
      this.props.openLoader()
      const __getVouchers = () => {
        var vouchers = []
        this.props.checkouts.forEach(voucher => {
          let voucher_products = []
          voucher.products.forEach(product => {
            if (product.active) {
              voucher_products.push({ voucher_product_id: product.id })
            }
          })
          vouchers.push({
            number: voucher.voucher,
            voucher_products: voucher_products,
          })
        })
        return vouchers
      }

      if (data) {
        let formated_data = {
          cep: data.cep.replace('-', ''),
          state: data.state,
          city: data.city,
          vouchers: __getVouchers(),
        }

        this.props.getFreight(formated_data)
      } else {
        this.props.resetFreight()
      }
      this.props.closeLoader()
    }
  }

  _modalFreightNotSuported() {
    return (
      <Modal show={this.state.showModalFreight}>
        <Modal.Body>
          <div className="modalHeader">
            <span className="icon-alert" />
            <h1>Atenção</h1>
          </div>

          <div className="modalContent">
            <p className="text-center">Infelizmente não podemos entregar no endereço de entrega digitado!</p>
            <p className="text-center">Por favor digite outro endereço para a entrega.</p>
          </div>

          <div className="modalFooter">
            <button
              className="modal-close"
              onClick={() => {
                this.setState({
                  showModalFreight: false,
                })
              }}
            >
              Entendi
            </button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  render() {
    let { specialDiscount, checkouts } = this.props
    const has_special_discount = !_.isEmpty(specialDiscount) && !_.isEmpty(specialDiscount.special_discounts_document)
    const can_proceed =
      !has_special_discount ||
      (this.state.document && this.state.document === specialDiscount.special_discounts_document)
    const first = _.first(checkouts)
    const is_teacher_order_duplicated = first.voucher.toLowerCase().startsWith('p') && this.state.showTeacherModal //TODO: handleShowTeacherModal
    // eslint-disable-next-line
    this.state.school_is_b2c = first ? Boolean(first.school_is_b2c) : false
    if (can_proceed) {
      return (
        <div className="update">
          {this.state.school_is_b2c && this._modalFreightNotSuported()}
          <TeacherDuplicatedOrderModal
            show={is_teacher_order_duplicated}
            onClose={() => {
              this.setState({
                showTeacherModal: false,
              })
            }}
          />
          <Modal show={this.state.school_is_b2c && !this.state.addressModalClose && !this.state.showTeacherModal}>
            <Modal.Body>
              <div className="modalHeader">
                <span className="icon-alert" />
                <h1>Atenção</h1>
              </div>

              <div className="modalContent">
                <p>
                  Por favor, certificar o preenchimento do "Endereço de Entrega", pois após a confirmação do pedido o
                  endereço não poderá ser alterado.{' '}
                </p>
              </div>

              <div className="modalFooter">
                <button
                  id="update-agreeing-address"
                  className="modal-close"
                  onClick={() => {
                    this.setState({
                      addressModalClose: true,
                    })
                  }}
                >
                  Entendi
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <div className="update__header">
            <h3 className="update__title">Atualização cadastral</h3>
            <h4 className="update__subtitle">Por favor confirme ou altere os seus dados cadastrais</h4>
          </div>
          <Form
            ref="Form"
            validate={values => {
              const validation = this._validateForm(values, this.state.school_is_b2c)
              setTimeout(() => {
                if (!this.state.address_zipcode || !regexZipcodeFull.test(this.state.address_zipcode)) {
                  this.setState({
                    address_incomplete: true,
                  })
                }
              }, 300)

              return validation
            }}
            onSubmit={values => {
              if (this.state.school_is_b2c) {
                if (this.props.calculated_freight !== null) {
                  this._handleEdit(values)
                } else {
                  this.setState({
                    showModalFreight: true,
                  })
                }
              } else {
                this._handleEdit(values)
              }
            }}
          >
            {({ submitForm, swapTo, setValue, getValue }) => {
              return (
                <form className="update__form" onSubmit={submitForm}>
                  <div className="field">
                    <span className="field__label update__form__label">Nome Completo *</span>
                    <Text className="field__text" field="full_name" autoComplete="off" />
                  </div>
                  {this.state.updatePassword ? (
                    <React.Fragment>
                      <div className="field">
                        <span className="field__label register__form__label">Senha *</span>
                        <InputPassword placeholder="" field="password" />
                      </div>
                      <div className="field">
                        <span className="field__label register__form__label">Confirme sua senha *</span>
                        <InputPassword
                          onPaste={event => {
                            event.preventDefault()
                            return false
                          }}
                          placeholder=""
                          field="confirm_password"
                        />
                      </div>
                    </React.Fragment>
                  ) : null}
                  <div className="field">
                    <span className="field__label update__form__label">Telefone *</span>
                    <InputText
                      field="phone"
                      mask="(99) 9999-9999?"
                      formatChars={{
                        9: '[0-9]',
                        '?': '[0-9]',
                      }}
                      from={/\D/g}
                    />
                  </div>
                  <div className="address_fields">
                    <span className="field__divider">Endereço para faturamento da nota fiscal</span>
                    <label className="field">
                      <span className="field__label update__form__label">CEP *</span>
                      <div className="zipcodefield">
                        <InputText
                          handleBlur={e => {
                            this._getAddress(e, setValue)
                          }}
                          mask="99999-999"
                          field="address_zipcode"
                          className="field__text"
                        />

                        {this.state.invalid_address_zipcode ? (
                          <div className="FormError invalid-zip">Dados não encontrados para o cep informado</div>
                        ) : null}

                        <a
                          className="update__cep-link"
                          href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm"
                          target="_blank"
                        >
                          Não sabe o CEP?
                        </a>
                      </div>
                    </label>
                    <label className={`field ${!this.state.unique_zipcode ? 'disabled' : ''}`}>
                      <span className="field__label update__form__label">Logradouro
                        <br />
                        <span style={{ fontSize: "12px" }}> Ex: Rua, Avenida, etc.</span>
                      </span>
                      <Text
                        className="field__text"
                        field="address_street"
                        disabled={!this.state.unique_zipcode}
                        autoComplete="off"
                      />
                    </label>
                    <label className={`field ${!this.state.address_found ? 'disabled' : ''}`}>
                      <span className="field__label update__form__label">Número</span>
                      <InputText
                        className="field__text"
                        field="address_number"
                        maxLength="10"
                        handleChange={event => {
                          if (this.state.same_address) {
                            setValue('address_number_delivery', event.target.value, true)
                          }
                        }}
                      />
                    </label>
                    <div className={`field ${!this.state.address_found ? 'disabled' : ''}`}>
                      <span className="field__label update__form__label">Complemento</span>
                      <InputText
                        className="field__text"
                        field="address_complement"
                        maxLength="10"
                        handleChange={event => {
                          if (this.state.same_address) {
                            setValue('address_complement_delivery', event.target.value, true)
                          }
                        }}
                      />
                    </div>
                    <label className={`field ${!this.state.unique_zipcode ? 'disabled' : ''}`}>
                      <span className="field__label update__form__label">Bairro</span>
                      <Text
                        className="field__text"
                        field="address_neighborhood"
                        disabled={!this.state.unique_zipcode}
                        autoComplete="off"
                      />
                    </label>
                    <label className={`field ${this.state.disable_address_state ? 'disabled' : ''}`}>
                      <span className="field__label update__form__label">Estado</span>
                      <SelectState
                        className="field__text"
                        field="address_state"
                        disabled={this.state.disable_address_state}
                        autoComplete="off"
                      />
                    </label>
                    <label className="field disabled">
                      <span className="field__label update__form__label">Cidade</span>
                      <Text className="field__text" field="address_city" disabled autoComplete="off" />
                    </label>
                  </div>
                  <div className="field">
                    <span className="field__label update__form__label">CPF/CNPJ *</span>
                    <RadioGroup
                      field="type"
                      errorProps={this.hideDefaultError}
                      errorBefore={false}
                      className="field__radio"
                    >
                      <label className="field__radio__option">
                        <Radio
                          value="cpf"
                          onChange={() => {
                            this._toggleTypeField('cpf')
                            setValue('cnpj', '', true)
                            setValue('ie', null, true)
                          }}
                        />
                        <span className="field__radio__label">Pessoa física</span>
                      </label>
                      <label className="field__radio__option">
                        <Radio
                          value="cnpj"
                          onChange={() => {
                            this._toggleTypeField('cnpj')
                            setValue('cpf', '', true)
                          }}
                        />
                        <span className="field__radio__label">Pessoa jurídica</span>
                      </label>
                    </RadioGroup>
                    {getValue('type') === 'cpf' ? (
                      <div className="field--right update__field__cpf">
                        <InputText field="cpf" mask="999.999.999-99" from={/\D/g} />
                      </div>
                    ) : null}
                    {getValue('type') === 'cnpj' ? (
                      <div className="field--right update__field__cnpj">
                        <InputText field="cnpj" mask="99.999.999/9999-99" from={/\D/g} />
                      </div>
                    ) : null}
                  </div>
                  {getValue('type') === 'cnpj' ? (
                    <React.Fragment>
                      {!this.state.ie_free ? (
                        <div className="field">
                          <span className="field__label register__form__label">Inscrição Estadual *</span>
                          <InputText field="ie" mask="" from={/\D/g} />
                        </div>
                      ) : null}
                      <div className="field field_ie_free">
                        <label>
                          <InputCheckbox
                            field="ie_free"
                            className="field__checkbox"
                            onChange={() => this._toggleIE()}
                          />
                          <span className="field__checkbox__label">Isento</span>
                        </label>
                      </div>
                    </React.Fragment>
                  ) : null}

                  {this.state.school_is_b2c ? (
                    <div className="address_delivery_fields">
                      <span className="field__divider">Endereço de entrega</span>
                      <div className="warning">
                        <span>Atenção:</span> Estes dados não poderão ser alterados após a confirmação do pagamento.
                      </div>
                      <label className="same_address_label">
                        <InputCheckbox
                          onChange={event => this._toggleSameAddress(event, setValue, getValue)}
                          field="same_address_"
                          className="field__checkbox same_address__checkbox"
                          checked={this.state.same_address}
                        />
                        <span className="field__label_same_address_">
                          Os dados de entrega são os mesmos dados de faturamento.
                        </span>
                        <FormError field="same_address_" />
                      </label>
                      <label className={`field ${this.state.same_address ? 'disabled' : ''}`}>
                        <span className="field__label register__form__label">CEP *</span>
                        <div className="zipcodefield">
                          <InputText
                            handleBlur={e => {
                              if (!this.state.same_address) {
                                this._getDeliveryAddress(e, setValue)
                              }
                            }}
                            disabled={this.state.same_address}
                            mask="99999-999"
                            field="address_zipcode_delivery"
                            className="field__text"
                          />
                          {this.state.invalid_address_zipcode ? (
                            <div className="FormError invalid-zip">Dados não encontrados para o cep informado</div>
                          ) : null}
                          <a
                            className="register__cep-link"
                            href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm"
                            target="_blank"
                          >
                            Não sabe o CEP?
                          </a>
                        </div>
                      </label>
                      <label
                        className={`field ${
                          !this.state.delivery_unique_zipcode || this.state.same_address ? 'disabled' : ''
                        }`}
                      >
                        <span className="field__label register__form__label">Logradouro
                          <br />
                          <span style={{ fontSize: "12px" }}> Ex: Rua, Avenida, etc.</span>
                        </span>
                        <Text
                          className="field__text"
                          field="address_street_delivery"
                          disabled={!this.state.delivery_unique_zipcode || this.state.same_address}
                        />
                      </label>
                      <label
                        className={`field ${
                          !this.state.delivery_address_found || this.state.same_address ? 'disabled' : ''
                        }`}
                      >
                        <span className="field__label register__form__label">Número</span>
                        <Text
                          className="field__text"
                          field="address_number_delivery"
                          maxLength="10"
                          disabled={this.state.same_address}
                        />
                      </label>
                      <div
                        className={`field ${
                          !this.state.delivery_address_found || this.state.same_address ? 'disabled' : ''
                        }`}
                      >
                        <span className="field__label register__form__label">Complemento</span>
                        <Text
                          className="field__text"
                          field="address_complement_delivery"
                          maxLength="10"
                          disabled={this.state.same_address}
                        />
                      </div>
                      <div
                        className={`field ${
                          !this.state.delivery_unique_zipcode || this.state.same_address ? 'disabled' : ''
                        }`}
                      >
                        <span className="field__label register__form__label">Bairro</span>
                        <Text
                          className="field__text"
                          field="address_neighborhood_delivery"
                          disabled={!this.state.delivery_unique_zipcode || this.state.same_address}
                        />
                      </div>
                      <div className={`field ${this.state.disable_delivery_address_state ? 'disabled' : ''}`}>
                        <span className="field__label register__form__label">Estado</span>
                        <SelectState
                          className="field__text"
                          field="address_state_delivery"
                          disabled={this.state.disable_delivery_address_state}
                          autoComplete="off"
                        />
                      </div>
                      <div className="field disabled">
                        <span className="field__label register__form__label">Cidade</span>
                        <Text className="field__text" field="address_city_delivery" disabled />
                      </div>
                    </div>
                  ) : null}

                  {this.state.hasUpdateTermUse && (
                    <div className="field__terms_of_use_accepted_at update__terms">
                      <label>
                        <Checkbox
                          errorProps={this.hideDefaultError}
                          field="terms_of_use_accepted_at"
                          className="field__checkbox terms__checkbox"
                          checked={!getValue('terms_of_use_accepted_at')}
                        />
                        <span className="field__checkbox__label admin-form__terms__label">
                          Li e aceito os{' '}
                          <a href="/termos-de-uso.html" target="_blank">
                            <strong>Termos de Uso</strong>
                            <span className="register__required">*</span>
                          </a>
                        </span>
                        <FormError field="terms_of_use_accepted_at" />
                      </label>
                    </div>
                  )}

                  {this.state.hasUpdatePrivacyPolicy && (
                    <div className="field__privacy_policy_accepted_at update__terms">
                      <label>
                        <Checkbox
                          errorProps={this.hideDefaultError}
                          field="privacy_policy_accepted_at"
                          className="field__checkbox terms__checkbox"
                          checked={!getValue('privacy_policy_accepted_at')}
                        />
                        <span className="field__checkbox__label admin-form__terms__label">
                          Li e aceito as{' '}
                          <a href="/politica-de-privacidade.html" target="_blank">
                            <strong>Políticas de Privacidade</strong>
                            <span className="register__required">*</span>
                          </a>
                        </span>
                        <FormError field="privacy_policy_accepted_at" />
                      </label>
                    </div>
                  )}

                  {/*<div className='field__receive_surveys update__terms'>
                    <label>
                      <InputCheckbox
                        field='receive_surveys'
                        className='field__checkbox terms__checkbox'
                        checked={!getValue('receive_surveys')}
                      />
                      <span className='field__checkbox__label update__terms__label'>
                        Não desejo participar de pesquisas de satisfação de produtos e serviços do Grupo SM
                      </span>
                    </label>
                  </div>*/}

                  <div className="field__receive_offers update__terms">
                    <label>
                      <InputCheckbox
                        field="receive_offers"
                        className="field__checkbox terms__checkbox"
                        checked={!getValue('receive_offers') ? true : false}
                      />
                      <span className="field__checkbox__label update__terms__label">
                        Não desejo receber comunicações comerciais do Grupo SM
                      </span>
                    </label>
                  </div>

                  <div className="update__terms">
                    <button id="update-save-button" className="update__button button__normal" type="submit">
                      Salvar e continuar
                    </button>
                  </div>
                </form>
              )
            }}
          </Form>
          <Modal show={this.state.ieModalOpen}>
            <Modal.Body>
              <div className="modalHeader">
                <span className="icon-alert" />
                <h1>Aviso</h1>
              </div>

              <div className="modalContent">
                <p>
                  Caso você possua a <span className="yellow-text">Inscrição Estadual Ativa</span> e opte por não
                  preencher este campo, a sua nota fiscal <strong>NÃO</strong> será aprovada pelo <strong>SEFAZ</strong>
                </p>
              </div>

              <div className="modalFooter">
                <button
                  className="modal-close"
                  onClick={() => {
                    this.setState({
                      ieModalOpen: false,
                    })
                  }}
                >
                  Entendi
                </button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.showModal && (this.state.hasUpdatePrivacyPolicy || this.state.hasUpdateTermUse)}>
            <Modal.Body>
              <div className="modalHeader">
                <span className="icon-alert" />
                <h1>Prezado(a) cliente</h1>
              </div>

              <div className="modalContent">
                {this._getUpdatedPrivacyAndOrTerm()}
                <p>Clique nos links apresentados ao final do formulário de cadastro para conhecê-los.</p>
                <p>Para prosseguir com a compra, é necessário aceitá-los.</p>
              </div>
              <div className="modalFooter">
                <button
                  className="modal-close"
                  onClick={() => {
                    this.setState({
                      showModal: false,
                    })
                  }}
                >
                  Fechar
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )
    } else {
      return (
        <div className="update">
          <div className="update__header">
            <h3 className="update__title">Documento incorreto</h3>
            <h4 className="update__subtitle">
              Identificamos que o documento informado na compra com desconto para professor é diferente do utilizado no
              seu cadastro de usuário.
            </h4>
          </div>

          <div className="update__footer">
            <button className="button__normal update__cancel" onClick={this._handleCancel}>
              Cancelar compra
            </button>
            <button className="button__normal update__logout" onClick={this._handleBack}>
              Entrar com outro usuário
            </button>
          </div>
        </div>
      )
    }
  }

  _getUpdatedPrivacyAndOrTerm() {
    if (this.state.hasUpdatePrivacyPolicy && this.state.hasUpdateTermUse) {
      return <p>Para sua maior segurança, a Política de Privacidade e os Termos de Uso do SM Direto mudaram.</p>
    } else if (this.state.hasUpdateTermUse) {
      return <p>Para sua maior segurança, os Termos de Uso do SM Direto mudaram.</p>
    }
    return <p>Para sua maior segurança, a Política de Privacidade do SM Direto mudou.</p>
  }
}

const mapStateToProps = (state, props) => ({
  specialDiscount: state.specialDiscount,
  sessionReducer: state.sessionReducer,
  checkouts: state.checkout,
  calculated_freight: state.freight.calculated_freight,
})

const mapDispatchToProps = dispatch => ({
  destroy: () => dispatch(destroy()),
  clearCheckout: () => dispatch(clearCheckout()),
  clearSpecialDiscount: () => dispatch(clearSpecialDiscount()),
  destroyUserSession: () => dispatch(destroyUserSession()),
  getUserByEmail: email => dispatch(getUserByEmail(email)),
  requestUpdate: (user, path) => dispatch(requestUpdate(user, path)),
  requestDelete: user => dispatch(requestDelete(user)),
  requestEnable: user => dispatch(requestEnable(user)),
  getUserData: async user => getUserData(user),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  submitRegisterForm: user => dispatch(requestRegister(user)),
  openModal: component => dispatch(openModal(component)),
  closeModal: () => dispatch(closeModal()),
  getSchools: () => dispatch(getSchools()),
  getFreight: data => dispatch(getFreight(data)),
  resetFreight: () => dispatch(resetFreight()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserUpdateForm))

