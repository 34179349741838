import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader.js'
import { closeModal, openModal } from '@actions/modal.js'
import { connect } from 'react-redux'
import { ModalVouchersInactivation } from './Modal'
import {
  setVouchersInactivation,
  updateVouchersInactivation,
  resetVouchersInactivation,
  getVouchersInactivation,
} from '@actions/vouchersInactivation'

class VouchersInactivation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      disabled_save: true,
    }
  }

  componentWillUnmount() {
    this.props.resetVouchersInactivation()
  }

  componentDidMount() {
    this.props.getVouchersInactivation()
  }

  async _setVouchersInactivation(business_line_id) {
    let { business_lines } = this.props

    let index = business_lines.findIndex(business_line => business_line.id === business_line_id)

    business_lines[index].inactive = (await business_lines[index].inactive) === 1 ? 2 : 1

    this.setState({
      disabled_save: false,
    })
  }

  _openModal(title, message) {
    this.props.openModal(
      <ModalVouchersInactivation closeModal={this.props.closeModal} title={title} message={message} />,
    )
  }

  async _handleSaveVouchersInactivation() {
    this.props.openLoader()
    this.props.updateVouchersInactivation(this.props.business_lines).then(resp => {
      if (resp && resp.code === 200) {
        this._openModal('Sucesso', () => <p>Linhas de negócio alteradas com sucesso!</p>)
      } else {
        this._openModal('Erro', () => (
          <p>Houve algum erro ao alterar as linhas de negócio. Por favor tente novamente.</p>
        ))
      }
      this.props.closeLoader()
    })
  }

  render() {
    const { business_lines } = this.props
    return (
      <div className="vouchers_inactivation">
        <div className="admin-form__header">
          <h1 className="admin-form__title">Inativação automática de vouchers</h1>
        </div>
        <div className="vouchers_inactivation_content">
          <h2 className="vouchers_inactivation_subtitle">
            Itens que podem ser configurados para inativação automática antes do fim do contrato
          </h2>
          <p className="vouchers_inactivation_description">
            As linhas de negócio marcadas abaixo serão inativadas quando preenchido o campo “Inativação automática de
            linha de negócio” na tela da escola.{' '}
            <b>A data de inativação será configurada através do perfil da escola.</b>
          </p>
          <p className="vouchers_inactivation_description">
            As linhas de negócio não marcadas se mantém ativas ao longo do ano até o encerramento da campanha.{' '}
            <b>A inativação respeita a data final do contrato.</b>
          </p>
          <div className="vouchers_inactivation_form">
            {business_lines.map(business_line => (
              <div className="vouchers_inactivation_checkbox" key={business_line.id}>
                <input
                  className="field__checkbox"
                  id={`business_line_${business_line.id}`}
                  type="checkbox"
                  name={`business_line_${business_line.id}`}
                  checked={business_line.inactive === 1}
                  onClick={() => {
                    this._setVouchersInactivation(business_line.id)
                  }}
                />
                <label htmlFor={`business_line_${business_line.id}`}>{business_line.name}</label>
              </div>
            ))}
            <div className="vouchers_inactivation_footer">
              <button
                className="button schoolcard__save"
                disabled={this.state.disabled_save}
                onClick={() => {
                  this._handleSaveVouchersInactivation()
                }}
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    business_lines: state.vouchersInactivation.business_lines,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModal: component => dispatch(openModal(component)),
    closeModal: () => dispatch(closeModal()),
    openLoader: () => dispatch(openLoader()),
    closeLoader: () => dispatch(closeLoader()),
    setVouchersInactivation: value => dispatch(setVouchersInactivation(value)),
    updateVouchersInactivation: data => dispatch(updateVouchersInactivation(data)),
    resetVouchersInactivation: () => dispatch(resetVouchersInactivation()),
    getVouchersInactivation: () => dispatch(getVouchersInactivation()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VouchersInactivation)

