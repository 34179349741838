import React, { Component } from 'react'
import { clearError, fetchSchool, updateContractDate, updateDeliveryType } from '@actions/school'
import { closeLoader, openLoader } from '@actions/loader'
import { updatePackEducamos, voucherGenerate } from '@services/sm-api'

import SchoolDetail from '@components/SchoolDetail'
import _ from 'underscore'
import { connect } from 'react-redux'
import { fetchContracts } from '@actions/contracts'
import { getContracts } from '@actions/importContracts'
import { withRouter } from '../../utils/withRouter'

class School extends Component {
  constructor(props) {
    super(props)

    this.state = {
      school: null,
      contract: null,
      contracts: null,
      isLoading: true,
    }

    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    if (this.state.isLoading && !this.state.school && !this.state.contract) {
      this.props.openLoader()
      this.props
        .fetchSchool(Number(this.props.params.school_id), this.props.currentCampaign.campaign_id)
        .then(response => {
          this._isMounted &&
            this.setState({
              school: response[0].payload,
              contract: response[1].payload,
              contracts: response[1].payload.contracts,
            })
        })
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (
      (!this.state.isLoading && !_.isEqual(nextProps.school, this.state.school)) ||
      !_.isEqual(nextProps.contract, this.state.contract)
    ) {
      this._isMounted &&
        this.setState({
          school: nextProps.school,
          contract: nextProps.contract,
        })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <div className="school">
        <SchoolDetail
          school={this.state.school}
          contract={this.state.contract}
          active_contract={
            this.state.contracts
              ? this.state.contracts.find(contract => {
                  return contract.campaign_id === this.props.currentCampaign.campaign_id
                })
              : null
          }
          sync={this.props.getContracts}
          update={this.props.updateContractDate}
          updateDeliveryType={this.props.updateDeliveryType}
          updatePackEducamos={(pack_id, pack_educamos) => {
            this.props.openLoader()

            updatePackEducamos(pack_id, pack_educamos)
              .then(() => {
                this.props
                  .fetchSchool(Number(this.props.params.school_id), this.props.currentCampaign.campaign_id)
                  .then(response => {
                    this._isMounted &&
                      this.setState({
                        school: response[0].payload,
                        contract: response[1].payload,
                      })
                  })
              })
              .finally(() => this.props.closeLoader())
          }}
          error={this.props.error}
          onMount={() => {
            this.state.isLoading &&
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  this.props.closeLoader()
                },
              )
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  params: props.params,
  school: state.school,
  error: state.school.error,
  contract: _.first(state.contract.contracts),
  currentCampaign: state.report.currentCampaign,
})

const mapDispatchToProps = dispatch => ({
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  fetchSchool: (id, currentCampaign) =>
    Promise.all([dispatch(fetchSchool(id, currentCampaign)), dispatch(fetchContracts(id))]),
  updateContractDate: data => {
    dispatch(openLoader())
    return dispatch(updateContractDate(data))
      .then(() => {
        dispatch(fetchSchool(data.id))
          .then(() => Promise.all([dispatch(fetchSchool(data.id)).then(() => dispatch(closeLoader()))]))
          .then(voucherGenerate)
      })
      .catch(error => {
        dispatch(closeLoader())
      })
  },
  updateDeliveryType: data => {
    dispatch(openLoader())
    return dispatch(updateDeliveryType(data))
      .then(() => {
        dispatch(fetchSchool(data.id))
          .then(() => Promise.all([dispatch(fetchSchool(data.id)).then(() => dispatch(closeLoader()))]))
          .then(voucherGenerate)
      })
      .catch(error => {
        dispatch(closeLoader())
      })
  },
  getContracts: id => {
    dispatch(openLoader())
    dispatch(clearError())
    return dispatch(getContracts(id))
      .then(() => Promise.all([dispatch(fetchSchool(id)), dispatch(fetchContracts(id))]))
      .then(() => dispatch(closeLoader()))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(School))

