import { updateAutomaticInactivationAPI } from '@services/sm-api'

export const SET_AUTOMATIC_INACTIVATION = 'automaticInactivation/SET_AUTOMATIC_INACTIVATION'
export const FAILURE_AUTOMATIC_INACTIVATION = 'automaticInactivation/FAILURE_AUTOMATIC_INACTIVATION'
export const RESET_AUTOMATIC_INACTIVATION = 'automaticInactivation/RESET_AUTOMATIC_INACTIVATION'

export function setAutomaticInactivation(payload) {
  return {
    type: SET_AUTOMATIC_INACTIVATION,
    payload,
  }
}

export function failureAutomaticInactivation(payload) {
  return {
    type: FAILURE_AUTOMATIC_INACTIVATION,
    payload,
  }
}

export function resetAutomaticInactivation() {
  return {
    type: RESET_AUTOMATIC_INACTIVATION,
  }
}

export function updateAutomaticInactivation(data) {
  return dispatch =>
    updateAutomaticInactivationAPI(data)
      .then(response => {
        return response
      })
      .catch(error => {
        dispatch(failureAutomaticInactivation(error))
        return error
      })
}
