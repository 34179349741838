import React, { Component } from 'react'
import SelectProducts from '../../components/SelectProducts'
// import StudentCheckout from '../../components/StudentCheckout'

export default class SelectProductsContainer extends Component {
  render() {
    return (
      <div className="select-books">
        <div className="content">
          <div className="select-books__list">
            <SelectProducts />
          </div>
          <div className="select-books__checkout">{/* <StudentCheckout /> */}</div>
        </div>
      </div>
    )
  }
}
