import React from 'react'

export const DigitalSolutionGC = props => {
  return (
    <div className="digital_solution_description">
      <p>
        <b>Educamos - Ambiente Digital:</b> todos os anos desta escola ativarão a licença digital Educamos através do
        Educamos Ambiente Digital, <b>mediante cadastro prévio da escola.</b> O e-mail com a chave digital do Sequência
        Didática será enviado via e-mail com instruções de cadastro via Educamos Ambiente Digital. Caso a escola também
        utilize livros didáticos, a ativação do livro digital será através da chave da contracapa do livro via Educamos
        Ambiente Digital. Esta observação será enviada no e-mail com as licenças.
      </p>
    </div>
  )
}
