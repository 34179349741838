import React, { Component } from 'react'

export default class Tooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      isTimerRunning: false,
      lastClicked: 0,
    }

    this.hideElement = this.hideElement.bind(this)
  }

  UNSAFE_componentWillUpdate() {
    clearTimeout(this.hideElement)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.open !== state.open) {
      clearTimeout(this.hideElement)
      let lastClicked = this.state.lastClicked - new Date()
      if ((!props.show || this.state.isTimerRunning) && lastClicked <= 3000) return null
      if (!this.props.disableTimeout) setTimeout(this.hideElement, props.hidesAfter)
      return {
        show: props.currentRow,
        isTimerRunning: true,
      }
    }
    return null
  }

  hideElement() {
    this.setState({ show: false, isTimerRunning: false })
  }

  render() {
    return (
      <div className={this.props.className} style={{ display: this.state.show ? 'block' : 'none' }}>
        {this.props.children}
      </div>
    )
  }
}

