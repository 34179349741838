import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import PropTypes from 'prop-types'

export class SwapItem extends Component {
  render() {
    return <div>{this.props.children}</div>
  }
}

export class Swap extends Component {
  render() {
    const {
      active,
      component = 'div',
      transitionName = 'fade',
      enterTimeout = 300,
      leaveTimeout = 400,
      children,
      ...rest
    } = this.props

    return <div>{children.filter(child => child.props.name === active)}</div>
  }
}

Swap.propTypes = {
  active: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  enterTimeout: PropTypes.number,
  leaveTimeout: PropTypes.number,
  component: PropTypes.string,
}

SwapItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

