import React, { Component } from 'react'
import { connect } from 'react-redux'
import { requestRegister, getUserByEmail, requestLogin } from '@actions/user'
import LoginForm from '../LoginForm'
import RegisterForm from '../RegisterForm'
import { Swap, SwapItem } from '../Swap'

class Identification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      swap: '',
    }
  }

  _swapTo(swap) {
    this.setState({ swap })
  }

  _getButtons() {
    if (!this.state.swap) {
      return (
        <div>
          <h3 className="identification__title">Identificação</h3>
          <h4 className="identification__subtitle">Faça o login ou cadastre-se para continuar</h4>
          <button
            id="identification-already-registered-button"
            className="identification__button--login"
            onClick={() => {
              window.ga('send', {
                hitType: 'event',
                eventCategory: 'compra',
                eventAction: 'click',
                eventLabel: 'usuario_existente_4',
              })

              this._swapTo('login')
            }}
          >
            Já tenho cadastro
          </button>
          <button
            className="identification__button--register"
            onClick={() => {
              window.ga('send', {
                hitType: 'event',
                eventCategory: 'compra',
                eventAction: 'click',
                eventLabel: 'novo_usuario_4',
              })

              this._swapTo('register')
            }}
          >
            Não tenho cadastro
          </button>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    let { submitRegisterForm, getUserByEmail, user, requestLogin } = this.props

    return (
      <div className="identification">
        {this._getButtons()}

        {this.state.swap ? (
          <Swap active={this.state.swap}>
            <SwapItem name="login">
              <div className="identification__login">
                <LoginForm swapTo={swap => this._swapTo(swap)} title="Falta pouco. Faça login para continuar" />
              </div>
            </SwapItem>
            <SwapItem name="register">
              <div className="identification__login">
                <RegisterForm
                  swapTo={swap => this._swapTo(swap)}
                  submitRegisterForm={submitRegisterForm}
                  getUserByEmail={getUserByEmail}
                  requestLogin={requestLogin}
                  user={user}
                  title="Cadastro"
                  subtitle="Falta pouco. Faça seu cadastro para continuar."
                />
              </div>
            </SwapItem>
          </Swap>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  submitRegisterForm: (user, isDefault) => {
    return new Promise((resolve, reject) => {
      let result = dispatch(requestRegister(user, isDefault))
      resolve(result)
    })
  },
  getUserByEmail: email => dispatch(getUserByEmail(email)),
  requestLogin: (user, path) => dispatch(requestLogin(user, path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Identification)

