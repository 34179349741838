import { getDefaultData } from '@services/sm-api'

export const contract_status = [
  {
    name: 'Ativo',
    value: 'active',
  },
  {
    name: 'Aguardando confirmação',
    value: 'awaiting',
  },
]

export const status = [
  {
    name: 'Pagamento confirmado',
    value: 'PG',
  },
  {
    name: 'Pagamento recusado',
    value: 'NP',
  },
  {
    name: 'Pagamento divergente',
    value: 'DV',
  },
  {
    name: 'Aguardando pagamento',
    value: 'BAP',
  },
  {
    name: 'Pagamento após vencimento',
    value: 'PAV',
  },
  {
    name: 'Pedido cancelado',
    value: 'C',
  },
  {
    name: 'Cancelado Manualmente',
    value: 'CM',
  },
  {
    name: 'Pedido e Boleto cancelados',
    value: 'CBS',
  },
  {
    name: 'Pedido cancelado e estornado',
    value: 'CES',
  },
]

export const roles = [
  {
    name: 'Comprador',
    value: 'Comprador',
  },
  {
    name: 'Escola',
    value: 'Escola',
  },
  {
    name: 'SM TI',
    value: 'SM TI',
  },
  {
    name: 'Atendimento pré venda',
    value: 'Atendimento pré venda',
  },
  {
    name: 'Atendimento pós venda',
    value: 'Atendimento pós venda',
  },
  {
    name: 'Gerente regional',
    value: 'Gerente regional',
  },
  {
    name: 'Financeiro',
    value: 'Financeiro',
  },
]

let campaignsList = null

export const getCampaigns = async () => {
  if (!campaignsList) {
    campaignsList = getDefaultData('campaigns/list').then(response => {
      return response.reduce((acc, v) => {
        acc.push({
          value: v.id,
          ...v,
        })
        return acc
      }, [])
    })
  }

  return campaignsList
}

export const queue = [
  {
    name: 'DIGITAL_LICENSE',
    value: 'DIGITAL_LICENSE',
  },
  {
    name: 'ECAT_PRODUCT',
    value: 'ECAT_PRODUCT',
  },
  {
    name: 'SAP_ORDER',
    value: 'SAP_ORDER',
  },
  {
    name: 'WELCOME_MAIL',
    value: 'WELCOME_MAIL',
  },
]

export const logType = [
  {
    name: 'ecat_book',
    value: 'ecat_book',
  },
  {
    name: 'maxipago',
    value: 'maxipago',
  },
  {
    name: 'boleto_simples',
    value: 'boleto_simples',
  },
  {
    name: 'enqueue_action',
    value: 'enqueue_action',
  },
  {
    name: 'system',
    value: 'system',
  },
]

export const logLevelName = [
  {
    name: 'EMERGENCY',
    value: 'EMERGENCY',
  },
  {
    name: 'ALERT',
    value: 'ALERT',
  },
  {
    name: 'CRITICAL',
    value: 'CRITICAL',
  },
  {
    name: 'ERROR',
    value: 'ERROR',
  },
  {
    name: 'WARNING',
    value: 'WARNING',
  },
  {
    name: 'NOTICE',
    value: 'NOTICE',
  },
  {
    name: 'INFO',
    value: 'INFO',
  },
  {
    name: 'DEBUG',
    value: 'DEBUG',
  },
]

export const series = [
  {
    name: '0Maternal',
    value: '0Maternal',
  },
  {
    name: '1EI',
    value: '1EI',
  },
  {
    name: '1EM',
    value: '1EM',
  },
  {
    name: '1F1',
    value: '1F1',
  },
  {
    name: '2EI',
    value: '2EI',
  },
  {
    name: '2EM',
    value: '2EM',
  },
  {
    name: '2F1',
    value: '2F1',
  },
  {
    name: '3EI',
    value: '3EI',
  },
  {
    name: '3EM',
    value: '3EM',
  },
  {
    name: '3F1',
    value: '3F1',
  },
  {
    name: '4F1',
    value: '4F1',
  },
  {
    name: '5F1',
    value: '5F1',
  },
  {
    name: '6F2',
    value: '6F2',
  },
  {
    name: '7F2',
    value: '7F2',
  },
  {
    name: '8F2',
    value: '8F2',
  },
  {
    name: '9F2',
    value: '9F2',
  },
]

export default { status, getCampaigns }
