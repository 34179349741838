import React, { Component } from 'react'

import PermissionsChecker from '@shared/PermissionsChecker'
import Receipt from '@components/Receipt'
import RolesChecker from '@shared/RolesChecker'
import ConfirmDialog from '@shared/ConfirmDialog'

class CardSubOrder extends Component {
  state = {
    opened: false,
  }

  _printHandler() {
    let { sub_order, buyer_name, status, openModal, closeModal } = this.props

    openModal(
      <Receipt
        sub_order={sub_order}
        buyer_name={buyer_name}
        closeModal={closeModal}
        status={status}
        title="Comprovante de retirada"
      />,
    )
  }

  // _confirmCancel() {
  //   let { sub_order, buyer_name, status, openModal, closeModal, cancelSubOrder } = this.props

  //   openModal(
  //     <ConfirmDialog
  //       text="Tem certeza que deseja cancelar esse pedido?"
  //       ok={() => {
  //         cancelSubOrder(sub_order.id)
  //         closeModal()
  //       }}
  //       cancel={() => closeModal()}
  //     />,
  //   )
  // }
  


  _isServiceLine(sub_order) {
    const exp = /SER/gm
    if (sub_order.business_line.match(exp)) {
      return true
    }
    return false
  }

  _isDigitalLine(sub_order) {
    const exp = /DIG/gm
    if (sub_order.business_line.match(exp)) {
      return true
    }
    return false
  }

  _getOrderDeliveryDestination() {
    const { order, sub_order } = this.props
    if (sub_order.school_is_b2c) {
      let address = ''
      let address_ = ''
      if (order.buyer_address_zipcode_delivery) {
        address = `${order.buyer_address_street_delivery}, ${order.buyer_address_number_delivery}${
          order.buyer_secondary_address_number_delivery ? ', ' + order.buyer_secondary_address_number_delivery : ''
        } - ${order.buyer_address_neighborhood_delivery}`
        address_ = `${order.buyer_address_city_delivery} - ${order.buyer_address_state_delivery}, ${order.buyer_address_zipcode_delivery}`
      } else {
        address = `${order.buyer_address_street}, ${order.buyer_address_number}${
          order.buyer_secondary_address_number ? ', ' + order.buyer_secondary_address_number : ''
        } - ${order.buyer_address_neighborhood}`
        address_ = `${order.buyer_address_city} - ${order.buyer_address_state}, ${order.buyer_address_zipcode}`
      }
      return (
        <React.Fragment>
          {address}
          <br />
          {address_}
        </React.Fragment>
      )
    }
    return 'Os livros serão entregues na escola'
  }

  render() {
    const { sub_order, status, children, cancelSubOrder } = this.props
    let show = this.state.opened ? 'less' : 'more'
    return (
      <div className="card-student-detail__container">
        <div className="card-student-detail">
          <div className="card-student-detail__body">
            <div className="card-student-detail__sub_order_code">Pedido: {sub_order.sub_order_code}</div>
            {sub_order.special_discount_id && (
              <RolesChecker roles={['SM TI']}>
                <div className={`card-student-detail__special_discount`}>
                  ID DESCONTO PROFESSOR: {sub_order.special_discount_id}
                </div>
              </RolesChecker>
            )}
            <div className={`card-student-detail__status_description card-status--${status.type}`}>
              {status.description}
            </div>
          </div>
          <div className="card-student-detail__column-wrapper">
            <div className="card-student-detail__row">
              <div className="card-student-detail__column">
                <span className="card-student-detail__header">Tipo de produto</span>
                <span className="card-student-detail__value-book-line">{sub_order.business_line_name}</span>
              </div>
              <div className="card-student-detail__column">
                <span className="card-student-detail__header">Aluno</span>
                <span className="card-student-detail__value">{sub_order.student_name}</span>
              </div>
              {this._isDigitalLine(sub_order) ? (
                <div className="card-student-detail__column-delivery">
                  <span className="card-student-detail__header">Entrega</span>
                  <span className="card-student-detail__value">
                    Após confirmação de pagamento, a licença será enviada para o e-mail que realizou a compra.
                  </span>
                </div>
              ) : this._isServiceLine(sub_order) ? (
                <div className="card-student-detail__column-delivery"></div>
              ) : (
                <div className="card-student-detail__column-delivery">
                  <span className="card-student-detail__header">Entrega</span>
                  <span className="card-student-detail__value">{this._getOrderDeliveryDestination()}</span>
                </div>
              )}
              <div className="card-student-detail__column-options">
                {status.code === 'AP' || status.code === 'PG' || status.code === 'PAV' ? (
                  <div className="card-student-detail__column-printer" onClick={() => this._printHandler()}>
                    <span className="card-student-detail__column-printer-text">Imprimir comprovante</span>
                  </div>
                ) : null}
                {
                  // Botão só aparece quando ainda não cancelado
                  status.code !== 'C' && status.code !== 'CBS' && status.code !== 'CES' && (
                    <PermissionsChecker permissions={['cancel_order']}>
                      <div className="card-student-detail__sub_order_cancel">
                      <a href="#"  onClick={e => e.preventDefault() & cancelSubOrder(sub_order.id) }>
                          Cancelar pedido
                        </a>
                      </div>
                    </PermissionsChecker>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div className={`card-student-detail__switch-book-view ${show}`}>
          <button onClick={() => this.setState({ opened: !this.state.opened })}>
            <span className="label">{this.state.opened ? 'Ocultar detalhes' : 'Ver detalhes'}</span>
          </button>
        </div>
        {this.state.opened ? children : null}
      </div>
    )
  }
}

export default CardSubOrder
