import {
  RESET_AUTOMATIC_INACTIVATION,
  FAILURE_AUTOMATIC_INACTIVATION,
  SET_AUTOMATIC_INACTIVATION,
} from '@actions/automaticInactivation'
import { REHYDRATE } from 'redux-persist/constants'

let initialState = {
  business_lines_cut_dates: [],
  error: null,
}

export default (state = initialState, action, reset_state = initialState) => {
  switch (action.type) {
    case SET_AUTOMATIC_INACTIVATION:
      return {
        ...state,
        business_lines_cut_dates: action.payload,
      }

    case FAILURE_AUTOMATIC_INACTIVATION:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_AUTOMATIC_INACTIVATION:
      return {
        ...reset_state,
      }

    case REHYDRATE:
      return {
        ...state,
      }

    default:
      return state
  }
}
