const reportColumns = {
  orders: [
    {
      label: 'Nº Pedido',
      field: 'order_id',
    },
    {
      label: 'Nº Sub Pedido',
      field: 'sub_order_id',
    },
    {
      label: 'Nome da Escola',
      field: 'school_name',
    },
    {
      label: 'Ano',
      field: 'serie_id',
    },
    {
      label: 'Nome do Comprador',
      field: 'buyer_name',
    },
    {
      label: 'Data da compra',
      field: 'created',
    },
    {
      label: 'Nome do Aluno',
      field: 'student_name',
    },
    {
      label: 'Status',
      field: 'status',
    },
    {
      label: '',
      field: 'details',
      url: '/admin/pedidos',
      concat_field: 'order_id',
    },
  ],
  schools: [
    {
      label: 'ID',
      field: 'id',
      show: false,
    },
    {
      label: 'SAP',
      field: 'school_sap_code',
    },
    {
      label: 'UF',
      field: 'address_state',
    },
    {
      label: 'Nome da Escola',
      field: 'name',
    },
    {
      label: 'Quantidade de Vouchers',
      field: 'vouchers_quantity',
    },
    {
      label: 'Quantidade de alunos',
      field: 'students',
    },
    {
      label: 'Linhas de negócio',
      field: 'business_line',
    },
    {
      label: 'Desconto',
      field: 'discount',
    },
    {
      label: 'Faturamento',
      field: 'total_amount',
    },
    {
      label: 'Status',
      field: 'status',
      formatter: status => (status === 'active' ? 'Ativo' : 'Aguardando confirmação'),
    },
    {
      label: '',
      field: 'details',
      url: '/admin/escolas',
      concat_field: 'id',
    },
  ],
  vouchers: [
    {
      label: 'ID Voucher',
      field: 'id',
      className: 'center',
    },
    {
      label: 'Nº Voucher',
      field: 'name',
      className: 'center',
    },
    {
      label: 'Série',
      field: 'serie_id',
      className: 'center',
    },
    {
      label: 'Qtd. Produtos',
      field: 'total_products',
      className: 'center',
    },
    {
      label: 'Linhas de negócio',
      field: 'business_lines',
      className: 'business_lines',
    },
    {
      label: 'Nº pedidos',
      field: 'total_orders',
      className: 'center',
    },
    {
      label: 'Status',
      field: 'voucher_status',
      concat_field: 'name',
      className: 'center',
    },
    {
      label: 'Editar',
      field: 'voucher_edit',
      concat_field: 'name',
      className: 'center',
    },
  ],
  sap_order_log: [
    {
      label: 'Numero do pedido',
      field: 'formatted_order_id',
    },
    {
      label: 'Erro do SAP',
      field: 'sap_return_message',
    },
    {
      label: 'Valor',
      field: 'order_amount',
    },
    {
      label: 'Status',
      field: 'order_status',
    },
    {
      label: '',
      field: 'details',
      url: '/admin/pedidos',
      concat_field: 'order_id',
    },
    {
      label: '',
      field: 'sync',
      url: '/orders/sap/synch',
      concat_field: 'formatted_order_id',
    },
  ],
  users: [
    {
      label: 'ID',
      field: 'id',
    },
    {
      label: 'Nome',
      field: 'full_name',
    },
    {
      label: 'Tipo',
      field: 'role_name',
    },
    {
      label: 'Criado em',
      field: 'created',
    },
    {
      label: 'Status',
      field: 'status',
    },
    // {
    // 	label: 'Email',
    // 	field: 'email'
    // },
    // {
    // 	label: 'Nome da escola',
    // 	field: 'school_name'
    // },
    {
      label: '',
      field: 'details',
      url: '/admin/edit',
      concat_field: 'id',
    },
  ],
  enqueue: [
    {
      label: 'ID',
      field: 'id',
    },
    {
      label: 'Prioridade',
      field: 'priority',
    },
    {
      label: 'Fila',
      field: 'queue',
    },
    {
      label: 'Payload',
      field: 'properties',
    },
    {
      label: 'Criado em',
      field: 'publishedAt',
    },
    {
      label: 'Enviar em',
      field: 'redeliverAfter',
    },
  ],
  log: [
    {
      label: 'ID',
      field: 'id',
    },
    {
      label: 'Mensagem',
      field: 'message',
    },
    {
      label: 'Level name',
      field: 'levelName',
    },
    {
      label: 'Tipo',
      field: 'type',
    },
    {
      label: 'Contexto',
      field: 'context',
    },
    {
      label: 'Criado em',
      field: 'createdAt',
    },
  ],
}

export default reportColumns

