import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { downloadContract } from '@services/sm-api'
import FileDownloader from '@shared/FileDownloader'
import { fetchContracts } from '@actions/contracts'
import contrato_big from './img/contrato_big.png'
import exp from './img/export.png'
import { getDefaultData } from '@services/sm-api.js'

class Contract extends Component {
  constructor(props) {
    super(props)

    this.state = {
      contract_of_current_campaingn: [],
    }
  }

  componentDidMount() {
    getDefaultData('campaigns/list').then(campaigns => {
      let current_campaign = campaigns.find(campaign => campaign.active === 1)
      this.props.fetchContracts().then(({ payload: { code, contracts } }) => {
        if (code === 200) {
          this.setState({
            contract_of_current_campaingn: contracts.filter(contract => contract.campaign_id === current_campaign.id),
          })
        } else {
          this.setState({
            contract_of_current_campaingn: [],
          })
        }
      })
    })
  }

  render() {
    return (
      <div className="contract">
        <span className="contract__title">Contratos</span>
        <div className="contract__content">
          {this.state.contract_of_current_campaingn.map(contract => (
            <div className="contract-card" key={contract.id}>
              <span className="contract-card__date">
                {contract.contract_start ? moment(contract.contract_start, 'YYYY-MM-DD').format('DD/MM/YYYY') : null}
              </span>
              <img src={contrato_big} className="contract-card__icon" alt="Contrato" />
              <span className="contract-card__school">Contrato do colégio</span>
              {contract.id !== 0 && contract.contract_has_file ? (
                <FileDownloader
                  onClick={() => downloadContract(contract.id)}
                  filename={`Contrato - ${moment(contract.contract_start, 'YYYY-MM-DD').format('DD/MM/YYYY')}.pdf`}
                >
                  <div className="contract-card__footer">
                    <span className="contract-card__link">Fazer download</span>
                    <img className="contract-card__link-icon" src={exp} alt="Download" />
                  </div>
                </FileDownloader>
              ) : (
                <div className="contract-card__footer">
                  <span className="contract-card__link">Nenhum contrato disponível</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.sessionReducer.user,
  contract: state.contract,
})

const mergeProps = (state, props) => ({
  fetchContracts: () => props.fetchContracts(state.user.school_id),
})

export default connect(mapStateToProps, { fetchContracts }, mergeProps)(Contract)
