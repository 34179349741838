import { SUCCESS_ORDER, FAILURE_ORDER, SUCCEES_INSTALLMENTS } from '@actions/creditCard'
import { REHYDRATE } from 'redux-persist/constants'

const initialState = {
  status: 0,
  installments: {},
}
export default (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_ORDER:
      return {
        ...state,
        ...action.payload,
      }

    case FAILURE_ORDER:
      return { ...action.error }

    case SUCCEES_INSTALLMENTS:
      return { installments: { ...action.payload } }

    case REHYDRATE:
      return { ...initialState }

    default:
      return state
  }
}
