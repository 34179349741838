/**
 * Dependencies
 */
import { createSelector } from 'reselect'

/**
 * Constants
 */
export const CREATE_STUDENT = 'checkout/CREATE_STUDENT'
export const INSERT_STUDENT = 'checkout/INSERT_STUDENT'
export const INSERT_SPECIAL_DISCOUNT = 'checkout/INSERT_SPECIAL_DISCOUNT'
export const SELECT_PRODUCT = 'checkout/SELECT_PRODUCT'
export const RESET_USER_WARNING = 'checkout/RESET_USER_WARNING'
export const RESET_ALL_WARNINGS = 'checkout/RESET_ALL_WARNINGS'
export const REMOVE_STUDENT = 'checkout/REMOVE_STUDENT'
export const UPDATE_NAME = 'checkout/UPDATE_NAME'
export const CLEAR = 'checkout/CLEAR'
export const AGREE_DELIVERY = 'checkout/AGREE_DELIVERY'

/**
 * Actions creators
 */

export function insertStudent(student) {
  return {
    type: INSERT_STUDENT,
    student,
  }
}

export function removeStudent(index) {
  return {
    type: REMOVE_STUDENT,
    index,
  }
}

export function selectProduct(student, product, active) {
  return {
    type: SELECT_PRODUCT,
    student,
    product: {
      ...product,
      active,
    },
  }
}

export function resetWarning(voucher) {
  return {
    type: RESET_USER_WARNING,
    voucher,
  }
}

export function resetAllWarnings() {
  return {
    type: RESET_ALL_WARNINGS,
  }
}

export function updateName(name, voucher, index=null) {
  return {
    type: UPDATE_NAME,
    voucher,
    name,
    index,
  }
}

export function clear() {
  return {
    type: CLEAR,
  }
}

export function agreeDelivery() {
  return {
    type: AGREE_DELIVERY,
  }
}

/**
 * Selectors
 */

const getCheckout = state => state.checkout

export const getActiveStudent = createSelector(
  [state => state.activeVoucher, getCheckout],
  (activeVoucher, checkout) => {
    const student = checkout.find(
      student => student.voucher === activeVoucher.voucher && student.name === activeVoucher.name,
    )
    return student ? student : { products: [] }
  },
)

export const checkoutTotal = createSelector([getCheckout], checkouts =>
  checkouts.reduce((prev, checkout) => (prev += checkout.total), 0),
)

export const checkoutTotalDiscounted = createSelector([getCheckout], checkouts =>
  checkouts.reduce((prev, checkout) => (prev += checkout.total_discounted), 0),
)

export const filterActiveProducts = createSelector([getCheckout], checkouts =>
  checkouts.map(checkout => ({ ...checkout, products: checkout.products.filter(product => product.active) })),
)
