import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ChromePicker } from 'react-color'

import { changeTheme } from '@actions/theme'

import FileInput from '@shared/FileInput.js'
import upload from './images/Upload.png'
import { uploadLogo } from '@services/sm-api'
import { changeThemeColor } from '@services/sm-auth'

class Appearance extends Component {
  constructor(props) {
    super(props)

    this.state = {
      message: '',
      fadeOut: false,
      img: null,
      height: null,
      width: null,
      background: '',
      text: '',
      colorMessage: '',
    }

    this._handleUpload = this._handleUpload.bind(this)
    this._handleError = this._handleError.bind(this)
  }

  _handleUpload(file) {
    let data = { file_mime: file.type, file: file.dataUrl }
    this.setState({ img: file.dataUrl, message: null, fadeOut: false, height: 0, width: 0 })

    uploadLogo(this.props.schoolId, data)
      .then(res => {
        switch (res.code) {
          case 200:
            this.setState({ message: 'Arquivo carregado com sucesso!' })
            break

          case 304:
            this.setState({ message: 'Arquivo já existe.' })
            break

          default:
            break
        }

        setTimeout(() => this.setState({ fadeOut: true }), 1000)
      })
      .catch(error => {
        this.setState({ message: 'Erro ao carregar arquivo.', fadeOut: false }, () =>
          setTimeout(() => this.setState({ fadeOut: true }), 1000),
        )
      })
  }

  _handleError(error) {
    this.setState({ message: error.message, fadeOut: false }, () =>
      setTimeout(() => this.setState({ fadeOut: true, img: null }), 1000),
    )
  }

  _handleThemeColor(opt, color) {
    this.setState({
      [opt]: color,
    })

    this.props.changeTheme({ background: this.state.background, text: this.state.text })
  }

  _handleChangeColor() {
    let body = {
      background: this.state.background,
      text: this.state.text,
      schoolId: this.props.schoolId,
    }

    changeThemeColor(body).then(response => {
      if (response.status) {
        this.setState({
          colorMessage: 'Cores alteradas com sucesso',
        })
      } else {
        this.setState({
          colorMessage: 'Erro ao alterar cores',
        })
      }
    })
  }

  componentWillUnmount() {
    this.setState({
      colorMessage: '',
    })
  }

  UNSAFE_componentWillMount() {
    this.setState({
      background: this.props.theme.background || '',
      text: this.props.theme.text || '',
    })
  }

  render() {
    return (
      <div className="appearance">
        <h1>Aparência</h1>
        <div className="appearance__file">
          <div className="appearance__preview">
            <img src={this.state.img} alt="" />
          </div>
          <FileInput
            className="appearance__upload"
            restrict={['jpg', 'jpeg', 'png']}
            onChange={this._handleUpload}
            onError={this._handleError}
          >
            <img src={upload} alt="Upload"></img>
            <span className="appearance__text">Alterar logotipo</span>
            <span className="appearance__text" style={{ display: 'block' }}>
              Tipo de imagem suportada: .jpg, jpeg, .png
            </span>
          </FileInput>
          <div className={`message${this.state.fadeOut ? '--opacity' : ''}`}>{this.state.message}</div>
        </div>
        <div className="appearance__color">
          <div className="appearance__picker">
            <span className="appearance__label">Cor do cabeçalho</span>
            <ChromePicker
              color={this.state.background}
              onChange={event => this._handleThemeColor('background', event.hex)}
              className="appearance__input"
            />
          </div>
          <div className="appearance__picker">
            <span className="appearance__label">Cor do texto do cabeçalho</span>
            <ChromePicker
              color={this.state.text}
              onChange={event => this._handleThemeColor('text', event.hex)}
              className="appearance__input"
            />
          </div>
          <div className="appearance__footer">
            <button onClick={() => this._handleChangeColor()} className="button__small">
              Alterar
            </button>
            <span className="appearance__message">{this.state.colorMessage}</span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateProps = state => ({
  schoolId: state.sessionReducer.user.school_id,
  theme: state.theme,
})

const mapDispatchToProps = dispatch => ({
  changeTheme: colors => dispatch(changeTheme(colors)),
})

export default connect(mapStateProps, mapDispatchToProps)(Appearance)
