import React, { Component } from 'react'

import { BarChart, Bar, YAxis, XAxis, CartesianGrid, Legend } from 'recharts'
import { CustomLabel, renderLegend } from './utils'

export default class SummarySalesChart extends Component {
  render() {
    let { data } = this.props

    return (
      <div className="chartbox">
        <span className="chartbox__title">Potencial de vendas (Alunos)</span>
        <BarChart
          width={410}
          height={450}
          barGap={0}
          barCategoryGap={0}
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: -15 }}
        >
          <XAxis hide={true} />
          <YAxis type="number" />
          <CartesianGrid vertical={false} />
          <Legend
            dataKey="value"
            wrapperStyle={{ bottom: -10, left: 60 }}
            content={props => renderLegend(props, data)}
          />
          <Bar
            dataKey="value"
            barSize={55}
            label={<CustomLabel xOffset={56} yOffset={40} chartHeight={400} data={data} />}
            isAnimationActive={false}
          />
        </BarChart>
      </div>
    )
  }
}
