import React, { Component } from 'react'

export default class FileInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      file: {},
      error: { valid: true, message: '' },
    }

    this._handleFileInputChange = this._handleFileInputChange.bind(this)
    this._processFile = this._processFile.bind(this)
    this._fileReader = this._fileReader.bind(this)
  }

  _handleFileInputChange(e) {
    if (e.nativeEvent.target.files.length > 0) {
      this._processFile(e.nativeEvent.target.files[0])
    } else {
      this.setState({ file: {} })
      this.props.onError({ message: 'Nenhum arquivo selecionado' })
    }
  }

  _fileReader(file) {
    return new Promise((resolve, reject) => {
      let reader = new window.FileReader()
      reader.readAsDataURL(file)

      reader.onloadend = () => {
        resolve(reader.result)
      }
    })
  }

  _processFile(file) {
    let extensions = this.props.restrict.map(extension => `.${extension}`).join('|')

    let regex = new RegExp(`${extensions}$`, 'g')

    if (file.size > this.props.limit && file.name.match(regex)) {
      this.setState({ file: {} })
      this.props.onError({ message: 'Arquivo execedeu tamanho permitido (25MB)' })
    } else if (file.name.match(regex)) {
      this.setState(
        {
          file: file,
          error: { valid: true, message: file.name },
        },
        () => {
          this._fileReader(this.state.file).then(file =>
            this.props.onChange({
              name: this.state.file.name,
              lastModified: this.state.file.lastModified,
              lastModifiedDate: this.state.file.lastModifiedDate,
              size: this.state.file.size,
              type: this.state.file.type,
              webkitRelativePath: this.state.file.webkitRelativePath,
              dataUrl: file,
            }),
          )
        },
      )
    } else {
      this.setState({ file: {} })
      this.props.onError({ message: 'Extensão não permitida.' })
    }

    this.refs.input.value = ''
  }

  render() {
    const { className, children } = this.props

    return (
      <div className="file-input">
        <input
          autoComplete="off"
          type="file"
          name="file"
          id="file"
          key="input"
          style={{ display: 'none' }}
          ref="input"
          onChange={this._handleFileInputChange}
        />
        <label htmlFor="file" key="label" className={className}>
          {children}
        </label>
        <span>{this.state.file.name}</span>
      </div>
    )
  }
}
