import React, { Component } from 'react'

class CardStudent extends Component {
  state = {
    opened: false,
  }

  render() {
    const { student, school_name, voucher_name, children } = this.props
    let show = this.state.opened ? 'less' : 'more'
    return (
      <div className="card-student-detail__item">
        <div className="card-student-detail__item_header">
          <h4 className="card-student-detail__student_name">{student}</h4>
          <div className="card-student-detail__school_name">{school_name}</div>
          <div className="card-student-detail__voucher_name">voucher: {voucher_name}</div>
          <div className="card-student-detail__show">
            <a
              className={`card-student-detail__show__${show}`}
              href="#"
              onClick={e => e.preventDefault() & this.setState({ opened: !this.state.opened })}
            >
              {this.state.opened ? 'Mostrar menos' : 'Mostrar mais'}
            </a>
          </div>
        </div>
        {this.state.opened ? children : null}
      </div>
    )
  }
}

export default CardStudent
