import React from 'react'

import { PAYMENT_PIX } from '.'
import barCode from './images/001-bar-code@2x.png'
import card from './images/002-credit-card@2x.png'
import boleto_hibrido from './images/boleto-hibrido.png'
import pixLogoImage from './images/pix.png'

const PAYMENT_BILLET = 'boleto'
const PAYMENT_CARD = 'card'

const Panel = ({
  active,
  children,
  setActive,
  hasBillet,
  hasCard,
  thisParent,
  hybridBilletActivation,
  isPixEnabled,
}) => {
  return (
    <div className="payment">
      <h3 className="payment__title">Pagamento</h3>
      <h4 className="payment__subtitle">Escolha a forma de pagamento</h4>
      {hasBillet === 1 && (
        <button
          className={`payment__button${active === PAYMENT_BILLET ? '--active' : ''} test-buttonBillet`}
          onClick={() => {
            setActive(PAYMENT_BILLET)
            window.ga('send', {
              hitType: 'event',
              eventCategory: 'compra',
              eventAction: 'click',
              eventLabel: 'boleto_5',
            })

            thisParent.setState({ acceptDeliveryTerms: false })
          }}
        >
          <span className="payment__button-text">{hybridBilletActivation == 1 ? 'Boleto/PIX' : 'Boleto'}</span>
          <img
            className="payment__button-icon"
            src={hybridBilletActivation == 1 ? boleto_hibrido : barCode}
            alt={hybridBilletActivation == 1 ? 'Boleto/PIX' : 'Boleto'}
          />
        </button>
      )}

      {hasCard === 1 && (
        <button
          id="payment-credid-card-button"
          className={`payment__button${active === PAYMENT_CARD ? '--active' : ''} test-buttonCard`}
          onClick={() => {
            setActive(PAYMENT_CARD)
            thisParent.setState({ acceptDeliveryTerms: false })
          }}
        >
          <span className="payment__button-text">Cartão de crédito</span>
          <img className="payment__button-icon" src={card} alt="Cartão de crédito" />
        </button>
      )}

      {isPixEnabled && (
        <button
          className={`payment__button${active === PAYMENT_PIX ? '--active' : ''}`}
          onClick={() => {
            setActive(PAYMENT_PIX)
          }}
        >
          <span className="payment__button-text">PIX</span>
          <img className="payment__button-icon" src={pixLogoImage} alt="PIX" />
        </button>
      )}

      {children}
    </div>
  )
}

export default Panel

