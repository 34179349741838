import { Checkbox, Form, Text } from 'react-form'
import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader.js'
import { closeModal, openModal } from '@actions/modal.js'
import { createNotification, getNotificationById, updateNotification, uploadImage } from '@services/sm-api'

import ConfirmDialog from '@shared/ConfirmDialog'
import { Editor } from '@tinymce/tinymce-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from '../../utils/withRouter'

class NotificationForm extends Component {
  constructor(props) {
    super(props)

    this.hideDefaultError = { style: { display: 'none' } }

    this.state = {
      message: '',
    }

    this.submitMethods = {
      CREATE: createNotification,
      UPDATE: updateNotification,
    }
  }

  _preProcess = notificationId => {
    return getNotificationById(notificationId)
      .then(({ notification: { title, message, block_checkout, is_active } }) => {
        this.setState(
          {
            message,
          },
          () => {
            this.refs.Form.setAllValues({
              title,
              message,
              block_checkout,
              is_active,
            })
          },
        )
      })
      .catch(({ response }) => {
        let errorMessage = 'Erro ao exibir a notificação. Tente novamente mais tarde'

        if (response.data && response.status === 404) {
          errorMessage = 'Notificação não encontrado.'
        }

        this.props.openModal(
          <ConfirmDialog
            text={errorMessage}
            okLabel="Confirmar"
            ok={() => {
              this.props.closeModal()
              this.props.navigate('/admin/notifications')
            }}
          />,
        )
      })
  }

  _isEmpty = field => (field ? null : 'Este campo é obrigatório')

  /**
   * All validations rules
   **/
  _validateForm = values => {
    const { title, message } = values

    let errors = {
      title: this._isEmpty(title),
      message: this._isEmpty(this.state.message),
    }

    return errors
  }

  _handleEdit(values) {
    const { editMode, openModal, navigate, params } = this.props

    let payload = {
      title: values.title,
      message: this.state.message,
      block_checkout: values.block_checkout || 0,
      is_active: values.is_active || 0,
    }

    if (editMode === 'UPDATE') {
      payload.id = params.id
    }

    this.submitMethods[editMode](payload)
      .then(() => {
        openModal(
          <ConfirmDialog
            text="Notificação salvo com sucesso."
            okLabel="OK"
            ok={() => {
              this.props.closeModal()
              navigate('/admin/notifications')
            }}
          />,
        )
      })
      .catch(error => {
        let errorMessage = 'Problemas ao salvar a notificação. Tente novamente mais tarde.'

        openModal(
          <ConfirmDialog
            text={errorMessage}
            okLabel="OK"
            ok={() => {
              this.props.closeModal()
            }}
          />,
        )
      })
  }

  componentDidMount() {
    const { openLoader, closeLoader, params } = this.props
    openLoader()
    if (params.id) this._preProcess(params.id).finally(closeLoader)
    else closeLoader()
  }

  render() {
    const { title, params } = this.props

    return (
      <div className="notification-form">
        <Form
          ref="Form"
          validate={this._validateForm}
          onSubmit={values => {
            this._handleEdit(values)
          }}
        >
          {({ submitForm, swapTo, setValue, getValue, values }) => {
            return (
              <form className="admin-form__form" onSubmit={submitForm}>
                <div className="admin-form__header">
                  <h1 className="admin-form__title">{title}</h1>
                </div>
                <div className="field">
                  <span className="field__label admin-form__form__label">Título</span>
                  <Text className="field__text" field="title" autoComplete="off" />
                </div>
                <div className="field">
                  <span className="field__label admin-form__form__label">Mensagem</span>
                  <Editor
                    apiKey='pug5llflmige8fv8tml2wg7t9053bgekkvurt64wxfgdg3sl'
                    value={this.state.message}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen textcolor blocks fontfamily fontsize',
                        'insertdatetime media table paste code help wordcount',
                      ],
                      toolbar:
                        'undo redo | bold italic underline | forecolor backcolor | fontselect fontsizeselect | link image | removeformat | code | help',
                      image_title: true,
                      language: 'pt_BR',
                      automatic_uploads: true,
                      file_picker_types: 'image',
                      images_upload_handler: function (blobInfo, success, failure) {
                        const { blob, filename } = blobInfo

                        const formData = new FormData()
                        formData.append('file', blob(), filename())

                        uploadImage(params.id, formData)
                          .then(response => {
                            const parserUrlImg = new URL(
                              response.urlImg,
                              process.env.REACT_APP_APPSETTING_SM_PHP_API_ENDPOINT,
                            )
                            success(parserUrlImg.href)
                          })
                          .catch(error => {
                            const { status, code } = error.response.data
                            failure(`Erro ao fazer upload: ${status} - ${code}`)
                          })
                      },
                    }}
                    onEditorChange={message => {
                      this.setState({ message })
                    }}
                  />
                </div>
                <div className="field field__block_checkout">
                  <label>
                    <Checkbox field="block_checkout" className="field__checkbox" />
                    <span className="field__checkbox__label">Bloquear checkout?</span>
                  </label>
                </div>
                <div className="field field__is_active">
                  <label>
                    <Checkbox field="is_active" className="field__checkbox" />
                    <span className="field__checkbox__label">Ativo?</span>
                  </label>
                </div>

                <div className="field">
                  <hr className="admin-form__division" />
                </div>
                <div className="field__submit">
                  <button className="admin-form__button button__normal" type="submit">
                    Salvar
                  </button>
                </div>
              </form>
            )
          }}
        </Form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sessionReducer: state.sessionReducer,
  user: state.user,
})

const mapDispatchToProps = { openLoader, closeLoader, openModal, closeModal }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationForm))

