import { closeLoader, openLoader } from '@actions/loader.js'
import { fetchSyncData, getDefaultData } from '@services/sm-api.js'

export const REPORT_LIST = 'report/list'
export const INFINITE_REPORT_LIST = 'report/infinite'
export const SUCCEED_SYNC = 'report/sync'
export const SUCCEED_SYNC_UPDATE = 'report/sync_update'
export const SET_CURRENT_CAMPAIGN = 'report/set_current_campaign'

export function list(payload, listType, url) {
  return {
    type: REPORT_LIST,
    payload,
    listType,
    url,
  }
}

export function reportInfinite(payload, list) {
  return {
    type: INFINITE_REPORT_LIST,
    payload,
    list,
  }
}

export function fetchReports(url, params, listType) {
  return dispatch => {
    return getDefaultData(url, params).then(response => {
      if (response) {
        dispatch(list(response, listType, url))
      }
    })
  }
}

export function getNextReports(url, params, listType) {
  return (dispatch, getState) => {
    const state = getState()
    params.page = +params.page + 1
    let loadMore = false
    if (state.report.list.links && state.report.list.links.self) {
      if (state.report.list.links.last) {
        loadMore = state.report.list.links.self !== state.report.list.links.last
      }
    } else {
      loadMore = params.page <= state.report.list.total_pages
    }
    if (loadMore) {
      return getDefaultData(url, params)
        .then(response => {
          let newList = []
          let list = state.report.list[listType].concat(response[listType])
          newList[listType] = list

          return dispatch(reportInfinite(response, newList))
        })
        .catch(error => console.log(error))
    }
  }
}

export function succeedSync(index, list, listType) {
  return {
    type: SUCCEED_SYNC,
    index,
    list,
    listType,
  }
}

export function succeedSyncUpdate(index, list, listType) {
  return {
    type: SUCCEED_SYNC_UPDATE,
    index,
    list,
    listType,
  }
}

export function setCurrentCampaign(payload) {
  return {
    type: SET_CURRENT_CAMPAIGN,
    payload,
  }
}

export function syncData(endpoint, listType, id) {
  return (dispatch, getState) => {
    dispatch(openLoader())
    return fetchSyncData(endpoint).then(response => {
      const state = getState()
      if (response[0].sap.Zreturn !== 0) {
        getDefaultData(state.report.url, state.report.page)
          .then(response => {
            state.report.list[listType] = state.report.list[listType].map((value, index) => {
              if (value.order_id === id) {
                value = response[listType][index]
              }
              return value
            })

            dispatch(list(state.report.list, listType, state.report.url))
          })
          .catch(error => console.log(error))
      }
      dispatch(closeLoader())
      return response
    })
  }
}
