const reportFilters = {
  orders: [
    {
      name: 'date',
      roles: [],
    },
    {
      name: 'status',
      roles: [],
    },
    {
      name: 'campaign',
      roles: [],
    },
    {
      name: 'order_id',
      roles: [],
    },
    {
      name: 'sub_order_id',
      roles: [],
    },
    {
      name: 'buyer_name',
      roles: [],
    },
    {
      name: 'buyer_cpf',
      roles: [],
    },
    {
      name: 'buyer_cnpj',
      roles: [],
    },
    {
      name: 'student_name',
      roles: [],
    },
    {
      name: 'school_name',
      roles: [],
    },
    {
      name: 'serie_id',
      roles: [],
    },
  ],
  vouchers: [
    {
      name: 'campaign',
      roles: [],
    },
  ],
  schools: [
    {
      name: 'school_id',
      roles: [],
    },
    {
      name: 'school_name',
      roles: [],
    },
    {
      name: 'school_sap_code',
      roles: [],
    },
    {
      name: 'contract_status',
      roles: [],
    },
    {
      name: 'campaign',
      roles: [],
    },
    {
      name: 'address_state',
      roles: [],
    },
  ],
  users: [
    {
      name: 'user_search',
      roles: [],
    },
    {
      name: 'role',
      roles: [],
    },
  ],
  enqueue: [
    {
      name: 'queue',
      roles: [],
    },
    {
      name: 'properties',
      roles: [],
    },
  ],
  log: [
    {
      name: 'message',
      roles: [],
    },
    {
      name: 'contexto',
      roles: [],
    },
    {
      name: 'type',
      roles: [],
    },
    {
      name: 'levelName',
      roles: [],
    },
  ],
}

export default reportFilters

