export const userPermissions = [
  {
    name: 'access_financial_dashboard',
    label: 'Dashboard',
    roles: ['Escola', 'SM TI'],
  },
  {
    name: 'access_order_report',
    label: 'Relatórios de Pedidos',
    roles: ['Escola', 'SM TI'],
  },
  {
    name: 'access_order',
    label: 'Acessar Pedidos',
    roles: ['SM TI'],
  },
  {
    name: 'cancel_order',
    label: 'Cancelar pedidos',
    roles: ['SM TI'],
  },
  {
    name: 'cancel_order_billet',
    label: 'Cancelar boletos',
    roles: ['SM TI'],
  },
  {
    name: 'access_voucher_report',
    label: 'Relatório de Vouchers',
    roles: ['Escola', 'SM TI'],
  },
  {
    name: 'create_vouchers',
    label: 'Criar vouchers',
    roles: ['SM TI'],
  },
  {
    name: 'edit_vouchers',
    label: 'Editar vouchers',
    roles: ['SM TI'],
  },
  {
    name: 'manage_voucher_status',
    label: 'Gerenciar status de vouchers',
    roles: ['SM TI'],
  },

  {
    name: 'access_product_report',
    label: 'Relatório de Produtos',
    roles: ['SM TI'],
  },
  {
    name: 'manage_voucher_products_status',
    label: 'Gerenciar status de produtos de vouchers',
    roles: ['SM TI'],
  },
  {
    name: 'create_voucher_products',
    label: 'Adicionar produtos aos vouchers',
    roles: ['SM TI'],
  },
  {
    name: 'manage_product_discount',
    label: 'Alterar descontos por produtos',
    roles: ['SM TI'],
  },

  {
    name: 'access_school_report',
    label: 'Relatório de Escolas',
    roles: ['SM TI'],
  },
  {
    name: 'manage_school_expected_start',
    label: 'Gerenciar previsão de início de escolas',
    roles: ['SM TI'],
  },
  {
    name: 'manage_school_freight',
    label: 'Gerenciar frete de escolas',
    roles: ['SM TI'],
  },
  {
    name: 'manage_school_discounts',
    label: 'Gerenciar descontos de escolas',
    roles: ['SM TI'],
  },
  {
    name: 'manage_contract_dates',
    label: 'Gerenciar datas de contratos',
    roles: ['SM TI'],
  },
  {
    name: 'manage_contract_packs',
    label: 'Gerenciar Packs de Contratos',
    roles: ['SM TI'],
  },
  {
    name: 'access_user_report',
    label: 'Relatório de Usuários',
    roles: ['Escola', 'SM TI'],
  },
  {
    name: 'access_users',
    label: 'Administração de usuários',
    roles: ['Escola', 'SM TI'],
  },
  {
    name: 'create_users',
    label: 'Criar usuários',
    roles: ['SM TI'],
  },
  {
    name: 'access_user_details',
    label: 'Acessar detalhes de usuários',
    roles: ['SM TI'],
  },
  {
    name: 'edit_users',
    label: 'Editar usuários',
    roles: ['SM TI'],
  },

  {
    name: 'access_school_payment_options',
    label: 'Acessar meios de pagamento de escolas',
    roles: ['SM TI'],
  },
  {
    name: 'manage_school_payment_options',
    label: 'Gerenciar meios de pagamento de escolas',
    roles: ['SM TI'],
  },

  {
    name: 'access_system',
    label: 'Acessar área de Sistema',
    roles: ['SM TI'],
  },
  {
    name: 'access_system_queues',
    label: 'Acessar filas de sistema',
    roles: ['SM TI'],
  },
  {
    name: 'access_system_logs',
    label: 'Acessar logs de sistema',
    roles: ['SM TI'],
  },
  {
    name: 'manage_system_variables',
    label: 'Gerenciar Variáveis de Sistema',
    roles: ['SM TI'],
  },
  {
    name: 'open_case',
    label: 'Atendimento',
    roles: ['SM TI'],
  },
  {
    name: 'access_contract',
    label: 'Contrato',
    roles: ['Escola', 'SM TI'],
  },
  {
    name: 'theme_aspect_change',
    label: 'Aparência',
    roles: ['Escola', 'SM TI'],
  },
  {
    name: 'access_log_error',
    label: 'Acessar log de erros',
    roles: ['SM TI'],
  },
  {
    name: 'sync_log_error',
    label: 'Sincronizar log de erros',
    roles: ['SM TI'],
  },

  {
    name: 'access_special_discounts',
    label: 'Visualizar descontos de professor',
    roles: ['SM TI'],
  },
  {
    name: 'manage_special_discounts',
    label: 'Gerenciar descontos de professor',
    roles: ['SM TI'],
  },

  {
    name: 'access_notifications',
    label: 'Visualizar notificações',
    roles: ['SM TI'],
  },
  {
    name: 'manage_notifications',
    label: 'Gerenciar notificações',
    roles: ['SM TI'],
  },
  {
    name: 'manage_vouchers_inactivation',
    label: 'Gerenciar inativação de vouchers',
    roles: ['SM TI'],
  },
]

