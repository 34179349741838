import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'

import InlineLoader from '@components/InlineLoader'

import './index.css'

const Message = ({ text, onClose, onRetry }) => (
  <React.Fragment>
    <div className="upload-file-modal__message-container">
      <p>{text}</p>
      <a className="upload-file-modal__anchor-button" onClick={onRetry}>
        Carregar outro arquivo
      </a>
    </div>
    <div className="modalFooter">
      <button className="modal-close upload-file-modal__submit" onClick={onClose}>
        Fechar
      </button>
    </div>
  </React.Fragment>
)

class UploadFileModal extends Component {
  DEFAULT_ERROR_MESSAGE = 'Ocorreu um problema ao carregar este arquivo. Tente novamente mais tarde.'

  state = {
    file: null,
    filename: null,
    errorMessage: this.DEFAULT_ERROR_MESSAGE,
    currentUIState: 'SELECTING-FILE',
  }

  _onClick = () => {
    this.refs.inputFile.click()
  }

  _onChange = event => {
    const file = event.target.files[0]
    this.setState({ file })
  }

  _onSuccess = () => {
    this.setState({ currentUIState: 'UPLOAD-SUCCESS' })
  }

  _onError = customMessage => {
    this.setState({ currentUIState: 'UPLOAD-ERROR', errorMessage: customMessage })
  }

  _setInitialState = () =>
    this.setState({ currentUIState: 'SELECTING-FILE', file: null, errorMessage: this.DEFAULT_ERROR_MESSAGE })

  _onClose = () => {
    this.props.onClose()
    setTimeout(() => this._setInitialState(), 400)
  }

  _setUploadingState = () => {
    this.setState({ currentUIState: 'UPLOADING' })
  }

  _onUpload = () => {
    this.props.onUpload(this.state.file, this._onSuccess, this._onError, this._setUploadingState, this._setInitialState)
  }

  render() {
    const { file, currentUIState, errorMessage } = this.state
    const { title, subTitle: SubTitle, description: Description, accept, show } = this.props

    return (
      <Modal show={show} className="upload-file-modal">
        <Modal.Header>
          {currentUIState !== 'UPLOADING' && (
            <button type="button" className="close" onClick={this._onClose}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
          )}
          <Modal.Title className="upload-file-modal__title">{title}</Modal.Title>
          <SubTitle />
        </Modal.Header>
        <Modal.Body>
          <div className="upload-file-modal__content">
            {currentUIState === 'UPLOADING' && (
              <div className="upload-file-modal__loader">
                <p>
                  Carregando o arquivo:<span className="upload-file-modal__filename">{file.name}</span>
                </p>
                <p>Por favor, aguarde!</p>
                <InlineLoader show />
              </div>
            )}
            {currentUIState === 'SELECTING-FILE' && (
              <React.Fragment>
                <Description />
                <div className="upload-file-modal__button-container">
                  <button className="button__upload upload-file-modal__button" onClick={this._onClick}>
                    Escolha um arquivo
                  </button>
                  <input
                    ref="inputFile"
                    className="upload-file-modal__input-file"
                    type="file"
                    id="avatar"
                    name="file"
                    onChange={this._onChange}
                    autoComplete="off"
                    accept={accept}
                  ></input>
                  <span className="upload-file-modal__filename">
                    {file ? file.name : 'Nenhum arquivo selecionado.'}
                  </span>
                </div>
                <div className="modalFooter">
                  <button className="modal-close upload-file-modal__submit" disabled={!file} onClick={this._onUpload}>
                    Carregar
                  </button>
                </div>
              </React.Fragment>
            )}
            {currentUIState === 'UPLOAD-SUCCESS' && (
              <Message text="Arquivo carregado com sucesso!" onClose={this._onClose} onRetry={this._setInitialState} />
            )}
            {currentUIState === 'UPLOAD-ERROR' && (
              <Message text={errorMessage} onClose={this._onClose} onRetry={this._setInitialState} />
            )}
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default UploadFileModal
