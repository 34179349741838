import React from 'react'
import printer from './images/imprimir.png'

const CardRequest = ({ date, orderNumber, price, detailsAction, status, billetUrl, cancelOrder, paymentType }) => {
  return (
    <div className="card-request">
      <div className="card-request__helper">
        <div className="card-request__order-container">
          <div className="card-request__order-header">
            <span className="card-request__text">Pedido realizado em {date}:</span>
            <span className="card-request__red-text">Nº {orderNumber}</span>
          </div>
          <div className="card-request__price-container">
            <span className="card-request__text">Valor total:</span>
            <span className="card-request__red-text">{price}</span>
          </div>
          <div className="card-request__buttons-container">
            <button className="button" onClick={detailsAction}>
              ver detalhes
            </button>
            {billetUrl && status.props.description === 'Aguardando pagamento' ? (
              <button className="button bg-darkgray" onClick={() => window.open(billetUrl)}>
                2ª via de boleto
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="card-request__status-container">{status}</div>
      <div className="card-request__footer">
        <img className="card-request__printer-icon" src={printer} alt="" />
        <span className="card-request__printer-text">Imprimir comprovante</span>
      </div>
    </div>
  )
}

export default CardRequest
