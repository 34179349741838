import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader'

import Tooltip from '@shared/Tooltip'
import _ from 'lodash'
import { checkSpecialDiscount } from '@actions/specialDiscount'
import { connect } from 'react-redux'
import { fetchSchoolPaymentOptions } from '@actions/school'
import { insertStudent } from '@actions/checkout'
import { updateActiveVoucher } from '@actions/activeVoucher.js'
import { withRouter } from '@utils/withRouter'

const GLOBAL_ERROR_MESSAGE = 1
const SPECIAL_DISCOUNT_INVALID_DOCUMENT = 2

class SpecialDiscount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeBus: '',
      show: false,
      activeSlider: '',
      hideInput: false,
      showTooltip: false,
      tooltipErrorMessage: '',
      emptyVoucher: true,
      nextSlideErrorMessage: null,
      alertNameStudent: false,
    }
  }

  _nextSlide() {
    this.setState({
      activeSlider: 'active',
    })
  }

  _clearSpecialDiscountInfo() {
    window.localStorage.removeItem('reduxPersist:checkout')
    window.localStorage.removeItem('reduxPersist:preVoucher')
    window.localStorage.removeItem('reduxPersist:specialDiscount')
  }

  _handleNext() {
    this.setState({ showTooltip: false })
    this._clearSpecialDiscountInfo()
    this.props.checkSpecialDiscount(this.refs.document.value).then(() => {
      const { error } = this.props

      switch (error.code) {
        case -1:
          break
        case 0:
        case 1:
        case 3:
        case 5:
          this.props.closeLoader()
          this.setState({
            nextSlideErrorMessage: GLOBAL_ERROR_MESSAGE,
            hideInput: true,
          })
          this._nextSlide()
          break

        case SPECIAL_DISCOUNT_INVALID_DOCUMENT:
          this.props.closeLoader()
          this.setState({
            nextSlideErrorMessage: SPECIAL_DISCOUNT_INVALID_DOCUMENT,
            hideInput: true,
          })
          this._nextSlide()
          break

        default:
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'compra',
            eventAction: 'click',
            eventLabel: 'inseriu_special_discount_1',
          })

          this._nextSlide()
          setTimeout(() => this.refs.special_discount.focus(), 500)
          break
      }
    })
  }

  _handleBuyButton(event) {
    let valid = true

    let errorMessage = ''

    let student_name = this.refs.name.value

    if (_.isEmpty(student_name)) {
      valid = false
      errorMessage = 'Por favor, escolha pelo menos um aluno'
    }

    let discount = null

    for (let available_discount of this.props.specialDiscount.special_discounts) {
      if (available_discount.student_name.toLowerCase().trim() === student_name.toLowerCase().trim()) {
        discount = available_discount
        break
      }
    }

    if (!discount) {
      this.setState({
        alertNameStudent: true
      })
      valid = false
      errorMessage = 'Não foi encontrado nenhum desconto para este nome de aluno'
    }

    if (!valid) {
      this.setState({
        showTooltip: true,
        tooltipErrorMessage: errorMessage,
      })
    } else {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'compra',
        eventAction: 'click',
        eventLabel: 'inseriu_nome_aluno_2',
      })

      this.props.getSchoolPaymentOptions(discount.school_sap_code)
      this.props.insertStudent(discount)
      this.props.navigate('/steps/selecionar-livros')
    }
  }

  render() {
    const { specialDiscount, error } = this.props

    return (
      <div className="voucher">
        <div className="content">
          <div className="special_discount__slider">
            <div className={`special_discount__slider__content ${this.state.activeSlider}`}>
              <div className="special_discount__insert--document">
                <h1 className="special_discount__title">
                  Insira o seu <span className="special_discount__highlighted">CPF ou CNPJ</span> para iniciar a compra.
                </h1>
                <input
                  autoFocus
                  className="special_discount__input"
                  type="text"
                  placeholder="Digite aqui o seu documento"
                  ref="document"
                  autoComplete="off"
                  onKeyPress={e => {
                    if (e.key === 'Enter') this._handleNext.apply(this)
                  }}
                />
                <button className="special_discount__button button__big" onClick={this._handleNext.bind(this)}>
                  Próximo
                </button>
                <Tooltip show={this.state.showTooltip} hidesAfter={10000} className="special_discount__tooltip">
                  <span className="special_discount__tooltip__error">X</span>
                  <span className="special_discount__tooltip__text">{error.message}</span>
                </Tooltip>
              </div>
              <div className={`special_discount__insert--student ${this.state.activeSlider}`}>
                {specialDiscount.voucher ? (
                  <div className="special_discount__infos">
                    <div className="special_discount__infos__logo">
                      <img src={specialDiscount.school_logo} alt="" />
                    </div>
                    <span className="special_discount__infos__school">{specialDiscount.school_name}</span> |
                    <span className="special_discount__infos__address">{specialDiscount.school_address}</span> -
                    <span className="special_discount__infos__degree">
                      {specialDiscount.study_year} {specialDiscount.study_step}
                    </span>
                    {this.state.hideInput && this.state.nextSlideErrorMessage === SPECIAL_DISCOUNT_INVALID_DOCUMENT ? (
                      <span className="special_discount__expired-text">{specialDiscount.error.message}</span>
                    ) : null}
                  </div>
                ) : null}
                {!this.state.hideInput && !_.isEmpty(specialDiscount.special_discounts) ? (
                  <div>
                    <h1 className="special_discount__title">
                      Qual o <span className="special_discount__highlighted">nome do aluno</span> que usará os livros?
                    </h1>
                    <input
                      className="special_discount__input"
                      type="text"
                      placeholder="Digite aqui o nome do aluno"
                      ref="name"
                      autoComplete="off"
                      onKeyPress={e => {
                        if (e.key === 'Enter') this._handleBuyButton.apply(this, [e])
                      }}
                    />
                    <button
                      to="/steps/selecionar-livros"
                      className="special_discount__button button__big"
                      onClick={this._handleBuyButton.bind(this)}
                    >
                      Comprar
                    </button>
                    {(this.state.alertNameStudent) ? (
                      <div className="special_discount__infos">
                        <span style={{ color: 'red' }}>
                          <br />
                          O nome informado não corresponde a nenhum aluno com desconto. <br/> Certifique-se de que o nome preenchido é igual ao fornecido pela escola.
                        </span>
                      </div>
                    ) : ''}
                    <span className="special_discount__info">
                      O pacote será identificado na escola pelo nome do aluno
                    </span>
                  </div>
                ) : null}
                {this.state.hideInput && this.state.nextSlideErrorMessage === GLOBAL_ERROR_MESSAGE ? (
                  <div>
                    <div className="special_discount__infos">
                      <span className="special_discount__expired-text text-red">{error.message}.</span>
                      <button
                        className="special_discount__button button__small"
                        onClick={() => this.setState({ activeSlider: '' })}
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  specialDiscount: state.specialDiscount,
  error: state.specialDiscount.error,
})

const mapDispatchToProps = dispatch => ({
  checkSpecialDiscount: document => dispatch(checkSpecialDiscount(document)),
  insertStudent: discount => dispatch(insertStudent(discount)),
  updateActiveVoucher: (voucher, name) => dispatch(updateActiveVoucher(voucher, name)),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  getSchoolPaymentOptions: schoolSapCode => dispatch(fetchSchoolPaymentOptions(schoolSapCode)),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  specialDiscount: stateProps.specialDiscount,
  error: stateProps.error,
  checkSpecialDiscount: dispatchProps.checkSpecialDiscount,
  clearSpecialDiscount: dispatchProps.clear,
  insertStudent: discount => {
    dispatchProps.insertStudent({ ...discount, name: discount.student_name })
    dispatchProps.updateActiveVoucher(discount.voucher, discount.student_name)
  },
  openLoader: dispatchProps.openLoader,
  closeLoader: dispatchProps.closeLoader,
  getSchoolPaymentOptions: dispatchProps.getSchoolPaymentOptions,
  navigate: ownProps.navigate,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(SpecialDiscount))

