import { FormInput } from 'react-form'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import React, { useState } from 'react'
import _ from 'lodash'
import icon_visibility_on from '../img/eye-outline.png'
import icon_visibility_off from '../img/eye-off-outline.png'

export const InputText = ({ field, mask, from, to, handleBlur, handleChange, ...rest }) => (
  <FormInput field={field}>
    {({ setValue, getValue, setTouched }) => {
      to = typeof to === 'undefined' ? '' : to
      return (
        <InputMask
          {...rest}
          className={`field__text ${rest.className}`}
          mask={mask}
          maskChar=" "
          formatChars={rest.formatChars}
          alwaysShowMask={false}
          autoComplete="off"
          onPaste={event => {
            if (rest.blockPaste) {
              event.preventDefault()
              return false
            }
          }}
          value={getValue() || ''}
          onChange={event => {
            setValue(event.target.value.replace(from, to))
            if (typeof handleChange !== 'undefined') {
              handleChange(event)
            }
          }}
          onBlur={event => {
            setTouched()
            if (typeof handleBlur !== 'undefined') {
              handleBlur(event)
            }
          }}
        />
      )
    }}
  </FormInput>
)

export const InputCheckbox = ({ field, onChange = () => {}, checked = null }) => (
  <FormInput field={field}>
    {({ setValue, getValue, setTouched, ...rest }) => {
      return (
        <input
          id={field}
          className="field__checkbox"
          type="checkbox"
          autoComplete="off"
          checked={!_.isNull(checked) ? checked : getValue() || 0}
          value={getValue() || 0}
          onChange={event => {
            // eslint-disable-next-line
            let value = event.target.value == true ? 0 : 1
            onChange(event, value)
            return setValue(value)
          }}
        />
      )
    }}
  </FormInput>
)

export const InputFile = ({ field, onChange = () => {}, current = null }) => (
  <FormInput field={field}>
    {({ setValue, getValue, setTouched, ...rest }) => {
      return (
        <React.Fragment>
          <input
            id={field}
            className="field__file"
            type="file"
            autoComplete="off"
            onChange={event => {
              // eslint-disable-next-line
              let value = !_.isEmpty(event.target.files) ? event.target.files[0] : null
              return setValue(value)
            }}
          />
          {current && (
            <a href={current} target="_blank">
              Abrir arquivo atual
            </a>
          )}
        </React.Fragment>
      )
    }}
  </FormInput>
)

export const InputEmail = ({ field, mask, from, to, handleBlur, ...rest }) => (
  <FormInput field={field}>
    {({ setValue, getValue, setTouched }) => {
      to = typeof to === 'undefined' ? '' : to
      return (
        <InputMask
          {...rest}
          className={`field__text ${rest.className}`}
          mask={mask}
          maskChar=" "
          formatChars={rest.formatChars}
          alwaysShowMask={false}
          autoComplete="off"
          value={getValue() || ''}
          onChange={event => {
            setValue(event.target.value.replace(from, to))
          }}
          onBlur={event => {
            setTouched()
            if (typeof handleBlur !== 'undefined') {
              handleBlur(event)
            }
          }}
        />
      )
    }}
  </FormInput>
)

export const InputZipCode = ({ field, from, to, handleBlur, ...rest }) => (
  <FormInput field={field}>
    {({ setValue, getValue, setTouched }) => {
      to = typeof to === 'undefined' ? '' : to
      return (
        <InputMask
          {...rest}
          className={`field__text $ ${rest.classname}`}
          maskChar=" "
          autoComplete="off"
          formatChars={rest.formatChars}
          value={getValue() || ''}
          onchange={e => {
            setValue(e.target.value.replace(from, to))
          }}
          onBlur={e => {
            setTouched()
            if (typeof handleBlur !== 'undefined') {
              handleBlur(e)
            }
          }}
        />
      )
    }}
  </FormInput>
)

export const InputPassword = ({ field, from, to, handleBlur, ...rest }) => {
  const [visibility, setVisibility] = useState(false)

  return (
    <FormInput field={field} className="field__password">
      {({ setValue, getValue, setTouched }) => {
        to = typeof to === 'undefined' ? '' : to
        return (
          <React.Fragment>
            <input
              id={rest.id}
              className={`field__text ${rest.className || ''}`}
              field={field}
              type={visibility ? 'text' : 'password'}
              placeholder={rest.placeholder || ''}
              value={getValue() || ''}
              onChange={event => {
                setValue(event.target.value.replace(from, to))
              }}
              autoComplete="off"
            />
            <button
              type="button"
              className="btn_visibility_password"
              onClick={event => {
                event.preventDefault()
                setVisibility(!visibility)
              }}
            >
              <img
                src={!visibility ? icon_visibility_off : icon_visibility_on}
                alt={visibility ? 'Ocultar' : 'Mostrar'}
                title={visibility ? 'Ocultar' : 'Mostrar'}
              />
            </button>
          </React.Fragment>
        )
      }}
    </FormInput>
  )
}

export const SelectState = ({ field, disabled, handleBlur, ...rest }) => {
  return (
    <FormInput field={field} className="field__select_state">
      {({ setValue, getValue, setTouched }) => {
        return (
          <React.Fragment>
            <select
              className={`field__text ${rest.className || ''}`}
              field={field}
              placeholder={rest.placeholder || ''}
              value={getValue() || ''}
              onChange={event => {
                setValue(event.target.value)
              }}
              autoComplete="off"
              disabled={disabled}
              onBlur={event => {
                setTouched()
                if (typeof handleBlur !== 'undefined') {
                  handleBlur(event)
                }
              }}
            >
              <option value=""></option>
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MT">MT</option>
              <option value="MS">MS</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </select>
          </React.Fragment>
        )
      }}
    </FormInput>
  )
}

export const SelectSchoolsUser = ({ field, schools, placeholder = '', ...rest }) => {
  const colourStyles = {
    control: styles => {
      return {
        ...styles,
        marginBottom: '5px',
        minHeight: '58px',
        borderColor: 'inherit',
        ':hover': {
          borderColor: 'inherit',
        },
      }
    },
    option: (styles, { data, isDisabled }) => {
      return {
        ...styles,
        width: 'inherit',
        backgroundColor: '#inherit',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ':hover': {
          backgroundColor: !!data.value && '#cccccc',
        },
      }
    },
    menu: styles => {
      return {
        ...styles,
        display: 'block',
      }
    },
  }

  const animatedComponents = makeAnimated()

  return (
    <FormInput field={field}>
      {({ setValue, getValue, setTouched }) => {
        const initialValue = getValue('schools') ?? getValue()
        
        const schoolsFilters = schools.filter(school => initialValue.includes(school.value));
        
        if(schools.length > 0) {
        return (
          <Select
          {...rest}
          defaultValue={schoolsFilters}
          isMulti
          noOptionsMessage={() => 'Nenhum resultado'}
          onChange={schools => {
            setValue(schools)
          }}
          closeMenuOnSelect={false}
          options={schools}
             theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: 'black',
              },
            })}
        />
          )
        }
      }}
    </FormInput>
  )
}

export const UFInputGroup = ({ field, handleBlur, ...rest }) => {
  return (
    <FormInput field={field}>
      {({ setValue, getValue }) => {
        const value = getValue() && JSON.parse(getValue())
        return (
          value && (
            <div className="field__uf_input_group">
              {Object.keys(value).map(fieldName => {
                const [day, month, year] = value[fieldName].split('/')
                return (
                  <div key={fieldName} className="field__uf_input_group_field">
                    <span>{fieldName}:</span>
                    <input
                      onChange={event => {
                        const [year, month, day] = event.target.value.split('-')
                        value[fieldName] = `${day}/${month}/${year}`
                        setValue(JSON.stringify(value))
                      }}
                      type="date"
                      value={`${year}-${month}-${day}`}
                    />
                  </div>
                )
              })}
            </div>
          )
        )
      }}
    </FormInput>
  )
}

/* eslint-disable */
export const regexEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
/* eslint-enable */
export const regexZipcode = /^[0-9]{5}-[0-9]{3}$/
export const regexZipcodeFull = /^[0-9]{5}-?[0-9]{3}$/
export const regexPassword =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
export default {
  InputText,
  InputEmail,
  InputPassword,
  regexEmail,
  SelectState,
  InputCheckbox,
  InputZipCode,
  regexZipcode,
}