import React, { Component } from 'react'

import calendar from './images/calendar.png'

export default class CustomDateInput extends Component {
  render() {
    return (
      <div className="inputdate" onClick={this.props.onClick}>
        <div className="inputdate__icon">
          <img src={calendar} alt="Calendário" />
        </div>
        <div className="inputdate__current">
          <span className="inputdate__label">{this.props.label}</span>
          {this.props.value}
        </div>
      </div>
    )
  }
}
