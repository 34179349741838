import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Form, Text, Textarea } from 'react-form'
import { InputText, regexEmail } from '@shared/CustomField'
import { openLoader, closeLoader } from '@actions/loader'
import { connect } from 'react-redux'

class FaqContact extends Component {
  _validateForm(values) {
    let fields = ['name', 'email', 'subject', 'body']
    let error = fields.reduce((prev, value) => {
      prev[value] = !values[value] ? 'Campo obrigatório' : undefined
      return prev
    }, {})

    error.email = !regexEmail.test(values.email) ? 'Email inválido' : undefined

    return error
  }

  _handleSubmit(values) {
    this.props.openLoader()
    this.props.send(values)
  }

  UNSAFE_componentWillReceiveProps() {
    this.props.closeLoader()
  }

  render() {
    let { title, sendStatus } = this.props
    if (!sendStatus) {
      return (
        <div className="faqcontact">
          <h3 className="faqcontact__title">{title}</h3>
          <Form
            ref="Form"
            validate={values => this._validateForm(values)}
            onSubmit={values => {
              this._handleSubmit(values)
            }}
          >
            {({ submitForm, swapTo, setValue }) => {
              return (
                <form className="register__form faqcontact__form" onSubmit={submitForm}>
                  <div className="field">
                    <span className="field__label register__form__label">Nome</span>
                    <Text className="field__text" field="name" autoComplete="off" />
                  </div>
                  <div className="field">
                    <span className="field__label register__form__label">Email</span>
                    <Text className="field__text" field="email" autoComplete="off" />
                  </div>
                  <div className="field">
                    <span className="field__label register__form__label">Assunto</span>
                    <Text className="field__text" field="subject" autoComplete="off" />
                  </div>
                  <div className="field">
                    <span className="field__label register__form__label">Telefone</span>
                    <InputText
                      field="telefone"
                      mask="(99) 9999-9999?"
                      formatChars={{
                        9: '[0-9]',
                        '?': '[0-9]',
                      }}
                      from={/\D/g}
                    />
                  </div>
                  <div className="field">
                    <span className="field__label register__form__label">Digite aqui sua dúvida</span>
                    <Textarea className="field__textarea" field="body" autoComplete="off" />
                  </div>
                  <div className="faqcontact__buttons">
                    <NavLink className="button__normal--cancel faqcontact__button cancel" to="/faq">
                      Cancelar
                    </NavLink>
                    <button className="faqcontact__button button__normal" type="submit">
                      Enviar
                    </button>
                  </div>
                </form>
              )
            }}
          </Form>
        </div>
      )
    } else {
      return (
        <div>
          <h3 className="faqcontact__msg">Mensagem enviada com sucesso!</h3>
          <NavLink className="button__normal faqcontact__button" to="/faq">
            voltar
          </NavLink>
        </div>
      )
    }
  }
}

const mapDispatchToProps = dispatch => ({
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
})

export default connect(null, mapDispatchToProps)(FaqContact)

