import React, { Component } from 'react'
import {
  setDigitalSolutions,
  setDisableDigitalLicenses,
  updateDigitalSolutions,
  setDigitalSolutionsVal,
  setDigitalSolutionsHybrid,
  resetDigitalSolutions,
} from '@actions/digitalSolutions'
import { closeLoader, openLoader } from '@actions/loader'
import { closeModal, openModal } from '@actions/modal'
import { connect } from 'react-redux'

import { SchoolDetailModal } from '../Modal'
import { DigitalSolutionGC } from './DigitalSolutionGC'
import { DigitalSolutionSMA } from './DigitalSolutionSMA'
import { DigitalSolutionSMAP } from './DigitalSolutionSMAP'
import DigitalSolutionH from './DigitalSolutionH'

export class DigitalSolutions extends Component {
  state = {
    button_disabled: true,
  }

  _openModal(title, message) {
    this.props.openModal(<SchoolDetailModal closeModal={this.props.closeModal} title={title} message={message} />)
  }

  _hasEducamos() {
    let { digital_solution_val = null } = this.props
    let has_educamos = false
    if (digital_solution_val !== null) {
      Object.entries(digital_solution_val).forEach(([ensino, value]) => {
        if (value > 0) {
          has_educamos = true
          return has_educamos
        }
      })
    }
    return has_educamos
  }

  _hasAllEducamos() {
    let { digital_solution_val = null } = this.props
    let all_educamos = false
    if (digital_solution_val !== null && Object.entries(digital_solution_val).length > 0) {
      all_educamos = true
      Object.entries(digital_solution_val).forEach(([ensino, value]) => {
        if (value === 0) {
          all_educamos = false
          return all_educamos
        }
      })
    }
    return all_educamos
  }

  _validateHybrid() {
    let valid = []
    Object.entries(this.props.hybrid).forEach(([key, value]) => {
      if (value === null || value === undefined || value === '') {
        valid.push(key)
      }
    })
    return valid
  }

  _normalizeEnsino(ensino) {
    switch (ensino) {
      case 'ei':
        return 'Ensino infantil'
      case 'ef1':
        return 'Fundamental 1'
      case 'ef2':
        return 'Fundamental 2'
      case 'em':
        return 'Ensino médio'
      default:
        break
    }
  }

  async _handleSaveDigitalSolution() {
    if (this.props.digital_solutions || this.props.disable_digital_licenses) {
      this.props.openLoader()
      let data = {
        digital_solution: this.props.digital_solutions,
        disable_digital_licenses: this.props.disable_digital_licenses,
        school_id: this.props.school_id,
      }
      let valid = []
      if (this.props.digital_solutions === 4) {
        valid = await this._validateHybrid()
        if (valid.length === 0) {
          data.hybrid = await this.props.hybrid
        }
      }
      if (valid.length === 0) {
        this.props.updateDigitalSolutions(data).then(resp => {
          this.props.closeLoader()
          if (resp.data && resp.data.code === 200) {
            this._openModal('Sucesso', () => <p>A solução digital foi alterada com sucesso!</p>)
          } else {
            this._openModal('Erro', () =>
              resp.message ? (
                <p>{resp.message}</p>
              ) : (
                <p>Houve algum erro ao alterar a solução digital. Por favor tente novamente.</p>
              ),
            )
          }
        })
      } else {
        this.props.closeLoader()
        this._openModal('Erro', () => (
          <div>
            <p>A alteração não pode ser salva.</p>
            <p>Por favor revisar a solução escolhida para:</p>
            {valid.map(item => {
              let ensino_ano = item.split('_')
              return <p key={item}>{`${this._normalizeEnsino(ensino_ano[0])} - ${ensino_ano[1]}º ano`}</p>
            })}
          </div>
        ))
      }
    }
  }

  _setDigitalSolutions(value) {
    Object.entries(this.props.digital_solution_val).forEach(([key]) => {
      this.props.setDigitalSolutionsHybrid({ [key]: null })
    })
    this.props.setDigitalSolutions(value)
  }

  componentWillUnmount() {
    this.props.resetDigitalSolutions()
  }

  componentDidMount() {
    if (this.props.contract !== null) {
      let {
        digital_solution = {},
        digital_solution_val = {},
        hybrid = {},
        disable_digital_licenses = 0,
      } = this.props.contract
      this.props.setDigitalSolutions(digital_solution)
      this.props.setDigitalSolutionsVal(digital_solution_val)
      this.props.setDisableDigitalLicenses(disable_digital_licenses === 1 ? true : false)
      Object.entries(digital_solution_val).forEach(([key]) => {
        this.props.setDigitalSolutionsHybrid({ [key]: hybrid[key] ? hybrid[key] : null })
      })
    }
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.contract !== nextProps.contract && nextProps.contract !== null) {
      let {
        digital_solution = {},
        digital_solution_val = {},
        hybrid = {},
        disable_digital_licenses = 0,
      } = nextProps.contract
      nextProps.setDigitalSolutions(digital_solution)
      nextProps.setDigitalSolutionsVal(digital_solution_val)
      nextProps.setDisableDigitalLicenses(disable_digital_licenses === 1 ? true : false)
      Object.entries(digital_solution_val).forEach(([key]) => {
        nextProps.setDigitalSolutionsHybrid({ [key]: hybrid[key] ? hybrid[key] : null })
      })
    }
  }

  render() {
    let { digital_solutions, disable_digital_licenses } = this.props

    return (
      <div className="digital_solution">
        <div className="schoolcard__content">Escolha a solução a ser utilizada pela escola</div>
        <div className="digital_solution_wrapper">
          <div
            className="digital_solution_col buttons"
            onChange={event => {
              // eslint-disable-next-line radix
              const valuesisNumber = parseInt(event.target.value)
              this._setDigitalSolutions(valuesisNumber)
              this.setState({
                button_disabled: false,
              })
            }}
          >
            <div className="digital_solution_btn">
              <input
                id="digital_solution_GC"
                type="radio"
                name="digital_solution"
                value="1"
                checked={digital_solutions === 1}
              />
              <label htmlFor="digital_solution_GC">Educamos - Ambiente Digital</label>
            </div>
            <div className="digital_solution_btn">
              <input
                id="digital_solution_SMA"
                type="radio"
                name="digital_solution"
                value="2"
                checked={digital_solutions === 2 && !this._hasEducamos()}
                disabled={this._hasEducamos()}
              />
              <label htmlFor="digital_solution_SMA">SM Aprendizagem</label>
            </div>
            <div className="digital_solution_btn">
              <input
                id="digital_solution_SMAP"
                type="radio"
                name="digital_solution"
                value="3"
                checked={digital_solutions === 3 && !this._hasEducamos()}
                disabled={this._hasEducamos()}
              />
              <label htmlFor="digital_solution_SMAP">SM Aprendizagem Plus</label>
            </div>
            <div className="digital_solution_btn">
              <input
                id="digital_solution_H"
                type="radio"
                name="digital_solution"
                value="4"
                checked={digital_solutions === 4 && !this._hasAllEducamos()}
                disabled={this._hasAllEducamos()}
              />
              <label htmlFor="digital_solution_H">Híbrido</label>
            </div>
          </div>
          <div className="digital_solution_col">
            {digital_solutions === 1 && <DigitalSolutionGC />}
            {digital_solutions === 2 && <DigitalSolutionSMA />}
            {digital_solutions === 3 && <DigitalSolutionSMAP />}
            {digital_solutions === 4 && <DigitalSolutionH />}
          </div>
        </div>
        <div className="digital_solution_disable_digital_licenses">
          <label className="schoolcard__checkbox_area">
            <input
              className="checkbox"
              id="disable_digital_licenses"
              type="checkbox"
              name="disable_digital_licenses"
              checked={disable_digital_licenses}
              onChange={() => {
                this.props.setDisableDigitalLicenses(!disable_digital_licenses)
                this.setState({
                  button_disabled: false,
                })
              }}
            />
            <span>Não gerar chaves de licenças e não enviar e-mail de ativação da plataforma.</span>
          </label>
        </div>
        <div className="digital_solution_footer">
          <button
            className="button schoolcard__save"
            disabled={this.state.button_disabled}
            onClick={() => {
              this._handleSaveDigitalSolution()
            }}
          >
            Salvar
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    digital_solutions: state.digitalSolutions.digital_solutions,
    disable_digital_licenses: state.digitalSolutions.disable_digital_licenses,
    hybrid: state.digitalSolutions.hybrid,
    digital_solution_val: state.digitalSolutions.digital_solution_val,
    contract: state.contract.contracts.find(contract => contract.id === props.contract_id) || null,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openModal: component => dispatch(openModal(component)),
    closeModal: () => dispatch(closeModal()),
    openLoader: () => dispatch(openLoader()),
    closeLoader: () => dispatch(closeLoader()),
    setDigitalSolutions: value => dispatch(setDigitalSolutions(value)),
    setDisableDigitalLicenses: value => dispatch(setDisableDigitalLicenses(value)),
    updateDigitalSolutions: data => dispatch(updateDigitalSolutions(data)),
    setDigitalSolutionsVal: data => dispatch(setDigitalSolutionsVal(data)),
    setDigitalSolutionsHybrid: data => dispatch(setDigitalSolutionsHybrid(data)),
    resetDigitalSolutions: () => dispatch(resetDigitalSolutions()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DigitalSolutions)
