import './index.css'

import { Cell, Column, Table } from 'fixed-data-table-2'
import { NavLink } from 'react-router-dom'
import React, { Component } from 'react'
import { closeLoader, openLoader } from '@actions/loader'

import FilterText from '@components/FilterText'
import InlineLoader from '@components/InlineLoader'
import _ from 'underscore'
import { connect } from 'react-redux'
import { getNotificationList } from '@services/sm-api.js'

const stripHtmlRegex = /(<([^>]+)>)/gi

const stripHtml = html => {
  return html.replace(stripHtmlRegex, '')
}

const HeaderCell = ({ ...props }) => (
  <Cell className={`report-table__header-cell ${props.className}`} {...props}>
    <strong>{props.children}</strong>
  </Cell>
)

const DefaultCell = ({ children, className }) => <Cell className={className}>{children}</Cell>

const HTMLCell = ({ children, className, data }) => <Cell className={className}>{stripHtml(data.message)}</Cell>

const YesNoCell = ({ children, className, data, field }) => (
  <Cell className={className}>
    {field === 'block_checkout' ? (data[field] ? 'Sim' : 'Não') : ''}
    {field === 'is_active' ? (data[field] ? 'Ativa' : 'Inativa') : ''}
  </Cell>
)

const EditButtonCell = ({ children, data }) => (
  <Cell className="report-table__button-cell center">
    <NavLink to={`/admin/notifications/${data.id}`} className={`button button__small center`}>
      Editar
    </NavLink>
  </Cell>
)

class Notification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notifications: [],
      isSearching: false,
    }

    this.pagination = {
      current: 1,
      total: 0,
      isLoading: false,
    }

    this.filter = {}

    this.columns = [
      {
        label: 'Título',
        field: 'title',
        component: DefaultCell,
      },
      {
        label: 'Mensagem',
        field: 'message',
        component: HTMLCell,
      },
      {
        label: 'Bloquear checkout?',
        field: 'block_checkout',
        component: YesNoCell,
        className: 'center',
      },
      {
        label: 'Status',
        field: 'is_active',
        component: YesNoCell,
        className: 'center',
      },
    ]

    if (this.props.hasCreateEditPermission) {
      this.columns.push({
        label: 'Editar',
        component: EditButtonCell,
        className: 'center',
      })
    }
  }

  _lazyLoadData = _.debounce((params, filter) => {
    this.setState({ notifications: [] })
    this.pagination.current = 1
    return this._loadData(params, filter).finally(() => this.setState({ isSearching: false }))
  }, 300)

  _onSearch = field => {
    this.filter = { ...this.filter, ...field }

    this.setState({ notifications: [], isSearching: true })
    return this._lazyLoadData()
  }

  _loadData = () => {
    return getNotificationList({
      page: this.pagination.current,
      ...this.filter,
    })
      .then(response => {
        this.pagination.total = response.total
        this.pagination.current += 1
        this.setState({
          notifications: [...this.state.notifications, ...response.notifications],
        })
        return response
      })
      .finally(closeLoader)
  }

  _handleLoadMore = () => {
    openLoader()
    this._loadData()
  }

  componentDidMount() {
    const { openLoader, closeLoader } = this.props
    openLoader()
    this._loadData().finally(closeLoader)
  }

  render() {
    const { notifications, isSearching } = this.state

    const { hasCreateEditPermission } = this.props

    return (
      <div className={`report notification`}>
        <h1 className="report__title">Notificações</h1>
        {hasCreateEditPermission && (
          <React.Fragment>
            <NavLink className="button__small" to="/admin/notifications/novo">
              Adicionar nova notificação
            </NavLink>
          </React.Fragment>
        )}
        <div className="filters" id="report_container">
          <div className="filters__item">
            <FilterText field="search" label="Buscar por título e/ou mensagem" onChange={this._onSearch} />
            <InlineLoader show={isSearching} />
          </div>
        </div>
        <div className="report__table">
          <div className="report-table">
            <Table
              ref="Table"
              className="report-table"
              onScrollEnd={this._handleLoadMore}
              rowHeight={45}
              rowsCount={notifications.length}
              width={960}
              height={400}
              headerHeight={50}
              data={notifications}
            >
              {this.columns.map((column, key) => {
                return (
                  <Column
                    key={`${column.field}-${key}`}
                    header={<HeaderCell className={column.className}>{column.label}</HeaderCell>}
                    columnKey={column.field}
                    fixed
                    width={960 / this.columns.length}
                    cell={({ rowIndex, columnKey, props }) => (
                      <column.component
                        data={notifications[rowIndex]}
                        field={column.field}
                        className={column.className}
                      >
                        {notifications[rowIndex][columnKey]}
                      </column.component>
                    )}
                  />
                )
              })}
            </Table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.sessionReducer.user,
  hasCreateEditPermission: state.sessionReducer.user.permissions.includes('manage_notifications'),
})

const mapDispatchToProps = { openLoader, closeLoader }

export default connect(mapStateToProps, mapDispatchToProps)(Notification)

