import React from 'react'
import { Modal } from 'react-bootstrap'

export const DuplicatedOrderAlertModal = ({show, onClose }) => (
  <Modal show={show}>
    <Modal.Body>
      <div className="modalHeader">
        <span className="icon-alert" />
        <h1>Atenção</h1>
      </div>
      <div className="modalContent">
        <p>Ops! Foi encontrada uma duplicidade no seu pedido e por isso não é possível prosseguir o pagamento. Não se preocupe, nenhum valor foi cobrado. </p>
        <p>Pedimos que inicie novamente o processo de compra clicando na página de Inicio. </p>
      </div>
      <div className="modalFooter">
        <button
        style={{ width: '100%' }}
          className="inline-button"
          onClick={() => {
            window.localStorage.removeItem('reduxPersist:checkout')
            window.localStorage.removeItem('reduxPersist:preVoucher')
            window.location.href = '/'
          }}
        >
          Voltar para o Início
        </button>
      </div>
    </Modal.Body>
  </Modal>
)