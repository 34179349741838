import { importContracts } from '@services/sm-api.js'

export const FETCH_CONTRACTS = 'school/FETCH_CONTRACTS'
export const SUCCESS_CONTRACTS = 'school/SUCCESS_CONTRACTS'

export function fetchContracts() {
  return {
    type: FETCH_CONTRACTS,
  }
}

export function successContracts(payload) {
  return {
    type: SUCCESS_CONTRACTS,
    payload,
  }
}

export function getContracts(schoolId) {
  return dispatch => {
    dispatch(fetchContracts())
    return importContracts(schoolId).then(response => dispatch(successContracts(response.data)))
  }
}
