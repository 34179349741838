const questions = [
  {
    category: 'Compra',
    items: [
      {
        question: 'Como faço para realizar a compra dos livros?',
        answer:
          'Os pais devem realizar o cadastro no <i>site</i> da loja para que possam efetuar a compra com desconto.',
      },
      {
        question: 'Como faço para comprar livros para alunos com Necessidades Educacionais Especiais (NEEs)?',
        answer:
          'A SM fornece livros para alunos Necessidades Educacionais Especiais (NEEs) nos seguintes formatos:<br>\r\n• Livro ampliado\r\n<br>• PDF\r\n<br>• TXT\r\n<br>Para comprar, entre em contato pelo <i>e-mail</i>: atendimento@grupo-sm.com.',
      },
      {
        question: 'Quais os valores de descontos que a SM oferece para a compra dos livros?',
        answer: 'O valor de desconto será definido pela escola e você poderá visualizar no momento da compra no site.',
      },
      {
        question:
          'Meu/minha(s) filho/filha(s) estuda(m) em escola que não está cadastrada na SM. Posso realizar a compra dos livros adotados com desconto?',
        answer:
          'Não. No momento, somente os pais e/ou responsáveis cujos filhos estudam em escolas que estão cadastradas podem realizar a compra no <i>site</i> com desconto.',
      },
      {
        question:
          'Sou professor. Além do desconto que a escola oferece, posso comprar livros com desconto para professor?',
        answer:
          'Não. No momento, somente os pais e/ou responsáveis cujos filhos estudam em escolas que estão cadastradas podem realizar a compra no <i>site</i> com desconto.',
      },
      {
        question: 'Onde posso verificar o valor de desconto no ato da compra?',
        answer:
          'Na própria página, onde estiver realizando a aquisição dos livros, você poderá verificar o valor real da compra e o valor com desconto.',
      },
      {
        question: 'Realizei a compra, mas desisti. Como proceder para o cancelamento?',
        answer:
          'O cliente tem prazo de até 7 dias úteis a partir do momento em que realizou a compra para solicitar o cancelamento. Se o pagamento foi com cartão de crédito deverá aguardar o prazo da operadora para estorno do valor. Caso a opção tenha sido via pagamento com boleto a SM irá informar o prazo para devolução do valor.',
      },
    ],
  },
  {
    category: 'Entrega',
    items: [
      {
        question: 'Após a conclusão da compra, onde posso acompanhar meu pedido para retirada?',
        answer:
          'A escola se encarregará de informar a data de retirada dos livros, que será de acordo com a data da compra',
      },
      {
        question: 'Posso ir à SM para a retirada dos livros?',
        answer:
          'Não. Os livros serão enviados para a escola, e a direção informará a data de entrega, que será de acordo com a data da compra',
      },
      {
        question:
          'Ao conferir os livros, verifiquei que um dos títulos está errado. Como proceder para realizar a troca?',
        answer:
          'Você deve informar na escola que um dos títulos não confere com o que foi comprado. A escola entrará em contato com a SM para realizar os trâmites de troca do produto.',
      },
    ],
  },
  {
    category: 'Dados Cadastrais',
    items: [
      {
        question: 'Esqueci minha senha. Como faço para recuperá-la?',
        answer:
          'Na página de identificação do <i>site</i>, poderá usar o recurso “esqueci minha senha” e digitar o <i>e-mail</i> cadastrado. O sistema enviará uma nova senha para o seu <i>e-mail</i>.',
      },
      {
        question: 'Estou com dificuldade para fazer o cadastro e realizar a compra. Como posso entrar em contato?',
        answer:
          'Você pode ligar para o Atendimento da SM, no telefone 0800 725 4876, de segunda a sexta-feira, das 8h às 17h.',
      },
      {
        question: 'Já tenho cadastro no site da SM, posso usá-lo para realizar a compra dos livros?',
        answer: 'Não. Será necessário realizar um novo cadastro.',
      },
    ],
  },
  {
    category: 'Pagamento',
    items: [
      {
        question:
          'Além dos livros didáticos, a escola adotou livros de Literatura. Essas obras podem ser compradas com desconto?',
        answer: 'Se a escola indicou os livros de literatura na lista de material, poderão ser comprados no portal',
      },
      {
        question: 'Qual a forma de pagamento?',
        answer:
          'São três opções de pagamento: boleto bancário e PIX com pagamento à vista e cartão de crédito com parcelamento em até 12 vezes sem juros de acordo com o mês da compra.',
      },
    ],
  },
]

export default questions
