import { NavLink } from 'react-router-dom'
import React, { Component } from 'react'
import { clearVoucher, requestVoucher } from '@actions/preVoucher'
import { closeLoader, openLoader } from '@actions/loader'
import { clearSpecialDiscount } from '@actions/specialDiscount'

import Tooltip from '@shared/Tooltip'
import _ from 'lodash'
import { connect } from 'react-redux'
import { fetchSchoolPaymentOptions } from '@actions/school'
import { insertStudent } from '@actions/checkout'
import { updateActiveVoucher } from '@actions/activeVoucher.js'
import { withRouter } from '../../utils/withRouter'
import { removeStudent } from '@actions/checkout'

const VOUCHER_NOT_EXIST = 0
const VOUCHER_EXPIRED = 2
const VOUCHER_VALID_DATE = 3
const VOUCHER_NOT_ACTUAL_CAMPAIGN = 4
const VOUCHER_IS_INACTIVE = 6

class Voucher extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeBus: '',
      show: false,
      activeSlider: '',
      hideInput: false,
      showTooltip: false,
      tooltipErrorMessage: '',
      emptyVoucher: true,
      nextSlideErrorMessage: null,
      addressModalClose: false,
      school_is_b2c: false,
    }
  }

  _nextSlide() {
    this.setState({
      activeSlider: 'active',
    })
  }

  _clearVoucherInfo() {
    window.localStorage.removeItem('reduxPersist:checkout')
    window.localStorage.removeItem('reduxPersist:preVoucher')
    this.props.clearSpecialDiscount()
  }

  _handleNext() {
    this.setState({ showTooltip: false })
    this._clearVoucherInfo()
    this.props.requestVoucher(this.refs.voucher.value).then(() => {
      const { error } = this.props

      switch (error.code) {
        case -1:
        case 1:
        case 5:
          this.setState({ showTooltip: true })
          break

        case VOUCHER_NOT_EXIST:
          this.props.closeLoader()
          this.setState({
            nextSlideErrorMessage: VOUCHER_NOT_EXIST,
            hideInput: true,
          })
          this._nextSlide()
          break

        case VOUCHER_EXPIRED:
          this.props.closeLoader()
          this.setState({
            nextSlideErrorMessage: VOUCHER_EXPIRED,
            hideInput: true,
          })
          this._nextSlide()
          break

        case VOUCHER_VALID_DATE:
          this.props.closeLoader()
          this.setState({
            nextSlideErrorMessage: VOUCHER_VALID_DATE,
            hideInput: true,
          })
          this._nextSlide()
          break

        case VOUCHER_NOT_ACTUAL_CAMPAIGN:
          this.props.closeLoader()
          this.setState({
            nextSlideErrorMessage: VOUCHER_NOT_ACTUAL_CAMPAIGN,
            hideInput: true,
          })
          this._nextSlide()
          break

        case VOUCHER_IS_INACTIVE:
          this.props.closeLoader()
          this.setState({
            nextSlideErrorMessage: VOUCHER_IS_INACTIVE,
            hideInput: true,
          })
          this._nextSlide()
          break

        default:
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'compra',
            eventAction: 'click',
            eventLabel: 'inseriu_voucher_1',
          })

          this._nextSlide()
          setTimeout(() => {
            document.querySelector('.voucher__slider').scrollLeft = 0
            this.refs.name.focus()
          }, 500)
          break
      }
    })
  }

  _handleBuyButton(event) {
    let valid = true,
      errorMessage = '',
      name = this.refs.name.value.trim()

    if (!name.length) {
      valid = false
      errorMessage = 'Por favor, preencher o nome.'
    }

    if (name.match(/[^A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]/g)) {
      valid = false
      errorMessage = 'O nome não pode conter números'
    }

    if (name.split(' ').length < 2) {
      valid = false
      errorMessage = 'Por favor, preencha o nome completo'
    }
    
    this.props.students.map((student, index) => {
      this.props.removeStudent(index)
    })

    if (!valid) {
      this.setState({
        showTooltip: true,
        tooltipErrorMessage: errorMessage,
      })
    } else {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'compra',
        eventAction: 'click',
        eventLabel: 'inseriu_nome_aluno_2',
      })
      this.props.getSchoolPaymentOptions(this.props.preVoucher.school_sap_code)
      this.props.insertStudent(this.refs.name.value)
      this.props.navigate('/steps/selecionar-livros')
    }
  }

  render() {
    const { preVoucher, error } = this.props

    return (
      <div className="voucher">
        <div className="content">
          <div className="voucher__slider">
            <div className={`voucher__slider__content ${this.state.activeSlider}`}>
              <div className="voucher__insert--voucher">
                <h1 className="voucher__title">
                  Insira o <span className="voucher__highlighted">número do voucher</span> que recebeu da escola para
                  iniciar a compra
                </h1>
                <input
                  id="homepage-voucher-input"
                  autoFocus
                  className="voucher__input"
                  type="text"
                  placeholder="Digite aqui o número do voucher"
                  ref="voucher"
                  autoComplete="off"
                  onKeyPress={e => {
                    if (e.key === 'Enter') this._handleNext.apply(this)
                  }}
                />
                <button
                  id="homepage-next-button"
                  className="voucher__button button__big"
                  onClick={this._handleNext.bind(this)}
                >
                  Próximo
                </button>
                <Tooltip show={this.state.showTooltip} hidesAfter={10000} className="voucher__tooltip">
                  <span className="voucher__tooltip__error">X</span>
                  <span className="voucher__tooltip__text">{error.message}</span>
                </Tooltip>
              </div>
              <div className={`voucher__insert--student ${this.state.activeSlider}`}>
                {preVoucher.voucher ? (
                  <div className="voucher__infos">
                    <div className="voucher__infos__logo">
                      <img src={preVoucher.school_logo} alt="" />
                    </div>
                    <span className="voucher__infos__school">{preVoucher.school_name}</span> |
                    <span className="voucher__infos__address">{preVoucher.school_address}</span> -
                    <span className="voucher__infos__degree">
                      {preVoucher.study_year} {preVoucher.study_step}
                    </span>
                    {this.state.hideInput && this.state.nextSlideErrorMessage === VOUCHER_NOT_ACTUAL_CAMPAIGN ? (
                      <span className="voucher__expired-text">{preVoucher.error.message}</span>
                    ) : null}
                    {this.state.hideInput && this.state.nextSlideErrorMessage === VOUCHER_VALID_DATE ? (
                      <span className="voucher__expired-text">{preVoucher.error.message}</span>
                    ) : null}
                    {this.state.hideInput && this.state.nextSlideErrorMessage === VOUCHER_EXPIRED ? (
                      <span className="voucher__expired-text">
                        Voucher expirado em <span className="voucher__infos__date">{preVoucher.contract_end} </span>
                      </span>
                    ) : (
                      <div>
                        Voucher <span className="voucher__infos__number">{preVoucher.voucher}</span>.
                      </div>
                    )}
                    {this.state.hideInput && this.state.nextSlideErrorMessage === VOUCHER_IS_INACTIVE ? (
                      <span className="voucher__expired-text">
                        Este voucher está inativo, pois o prazo de compras foi encerrado.
                      </span>
                    ) : null}
                  </div>
                ) : !preVoucher.voucher && this.state.nextSlideErrorMessage === VOUCHER_NOT_EXIST ? (
                  <div className="voucher__infos">
                    <span className="voucher__expired-text">{preVoucher.error.message}</span>
                  </div>
                ) : null}
                {!this.state.hideInput ? (
                  <div>
                    <h1 className="voucher__title">
                      Qual o <span className="voucher__highlighted">nome do aluno</span> que usará os produtos?
                    </h1>
                    <input
                      id="homepage-student-name-input"
                      className="voucher__input"
                      type="text"
                      placeholder="Digite aqui o nome do aluno"
                      ref="name"
                      autoComplete="off"
                      onKeyPress={e => {
                        if (e.key === 'Enter') this._handleBuyButton.apply(this, [e])
                      }}
                    />
                    <button
                      id="homepage-buy-button"
                      to="/steps/selecionar-livros"
                      className="voucher__button button__big"
                      onClick={this._handleBuyButton.bind(this)}
                    >
                      Comprar
                    </button>
                    <Tooltip show={this.state.showTooltip} hidesAfter={10000} className="voucher__tooltip">
                      <span className="voucher__tooltip__error">X</span>
                      {this.state.tooltipErrorMessage}
                    </Tooltip>
                    <span className="voucher__info">O pacote será identificado na escola pelo nome do aluno</span>
                  </div>
                ) : null}
                {this.state.nextSlideErrorMessage === VOUCHER_IS_INACTIVE ? (
                  <span className="voucher__info--warn">
                    Para mais informações, entre em contato:
                    <a className="voucher__info--warn--link" href="mailto:smdireto@grupo-sm.com">
                      smdireto@grupo-sm.com
                    </a>
                  </span>
                ) : (
                  <NavLink className="voucher__info--warn" to="/faq">
                    Para saber mais, clique aqui e acesse nossa área de ajuda.
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  preVoucher: state.preVoucher,
  error: state.preVoucher.error,
  students: state.checkout,
})

const mapDispatchToProps = dispatch => ({
  removeStudent : index => dispatch(removeStudent(index)),
  requestVoucher: voucher => dispatch(requestVoucher(voucher)),
  insertStudent: student => dispatch(insertStudent(student)),
  updateActiveVoucher: (voucher, name) => dispatch(updateActiveVoucher(voucher, name)),
  clear: () => dispatch(clearVoucher()),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  getSchoolPaymentOptions: schoolSapCode => dispatch(fetchSchoolPaymentOptions(schoolSapCode)),
  clearSpecialDiscount: () => dispatch(clearSpecialDiscount()),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  removeStudent: dispatchProps.removeStudent,
  preVoucher: stateProps.preVoucher,
  error: stateProps.error,
  students: stateProps.students,
  requestVoucher: dispatchProps.requestVoucher,
  clearVoucher: dispatchProps.clear,
  insertStudent: (name, path) => {
    dispatchProps.insertStudent({ ...stateProps.preVoucher, name })
    dispatchProps.updateActiveVoucher(stateProps.preVoucher.voucher, name)
    dispatchProps.clear()
  },
  openLoader: dispatchProps.openLoader,
  closeLoader: dispatchProps.closeLoader,
  navigate: ownProps.navigate,
  getSchoolPaymentOptions: dispatchProps.getSchoolPaymentOptions,
  clearSpecialDiscount: dispatchProps.clearSpecialDiscount,
  navigate: ownProps.navigate,
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Voucher))

