import _ from 'underscore'
import { connect } from 'react-redux'

const RolesChecker = ({ roles, sessionReducer, negate = false, ...props }) => {
  let userRoles = sessionReducer.user.roles
  let hasPermission = false
  userRoles = userRoles.map(role => role.name.trim().toLowerCase())

  roles = roles.map(role => role.trim().toLowerCase())

  if (_.isEmpty(roles)) return props.children

  userRoles.map(role => {
    if (_.contains(roles, role)) {
      hasPermission = true
    }

    return role
  })

  if ((hasPermission && !negate) || (negate && !hasPermission)) {
    return props.children
  } else {
    return null
  }
}

const mapStateToProps = state => ({
  sessionReducer: state.sessionReducer,
})

export default connect(mapStateToProps)(RolesChecker)
