import {
  getDefaultData,
  schoolContractDiscount as getDiscount,
  getSchoolPaymentOptions as getSchoolPaymentOptionsAPI,
  schoolContractUpdateDiscount as saveDiscount,
  updateContract as updateContractAPI,
  updateDeliveryType as updateDeliveryTypeAPI,
  validateContract as validateContractAPI,
} from '@services/sm-api.js'

import _ from 'lodash'
import { getCampaigns } from '@components/ReportFilter/options'
import smAuth from '@services/sm-auth'

export const FETCH_SCHOOL = 'school/FETCH_SCHOOL'
export const FETCH_SCHOOLS = 'school/FETCH_SCHOOLS'
export const UPDATE_CONTRACT_DATE = 'school/UPDATE_CONTRACT_DATE'
export const POST_CONTRACT_DATE = 'school/POST_CONTRACT_DATE'
export const ERROR_CONTRACT_DATE = 'school/ERROR_CONTRACT_DATE'
export const CLEAR_ERROR = 'school/CLEAR_ERROR'
export const FETCH_SCHOOL_PAYMENT_OPTIONS = 'school/FETCH_SCHOOL_PAYMENT_OPTIONS'
export const UPDATE_DELIVERY_TYPE = 'school/UPDATE_DELIVERY_TYPE'
export const ERROR_UPDATE_DELIVERY_TYPE = 'school/ERROR_UPDATE_DELIVERY_TYPE'

export function getSchools(payload) {
  return {
    type: FETCH_SCHOOLS,
    payload,
  }
}

export function getSchool(payload) {
  return {
    type: FETCH_SCHOOL,
    payload,
  }
}

export function clearError() {
  return {
    type: CLEAR_ERROR,
  }
}

export function fetchSchools() {
  return (dispatch, getState) => {
    return smAuth.getSchoolList().then(response => {
      const schools = response.map((school, index) => {
        school.label = school.fantasy_name
        school.value = school.id
        school.key = index
        delete school.id
        delete school.fantasy_name
        delete school.social_name
        return school
      })

      return dispatch(getSchools(schools))
    })
  }
}

export function fetchSchool(school_id, campaign_id) {
  return async (dispatch, getState) => {
    let campaignId = campaign_id

    if (typeof campaign_id === 'undefined') {
      const campaings = await getCampaigns()
      campaignId = _.find(campaings, { active: 1 }).id
    }

    return getDefaultData(`/contracts/schools/${school_id}/detail?campaign_id=${campaignId}`).then(response =>
      dispatch(getSchool(response.school)),
    )
  }
}

export function postContract() {
  return {
    type: POST_CONTRACT_DATE,
  }
}

export function updateContract(payload) {
  return {
    type: UPDATE_CONTRACT_DATE,
    payload,
  }
}

export function failureContract(error) {
  return {
    type: ERROR_CONTRACT_DATE,
    error,
  }
}

export function updateDelivery(payload) {
  return {
    type: UPDATE_DELIVERY_TYPE,
    payload,
  }
}

export function failureUpdateDeliveryType(error) {
  return {
    type: ERROR_UPDATE_DELIVERY_TYPE,
    error,
  }
}

export function updateContractDate(data) {
  return dispatch => {
    dispatch(postContract())
    return updateContractAPI(data.id, {
      date_start: data.date_start,
      date_end: data.date_end,
      cut_dates: data.cut_dates,
      disable_dates_sync: data.disable_sync,
      is_b2c: data.is_b2c,
    }).then(response => {
      if (response.contracts.code > 300) {
        dispatch(failureContract(response.contracts))
        throw new Error(response.contracts.message)
      } else {
        dispatch(updateContract(response))
      }
    })
  }
}

export function updateDeliveryType(data) {
  return dispatch => {
    return updateDeliveryTypeAPI(data.id, {
      ef1: data.ef1,
      ef2: data.ef2,
      em: data.em,
      ei: data.ei,
      disable_period_sync: data.disable_period_sync,
    }).then(response => {
      if (response.contracts.code > 300) {
        dispatch(failureUpdateDeliveryType(response.contracts))
        throw new Error(response.contracts.message)
      } else {
        dispatch(updateDelivery(response))
      }
    })
  }
}

export function fetchSchoolPaymentOptions(school_sap_code) {
  return dispatch => {
    return getSchoolPaymentOptionsAPI(school_sap_code)
      .then(response => {
        dispatch({
          type: FETCH_SCHOOL_PAYMENT_OPTIONS,
          payload: response,
        })
        return response
      })
      .catch(error => {
        throw error
      })
  }
}

export function validateContract(data) {
  return dispatch => {
    return validateContractAPI(data)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}

export function fetchDiscount(id) {
  return dispatch => {
    return getDiscount(id)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}

export function updateDiscount(contractId, discount) {
  return dispatch => {
    return saveDiscount(contractId, discount)
      .then(response => {
        return response
      })
      .catch(error => {
        throw error
      })
  }
}
