import { closeLoader, openLoader } from '@actions/loader.js'
import { setToken, userCreatedMailSend } from '@services/sm-api'

import _ from 'underscore'
import { createSelector } from 'reselect'
import menuPermissions from '@components/Header/permissions.js'
/**
 * Dependencies
 */
import { sessionService } from 'redux-react-session'
import smAuth from '@services/sm-auth.js'
import { userPermissions } from '@components/EditUser/permissions'

/**
 * Actions types
 */
export const FIND_USER = 'user/VERIFY'
export const FAILURE_USER = 'user/FAILURE'
export const REGISTER_USER = 'user/REGISTER'
export const SUCCEED_USER = 'user/SUCCEED'
export const USER_ALREADY_EXISTS = 'user/USER_ALREADY_EXISTS'
export const DESTROY = 'user/DESTROY'
export const SUCCEED_UPDATE = 'user/SUCCEED_UPDATE'
export const FAILURE_UPDATE = 'user/FAILURE_UPDATE'
export const SUCCEED_DELETE = 'user/SUCCEED_DELETE'
export const FAILURE_DELETE = 'user/FAILURE_DELETE'
export const SUCCEED_ENABLE = 'user/SUCCEED_ENABLE'
export const FAILURE_ENABLE = 'user/FAILURE_ENABLE'

/**
 * Actions creators
 */
export function findUser() {
  return {
    type: FIND_USER,
  }
}

export function failureUser(error) {
  return {
    type: FAILURE_USER,
    message: error,
  }
}

export function succeedUser(payload) {
  return {
    type: SUCCEED_USER,
    payload,
  }
}

export function succeedUpdate() {
  return {
    type: SUCCEED_UPDATE,
  }
}

export function failureUpdate() {
  return {
    type: FAILURE_UPDATE,
  }
}

export function succeedDelete() {
  return {
    type: SUCCEED_DELETE,
  }
}

export function failureDelete() {
  return {
    type: FAILURE_DELETE,
  }
}

export function succeedEnable() {
  return {
    type: SUCCEED_ENABLE,
  }
}

export function failureEnable() {
  return {
    type: FAILURE_ENABLE,
  }
}

export function failureRegister(error) {
  return {
    type: REGISTER_USER,
    message: error,
  }
}

export function consultUser(error) {
  return {
    type: USER_ALREADY_EXISTS,
    error,
  }
}

export function destroyUserSession() {
  sessionService.deleteSession()
  sessionService.deleteUser()
  return {
    type: DESTROY,
  }
}

export function destroy() {
  sessionService.deleteSession()
  sessionService.deleteUser()

  const itemToPreserve = localStorage.getItem('modalShown');
  window.localStorage.clear()
  localStorage.setItem('modalShown', itemToPreserve);

  return {
    type: DESTROY,
  }
}

export function getUserByEmail(email) {
  return dispatch => {
    return smAuth.getUserByEmail(email).then(response => {
      if (response.data) {
        dispatch(consultUser(response.data.message))
      } else {
        dispatch(consultUser())
      }
    })
  }
}

export function requestRegister(user, isAdminOperation = false) {
  return dispatch => {
    dispatch(openLoader())

    if (!isAdminOperation) {
      delete user.role_id

      for (let permission of userPermissions) {
        delete user[permission.name]
      }
    }

    return smAuth.handleRegister(user).then(async response => {
      dispatch(closeLoader())
      if (response.data.code) {
        let results = response.data.results
        if (results) {
          userCreatedMailSend({ email: user.email, name: user.full_name })
          await results
          return results
        }
      }
    })
  }
}

export function getSchools() {
  smAuth.getSchoolList()
}

export function requestLogin(user) {
  return (dispatch, getState) => {
    dispatch(findUser)
    dispatch(openLoader())

    return smAuth.handleLogin(user).then(async response => {
      const data = response.data
      let token = data.token
      setTimeout(() => {
        dispatch(closeLoader())
      }, 1000)
      if (token) {
        sessionService.saveSession({ token })
        data.user.persitSession = user.persitSession
        await sessionService.saveUser(data.user)
        setToken(token)
        return dispatch(succeedUser(data))
      } else {
        sessionService.deleteSession()
        sessionService.deleteUser()
        return dispatch(failureUser(data))
      }
    })
  }
}

export function requestUpdate(user) {
  return dispatch => {
    if (user.token) smAuth.setToken(user.token)
    delete user['confirm_password']
    delete user['token']
    return smAuth
      .handleUpdate(user, 'update')
      .then(response => {
        if (response.data.status) {
          dispatch(succeedUpdate())
        } else {
          dispatch(failureUpdate())
        }
      })
      .catch(() => dispatch(failureUpdate()))
  }
}

export function requestDelete(user) {
  return dispatch => {
    return smAuth
      .handleUpdate(user, 'delete')
      .then(response => {
        if (response.data.status === 1) {
          dispatch(succeedDelete())
        } else {
          dispatch(failureDelete())
        }
      })
      .catch(() => dispatch(failureUpdate()))
  }
}

export function requestEnable(user) {
  return dispatch => {
    return smAuth
      .handleUpdate(user, 'enable')
      .then(response => {
        if (response.data.status === 1) {
          dispatch(succeedEnable())
        } else {
          dispatch(failureEnable())
        }
      })
      .catch(() => dispatch(failureUpdate()))
  }
}

export function getUserData(user) {
  const storage = JSON.parse(window.localStorage.getItem('reduxPersist:user'))
  const token = storage.token
  smAuth.setToken(token)
  return smAuth
    .handleGetUserInformation(user)
    .then(response => {
      if (response.data.value) {
        return response.data.value
      }
    })
    .catch(error => console.log(error))
}

/**
 * Selectors
 */
export const menu = createSelector([state => state.sessionReducer.user], ({ roles = [], permissions = [] }) => {
  let newMenu = menuPermissions.filter((item, index) => {
    let diff = _.difference(permissions, item.permissions)
    return diff.length !== permissions.length
  })

  newMenu.map(item => {
    item.items = item.items.filter(subitem => {
      let diff = _.difference(permissions, subitem.permissions)
      return diff.length !== permissions.length
    })

    return item
  })

  return newMenu
})

