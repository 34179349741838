import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'

import FaqHero from '@components/FaqHero'
import SpecialDiscount from '@components/SpecialDiscount'
import Voucher from '@components/Voucher'
import bus from './images/bus.png'
import cloud_1 from './images/cloud_1.png'
import cloud_2 from './images/cloud_2.png'
import cloud_3 from './images/cloud_3.png'
import cloud_4 from './images/cloud_4.png'
import cloud_5 from './images/cloud_5.png'
import cloud_6 from './images/cloud_6.png'
import school from './images/school.png'
import trafficLight from './images/semaforo.png'

const Hero = ({ activeNotification }) => {
  const [activeBus, setActiveBus] = useState('')

  const location = useLocation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveBus('active')
    }, 500)

    return () => clearTimeout(timeout)
  }, [])

  const showSwitch = () => {
    if (activeNotification && activeNotification.block_checkout) {
      return <FaqHero />
    }

    switch (location.pathname) {
      case '/':
        return <Voucher />
      case '/faq':
        return <FaqHero />
      case '/desconto-professor':
        return <SpecialDiscount />
      default:
        return null
    }
  }

  return (
    <div className="hero">
      <div className="content">
        <img className="hero__cloud" src={cloud_1} alt="Nuvem" />
        <img className="hero__cloud" src={cloud_2} alt="Nuvem" />
        <img className="hero__cloud" src={cloud_3} alt="Nuvem" />
        <img className="hero__cloud" src={cloud_4} alt="Nuvem" />
        <img className="hero__cloud" src={cloud_5} alt="Nuvem" />
        <img className="hero__cloud" src={cloud_6} alt="Nuvem" />
        <div className="traffic-light">
          <img src={trafficLight} alt="Semáforo" />
        </div>
        <div className={`bus ${activeBus}`}>
          <img src={bus} alt="Onibus" />
        </div>
        {showSwitch()}
        <div className="hero__school">
          <img src={school} alt="Escola" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    activeNotification: state.activeNotification,
  }
}

export default connect(mapStateToProps)(Hero)

