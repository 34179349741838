import _ from 'underscore'
import { connect } from 'react-redux'

const PermissionsChecker = ({ permissions, sessionReducer, negate = false, ...props }) => {
  let userPermissions = sessionReducer.user.permissions
  let hasPermission = false

  permissions = permissions.map(role => role.trim().toLowerCase())

  userPermissions.map(role => {
    if (_.contains(permissions, role)) {
      hasPermission = true
    }

    return role
  })

  if ((hasPermission && !negate) || (negate && !hasPermission)) {
    return props.children
  } else {
    return null
  }
}

const mapStateToProps = state => ({
  sessionReducer: state.sessionReducer,
})

export default connect(mapStateToProps)(PermissionsChecker)
