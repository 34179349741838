import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import exit from './images/exit.png'
import { destroy, menu } from '@actions/user'
import { resetTheme } from '@actions/theme'
import { withRouter } from '../../utils/withRouter'

class AdminMenu extends Component {
  render() {
    const { user, menu, destroy } = this.props

    const isSchoolUser = !!user.school_id

    return (
      <div className="admin-menu">
        <div className="admin-menu__user">
          <h1 className="admin-menu__title">{user.full_name}</h1>
          <NavLink to={`/admin/edit/${user.id}`} className="admin-menu__edit">
            Editar perfil
          </NavLink>
          <hr />
        </div>
        <div className="admin-menu__list">
          <ul className="admin-menu__items">
            {menu.map((item, index) => (
              <li className="admin-menu__item" key={index}>
                <img className="admin-menu__icon" src={item.icon} alt="" />
                {item.notClickable ? (
                  <span className="admin-menu__item-text--no-hover">{item.name}</span>
                ) : (
                  <NavLink
                    to={`/admin/${item.url}`}
                    className={({ isActive }) => 'admin-menu__item-text' + (isActive ? ' selected' : '')}
                  >
                    {item.name}
                  </NavLink>
                )}
                <ul className="admin-menu__items--subitem">
                  {item.items.map((subitem, index) => (
                    <li className="admin-menu__item--subitem" key={index}>
                      {!isSchoolUser && subitem.name === 'Vouchers' ? null : (
                        <NavLink
                          to={`/admin/${item.url}/${subitem.url}`}
                          className={({ isActive }) => 'admin-menu__item-text' + (isActive ? ' selected' : '')}
                        >
                          {subitem.name}
                        </NavLink>
                      )}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
            <li className="admin-menu__item">
              <img className="admin-menu__icon" src={exit} alt="" />
              <a
                className="admin-menu__item-text"
                onClick={() => {
                  destroy()
                  this.props.resetTheme()
                  this.props.navigate('/')
                }}
              >
                Sair
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.sessionReducer.user,
  menu: menu(state),
})

const mapDispatchToProps = dispatch => ({
  destroy: () => dispatch(destroy()),
  resetTheme: () => dispatch(resetTheme()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminMenu))

