import { Form, Text } from 'react-form'
import React, { Component } from 'react'
import { Swap, SwapItem } from '@components/Swap'
import { clear, resetPassword } from '@actions/resetPassword'
import { closeLoader, openLoader } from '@actions/loader'

import { InputCheckbox, InputPassword } from '@shared/CustomField'
import { closeModal } from '@actions/modal'
import { connect } from 'react-redux'
import { requestLogin } from '@actions/user'
import { withRouter } from '../../utils/withRouter'

class LoginModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: props.active === 'forgot' ? 'forgot' : 'login',
      blocked: props.blocked,
      embedded: props.embedded,
      disableSubmit: false,
    }
    this._handleSubmit = this._handleSubmit.bind(this)
    this._handleValidation = this._handleValidation.bind(this)
    this._handleRemindPassword = this._handleRemindPassword.bind(this)
    this._handleBackButton = this._handleBackButton.bind(this)
  }

  _handleSubmit(values) {
    this.props.requestLogin(values).then(response => {
      this.props.navigate('/admin')
      if (response.message) {
        this.refs.Form.state.errors = {
          email: response.message.type === 'email' ? response.message.error : '',
          password: response.message.type === 'password' ? response.message.error : '',
        }
        this.refs.Form.setAllTouched()

        if (response.message.type === 'blocked') {
          this.setState({
            blocked: true,
          })
        }
      } else {
        this.props.closeModal()
      }
    })
  }

  _handleRemindPassword(values) {
    this.props.openLoader()
    this.setState({ disableSubmit: true })
    this.props
      .resetPassword(values)
      .then(() => {
        this.setState({ disableSubmit: false })
        this.props.closeLoader()
      })
      .catch(error => {
        this.refs.Form.state.errors = {
          email: 'Não foi possível encontrar sua conta.',
        }
        this.setState({ disableSubmit: false })
        this.refs.Form.setAllTouched()
        this.props.closeLoader()
      })
  }

  _handleValidation({ email = '', password = '' }) {
    return {
      email: email.length === 0 ? 'Por favor, digitar o email do usuário' : undefined,
      password: password.length === 0 ? 'Por favor, digitar a senha.' : undefined,
    }
  }

  _handleRemindValidation({ email = '' }) {
    return {
      email: email.length === 0 ? 'Por favor, digitar o email do usuário' : undefined,
    }
  }

  _handleBackButton() {
    this.props.clear()
    this.setState({ active: 'login' })
    this.state.embedded && this.props.closeModal()
  }

  render() {
    const { closeModal, reset } = this.props

    return (
      <div className={`login-modal ${this.state.blocked && this.state.active === 'login' ? 'blocked' : ''}`}>
        <Swap active={this.state.active}>
          <SwapItem name="login">
            <span className="login-modal__close" onClick={closeModal}>
              X
            </span>
            {this.state.blocked ? (
              <h3 className="login-modal__title">Acesso bloqueado</h3>
            ) : (
              <h3 className="login-modal__title">Entrar</h3>
            )}
            <Form
              ref="Form"
              onSubmit={this._handleSubmit}
              validate={this._handleValidation}
              defaultValues={{ persitSession: 1 }}
            >
              {({ values, submitForm, getError, setAllTouched, ...rest }) => {
                return (
                  <form onSubmit={submitForm} className="login-modal__form">
                    {this.state.blocked && (
                      <div className={`field login-modal__field ${this.state.blocked && 'blocked'}`}>
                        O acesso foi bloqueado por excesso de tentativas. Utilize o link{' '}
                        <strong>Esqueci minha senha</strong> abaixo para prosseguir.
                      </div>
                    )}
                    {!this.state.blocked && (
                      <div className="field login-modal__field">
                        <Text
                          className="field__text"
                          field="email"
                          placeholder="digite seu e-mail"
                          autoComplete="off"
                        />
                      </div>
                    )}
                    {!this.state.blocked && (
                      <div className="field login-modal__field">
                        <InputPassword placeholder="digite sua senha" field="password" />
                      </div>
                    )}
                    <div className="field login-modal__field--checkbox">
                      {!this.state.blocked && (
                        <InputCheckbox
                          className="select-book__list__checkbox login-modal__checkbox"
                          field="persitSession"
                        />
                      )}
                      {!this.state.blocked && (
                        <label htmlFor="persitSession" className="login-modal__persist">
                          Permanecer conectado
                        </label>
                      )}
                      <span
                        className={`login-modal__forgot ${this.state.blocked && 'blocked'}`}
                        onClick={() => this.setState({ active: 'forgot' })}
                      >
                        Esqueci minha senha
                      </span>
                    </div>
                    {!this.state.blocked && (
                      <div className="field login-modal__field--button">
                        <button className="button__normal login-modal__button" type="submit">
                          Entrar
                        </button>
                      </div>
                    )}
                  </form>
                )
              }}
            </Form>
          </SwapItem>
          <SwapItem name="forgot">
            {/*
              reset.code !== '200' ?
                <span className="login-modal__back" onClick={() => this.setState({ active: 'login' })}> voltar </span>
              : null
            */}
            <span className="login-modal__close" onClick={closeModal}>
              X
            </span>
            <h3 className="login-modal__title">Esqueci minha senha</h3>
            <Form ref="Form" onSubmit={this._handleRemindPassword} validate={this._handleRemindValidation}>
              {({ values, submitForm, getError, setAllTouched }) => {
                return (
                  <form onSubmit={submitForm} className="login-modal__form">
                    <div className="field login-modal__field">
                      <Text className="field__text" field="email" placeholder="digite seu e-mail" autoComplete="off" />
                    </div>
                    <div className="field login-modal__field--button">
                      <button
                        type="button"
                        className="button__normal login-modal__button--grey"
                        onClick={this._handleBackButton}
                      >
                        Voltar
                      </button>
                      {reset.code === '200' ? (
                        <span className="login-modal__message--success">
                          Senha alterada com sucesso, verifique seu email.
                        </span>
                      ) : (
                        <button
                          className="button__normal login-modal__button"
                          type="submit"
                          disabled={this.state.disableSubmit}
                        >
                          Gerar nova senha
                        </button>
                      )}
                    </div>
                  </form>
                )
              }}
            </Form>
          </SwapItem>
        </Swap>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.sessionReducer,
  reset: state.resetPassword,
})

const mapDispatchToProps = {
  requestLogin,
  closeModal,
  resetPassword,
  clear,
  openLoader,
  closeLoader,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginModal))

