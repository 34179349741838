import React from 'react'

export const SimpleCard = ({ title, value, color, className, position, children }) => {
  return (
    <div className={`simplecard ${className}`}>
      {position === 'top' ? (
        <div>
          <span className="simplecard__title">{title}</span>
          <span className="simplecard__value" style={{ color: color }}>
            {value}
          </span>
        </div>
      ) : (
        <div>
          <span className="simplecard__value" style={{ color: color }}>
            {value}
          </span>
          <span className="simplecard__title">{title}</span>
        </div>
      )}
      {children}
    </div>
  )
}

export default { SimpleCard }
