import React, { Component } from 'react'

export default class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openMenu: false,
      resize: false,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.setState({
        toggleMenuContent: '',
      })
    })
  }

  toggleMenu(event) {
    event.preventDefault()
    this.setState({
      openMenu: !this.state.openMenu,
      toggleMenuContent: this.state.openMenu ? 'close' : 'open',
    })
  }

  render() {
    let { theme } = this.props
    let themeColor = {
      style: {
        backgroundColor: theme.text,
      },
    }
    let toggleMenu = this.state.openMenu ? 'active' : ''
    return (
      <div className="container-menu">
        <a
          href="#"
          className={`container-menu__toggle ${toggleMenu}`}
          onClick={event => {
            this.toggleMenu(event)
          }}
        >
          <span {...themeColor}></span>
          <span {...themeColor}></span>
          <span {...themeColor}></span>
        </a>
        <div
          className={`container-menu__content ${this.state.toggleMenuContent}`}
          style={{ backgroundColor: this.props.backgroundColor, width: this.props.width }}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}
