import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App/index'
import './css/index.css'

import { applyMiddleware, createStore, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import { persistStore, autoRehydrate } from 'redux-persist'
import { sessionService } from 'redux-react-session'
import { BrowserRouter } from 'react-router-dom'

import thunkMiddleware from 'redux-thunk'
import { Provider } from 'react-redux'
import { createLogger } from 'redux-logger'
// import { createBrowserHistory } from 'history'

import reducers from './reducers'
const logger = createLogger({
  collapsed: true,
})

const remove_presist = () => {
  window.localStorage.removeItem('reduxPersist:activeNotification')
}

remove_presist()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
//const history = createBrowserHistory()

let middleware

if (process.env.NODE_ENV !== 'production') {
  middleware = composeEnhancers(applyMiddleware(thunkMiddleware, routerMiddleware(), logger), autoRehydrate())
} else {
  middleware = composeEnhancers(applyMiddleware(thunkMiddleware, routerMiddleware()), autoRehydrate())
}

const store = middleware(createStore)(reducers, {})

const renderApp = () =>
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
  )

persistStore(store, {}, () => renderApp())
sessionService.initSessionService(store)

