import {
  POST_RESET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
  FAILURE_RESET_PASSWORD,
  CLEAR_RESET_PASSWORD,
} from '@actions/resetPassword'
import { REHYDRATE } from 'redux-persist/constants'

export default (state = { isPosting: false }, action) => {
  switch (action.type) {
    case POST_RESET_PASSWORD:
      return { ...state, isPosting: true }

    case SUCCESS_RESET_PASSWORD:
      return { ...state, ...action.payload, isPosting: false }

    case FAILURE_RESET_PASSWORD:
      return { ...state, ...action.payload, isPosting: false }

    case CLEAR_RESET_PASSWORD:
      return { isPosting: false }

    case REHYDRATE:
      return { isPosting: false }

    default:
      return state
  }
}
