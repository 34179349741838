import React, { Component } from 'react'

import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import StudentList from '@components/StudentList'
import { activateVoucherForm } from '@actions/voucherForm'
import { connect } from 'react-redux'
import { resetWarning } from '@actions/checkout'
import { updateActiveVoucher } from '@actions/activeVoucher'

export class ConfirmProductsContainer extends Component {
  _handleEdit(student) {
    this.props.updateVoucher(student)
    this.props.resetWarning(this.props.activeVoucher)
  }

  render() {
    const { checkout } = this.props

    return (
      <div className="confirmbooks">
        <div className="confirmbooks__header">
          <h1 className="confirmbooks__title">Confirmação de compra</h1>
          <h2 className="confirmbooks__subtitle">Você confirma a informação abaixo?</h2>
        </div>
        <StudentList students={checkout} edit={student => this._handleEdit(student)} />
        <div className="confirmbooks__buttons">
          <NavLink
            to="/steps/selecionar-livros"
            className="button button__normal button-not-filled confirmbooks__backbutton test-backbtn"
          >
            Voltar
          </NavLink>
          <NavLink
            id="confirm-products-confirm-button"
            to="/steps/identificacao"
            className="button button__normal confirmbooks__confirmbutton test-confirmbtn"
          >
            Confirmar
          </NavLink>
        </div>
      </div>
    )
  }
}

ConfirmProductsContainer.propTypes = {
  checkout: PropTypes.array,
  activeVoucher: PropTypes.object,
  updateVoucher: PropTypes.func,
  resetWarning: PropTypes.func,
}

const mapStateToProps = state => ({
  checkout: state.checkout,
  activeVoucher: state.activeVoucher,
})

const mapDispatchToProps = dispatch => ({
  updateVoucher: voucher => {
    dispatch(activateVoucherForm(false))
    dispatch(updateActiveVoucher(voucher.voucher, voucher.name))
  },
  resetWarning: activeVoucher => dispatch(resetWarning(activeVoucher)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmProductsContainer)
