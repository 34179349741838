import React, { Component } from 'react'
import { sendContactForm } from '@services/sm-api'
import FaqList from '@components/FaqList'
import FaqAnswer from '@components/FaqAnswer'
import FaqContact from '@components/FaqContact'

class Faq extends Component {
  state = {
    question: '',
    answer: '',
    sendStatus: null,
    showCategories: true,
    showAnswer: false,
    showContactForm: false,
  }

  _getQuestion(question, answer) {
    this.setState({
      question,
      answer,
      sendStatus: null,
    })
    this._changeFragment('showAnswer')
  }

  _changeFragment(view) {
    this.setState({
      showAnswer: false,
      showCategories: false,
      showContactForm: false,
    })
    this.setState({ [view]: true })
  }

  _send(fields) {
    sendContactForm('/contact-form', fields)
      .then(response => {
        if (response.otrs_id) {
          this.setState({
            sendStatus: 1,
          })
        }
      })
      .catch(error => {
        this.setState({
          sendStatus: 0,
        })
      })
  }

  closeAnswer = () => {
    this.setState({ showAnswer: false, showCategories: true })
  }

  render() {
    return (
      <div className="faq">
        {this.state.showContactForm && (
          <FaqContact
            sendStatus={this.state.sendStatus}
            send={fields => this._send(fields)}
            title="Diga como podemos ajudar?"
          />
        )}

        {this.state.showCategories && (
          <FaqList
            className="faq__list"
            title="Navegue pelos temas"
            getQuestion={(question, answer) => this._getQuestion(question, answer)}
          />
        )}

        {this.state.showAnswer && (
          <FaqAnswer
            onBack={this.closeAnswer}
            question={this.state.question}
            answer={this.state.answer}
            showForm={() => this._changeFragment('showContactForm')}
          />
        )}
      </div>
    )
  }
}

export default Faq

