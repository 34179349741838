import { Navigate, NavLink } from 'react-router-dom'
import React, { Component } from 'react'

import AlertModal from '../AlertModal'
import LoginModal from '../LoginModal'
import { connect } from 'react-redux'
import { openModal, closeModal } from '@actions/modal'
import { withRouter } from '../../utils/withRouter'

class MainMenu extends Component {
  state = {
    redirectToAdmin: false,
  }

  _handleLoginModal(e) {
    e.preventDefault()
    if (Object.keys(this.props.loggedUser).length) {
      this.setState({ redirectToAdmin: true })
    } else {
      this.props.openModal(<LoginModal />)
    }
  }

  componentDidUpdate() {
    window.addEventListener('beforeunload', event => {
      if (event.type === 'beforeunload') {
        this.props.openModal()
      }
    })
    const notificationIsShowed = !!localStorage.getItem('notificationIsShowed')

    // Exibição do alerta apenas na páginas inicial e somente uma vez.
    if (
      !notificationIsShowed &&
      this.props.activeNotification &&
      ['/', '/desconto-professor'].includes(this.props.location.pathname)
    ) {
      localStorage.setItem('notificationIsShowed', 'showed')
      this.props.openModal(<AlertModal notification={this.props.activeNotification} />)
    }
  }

  render() {
    let { loggedUser, theme } = this.props
    let linkColor = {
      style: {
        color: theme.text,
      },
    }

    return (
      <div className="main-menu">
        {this.state.redirectToAdmin && <Navigate to="/admin" />}
        <ul className="main-menu__list">
          <li className="main-menu__item">
            <a {...linkColor} href="/" className="main-menu__link">
              início
            </a>
          </li>
          <li className="main-menu__item">
            <a
              {...linkColor}
              href="http://www.edicoessm.com.br/#!/edicoessm"
              target="_blank"
              className="main-menu__link"
            >
              sobre
            </a>
          </li>
          <li className="main-menu__item">
            <NavLink {...linkColor} to="/faq" className="main-menu__link">
              ajuda
            </NavLink>
          </li>
        </ul>
        <ul className="main-menu__list--icon">
          <li className="main-menu__item--icon">
            <a href="#" onClick={e => this._handleLoginModal(e)} {...linkColor}>
              <i className="fa fa-user fa-lg"></i>
              {typeof loggedUser.full_name !== 'undefined' ? (
                <span {...linkColor} className="main-menu__link--icon">{`Olá, ${loggedUser.full_name}`}</span>
              ) : (
                <span {...linkColor} className="main-menu__link--icon">
                  Entrar
                </span>
              )}
            </a>
          </li>
        </ul>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loggedUser: state.sessionReducer.user,
  theme: state.theme,
  activeNotification: state.activeNotification,
})

const mapDispatchToProps = {
  openModal,
  closeModal,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainMenu))

