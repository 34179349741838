import React, { Component } from 'react'
import { links } from './links'
import book from './images/book.png'
export default class StepsNavigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nextStepActive: '',
    }
  }

  render() {
    let { pathname } = this.props
    let activeIndex = null
    links.forEach((value, key) => {
      if (value.url === pathname) {
        activeIndex = key
      }
    })

    let items = links.map((step, index) => {
      let classNameItem = activeIndex > index ? 'past' : activeIndex === index ? 'current' : 'future'
      return (
        <li key={index} className={`steps__item ${classNameItem}`}>
          <div className="steps__position"></div>
          <div className="steps__bar"></div>
          <div className="steps__bar--success"></div>
          <div className="steps__description">{step.name}</div>
          <div className="steps__book">
            <img src={book} alt="Livro" />
          </div>
        </li>
      )
    })

    return (
      <div className="steps">
        <ul className="steps__list">{items}</ul>
      </div>
    )
  }
}
