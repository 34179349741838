import React, { Component } from 'react'
import selection_books from './images/selection_books.png'
import payment from './images/payment.png'
import receipt from './images/receipt.png'

export default class Instructions extends Component {
  render() {
    return (
      <div className="instructions">
        <h2 className="instructions__title">Veja como é fácil comprar</h2>
        <ul className="instructions__list">
          <li className="instructions__item">
            <div className="instructions__header">
              <span className="instructions__position">1</span>
              <h3 className="instructions__list__title">Seleção de produtos</h3>
            </div>
            <div className="instructions__body">
              <div className="instructions__image">
                <img src={selection_books} alt="Seleção de produtos" />
              </div>
              <p className="instructions__text">
                Insira o número do voucher, digite o nome do aluno e selecione os produtos.
              </p>
            </div>
          </li>
          <li className="instructions__item">
            <div className="instructions__header">
              <span className="instructions__position">2</span>
              <h3 className="instructions__list__title">Pagamento</h3>
            </div>
            <div className="instructions__body">
              <div className="instructions__image">
                <img src={payment} alt="Pagamento" />
              </div>
              <p className="instructions__text">Finalize seu cadastro e escolha a forma de pagamento.</p>
            </div>
          </li>
          <li className="instructions__item">
            <div className="instructions__header">
              <span className="instructions__position">3</span>
              <h3 className="instructions__list__title">Recebimento</h3>
            </div>
            <div className="instructions__body">
              <div className="instructions__image">
                <img src={receipt} alt="Recebimento" />
              </div>
              <p className="instructions__text">
                A entrega é feita na escola. Não se preocupe, ela te avisa quando os livros chegarem.
              </p>
            </div>
          </li>
        </ul>
        <a className="instructions__help" href="/faq">
          Ainda tem dúvidas, clique aqui que a gente te ajuda
        </a>
      </div>
    )
  }
}
