import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import './index.css'

const ClickOutsideDetector = ({ children, onOutsideClick }) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onOutsideClick()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onOutsideClick])

  return <span ref={wrapperRef}>{children}</span>
}

const SelectSchoolInput = ({ schools, value, setInputValue, setState }) => {
  const [list, setList] = useState(schools)
  const [showList, setShowList] = useState(false)

  const handleInputChange = e => {
    const { value } = e.target

    setInputValue(value)

    const normalizeString = str => str.toLowerCase().trim()
    const inputValue = value
    const normalizedInputValue = normalizeString(inputValue)
    const filteredSchools = schools.filter(item => normalizeString(item.label).includes(normalizedInputValue))

    setList(filteredSchools)
  }

  const handleSchoolSelect = school => {
    setState(school.value)
    setInputValue(school.label)
    setShowList(false)
  }

  const handleResetSearch = () => {
    setList(schools)
    setInputValue('')
    setShowList(false)
    setState(null)
  }

  return (
    <ClickOutsideDetector onOutsideClick={() => setShowList(false)}>
      <input
        className="summary__school-filter"
        type="text"
        value={value}
        placeholder="Selecione uma escola"
        onFocus={() => setShowList(true)}
        onChange={e => handleInputChange(e)}
      />
      {showList && (
        <div className="school-list">
          <div>
            {!list.length ? (
              <div>
                <button className="school-list__item" onClick={() => handleResetSearch()}>
                  Nenhum resultado
                </button>
              </div>
            ) : (
              list.map(school => (
                <div key={school.key}>
                  <button onClick={() => handleSchoolSelect(school)} className="school-list__item">
                    {school.label}
                  </button>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </ClickOutsideDetector>
  )
}

export default SelectSchoolInput

