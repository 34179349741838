import React from 'react'
import icon_alert from './images/alert.svg'

export const SchoolDetailModal = ({ title, message, closeModal, ...rest }) => {
  return (
    <div className="school_detail_modal">
      <div className="school_detail_modal_content">
        <div className="school_detail_modal_header">
          <img className="school_detail_modal_icone" src={icon_alert} alt="Ícone de alerta" />
          <h3 className="school_detail_modal_title">{title}</h3>
        </div>
        <div className="school_detail_modal_main">{message()}</div>
        <div className="school_detail_modal_footer">
          <button
            className="school_detail_modal_close"
            onClick={() => {
              closeModal()
            }}
          >
            Entendi
          </button>
        </div>
      </div>
    </div>
  )
}
