import { UPDATE_ACTIVE_VOUCHER } from '../actions/activeVoucher'

export default (state = null, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_VOUCHER:
      return { voucher: action.voucher, name: action.name }

    default:
      return state
  }
}
