import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'

import AdminMenu from '@components/Header/AdminMenu'
import { changeTheme } from '@actions/theme'
import { connect } from 'react-redux'

class Admin extends Component {
  UNSAFE_componentWillMount() {
    this._changeTheme()
  }

  _changeTheme() {
    let { sessionReducer, changeTheme } = this.props
    let colors = {
      text: sessionReducer.school_text_color,
      background: sessionReducer.school_theme_color,
    }

    changeTheme(colors)
  }

  render() {
    return (
      <div className="admin">
        <div className="admin__left">
          <AdminMenu />
        </div>
        <div className="admin__right">
          <Outlet />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  sessionReducer: state.sessionReducer.user,
})

const mapDispatchToProps = dispatch => ({
  changeTheme: colors => dispatch(changeTheme(colors)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Admin)

