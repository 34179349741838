import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { sessionReducer } from 'redux-react-session'

import activeVoucher from './activeVoucher'
import checkout from './checkout'
import contract from './contracts'
import creditCard from './creditCard'
import importContracts from './importContracts'
import loader from './loader'
import modal from './modal'
import order from './order'
import preVoucher from './preVoucher'
import report from './report'
import resetPassword from './resetPassword'
import school from './school'
import specialDiscount from './specialDiscount'
import summary from './summary'
import theme from './theme'
import ticket from './ticket'
import user from './user'
import voucherForm from './voucherForm'
import activeNotification from './activeNotification'
import freight from './freight'
import digitalSolutions from './digitalSolutions'
import vouchersInactivation from './vouchersInactivation'
import automaticInactivation from './automaticInactivation'

export default combineReducers({
  activeVoucher,
  checkout,
  routerReducer,
  modal,
  sessionReducer,
  specialDiscount,
  voucherForm,
  preVoucher,
  loader,
  user,
  creditCard,
  order,
  report,
  ticket,
  school,
  summary,
  contract,
  importContracts,
  resetPassword,
  theme,
  activeNotification,
  freight,
  digitalSolutions,
  vouchersInactivation,
  automaticInactivation,
})
