export const ACTIVATE = 'voucherForm/ACTIVATE_FORM'
export const ACTIVATE_STUDENT = 'voucherForm/ACTIVATE_STUDENT_FORM'

export function activateVoucherForm(show) {
  return {
    type: ACTIVATE,
    show,
  }
}

export function activateStudentForm(showStudentForm, index = null) {
  return {
    type: ACTIVATE_STUDENT,
    showStudentForm,
    index,
  }
}
