import React from 'react'

export const DigitalSolutionSMAP = props => {
  return (
    <div className="digital_solution_description">
      <p>
        <b>SMA+:</b> a solução SM Aprendizagem Plus (SMA+), possibilita ao colégio ter autonomia para gerenciar
        eficazmente e de forma centralizada a configuração de usuários, gestão de turmas e ciclo escolar, gestão de
        ativação de licenças e monitoramento sobre aceitação das políticas (LGPD).
      </p>
    </div>
  )
}
