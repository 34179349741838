import { getDefaultData } from '@services/sm-api.js'
import { openLoader, closeLoader } from '@actions/loader'

export const FETCH_SUMMARY = 'summary/FETCH_SUMMARY'

export function getSummary(payload) {
  return {
    type: FETCH_SUMMARY,
    payload,
  }
}

export function fetchSummary(userId, data) {
  return dispatch => {
    dispatch(openLoader())
    getDefaultData(`orders/${userId}/summary`, data)
      .then(response => dispatch(getSummary(response)))
      .finally(() => {
        setTimeout(() => {
          dispatch(closeLoader())
        }, 1000)
      })
  }
}
