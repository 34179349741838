export const CHANGE_THEME = 'theme/CHANGE'
export const RESET_THEME = 'theme/RESET'

export function changeTheme(payload) {
  return {
    type: CHANGE_THEME,
    payload,
  }
}

export function resetTheme() {
  return {
    type: RESET_THEME,
  }
}
