import React, { Component } from 'react'
import { contract_status, getCampaigns, logLevelName, logType, queue, roles, series, status } from './options'
import { estados } from '../RegisterForm/states'

import FilterDate from '@components/FilterDate'
import FilterSelect from '@components/FilterSelect'
import FilterText from '@components/FilterText'

export default class ReportFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      campaigns: [],
      isFetching: true,
    }

    this._isMounted = false
  }

  UNSAFE_componentWillMount() {
    this.props.type === 'campaign' &&
      getCampaigns()
        .then(campaigns => {
          this._isMounted &&
            this.setState({
              campaigns,
              selectedCampaign: campaigns.find(campaign => campaign.active === 1),
            })
        })
        .finally(() => {
          this._isMounted &&
            this.setState({
              isFetching: false,
            })
        })
  }

  parserUF() {
    return estados.map(item => ({
      name: item.nome,
      value: item.sigla,
    }))
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  changeCampaign(campaign) {
    this.props.setCurrentCampaign(campaign)
    this.props.onChange(campaign)
  }

  render() {
    return (
      <div>
        {
          {
            role: <FilterSelect field="role_name" label="Perfil" options={roles} onChange={this.props.onChange} />,
            user_search: (
              <FilterText
                field="user_search"
                label="Buscar por nome, e-mail e CPF/CNPJ"
                onChange={this.props.onChange}
              />
            ),
            campaign: (
              <FilterSelect
                field="campaign_id"
                label="Campanha"
                isFetching={this.state.isFetching}
                selected={this.state.selectedCampaign}
                options={this.state.campaigns}
                onChange={campaign => this.changeCampaign(campaign)}
              />
            ),
            status: <FilterSelect field="status" label="Status" options={status} onChange={this.props.onChange} />,
            contract_status: (
              <FilterSelect field="status" label="Status" options={contract_status} onChange={this.props.onChange} />
            ),
            queue: <FilterSelect field="queue" label="Fila" options={queue} onChange={this.props.onChange} />,
            type: <FilterSelect field="type" label="Categoria" options={logType} onChange={this.props.onChange} />,
            levelName: (
              <FilterSelect field="levelName" label="Tipo" options={logLevelName} onChange={this.props.onChange} />
            ),
            date: <FilterDate onChange={this.props.onChange} />,
            order_id: <FilterText field="order_id" label="Buscar por ID do pedido" onChange={this.props.onChange} />,
            sub_order_id: (
              <FilterText field="sub_order_id" label="Buscar por ID do sub pedido" onChange={this.props.onChange} />
            ),
            buyer_name: (
              <FilterText field="buyer_name" label="Buscar por nome de comprador" onChange={this.props.onChange} />
            ),
            buyer_cpf: (
              <FilterText field="buyer_cpf" label="Buscar pelo CPF do comprador" onChange={this.props.onChange} />
            ),
            buyer_cnpj: (
              <FilterText field="buyer_cnpj" label="Buscar pelo CNPJ do comprador" onChange={this.props.onChange} />
            ),
            student_name: (
              <FilterText field="student_name" label="Buscar por nome do estudante" onChange={this.props.onChange} />
            ),
            school_id: (
              <FilterText field={this.props.type} label="Buscar por ID da escola" onChange={this.props.onChange} />
            ),
            school_name: (
              <FilterText field={this.props.type} label="Buscar por nome da escola" onChange={this.props.onChange} />
            ),
            school_sap_code: (
              <FilterText field={this.props.type} label="Buscar por código SAP" onChange={this.props.onChange} />
            ),
            address_state: (
              <FilterSelect field="address_state" label="UF" options={this.parserUF()} onChange={this.props.onChange} />
            ),
            serie_id: (
              <FilterSelect
                field={this.props.type}
                label="Código do ano"
                options={series}
                onChange={this.props.onChange}
              />
            ),
            properties: (
              <FilterText field={this.props.type} label="Busca (campo Payload)" onChange={this.props.onChange} />
            ),
            message: (
              <FilterText field={this.props.type} label="Busca (campo Mensagem)" onChange={this.props.onChange} />
            ),
            contexto: (
              <FilterText field={this.props.type} label="Busca (campo Contexto)" onChange={this.props.onChange} />
            ),
            default: null,
          }[this.props.type]
        }
      </div>
    )
  }
}
