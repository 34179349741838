import React, { Fragment, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import copyToClipboard from 'copy-to-clipboard'

import { checkoutTotalDiscounted, clear } from '@actions/checkout'
import { closeLoader, openLoader } from '@actions/loader.js'
import logo from './images/logo-pix.png'
import { Timer } from './Timer'
import { requestCheckPixPayment } from '@actions/checkPixPayment'
import { PIX_EXPIRATION_TIME, getExpirationSeconds } from '@utils/pix'

export const PixPaymentModalComponent = ({
  order: { order_id: orderId, pix_copy_paste: copyPaste, pix_qrcode: qrCode, pix_expiration: expiration },
  total,
  checkPayment: checkPaymentRequest,
  onClose,
  clearCheckout,
  openLoader,
  closeLoader,
}) => {
  const [show, setShow] = useState(false)
  const [wasCopied, setWasCopied] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const expirationSeconds = expiration ? getExpirationSeconds(expiration.criacao) : PIX_EXPIRATION_TIME
  let interval = null
  if (!show) {
    openLoader()
  }

  const checkPayment = () => {
    checkPaymentRequest(orderId).then(({ payload: { message } }) => {
      if (message === 'Canceled') {
        setIsCanceled(true)
      } else if (message === 'Paid') {
        clearCheckout()
        clearInterval(interval)
        redirect('/steps/concluido')
      } else if (!show) {
        setShow(true)
        closeLoader()
      }
    })
  }

  checkPayment()

  useEffect(() => {
    interval = setInterval(checkPayment, 3000)
    return () => {
      clearInterval(interval)
    }
  })

  const copy = () => {
    copyToClipboard(copyPaste)
    setWasCopied(true)
  }

  return (
    <Modal show={show} className="pix-modal">
      <Modal.Body>
        <div className="pix-content">
          <img className="pix-logo" src={logo} alt="Logo do PIX" />
          <Timer seconds={expirationSeconds} />
          {!isCanceled ? (
            <Fragment>
              <p>
                1. Acesse o app de seu banco ou o seu internet banking.
                <br />
                2. Escolha pagar via PIX.
                <br />
                3. Copie e cole a chave abaixo ou escaneie o QR code.
                <br />
                4. Realize o pagamento.
                <br />
                5. O pagamento será automaticamente confirmado.
              </p>
              <button className="pix-copy-paste" onClick={copy}>
                <span>{copyPaste}</span>
                <span>{wasCopied ? 'Copiado' : 'Copiar'}</span>
              </button>
              <span>OU</span>
              <img width="175" src={'data:image/png;base64, ' + qrCode} alt="QR code de pagamento" />
            </Fragment>
          ) : (
            <Fragment>
              <span>Chave expirada.</span>
              <p className="pix-expired-info">
                Seu pedido foi cancelado. Você poderá realizar um novo pedido escolhendo outra forma de pagamento ou
                gerando novamente uma chave PIX.
              </p>
              <button style={{ fontWeight: 600 }} className="button__small" onClick={onClose}>
                Fechar
              </button>
            </Fragment>
          )}
        </div>
      </Modal.Body>
      {!isCanceled && (
        <Modal.Footer>
          <span>Valor total:</span>
          <span>{total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
        </Modal.Footer>
      )}
    </Modal>
  )
}

const mapStateToProps = (state, ownProps) => ({
  total: ownProps.total || checkoutTotalDiscounted(state) + state.freight.calculated_freight,
  order: ownProps.order || state.creditCard,
})

const mapDispatchToProps = dispatch => ({
  checkPayment: data => dispatch(requestCheckPixPayment(data)),
  clearCheckout: () => dispatch(clear()),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
})

export const PixPaymentModal = connect(mapStateToProps, mapDispatchToProps)(PixPaymentModalComponent)

