import React, { Component } from 'react'
import { checkoutTotal, checkoutTotalDiscounted, filterActiveProducts } from '@actions/checkout'
import { closeLoader, openLoader } from '@actions/loader'

import { Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { requestOrder } from '@actions/creditCard'
import { PixPaymentModal } from './PixPaymentModal.jsx'
import { DuplicatedOrderAlertModal } from '../../shared/DuplicatedOrderAlertModal'

class Pix extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: '',
      errorModalOpen: false,
      disabledButton: false,
      showPaymentModal: false,
      showDuplicatedOrderAlertModal: false,
    }
  }

  finalizeOrder = () => {
    this.setState({ disabledButton: true })
    this.props
      .requestOrder()
      .then(response => {
        const { pix_qrcode: qrCode, message } = response.payload
        if (qrCode) {
          this.setState({ showPaymentModal: true })
        } else {
          if(response.payload.error.error_type == 'duplicated_students') {
            this.setState({ showDuplicatedOrderAlertModal: true })
            return false;
          }
          this.setState({ errorMessage: message })
          this._showErrorModal()
        }
      })
      .finally(() => {
        this.props.closeLoader()
        this.setState({ disabledButton: false })
      })
  }

  _showErrorModal() {
    if (!this.state.errorModalOpen) {
      this.setState({
        errorModalOpen: true,
      })
    }
  }

  render() {
    const { showPaymentModal } = this.state
    return (
      <div>
        <button className="button__medium" onClick={this.finalizeOrder} disabled={this.state.disabledButton}>
          <span>Finalizar pedido e gerar chave PIX</span>
        </button>
        {this.state.errorMessage && <span className="payment__error">{this.state.errorMessage}</span>}
        <DuplicatedOrderAlertModal
          show={this.state.showDuplicatedOrderAlertModal}
          onClose={() => {
            this.setState({ showDuplicatedOrderAlertModal: false })
          }}  
        />
        <Modal show={this.state.errorModalOpen}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert"></span>
              <h1>Erro ao finalizar o pedido</h1>
            </div>
            <div className="modalContent">
              <p>Desculpe, não foi possível gerar a chave PIX nesse momento.</p>
              <p>Tente novamente em alguns minutos.</p>
            </div>
            <div className="modalFooter">
              <button
                className="modal-close"
                onClick={() => {
                  this.setState({
                    errorModalOpen: false,
                  })
                }}
              >
                Entendi
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {showPaymentModal && (
          <PixPaymentModal
            onClose={() => {
              this.setState({ showPaymentModal: false })
            }}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  checkout: filterActiveProducts(state),
  orderTotal: checkoutTotalDiscounted(state),
  withoutDiscount: checkoutTotal(state),
})

const mapDispatchToProps = dispatch => ({
  requestOrder: data => dispatch(requestOrder(data)),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
})

const mergeProps = (stateProps, dispatchProps) => ({
  requestOrder: () => {
    dispatchProps.openLoader()
    return dispatchProps.requestOrder({
      voucher_data: [...stateProps.checkout],
      user_data: {},
      card_data: {},
      type: 'pix',
      order_total: stateProps.orderTotal.toFixed(2),
      order_without_discount: stateProps.withoutDiscount.toFixed(2),
    })
  },
  closeLoader: dispatchProps.closeLoader,
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Pix)

