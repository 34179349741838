import React, { Component } from 'react'

import arrow from './images/arrow.png'

export default class FaqAnswer extends Component {
  render() {
    let { question, answer } = this.props
    return (
      <div className="faqanswer">
        <h3 className="faqanswer__question" dangerouslySetInnerHTML={{ __html: question }}></h3>
        <span onClick={this.props.onBack} className="faqanswer__back">
          <img src={arrow} alt="Voltar" className="faqanswer__icon" />
          <span className="faqanswer__label">voltar</span>
        </span>
        <p className="faqanswer__text" dangerouslySetInnerHTML={{ __html: answer }}></p>
        <div className="faqanswer__footer">
          <p className="faqanswer__text">
            Ainda precisa de ajuda?
            <a
              href="https://app.pipefy.com/public/form/4k5tgRPD"
              target="_blank"
              className="button button__medium faqanswer__button"
            >
              Sim
            </a>
          </p>
        </div>
      </div>
    )
  }
}

