import React from 'react'

export const SelectDigitalSolution = ({ options, label, value = '', handleChange = null, showEmptyOption = true }) => {
  return (
    <div className="digital_solution_hybrid_select">
      <label className="filterselect__label">{label}:</label>
      <select
        value={value}
        onChange={event => {
          if (handleChange) {
            handleChange(event)
          }
        }}
        style={{ color: value ? '#333' : '#ccc' }}
      >
        {showEmptyOption && <option value="">Selecione</option>}
        {Object.entries(options).map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
    </div>
  )
}
