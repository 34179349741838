import { ACTIVATE } from '@actions/voucherForm'
import { ACTIVATE_STUDENT } from '@actions/voucherForm'
import { REHYDRATE } from 'redux-persist/constants'

export default (state = { show: false }, action) => {
  switch (action.type) {
    case ACTIVATE:
      return { ...state, show: action.show }

    case ACTIVATE_STUDENT:
      return { ...state, showStudentForm: action.showStudentForm, indexStudent: action.index }

    case REHYDRATE:
      const incoming = action.payload.voucherForm
      return { ...state, ...incoming, show: false, showStudentForm: false }

    default:
      return state
  }
}
