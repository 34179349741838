export const OPEN_LOADER = 'loader/OPEN'
export const CLOSE_LOADER = 'loader/CLOSE'

export function openLoader() {
  return {
    type: OPEN_LOADER,
    show: true,
  }
}

export function closeLoader() {
  return {
    type: CLOSE_LOADER,
    show: false,
  }
}
