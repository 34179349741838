import { CLEAR, FETCH_SPECIAL_DISCOUNT, SUCCESS_SPECIAL_DISCOUNT } from '@actions/specialDiscount'

import _ from 'lodash'

const initialState = {
  specialDiscount: null,
  error: { code: -1 },
}

const errorReducer = function (state, action) {
  let error

  if (action.payload.error) {
    error = { ...action.payload.error }
  } else if (_.isEmpty(action.payload.special_discounts)) {
    error = {
      code: -1,
      message: 'Nenhum desconto encontrado para este documento',
    }
  } else {
    error = { code: null }
  }

  return error
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SPECIAL_DISCOUNT:
      return initialState

    case SUCCESS_SPECIAL_DISCOUNT:
      return { ...state, ...action.payload, error: errorReducer(state, action) }

    case CLEAR:
      return initialState

    default:
      return state
  }
}
