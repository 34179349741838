import { checkPixPayment as checkPixPaymentResquest } from '@services/sm-api.js'

export const CHECK_PIX_PAYMENT = 'pix/CHECK_PAYMENT'
export const SUCCESS_CHECK_PIX = 'pix/SUCCESS_CHECK'

export function successOrder(payload) {
  return {
    type: SUCCESS_CHECK_PIX,
    payload,
  }
}

export const requestCheckPixPayment = order => {
  return dispatch => {
    return checkPixPaymentResquest(order).then(response => {
      return dispatch(successOrder(response))
    })
  }
}

