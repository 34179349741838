import { Form, Select, Text } from 'react-form'
import React, { Component } from 'react'
import { checkoutTotal, checkoutTotalDiscounted, clear, filterActiveProducts } from '@actions/checkout'
import { closeLoader, openLoader } from '@actions/loader'
import { requestInstallments, requestOrder } from '@actions/creditCard'

import Cards from 'react-credit-cards'
import CreditCardReasons from '@components/CreditCardReasons'
import CustomText from '@shared/CustomText'
import _ from 'lodash'
import { connect } from 'react-redux'
import creditMasks from './credit-masks'
import valid from 'card-validator'
import { Navigate } from 'react-router-dom'
import { DuplicatedOrderModal } from '../../shared/DuplicatedOrderModal'
import { DuplicatedOrderAlertModal } from '../../shared/DuplicatedOrderAlertModal'
import { getOrdersByUser } from '../../services/sm-api'

class CreditCard extends Component {
  purchasedOrder = ''

  constructor(props) {
    super(props)
    this._isMounted = false
  }

  state = {
    card_number: '',
    name: '',
    card_expire: '',
    cvv: ' ',
    num_parcela: '',
    issuer: null,
    maxLength: 16,
    isValid: false,
    paymentError: null,
    paymentErrorCode: null,
    order_id: null,
    cardRegex: { number: '9999 9999 9999 9999 ', cvv: '999' },
    processing: false,
    showDuplicatedOrderModal: false,
    showDuplicatedOrderAlertModal: false,
    isDone: false,
  }

  _generateInstalmentOptions(installments = []) {
    installments = Object.keys(installments).reduce((prev, value) => {
      prev[value - 1] = {
        label: installments[value],
        value: +value,
      }
      return prev
    }, [])

    return installments
  }

  _handleOnSubmit = () => {
    if (this.state.processing === true) {
      return false
    }
    this.setState({ processing: true })

    window.ga('send', {
      hitType: 'event',
      eventCategory: 'compra',
      eventAction: 'click',
      eventLabel: 'enviou_cartao_5.1',
    })

    return this.props
      .requestOrder(this.state)
      .then(response => {
        const { status, error = {} } = response.payload
        this.props.closeLoader()
        //eslint-disable-next-line
        if (this._isMounted && error.status > 0) {

          if(error.error_type == 'duplicated_students') {
            this.setState({ showDuplicatedOrderAlertModal: true })
          }

          this.setState({ order_id: null })
          this.setState({ paymentError: error.message })
          this.setState({ paymentErrorCode: error.status })
        }
        //eslint-disable-next-line
        if (status === 0) {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'compra',
            eventAction: 'click',
            eventLabel: 'confirmou_cartao_5.1',
          })
          this.props.clear()
          this.setState({ isDone: true })
        }
        this.setState({ processing: false })
      })
      .catch(error => {
        this.setState({ processing: false })
        this.props.closeLoader()
        this._isMounted && this.setState({ paymentError: 'Erro no servidor.' })
      })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    this.props.getInstallments(this.props.orderTotal + this.props.freightAmount, this.props.schoolSapCode)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Recalcula os valores de parcelas caso ocorra a remoção de um livro/voucher
    if (this.props.orderTotal !== nextProps.orderTotal && nextProps.orderTotal > 0) {
      this.props.getInstallments(nextProps.orderTotal + nextProps.freightAmount, nextProps.schoolSapCode)
    }
  }

  checkAlreadyPurchase = async () => {
    const { orders } = await getOrdersByUser({ id: this.props.user.id })
    const voucher_id = this.props.activeVoucher.toUpperCase()
    const order = _.first(
      orders.filter(order => {
        if (['PG', 'AP', 'BAP', 'PAV'].includes(order.status)) {
          const vouchers = _.values(order.vouchers).map(voucher => {
            return _.keys(voucher)[0].toUpperCase()
          })
          return vouchers.includes(voucher_id)
        }
        return false
      }),
    )
    if (order) {
      this.purchasedOrder = order.order_id
      this.setState({ showDuplicatedOrderModal: true })
    } else {
      this._handleOnSubmit()
    }
  }

  render() {
    return (
      <div className="mx-auto">
        {this.state.isDone && <Navigate to="/steps/concluido" />}
        <div className="card-register">
          <h3 className="card-register__title">Dados do seu cartão</h3>
          <div className="card-register__form">
            <Form
              onSubmit={this.checkAlreadyPurchase}
              onChange={({ values: { card_number } }) => {
                let result = valid.number(card_number)

                if (result.card) {
                  this.setState({
                    cardRegex: {
                      number: creditMasks(result.card.type).number,
                      cvv: creditMasks(result.card.type).cvv,
                    },
                  })
                }
              }}
              validate={({ card_number, name, card_expire, cvv, num_parcela }) => {
                let card = valid.number(card_number)

                return {
                  card_number: !card.isValid ? 'Número inválido' : undefined,
                  name: !name ? 'Nome é obrigatório.' : undefined,
                  card_expire: !card_expire ? 'Validade é obrigatório' : undefined,
                  cvv: !cvv ? 'CVV é obrigatório' : undefined,
                  num_parcela: !num_parcela ? 'Selecione uma parcela.' : undefined,
                }
              }}
            >
              {({ values, submitForm }) => {
                return (
                  <form
                    onSubmit={submitForm}
                    onChange={() =>
                      this.setState({
                        ...values,
                        paymentError: null,
                        paymentErrorCode: null,
                      })
                    }
                  >
                    <div className="field card-register__field">
                      <span className="field__label card-register__label">Número cartão</span>
                      <CustomText
                        id="payment-card-number-input"
                        className="field_text card-register__input"
                        field="card_number"
                        mask={this.state.cardRegex.number}
                        regex={/\s/g}
                        onFocus={() => this.setState({ focused: 'card_number' })}
                      />
                    </div>
                    <div className="field card-register__field">
                      <span className="field__label card-register__label">Nome</span>
                      <Text
                        id="payment-name-input"
                        className="field__text card-register__input"
                        field="name"
                        autoComplete="off"
                        onFocus={() => this.setState({ focused: 'name' })}
                      />
                    </div>
                    <div className="field card-register__container">
                      <div className="card-register__container__field--expiry">
                        <span className="field__label card-register__label">Validade</span>
                        <CustomText
                          id="payment-validity-input"
                          className="field__text"
                          field="card_expire"
                          mask="99/99"
                          onFocus={() => this.setState({ focused: 'expiry' })}
                        />
                      </div>
                      <div className="card-register__container__field--cvv">
                        <span className="field__label">CVV</span>
                        <CustomText
                          id="payment-cvv-input"
                          className="field__text card-register__cvv"
                          field="cvv"
                          mask={this.state.cardRegex.cvv}
                          onFocus={() => this.setState({ focused: 'cvc' })}
                        />
                      </div>
                    </div>
                    <div className="field card-register__select-field">
                      <span className="field__label card-register__label">Parcelas</span>
                      <div className="field__select card-register__select">
                        <Select
                          id="payment-installment-select"
                          placeholder="Selecione"
                          field="num_parcela"
                          value="0"
                          options={this._generateInstalmentOptions(this.props.creditCard.installments)}
                        />
                      </div>
                    </div>
                    <div className="field card-register__footer">
                      <span className="field__error">{this.state.paymentError}</span>
                      <button
                        id="payment-finish-button"
                        className="button__normal card-register__button"
                        type="submit"
                        disabled={this.state.processing}
                      >
                        Finalizar
                      </button>
                    </div>
                  </form>
                )
              }}
            </Form>
          </div>
          <div className="card-register__card">
            <Cards
              number={this.state.card_number}
              name={this.state.name}
              expiry={this.state.card_expire}
              cvc={this.state.cvv}
              callback={(card, isValid) => this.setState({ ...card, isValid })}
              focused={this.state.focused}
            />
          </div>
        </div>
        {this.state.paymentErrorCode > 0 ? <CreditCardReasons /> : null}
        <DuplicatedOrderModal
          show={this.state.showDuplicatedOrderModal}
          onClose={() => {
            this.setState({ showDuplicatedOrderModal: false })
            this._handleOnSubmit()
          }}
          purchasedOrder={this.purchasedOrder}
        />
        <DuplicatedOrderAlertModal
          show={this.state.showDuplicatedOrderAlertModal}
          onClose={() => {
            this.setState({ showDuplicatedOrderAlertModal: false })
          }}  
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.sessionReducer.user,
    creditCard: state.creditCard,
    freightAmount: state.freight.calculated_freight,
    checkout: filterActiveProducts(state),
    orderTotal: checkoutTotalDiscounted(state),
    withoutDiscount: checkoutTotal(state),
    schoolSapCode: _.isEmpty(state.checkout) ? null : state.checkout[0].school_sap_code,
    orders: state.order.orders,
    activeVoucher: state.activeVoucher.voucher,
  }
}

const mapDispatchToProps = dispatch => ({
  requestOrder: data => dispatch(requestOrder(data)),
  openLoader: () => dispatch(openLoader()),
  closeLoader: () => dispatch(closeLoader()),
  getInstallments: (order_total, school_sap_code) => dispatch(requestInstallments(order_total, school_sap_code)),
  clear: () => dispatch(clear()),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  requestOrder: cardData => {
    dispatchProps.openLoader()
    return dispatchProps.requestOrder({
      voucher_data: [...stateProps.checkout],
      user_data: {},
      card_data: {
        name: cardData.name,
        card_expire: cardData.card_expire,
        card_number: cardData.card_number,
        issuer: cardData.issuer,
        num_parcela: cardData.num_parcela,
        cvv: cardData.cvv,
        card_len: cardData.card_number.length,
        status_cvv: 1,
        tipo_cred: cardData.num_parcela > 1 ? 2 : 0,
      },
      type: 'credit_card',
      order_id: cardData.order_id,
      order_total: stateProps.orderTotal.toFixed(2),
      order_without_discount: stateProps.withoutDiscount.toFixed(2),
    })
  },
  getInstallments: (order_total, school_sap_code) =>
    dispatchProps.getInstallments(order_total.toFixed(2), school_sap_code),
  clear: dispatchProps.clear,
  closeLoader: dispatchProps.closeLoader,
  creditCard: stateProps.creditCard,
  orderTotal: stateProps.orderTotal,
  schoolSapCode: stateProps.schoolSapCode,
  freightAmount: stateProps.freightAmount,
  activeVoucher: stateProps.activeVoucher,
  orders: stateProps.orders,
  user: stateProps.user,
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreditCard)