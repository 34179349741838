import React from 'react'
import { closeLoader, openLoader } from '@actions/loader'

import DOMPurify from 'dompurify'
import { closeModal } from '@actions/modal'
import { connect } from 'react-redux'

const AlertModal = ({ closeModal, notification }) => (
  <div className="alert-modal">
    <h3 className="alert-modal__title">{notification.title}</h3>
    <h4 className="alert-modal__text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification.message) }} />
    <div className="field alert-modal__field--button">
      <button className="button__normal alert-modal__button" onClick={closeModal}>
        Fechar
      </button>
    </div>
  </div>
)

const mapStateToProps = state => ({
  user: state.sessionReducer,
})

const mapDispatchToProps = { closeModal, openLoader, closeLoader }

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal)

