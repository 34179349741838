import {
  RESET_DIGITAL_SOLUTIONS,
  FAILURE_DIGITAL_SOLUTIONS,
  SET_DIGITAL_SOLUTIONS,
  SET_DIGITAL_SOLUTIONS_HYBRID,
  SET_DIGITAL_SOLUTIONS_VAL,
  SET_DISABLE_DIGITAL_LICENSES,
} from '@actions/digitalSolutions'
import { REHYDRATE } from 'redux-persist/constants'

let initialState = {
  digital_solutions: null,
  disable_digital_licenses: false,
  digital_solution_val: null,
  hybrid: null,
  error: null,
}

export default (state = initialState, action, reset_state = initialState) => {
  switch (action.type) {
    case SET_DIGITAL_SOLUTIONS:
      return {
        ...state,
        digital_solutions: action.payload,
      }

    case SET_DISABLE_DIGITAL_LICENSES:
      return {
        ...state,
        disable_digital_licenses: action.payload,
      }

    case SET_DIGITAL_SOLUTIONS_HYBRID:
      return {
        ...state,
        hybrid: {
          ...state.hybrid,
          ...action.payload,
        },
      }

    case SET_DIGITAL_SOLUTIONS_VAL:
      return {
        ...state,
        digital_solution_val: action.payload,
      }

    case FAILURE_DIGITAL_SOLUTIONS:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_DIGITAL_SOLUTIONS:
      return {
        ...reset_state,
      }

    case REHYDRATE:
      return {
        ...state,
      }

    default:
      return state
  }
}
