export const OPEN_MODAL = 'modal/OPEN'
export const CLOSE_MODAL = 'modal/CLOSE'
export const SHOW_OVERLAY = 'modal/OVERLAY_SHOW'

export function openModal(component) {
  return {
    type: OPEN_MODAL,
    component,
    show: true,
  }
}

export function closeModal() {
  return {
    type: CLOSE_MODAL,
    show: false,
  }
}

export function showOverlay(showOverlay) {
  return {
    type: SHOW_OVERLAY,
    showOverlay,
  }
}
