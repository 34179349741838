import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class FilterText extends Component {
  state = {
    selectValue: '',
  }

  _handleOnChange(event) {
    this.setState({
      selectValue: event.target.value,
    })
    let filter = { [this.props.field]: event.target.value }
    this.props.onChange(filter)
  }

  render() {
    let { label, customClass, ...rest } = this.props
    return (
      <div className="filtertext">
        <input
          {...rest}
          className={customClass ?? 'filtertext__input test-filtertext'}
          type="text"
          placeholder={label}
          onChange={event => this._handleOnChange(event)}
          autoComplete="off"
        />
      </div>
    )
  }
}

FilterText.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  customClass: PropTypes.string,
}

