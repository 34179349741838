import React from 'react'
import PropTypes from 'prop-types'

import warning from './images/warning.png'
import denied from './images/warning-white.png'
import ok from './images/Group 4957.png'

const images = { warning, denied, ok }

const CardStatus = ({ type, title = 'Status', icon, description, updated }) => (
  <div className={`card-status--${type}`}>
    <div className={`card-status__container`}>
      <span className="card-status__title">{title}</span>
      <div className="card-status__icon">
        <img src={images[type]} alt="" />
      </div>
      <span className="card-status__description">{description}</span>
    </div>
  </div>
)

CardStatus.propTypes = {
  title: PropTypes.string,
}

export default CardStatus
