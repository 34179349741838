import { requestOrder as requestOrderApi, requestInstallments as requestInstallmentsApi } from '@services/sm-api.js'

/**
 * Constants
 */
export const POST_ORDER = 'creditCard/REQUEST_ORDER'
export const SUCCESS_ORDER = 'creditCard/SUCCESS_ORDER'
export const FAILURE_ORDER = 'creditCard/FAILURE_ORDER'
export const SUCCEES_INSTALLMENTS = 'creditCard/SUCCEES_INSTALLMENTS'

/**
 * Actions creators
 */
export function postOrder() {
  return {
    type: POST_ORDER,
  }
}

export function successOrder(payload) {
  return {
    type: SUCCESS_ORDER,
    payload,
  }
}

export function succeedInstallments(payload) {
  return {
    type: SUCCEES_INSTALLMENTS,
    payload,
  }
}

export function failureOrder(error) {
  return {
    type: FAILURE_ORDER,
    error,
  }
}

export function requestOrder(data) {
  return dispatch => {
    dispatch(postOrder())
    return requestOrderApi(data)
      .then(response => {
        return dispatch(successOrder(response))
      })
      .catch(error => dispatch(failureOrder(error)))
  }
}

export function requestInstallments(orderTotal, schoolSapCode) {
  return dispatch => {
    return requestInstallmentsApi(orderTotal, schoolSapCode).then(response => dispatch(succeedInstallments(response)))
  }
}
