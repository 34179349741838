import { FETCH_ACTIVE_NOTIFICATION } from '@actions/activeNotification'

export default (state = null, action) => {
  switch (action.type) {
    case FETCH_ACTIVE_NOTIFICATION:
      return action.payload
    default:
      return state
  }
}
