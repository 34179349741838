import './index.css'

import { Cell, Column, Table } from 'fixed-data-table-2'
import { NavLink } from 'react-router-dom'
import React, { Component } from 'react'
import {
  blobDownload,
  downloadDiscountFile,
  getDefaultData,
  getSpecialDiscountList,
  uploadDiscountFile,
} from '@services/sm-api.js'
import { closeLoader, openLoader } from '@actions/loader'

import FileDownloader from '@shared/FileDownloader'
import FilterSelect from '@components/FilterSelect'
import FilterText from '@components/FilterText'
import InlineLoader from '@components/InlineLoader'
import UploadErrorsModal from './UploadErrorsModal'
import UploadFileModal from '@components/UploadFileModal'
import _ from 'underscore'
import { connect } from 'react-redux'
import export_report from '@images/export_report.png'
import moment from 'moment'

const HeaderCell = ({ ...props }) => (
  <Cell className={`report-table__header-cell ${props.className}`} {...props}>
    {props.children}
  </Cell>
)

const DefaultCell = ({ children, className }) => <Cell className={className}>{children}</Cell>

const ButtonCell = ({ children, data }) => (
  <Cell className="report-table__button-cell center">
    <NavLink to={`/admin/special-discounts/${data.id}`} className="button button__small users center">
      Editar
    </NavLink>
  </Cell>
)

class SpecialDiscount extends Component {
  constructor(props) {
    super(props)

    this.state = {
      discounts: [],
      campaigns: [],
      defaultCampaign: null,
      isSearching: false,
      showUploadFileModal: false,
      showUploadErrrosModal: false,
      file: null,
      uploadErrorsData: { errors: [] },
    }

    this.pagination = {
      current: 1,
      total: 0,
      isLoading: false,
    }

    this.filter = {}

    this.columns = [
      {
        label: 'ID SIC',
        field: 'school_id',
        component: DefaultCell,
        className: 'center',
      },
      {
        label: 'Nome Escola',
        field: 'school_name',
        component: DefaultCell,
      },
      {
        label: 'Voucher',
        field: 'voucher_name',
        component: DefaultCell,
        className: 'center',
      },
      {
        label: 'Documento Comprador',
        field: 'buyer_document',
        component: DefaultCell,
      },
      {
        label: 'Professor',
        field: 'buyer_name',
        component: DefaultCell,
      },
      {
        label: 'Aluno',
        field: 'student_name',
        component: DefaultCell,
      },
    ]

    if (this.props.hasCreateEditPermission) {
      this.columns.push({
        label: 'Editar',
        component: ButtonCell,
        className: 'center',
      })
    }
  }

  _lazyLoadData = _.debounce((params, filter) => {
    this.setState({ discounts: [] })
    this.pagination.current = 1
    return this._loadData(params, filter).finally(() => this.setState({ isSearching: false }))
  }, 300)

  _onSearch = field => {
    this.filter = { ...this.filter, ...field }

    this.setState({ discounts: [], isSearching: true })
    return this._lazyLoadData()
  }

  _loadData = () => {
    return getSpecialDiscountList({ page: this.pagination.current, ...this.filter })
      .then(response => {
        this.pagination.total = response.total
        this.pagination.current += 1
        this.setState({ discounts: [...this.state.discounts, ...response.discounts] })
        return response
      })
      .finally(closeLoader)
  }

  _handleReportDownload = () => {
    this.props.openLoader()
    return downloadDiscountFile().finally(() => this.props.closeLoader())
  }

  _handleLoadMore = () => {
    openLoader()
    this._loadData()
  }

  _downloadDiscountsFileExample = event => {
    event.preventDefault()

    return blobDownload('/special-discounts/import/example')
      .then(response => {
        let blob = new Blob([response])
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'planilha-importação-exemplo.xlsx'
        link.click()
        link.remove()
      })
      .finally(() => this.props.closeLoader())
  }

  _retryUploadFile = () => {
    this.setState({ showUploadFileModal: true, showUploadErrrosModal: false })
  }

  _handleDiscountsImportUpload = (file, onSuccess, onError, setUploadingState, setInitialState) => {
    setUploadingState()

    this.setState({
      file,
    })

    return uploadDiscountFile(file)
      .then(({ data }) => {
        if (data.errors && data.errors.length > 0) {
          this.setState({ showUploadFileModal: false, showUploadErrrosModal: true, uploadErrorsData: data })
          setTimeout(() => setInitialState(), 400)
        } else {
          onSuccess()
        }
      })
      .catch(({ response }) => {
        let errorMessage = 'Ocorreu um problema ao carregar este arquivo. Tente novamente mais tarde.'
        if (response && response.status === 400) {
          errorMessage = 'Arquivo inválido. Verique a extensão e o formato do arquivo e tente novamente.'
        }
        onError(errorMessage)
      })
      .finally(() => {
        this.pagination.current = 1
        this.setState({ discounts: [] }, this._loadData)
      })
  }

  componentDidMount() {
    const { openLoader, closeLoader } = this.props

    openLoader()

    getDefaultData('campaigns/list').then(
      campaigns => {
        const activeCampaign = campaigns.find(campaign => campaign.active)
        this.setState({
          defaultCampaign: { value: activeCampaign.id },
          campaigns: campaigns.map(campaign => ({
            name: campaign.name,
            value: campaign.id,
          })),
        })
      },
      () => {
        this._loadData().finally(closeLoader)
      },
    )
  }

  render() {
    const {
      discounts,
      campaigns,
      isSearching,
      showUploadFileModal,
      showUploadErrrosModal,
      uploadErrorsData,
      defaultCampaign,
    } = this.state

    const { openLoader, closeLoader, hasCreateEditPermission } = this.props

    return (
      <div className={`report special-discount`}>
        <h1 className="report__title">Desconto Professor</h1>
        {hasCreateEditPermission && (
          <React.Fragment>
            <NavLink className="button__small" to="/admin/special-discounts/novo">
              Adicionar novo desconto
            </NavLink>
            <button
              onClick={() => this.setState({ showUploadFileModal: true })}
              className="button__upload schooldetail__upload"
            >
              Importar descontos via planilha
            </button>
          </React.Fragment>
        )}
        <div className="filters" id="report_container">
          <div className="filters__item">
            <FilterText field="search" label="Buscar por documento, professor e ou aluno" onChange={this._onSearch} />
            <InlineLoader show={isSearching} />
          </div>
          <div className="filters__item">
            <FilterSelect
              selected={defaultCampaign}
              field="campaign_id"
              label="Campanha"
              options={campaigns}
              showEmptyOption={false}
              onChange={field => {
                this.filter = { ...this.filter, ...field }
                this.setState({ discounts: [] })
                this.pagination.current = 1
                openLoader()
                this._loadData().finally(closeLoader)
              }}
            />
          </div>
        </div>
        <div className="report__table">
          <div className="report-table">
            <div className="report-table__header">
              <h1 className="report-table__title">Descontos</h1>
              <FileDownloader
                className="report-table__export-title export"
                onClick={this._handleReportDownload}
                filename={`descontos-${moment().format('x')}.xlsx`}
              >
                <span className="report-table__export-title-label"> Exportar Dados </span>
                <img className="report__export" src={export_report} alt="Botão Exportar" />
              </FileDownloader>
            </div>
            <Table
              ref="Table"
              className="report-table"
              onScrollEnd={this._handleLoadMore}
              rowHeight={45}
              rowsCount={discounts.length}
              width={960}
              height={400}
              headerHeight={50}
              data={discounts}
            >
              {this.columns.map((column, key) => {
                return (
                  <Column
                    key={`${column.field}-${key}`}
                    header={<HeaderCell className={column.className}>{column.label}</HeaderCell>}
                    columnKey={column.field}
                    fixed={true}
                    width={960 / this.columns.length}
                    cell={({ rowIndex, columnKey, props }) => (
                      <column.component data={discounts[rowIndex]} className={column.className}>
                        {discounts[rowIndex][columnKey]}
                      </column.component>
                    )}
                  />
                )
              })}
            </Table>
            <UploadFileModal
              show={showUploadFileModal}
              title="Importaçao da planilha de descontos"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onClose={() => {
                this.setState({ showUploadFileModal: false })
              }}
              subTitle={() => (
                <a className="special-discount__subtitle" onClick={this._downloadDiscountsFileExample}>
                  Baixar exemplo de planilha para importação
                </a>
              )}
              onUpload={this._handleDiscountsImportUpload}
              description={() => <p>Arquivos aceitos no formato XLSX.</p>}
            />
            <UploadErrorsModal
              show={showUploadErrrosModal}
              totalErrors={uploadErrorsData.errors_count}
              totalRows={uploadErrorsData.rows_count}
              totalSuccess={uploadErrorsData.success_count}
              file={this.state.file}
              onClose={() => {
                this.setState({ showUploadErrrosModal: false })
              }}
              onRetry={this._retryUploadFile}
              errors={uploadErrorsData.errors}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.sessionReducer.user,
  hasCreateEditPermission: state.sessionReducer.user.permissions.indexOf('manage_special_discounts') > -1,
})

const mapDispatchToProps = { openLoader, closeLoader }

export default connect(mapStateToProps, mapDispatchToProps)(SpecialDiscount)

