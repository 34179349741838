import React, { Component } from 'react'

import { Modal } from 'react-bootstrap'
import PermissionsChecker from '@shared/PermissionsChecker'
import { formatMoney } from '@shared/FormatMoney'
import _ from 'lodash'

export default class ProductsTable extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showCancelItemModal: false,
      itemIdToCancel: null,
    }

    this.handleCancelItem = this.handleCancelItem.bind(this)
  }

  handleCancelItem() {
    this.setState(
      {
        showCancelItemModal: false,
      },
      () =>
        this.props.handleCancelOrderItem(this.state.itemIdToCancel).then(() =>
          this.setState({
            itemIdToCancel: null,
          }),
        ),
    )
  }

  render() {
    const { products, header, fixedCol } = this.props
    const list = products.map(product => {
      let itens = header.reduce((prev, item, index, arr) => {
        let temp = {}
        if (fixedCol[item.property]) {
          temp[item.property] = fixedCol[item.property]
        } else if (item.key) {
          temp[item.property] = product[item.property][item.key]
        } else {
          temp[item.property] = product[item.property]
        }
        temp.id = product.id
        temp.bookLicenses = product.bookLicenses
        return { ...prev, ...temp }
      }, {})
      return { ...itens }
    })

    return (
      <div className="books-table">
        <Modal show={this.state.showCancelItemModal}>
          <Modal.Body>
            <div className="modalHeader">
              <span className="icon-alert" />
              <h1>Atenção</h1>
            </div>

            <div className="modalContent">
              <p>Deseja cancelar este item do pedido?</p>
              <p>Ao realizar esta ação, o item será cancelado apenas no SM Direto.</p>
              <p>Antes de realizar esta ação, recomendamos que o item já esteja cancelado no SAP.</p>
              <p>O cálculo total deste subpedido e pedido serão refeitos e o retributivo da escola será recalculado.</p>
            </div>

            <div className="actions-area">
              <button className="btn-accept" onClick={this.handleCancelItem}>
                Sim
              </button>

              <button
                className="btn-decline"
                onClick={() =>
                  this.setState({
                    showCancelItemModal: false,
                    itemIdToCancel: null,
                  })
                }
              >
                Cancelar
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <table cellSpacing="0" cellPadding="0" className="books-table__table">
          <thead className="books-table__header">
            <tr className="books-table__header-row">
              {header.map((column, index) => (
                <th className="books-table__col" key={index}>
                  {column.alias}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="books-table__body">
            {list.map((item, index) => (
              <tr className="books-table__row" key={index}>
                {header.map((column, index) => (
                  <td
                    className={`books-table__cell ${
                      column.property !== 'cancelled_at' && !_.isEmpty(item.cancelled_at) ? 'cancelled' : ''
                    }`}
                    key={index}
                  >
                    {column.property === 'cancelled_at' ? (
                      <React.Fragment>
                        {!_.isEmpty(item[column.property]) ? (
                          <span className={`column column__${column.property}`}>Item cancelado</span>
                        ) : (
                          <PermissionsChecker permissions={['cancel_order_item']}>
                            <button
                              className="button button__cancel"
                              onClick={() =>
                                this.setState({
                                  showCancelItemModal: true,
                                  itemIdToCancel: item.id,
                                })
                              }
                            >
                              Cancelar item
                            </button>
                          </PermissionsChecker>
                        )}
                      </React.Fragment>
                    ) : (
                      {
                        image: (
                          <img src={item[column.property]} alt="" className={`column column__${column.property}`} />
                        ),
                        currency: (
                          <div className={`column column__${column.property}`}>
                            {formatMoney(item[column.property], 2, 2)}
                          </div>
                        ),
                        default: <span className={`column column__${column.property}`}>{item[column.property]}</span>,
                        name: (
                          <div>
                            <span className={`column column__${column.property}`}>{item[column.property]}</span>
                            {Object.entries(item.bookLicenses).map(([key, license_code]) => (
                              <span key={license_code.license_code} className={`column column__license_code`}>
                                <b>{license_code.component || 'Chave da licença'}: </b>
                                {license_code.license_code}
                              </span>
                            ))}
                          </div>
                        ),
                      }[column.type]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

