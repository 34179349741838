import {
  ERROR_CONTRACT_DATE,
  FETCH_SCHOOL,
  FETCH_SCHOOLS,
  POST_CONTRACT_DATE,
  UPDATE_CONTRACT_DATE,
  CLEAR_ERROR,
  FETCH_SCHOOL_PAYMENT_OPTIONS,
} from '@actions/school'

let initial_state = {
  schools: [],
  name: '',
  contract_start: null,
  contract_end: null,
  contract_cut_dates: [],
  disable_sync: null,
  error: null,
  payment_options: [],
}

export default (state = initial_state, action) => {
  switch (action.type) {
    case FETCH_SCHOOLS:
      return {
        ...state,
        schools: action.payload,
        error: null,
      }

    case FETCH_SCHOOL:
      return {
        ...state,
        ...action.payload,
        error: null,
      }

    case POST_CONTRACT_DATE:
      return {
        ...state,
        isPosting: true,
        error: null,
      }

    case UPDATE_CONTRACT_DATE:
      return {
        ...state,
        isPosting: false,
        error: null,
      }

    case ERROR_CONTRACT_DATE:
      return {
        ...state,
        error: { ...action.error },
      }

    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
      }

    case FETCH_SCHOOL_PAYMENT_OPTIONS:
      return {
        ...state,
        payment_options: action.payload,
      }

    default:
      return state
  }
}
