import React from 'react'

export const DigitalSolutionSMA = props => {
  return (
    <div className="digital_solution_description">
      <p>
        <b>SM Aprendizagem:</b> todos os anos desta escola utilizam livros didáticos e a ativação da licença digital
        será através do SM Aprendizagem. O e-mail com as instruções de ativação no SM Aprendizagem será enviado no
        e-mail do pedido confirmado. Caso a escola adote livro didático digital, a chave da licença será enviada com
        instruções de ativação no SM Aprendizagem.
      </p>
    </div>
  )
}
